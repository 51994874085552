import React, {PureComponent} from 'react';
import * as userActions from '../actions/user'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Errors from '../components/utils/Errors';
import {Button, Card, Form, Icon, Input, message} from 'antd';
import {FORGOT_PASSWORD_PENDING, LOGIN_PENDING} from "../actions/types";

class Login extends PureComponent {

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.forgotPasswordAction(values)
                    .then(() => {
                        message.success('Please check your email');
                        this.props.form.resetFields();
                    })
                    .catch(() => {})
                    .finally(() => {
                    });
            }
        })
    }

    render() {

        const {getFieldDecorator} = this.props.form;

        const { action, errors } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page-forgot-password'}>
                <h1>Forgot Your Password?</h1>
                <Card>
                    {errors && <Errors errors={errors} />}
                    <Form onSubmit={this.handleSubmit} colon={false} hideRequiredMark={true}>
                        <Form.Item label={'Email'}>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please input your email!' }],
                            })(
                                <Input
                                    size={'large'}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />,
                            )}
                        </Form.Item>
                        <Button type="primary" size="large" htmlType="submit" loading={type === FORGOT_PASSWORD_PENDING} className={'button primary login--button'}>
                            Send Password Reset
                        </Button>
                    </Form>
                </Card>
                <p className={'back'}><Link to={'/'}>← Back to Login</Link></p>
            </div>
        );
    }
}

const mapStateToProps = ({ user }, ownProps) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login))
