import React, {Component} from 'react';
import {Button, Card, Col, Row} from "antd";
import history from '../../helpers/history';
import {connect} from "react-redux";

class UnlockFeature extends Component {

    render() {

        const { user, children } = this.props;

        return (
            <>
                {
                    !user.active_subscription &&
                    <Row gutter={24} type="flex">
                        <Col xs={24}>
                            <Card className={'unlock-feature'}>
                                <i className="icon icon-lock" />
                                <p>Upgrade to unlock this feature.</p>
                                <Button className={'button primary'} onClick={(e) => { history.push('/upgrade') }}>Learn More</Button>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    user.active_subscription &&
                    children
                }
            </>
        )
    }

}
const mapStateToProps = ({ user }) => ({ user: user.user });

export default connect(mapStateToProps, null)(UnlockFeature);
