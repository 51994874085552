import {
    FETCH_SOCIAL_ERROR,
    FETCH_SOCIAL_PENDING,
    FETCH_SOCIAL_SUCCESS,
    UPDATE_SOCIAL_ERROR,
    UPDATE_SOCIAL_PENDING,
    UPDATE_SOCIAL_SUCCESS,
} from '../actions/types'

const defaultState = {
    list: [],
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_SOCIAL_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_SOCIAL_SUCCESS:
            return { ...state, action, list: action.payload, errors: {}, loading: false };
        case FETCH_SOCIAL_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_SOCIAL_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_SOCIAL_SUCCESS:
            return { ...state, action, list: action.payload, errors: {}, loading: false };
        case UPDATE_SOCIAL_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};
