import React, {Component} from "react";
import {Button, Card, Form } from "antd";
import {connect} from "react-redux";
import { UPDATE_SOCIAL_PENDING} from "../../../actions/types";
import * as socialActions from "../../../actions/social";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import OAuthPopup from "react-oauth-popup";
import config from '../../../config';

class Social extends Component {

    constructor(props){

        super(props);
        this.state = {
            submitting: false,
            provider: null,
        };

        this.linkedInRedirectUri = config.REACT_APP_URL ? `${config.REACT_APP_URL}/signup` : '';
        this.linkedInClientId = '78xknhv1qruuqm';
    }

    componentDidMount() {
        this.props.fetchSocial();
    }

    handleOnGoogleLink = (response) => {
        console.log(response);

        this.setState({ provider: 'google' });

        const values = {
            social_provider: 'google',
            access_token: response.accessToken,
        };

        this.props.linkSocial(values);
    }

    handleOnFacebookLink = (response) => {

        console.log(response);

        this.setState({ provider: 'facebook' });

        const values = {
            social_provider: 'facebook',
            access_token: response.accessToken,
        };

        this.props.linkSocial(values);

    }

    handleOnLinkedInLink = (code) => {

        console.log(code);

        this.setState({ provider: 'linkedin' });

        const values = {
            social_provider: 'linkedin',
            access_token: code,
        };

        this.props.linkSocial(values);

    }

    isLinked(socialProvider) {
        const { social } = this.props;

        if(social && social.length > 0) {

            const index = social.findIndex(account => {
                return account.provider_name === socialProvider;
            });

            return index > -1;

        }

        return false;

    }

    render() {
        const {
            socialAction,
        } = this.props;

        const socialType = (socialAction && socialAction.type) ? socialAction.type : null;

        return (
            <Card title={'Connected Accounts'}>
                <p>Connect your professional or personal social media accounts to make it easier to login: </p>
                {
                    this.isLinked('google') &&
                    <Button className={'button social'} disabled={true}>
                        <img src="/images/icons/google.svg" alt=""/>
                        <span>Connected</span>
                    </Button>
                }
                {
                    !this.isLinked('google') &&
                    <GoogleLogin
                        clientId="498863115396-6p53odlqc25luvjtmjrakboa71bbquah.apps.googleusercontent.com"
                        render={renderProps => (
                            <Button loading={socialType === UPDATE_SOCIAL_PENDING && this.state.provider === 'google'} className={'button social'} onClick={renderProps.onClick}>
                                <img src="/images/icons/google.svg" alt=""/>
                                <span>Connect with Google</span>
                            </Button>
                        )}
                        buttonText="Login"
                        onSuccess={this.handleOnGoogleLink}
                        onFailure={this.handleOnGoogleLink}
                        cookiePolicy={'single_host_origin'}
                    />
                }
                {
                    this.isLinked('facebook') &&
                    <Button className={'button social'}>
                        <img src="/images/icons/facebook.svg" alt=""/>
                        <span>Connected</span>
                    </Button>
                }
                {
                    !this.isLinked('facebook') &&
                    <FacebookLogin
                        appId="461558117795162"
                        callback={this.handleOnFacebookLink}
                        render={renderProps => (
                            <Button className={'button social'}  loading={socialType === UPDATE_SOCIAL_PENDING && this.state.provider === 'facebook'} onClick={renderProps.onClick}>
                                <img src="/images/icons/facebook.svg" alt=""/>
                                <span>Connect with Facebook</span>
                            </Button>
                        )}
                    />
                }
                {
                    this.isLinked('linkedin') &&
                    <Button className={'button social'}
                    >
                        <img src="/images/icons/linkedin.svg" alt=""/>
                        <span>Connected</span>
                    </Button>
                }
                {
                    !this.isLinked('linkedin') &&
                    <OAuthPopup
                        url={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.linkedInClientId}&redirect_uri=${this.linkedInRedirectUri}&scope=r_liteprofile%20r_emailaddress%20w_member_social`}
                        onCode={this.handleOnLinkedInLink}
                    >
                        <Button
                            className={'button social'}
                            loading={socialType === UPDATE_SOCIAL_PENDING && this.state.provider === 'linkedin'}
                        >
                            <img src="/images/icons/linkedin.svg" alt=""/>
                            <span>Connect with LinkedIn</span>
                        </Button>
                    </OAuthPopup>
                }
            </Card>
        );
    }

}

const mapStateToProps = ({ social }) => ({ social: social.list, socialAction: social.action });
const mapDispatchToProps = {
    ...socialActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Social));
