// Retrieve persisted state
import StorageService from "./helpers/storageService";
import rootReducer from "./reducers";
import {applyMiddleware, compose, createStore} from "redux";
import reduxThunk from "redux-thunk";

const persistedState = StorageService.get('pro.digs');

/*
// UNCOMMENT IT FOR PRODUCTION
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(rootReducer);
*/

/* COMMENT IT OUT FOR PRODUCTION */
const store = createStore(
  rootReducer,
  persistedState,
  compose(
    applyMiddleware(reduxThunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);

export default store;

