import React, {PureComponent} from 'react';
import * as userActions from '../actions/user'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Card, Form, Input, message} from 'antd';
import {RESET_PASSWORD_PENDING} from "../actions/types";
import Errors from "../components/utils/Errors";

class ResetPassword extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            password_confirmationDirty: false,
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.token = this.props.match.params.token;
                this.props.resetPassword({
                    ...values
                })
                    .then(() => {
                        message.success('Password reset successfully. Please login.');
                        this.props.form.resetFields();
                    })
                    .catch(() => {})
                    .finally(() => {});
            }
        });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords entered do not match!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.password_confirmationDirty) {
            form.validateFields(['password_confirmation'], { force: true });
        }
        callback();
    }

    render() {

        const {getFieldDecorator} = this.props.form;

        const { action, errors } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page-reset-password'}>
                <h1>Reset Your Password</h1>
                <Card>
                    {errors && <Errors errors={errors} />}
                    <Form onSubmit={this.handleSubmit} colon={false} hideRequiredMark={true}>
                        <Form.Item label={'Email'}>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, type: 'email', message: 'Please enter a valid email!' }],
                            })(<Input size="large" type="email" />)}
                        </Form.Item>
                        <Form.Item label={'New Password'}>
                            {getFieldDecorator('password', {
                                rules: [
                                    { required: true, message: 'Please enter a valid password!' },
                                    { min: 6, message: <div>Password must contain at least six characters</div> },
                                    { pattern: new RegExp(/\d/), message: <div>Password must contain at least one number</div> },
                                    { pattern: new RegExp(/[`~!@#$%^&*()_+=]/),
                                        message: <div>Password must contain at least one special character</div>
                                    },
                                    {
                                        validator: this.validateToNextPassword,
                                    }
                                ],
                            })(<Input size="large" type="password"/>)}
                        </Form.Item>
                        <Form.Item label={'Confirm New Password'}>
                            {getFieldDecorator('password_confirmation', {
                                rules: [
                                    { required: true, message: 'Please enter confirm password!' }
                                    ,
                                    {
                                        validator: this.compareToFirstPassword,
                                    }
                                ],
                            })(<Input size="large" type="password"/>)}
                        </Form.Item>
                        <Button type="primary" size="large" htmlType="submit"  loading={type === RESET_PASSWORD_PENDING} className={'button primary login--button'}>
                            Reset Password
                        </Button>
                    </Form>
                </Card>
                <p className={'back'}><Link to={'/'}>← Back to Login</Link></p>
            </div>
        );
    }
}

const mapStateToProps = ({ user }, ownProps) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ResetPassword))
