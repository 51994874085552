import React from 'react'
import {Alert} from 'antd'
import _ from 'lodash'

export default function Errors (props) {
    let keys = _.isString(props.errors) ? null : Object.keys(props.errors);
    let errors = [];

    if (keys && keys.length) {
        keys.forEach((item, i) => {

            if (Array.isArray(props.errors[item])) {
                for (let i in props.errors[item]) {
                    errors.push(<Alert key={item + i} message={props.errors[item][i]}
                                       type='error'
                                       closable={Object.prototype.hasOwnProperty.call(props, 'closable') ? props.closable : true} />)
                }
            } else {
                errors.push(<Alert key={i} message={props.errors[item]}
                                   type='error'
                                   closable />)
            }
        })
    } else if (_.isString(props.errors)) {
        errors.push(<Alert key={1} message={props.errors}
                           type='error'
                           closable={Object.prototype.hasOwnProperty.call(props, 'closable') ? props.closable : true} />)
    }
    return (
        <div className='custom-errors-component'>
            {errors}
        </div>
    )
}
