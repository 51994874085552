import {
    FETCH_INTEGRATIONS_ERROR,
    FETCH_INTEGRATIONS_PENDING,
    FETCH_INTEGRATIONS_SUCCESS,
    FUB_CONFIG_ERROR,
    FUB_CONFIG_PENDING,
    FUB_CONNECT_ERROR,
    FUB_CONNECT_PENDING,
    FUB_DISCONNECT_ERROR,
    FUB_DISCONNECT_PENDING,
    FUB_FETCH_ERROR,
    FUB_FETCH_PENDING,
    FUB_SYNC_ERROR,
    FUB_SYNC_PENDING,
    FUB_SYNC_SUCCESS,
    SALESFORCE_CONFIG_ERROR,
    SALESFORCE_CONFIG_PENDING,
    SALESFORCE_CONNECT_ERROR,
    SALESFORCE_CONNECT_PENDING,
    SALESFORCE_DISCONNECT_ERROR,
    SALESFORCE_DISCONNECT_PENDING,
    SALESFORCE_FETCH_ERROR,
    SALESFORCE_FETCH_PENDING,
    SALESFORCE_SYNC_ERROR,
    SALESFORCE_SYNC_PENDING,
    SALESFORCE_SYNC_SUCCESS,
} from '../actions/types'

const defaultState = {
    integrations: {},
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_INTEGRATIONS_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_INTEGRATIONS_SUCCESS:
            state.integrations = {};
            if(action.payload.length > 0) {
                action.payload.forEach((integration) => {
                    state.integrations[integration.service] = integration;
                });
            }
            return { ...state, action, errors: {}, loading: false };
        case FETCH_INTEGRATIONS_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SALESFORCE_CONNECT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case SALESFORCE_CONNECT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SALESFORCE_DISCONNECT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case SALESFORCE_DISCONNECT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SALESFORCE_SYNC_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case SALESFORCE_SYNC_SUCCESS:
            return { ...state, action, loading: true, errors: {} };
        case SALESFORCE_SYNC_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SALESFORCE_FETCH_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case SALESFORCE_FETCH_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SALESFORCE_CONFIG_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case SALESFORCE_CONFIG_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FUB_CONNECT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FUB_CONNECT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FUB_DISCONNECT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FUB_DISCONNECT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FUB_SYNC_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FUB_SYNC_SUCCESS:
            return { ...state, action, loading: true, errors: {} };
        case FUB_SYNC_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FUB_FETCH_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FUB_FETCH_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FUB_CONFIG_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FUB_CONFIG_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};

export const integrationsSelector = state => Object.keys(state.integrations.integrations).map(key => state.integrations.integrations[key]);

export const integrationByService = (state, service) => state.integrations.integrations[service];
