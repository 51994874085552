import React, {PureComponent} from 'react';
import {Button, Card, Col, Collapse, Divider, Form, Icon, Input, Modal, Row, Switch, message, Select, Spin} from 'antd';
import {connect} from 'react-redux';
import * as integrationsActions from "../../../actions/integrations";
import history from "../../../helpers/history";
import {integrationByService} from '../../../reducers/integrations';
import GenericInactive from "../../../components/integrations/GenericInactive";

import {
    FUB_CONFIG_PENDING,
    FUB_CONNECT_PENDING,
    FUB_DISCONNECT_PENDING,
    FUB_SYNC_PENDING
} from "../../../actions/types";
import isEqual from "lodash/isEqual";

const Option = Select.Option;

const DEFAULT_MAPPING = {
    firstname: 'firstName',
    lastname: 'lastName',
    email: 'email',
    mobile: 'mobile',
};

class FollowUpBoss extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            /* loading */
            apiKeyModal: false,
            validatingKey: false,
            /* Mapping */
            historicalMapping: null,
            fubMapping: DEFAULT_MAPPING,
            /* Active Toggle */
            historicalIsActive: false,
            isActive: false,
            /* Custom Fields */
            fubFields: [
                {
                    name: 'firstName',
                    label: 'First Name',
                },
                {
                    name: 'lastName',
                    label: 'Last Name',
                },
                {
                    name: 'email',
                    label: 'Email',
                },
                {
                    name: 'mobile',
                    label: 'Mobile'
                },
                {
                    name: 'property.code',
                    label: 'Zip Code (Property)',
                },
                {
                    name: 'price',
                    label: 'Price (Person)',
                },
                {
                    name: 'property.price',
                    label: 'Price (Property)',
                },
                {
                    name: 'property.bedrooms',
                    label: 'Bedrooms (Property)',
                },
                {
                    name: 'property.bathrooms',
                    label: 'Bathrooms (Property)',
                },
            ],
            fubVersioning: 0,
        };

        this.mappableFields = [
            {
                'text': 'First Name',
                'value': 'firstname',
                'type': 'string',
                'locked': true,
            },
            {
                'text': 'Last Name',
                'value': 'lastname',
                'type': 'string',
                'locked': true,
            },
            {
                'text': 'Email',
                'value': 'email',
                'type': 'string',
                'locked': true,
            },
            {
                'text': 'Phone',
                'value': 'mobile',
                'type': 'string',
                'locked': true,
            },
            {
                'text': 'Zip Code',
                'value': 'zip_code',
                'type': 'string',
            },
            {
                'text': 'Est. Purchase Price',
                'value': 'purchase_price',
                'type': 'number',
            },
            {
                'text': 'Est. Purchase Date',
                'value': 'purchase_date',
                'type': 'date',
            },
            {
                'text': 'Home Use',
                'value': 'property_use',
                'type': 'string',
            },
            {
                'text': 'Home Type',
                'value': 'property_type',
                'type': 'string',
            },
            {
                'text': 'Home Variant',
                'value': 'property_variant',
                'type': 'string',
            },
            {
                'text': 'Bedrooms',
                'value': 'bedrooms',
                'type': 'number',
            },
            {
                'text': 'Bathrooms',
                'value': 'bathrooms',
                'type': 'number',
            },
            {
                'text': 'Has Linked Accounts?',
                'value': 'savings_method_count',
                'type': 'boolean',
            },
            {
                'text': 'Birthday',
                'value': 'birthday',
                'type': 'date',
            },
            {
                'text': 'Monthly Rent',
                'value': 'current_rent',
                'type': 'number'
            },
            {
                'text': 'Combined Annual Income',
                'value': 'combined_income',
                'type': 'number'
            },
            {
                'text': 'Marital Status',
                'value': 'marital_status',
                'type': 'boolean'
            },
            {
                'text': 'Military Status',
                'value': 'marital_status',
                'type': 'boolean'
            }
        ];

    }

    componentDidMount() {

        this.setState({ loading: true });

        this.props.fetchIntegrations()
            .then(() => {
                const { followupboss } = this.props;
                if(followupboss) {
                    this.setData();
                    this.fetchFUBFields()
                        .then(() => {
                            this.setState({
                                loading: false,
                            });
                        });
                } else {
                    this.setState({ loading: false });
                }
            });
    }

    /* Followup Boss API */

    onFUBConnect = () => {

        this.props.form.validateFields((err, fields) => {
            if (err) return;
            this.props.connectFUB(fields.apiKey, this.state.fubMapping)
                .then(() => {

                    message.success('Follow Up Boss successfully connected!');

                    this.setData();

                });
        });

    };

    onFUBDisconnect() {
        this.props.disconnectFUB();
    }

    onFUBSaveConfig() {

        const { fubMapping, isActive } = this.state;

        this.setState({ saving: true });

        this.props.saveFUBConfig({ config: fubMapping, is_active: isActive })
            .then(() => {

                this.setData();
                this.setState({ saving: false });

            });
    }

    onFUBSync = () => {
        this.props.syncFUB()
            .then(() => {
                message.success('Sync successfully initiated.');
            });
    };

    fetchFUBFields = async () => {

        const { followupboss } = this.props;

        return new Promise((resolve, reject) => {
            if(followupboss) {

                this.setState({
                    loading: true,
                });

                this.props.fetchFUB()
                    .then(({ data }) => {

                        let items = [];

                        for (let property in data) {
                            if(data.hasOwnProperty(property)) {
                                items.push(property);
                            }
                        }

                        if(followupboss && followupboss.config) {
                            this.setState({
                                fubMapping: followupboss.config,
                            });
                        }

                        this.setState({
                            fubFields: this.state.fubFields.concat(data),
                            loading: false,
                        })
                    })
                    .finally(() => {
                        resolve();
                    });

            }
        });

    };

    /* Set Data */
    setData = () => {

        const { followupboss } = this.props;

        if(followupboss.config) {
            this.setState({
                historicalIsActive: followupboss.is_active,
                isActive: followupboss.is_active,
                historicalMapping: followupboss.config,
                fubMapping: followupboss.config
            });
        }

    };

    /* Utilities */

    generateFieldMapTable() {

        return this.mappableFields.map(field => {

            return (
                <div className="module--table-row" key={`${field.text}`}>
                    <div className="module--table-column">
                        <div className={'fields-column'}>
                            <span className={'field-title'}>{ field.text }</span>
                            <span className={'field-type'}>type: { field.type }</span>
                        </div>
                    </div>
                    <div className="module--table-column">
                        <div className={'fields-column'}>
                            {
                                this.generateObjectFields(field.value)
                            }
                        </div>
                    </div>
                </div>
            )

        });

    }

    generateObjectFields(fieldName) {

        let defaultValue = 'Loading...';

        const mappedValue = this.state.fubMapping[fieldName];
        const fieldProperties = this.mappableFields.find(fields => fields.value === fieldName);

        const items = this.state.fubFields.map((option, i) => {

            let disabled = false;

            if(this.state.fubMapping) {
                for (let property in this.state.fubMapping) {
                    if(this.state.fubMapping.hasOwnProperty(property)) {
                        if(property !== fieldName) {
                            if (option.name === this.state.fubMapping[property]) {
                                disabled = true;
                            }
                        }
                    }
                }
            }

            return (
                <Option value={option.name} key={`${i}-${disabled}`} disabled={disabled}>{option.label}</Option>
            )
        });

        if(mappedValue) {
            defaultValue = mappedValue;
        } else {
            defaultValue = 'Please select...';
        }

        return (
            <Select
                key={`${this.state.fubVersioning}-${fieldName}-${defaultValue}-${mappedValue}`}
                loading={this.state.loading}
                defaultValue={defaultValue}
                disabled={!!fieldProperties.locked}
                onChange={(val) => { this.updateObjectField(fieldName, val)}}
            >
                {items.length > 0 && items}
            </Select>
        )

    }

    updateObjectField(fieldName, val) {

        const { fubMapping, fubVersioning } = this.state;

        this.setState({
            fubVersioning: fubVersioning + 1,
            fubMapping: {...fubMapping, [fieldName]: val},
        });

    }

    discardChanges = () => {

        this.setState({
            isActive: !!this.state.historicalIsActive,
            fubMapping: { ...this.state.historicalMapping },
        })

    };

    hasChanged = () => {
        const { historicalIsActive, isActive, fubMapping, historicalMapping} = this.state;

        return !(isEqual(historicalIsActive, isActive) && isEqual(historicalMapping, fubMapping));
    };

    render() {

        const followupboss = this.props.followupboss;

        const { action, form } = this.props;
        const { isActive, loading } = this.state;
        const type = (action && action.type) ? action.type : null;
        const { getFieldDecorator } = form;
        const hasChanged = this.hasChanged();

        const data = {
            text: 'Connect to your Follow Up Boss account to sync prospects and collect more data about the people in your funnel.',
            list: [
                'Push leads to Follow Up Boss',
                'Sync existing contacts',
                'Update fields'
            ],
            image: '/images/integrations/fub-digs-logo.png',
            button: <Button className={'button primary'} onClick={(e) => { this.setState({ apiKeyModal: true }) }}>Connect to Follow Up Boss</Button>
        };

        return (
            <div className={"page page-integrations page-integrations-followupboss"}>
                <Row gutter={24}>
                    <Col xs={24} xl={16}>
                        <div className={'title-module with-back'}>
                            <div className={'title-module--container'}>
                                <Icon type="arrow-left" style={{ fontSize: '18px'}} onClick={() => { history.push('/my-app/integrations') }}/>
                                <h1>Follow Up Boss</h1>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                {
                    loading &&
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '100px'}}>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                    </div>
                }
                {
                    !loading &&
                    <div className={'followup-module'}>
                        {
                            !followupboss &&
                            <Row>
                                <Col xs={24} className={'column-max-width'}>
                                    <GenericInactive data={data} />
                                </Col>
                                <Modal
                                    title="Let's get started!"
                                    visible={this.state.apiKeyModal}
                                    onOk={this.onFUBConnect}
                                    onCancel={() => { this.setState({ apiKeyModal: false }) }}
                                    footer={[
                                        <Button key="back" onClick={() => { this.setState({ apiKeyModal: false }) }}>
                                            Cancel
                                        </Button>,
                                        <Button key="submit" loading={type === FUB_CONNECT_PENDING } type="primary" onClick={this.onFUBConnect}>
                                            Validate API Key
                                        </Button>,
                                    ]}
                                >
                                    <p>In order to automatically sync your Digs Savers with Follow Up Boss, we'll need an API key to use. To find yours, go <a href={'https://app.followupboss.com/2/api'} target={'_blank'}>here</a> and copy/paste the key provided in the form below:</p>
                                    <Form>
                                        <Form.Item>
                                            {
                                                getFieldDecorator('apiKey', {
                                                    rules: [{ required: true, message: 'Please enter a valid API key' }],
                                                })(
                                                    <Input />
                                                )
                                            }
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </Row>
                        }
                        {
                            followupboss &&
                            <Form>
                                <Row gutter={24}>
                                    <Col xs={24} xl={16}>
                                        <
                                            Card
                                            title={
                                                <div className={'card--header'}>
                                                    <span>Integration Details</span>
                                                    <span className={'switch'}>
                                                {
                                                    isActive ? 'Auto-Sync Enabled' : 'Auto-Sync Disabled'
                                                }
                                                        {
                                                            getFieldDecorator('active', {
                                                                onChange: (val) => { this.setState({ isActive: val }) },
                                                                initialValue: isActive,
                                                            })(<Switch checked={isActive} />)
                                                        }
                                            </span>
                                                </div>
                                            }
                                            bordered={false}
                                        >
                                            <div className="module--table">
                                                <div className="module--table-row module--table-header">
                                                    <div className="module--table-column">
                                                        <span>Digs Fields</span>
                                                    </div>
                                                    <div className="module--table-column">
                                                        <span>Follow Up Boss Fields</span>
                                                    </div>
                                                </div>
                                                {
                                                    this.generateFieldMapTable().slice(0, 4)
                                                }
                                                <Collapse>
                                                    <Collapse.Panel header={'See All Fields'} key={1}>
                                                        {
                                                            this.generateFieldMapTable().slice(4, this.generateFieldMapTable().length - 1)
                                                        }
                                                    </Collapse.Panel>
                                                </Collapse>
                                            </div>
                                            <div className={'module--buttons split'}>
                                                <Button loading={ type === FUB_DISCONNECT_PENDING } onClick={() => { this.onFUBDisconnect() }}>Disconnect Follow Up Boss</Button>
                                                {
                                                    !hasChanged && !loading &&
                                                    <Button loading={ type === FUB_SYNC_PENDING } onClick={() => { this.onFUBSync() }} type={'primary'} >One-Time Sync</Button>
                                                }
                                                {
                                                    hasChanged && !loading &&
                                                    <div>
                                                        {
                                                            type !== FUB_CONFIG_PENDING &&
                                                            <Button onClick={() => { this.discardChanges() }} style={{ marginRight: 10}}>Discard Changes</Button>
                                                        }
                                                        <Button loading={ type === FUB_CONFIG_PENDING } onClick={() => { this.onFUBSaveConfig() }} type={'primary'}>Save Configuration</Button>
                                                    </div>
                                                }
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <
                                            Card
                                            title={'Directions'}
                                            bordered={false}
                                        >
                                            <div className="card--inner">
                                                <p>
                                                    To have complete control of where (and how) your Digs Savers data gets synced within your organization:
                                                </p>
                                                <ul>
                                                    <li>Change where your Savers are synced by selecting the appropriate <b>User Model</b> located in the first row.</li>
                                                    <li>Match every data point in Digs with the <b>Follow Up Boss Fields</b> of your choosing with the remaining dropdowns.</li>
                                                    <li>Remember to <b>Save & Synchronize</b> your changes when you are satisfied with your selections using the button at the bottom of the page..</li>
                                                </ul>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    followupboss: integrationByService(state, 'followupboss'),
    action: state.integrations.action
});

const mapDispatchToProps = {
    ...integrationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FollowUpBoss));
