import React from 'react';
import {Input} from 'antd';
import Sketch from './CustomSketchPicker/Sketch';

class ColorInputField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayColorPicker: false,
            color: props.value,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value.match(/^#[a-f0-9]{6}$/i) !== null && this.state.color !== this.props.value) {
            this.setState({ color: this.props.value })
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.props.onChange(color.hex);
    };

    renderColorPicker() {
        return (
            <div className="color-input-field-picker">
                <div className="swatch" onClick={ this.handleClick }>
                    <div className="color" style={{background: this.state.color }}/>
                    <div className="text">Select Color</div>
                </div>
                { this.state.displayColorPicker ? <div className="popover">
                    <div className="cover" onClick={ this.handleClose }/>
                    <Sketch disableAlpha={true} color={this.state.color} onChange={ this.handleChange } />
                </div> : null }
            </div>
        )
    };

    render() {
        return (
            <div className="color-input-field">
                <Input addonBefore={this.renderColorPicker()} {...this.props}/>
            </div>
        );
    }
}

export default ColorInputField;
