import React, {Component} from 'react'
import {Button, Card, Col, Divider, Icon, message, Row, Spin, Input, Modal} from 'antd';
import {ReactSortable} from "react-sortablejs";
import uniqueId from "lodash/uniqueId";
import {connect} from "react-redux";
import isEqual from 'lodash/isEqual';
import { feedOrderedSelector, feedCategorySelector } from '../../reducers/feed';
import FeedItem from "../../components/feed/FeedItem";
import client from "../../actions";

import {
    createFeedItem,
    deleteFeedItem,
    fetchFeed,
    resetFeed,
    updateFeedItem,
    updateFeedOrder,
    uploadFeedItemMedia,
} from "../../actions/feed";
import CategoryModal from "../../components/feed/CategoryModal";

const { Search } = Input;

const defaultFeedItem = {
    published: false,
    url: '',
    title: '',
    image_url: '',
};

class ContentFeed extends Component {

    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            fetching: false,
            resetting: false,
            items: {},
            historicalOrder: [],
            activeItems: [],
            inactiveItems: [],
            itemsLoading: [],
            itemsOpen: null,
            categories: [],
            categoryModal: {
                visible: false,
                category: null,
                articleCount: null
            }
        };

    }

    componentDidMount() {
        this.fetchAndProcessItems();
    }

    fetchAndProcessItems() {

        this.setState({loading: true});

        this.props.fetchFeed()
            .then(() => {
                this.processItems();
            });

    };

    processItems() {
        const active = this.props.feed.filter((m) => m.published).map(m => ({ id: m.id}));
        const inactive = this.props.feed.filter((m) => !m.published).map(m => ({ id: m.id }));
        const items = {};

        for (let x = 0; x < this.props.feed.length; x++) {

            this[`${this.props.feed[x].id}_ref`] = React.createRef();

            items[this.props.feed[x].id] = {
                published: {
                    ...this.props.feed[x]
                }
            };
        }

        this.setState({
            historicalOrder: active,
            activeItems: active,
            inactiveItems: inactive,
            items: items,
            loading: false,
        });

    };

    /* Ordering */

    updateActiveList = (updatedList) => {
        this.setState({activeItems: updatedList});
    };

    updateInactiveList = (updatedList) => {
        this.setState({inactiveItems: updatedList});
    };

    hasOrderChanged = () => {

        const historicalOrder = this.state.historicalOrder.map(o => o.id);
        const activeOrder = this.state.activeItems.map(o => o.id);
        return !isEqual(historicalOrder, activeOrder);

    };

    createCustomItem = (url) => {

        this.setState({ itemsOpen: true, fetching: true });

        client.get(`/provider/preview?url=${url}`)
            .then(({ data: { preview } }) => {

                    const items = this.state.items;

                    const customId = `custom-${uniqueId()}`;

                    items[customId] = {
                        id: customId,
                        draft: {
                            id: customId,
                            ...defaultFeedItem,
                            title: preview.title ? preview.title : `Custom Content ${this.state.inactiveItems.length + 1}`,
                            url: url,
                            image_url: preview.primary_image ? preview.primary_image : undefined
                        }
                    };

                    this.setState({
                        fetching: false,
                        items: items,
                        inactiveItems: [...this.state.inactiveItems, { id: customId}],
                        itemsOpen: customId
                    }, () => {
                        this.scrollTo();
                    });

            })
            .catch((err) => {
                this.setState({ fetching: false, itemsOpen: null });
            });

    };

    /* Prop functions */

    updateItemOrder = () => {
        message.loading('Saving content order...', 0);
        const feedOrder = this.state.activeItems.map(m => m.id);

        this.props.updateFeedOrder(feedOrder)
            .then(() => {
                this.processItems();
                message.destroy();
                message.success('Content updated successfully.');
            })
            .catch(() => {
                message.destroy();
                message.error('An error occurred.');
            });
    };

    onItemEdit = (itemId, payload) => {

        const items = this.state.items;
        let updatedItem = items[itemId];

        const updates = {};

        for (let key in payload) {
            if (payload.hasOwnProperty(key)) {
                updates[payload[key].name] = payload[key].value;
            }
        }

        if(updatedItem.draft) {
            updatedItem = { ...updatedItem, draft: { ...updatedItem.draft, ...updates}}
        } else {
            updatedItem = { ...updatedItem, draft: { ...updatedItem.published, ...updates}}
        }

        items[itemId] = updatedItem;

        this.setState({
            items: items
        });

    };

    onItemSave = (itemId, toDraft = false) => {

        const items = this.state.items;
        let updatedItem = items[itemId];

        this.setState({itemsLoading: [...this.state.itemsLoading, itemId]});
        message.loading('Saving feed item(s)...', 0);

        if(updatedItem.draft) {
            updatedItem = { ...updatedItem, published: { ...updatedItem.draft }, draft: undefined}
        }

        if(isNaN(updatedItem.published.id)) {

            this.props.createFeedItem(updatedItem.published)
                .then(() => {
                    // Display messages
                    this.processItems();
                    message.destroy();
                    message.success('Feed item(s) saved successfully.');

                    this.setState({
                        itemsOpen: null,
                        itemsLoading: [...this.state.itemsLoading.filter(item => item !== itemId)],
                    });

                })
                .catch(() => {
                    // Display messages
                    message.destroy();
                    message.error('An error occurred.');

                    this.setState({
                        itemsLoading: [...this.state.itemsLoading.filter(item => item !== itemId)],
                    });

                });

        } else {

            this.props.updateFeedItem({
                ...updatedItem.published,
                published: toDraft ? false : updatedItem.published.published,
                order: toDraft ? null : updatedItem.published.order,
            })
                .then(() => {
                    // Display messages
                    this.processItems();
                    message.destroy();
                    message.success('Feed item(s) saved successfully.');

                    this.setState({
                        itemsLoading: [...this.state.itemsLoading.filter(item => item !== itemId)],
                        itemsOpen: toDraft ? null : this.state.itemsOpen,
                    });

                })
                .catch(() => {
                    // Display messages
                    message.destroy();
                    message.error('An error occurred.');

                    this.setState({
                        itemsLoading: [...this.state.itemsLoading.filter(item => item !== itemId)],
                    });

                });

        }

    };

    onItemDelete = (itemId) => {

        if(isNaN(itemId)) {

            this.setState(({
                inactiveItems: [...this.state.inactiveItems.filter(m => m.id !== itemId)],
                itemsOpen: null,
            }));

        } else {

            message.loading('Deleting feed item...', 0);

            this.props.deleteFeedItem(itemId)
                .then(() => {
                    message.destroy();
                    message.success('Feed item deleted successfully.');
                    this.processItems();
                    this.setState(({
                        inactiveItems: [...this.state.inactiveItems.filter(m => m.id !== itemId)],
                        itemsOpen: null,
                    }));
                })
                .catch(() => {
                    message.destroy();
                    message.error('An error occurred.');
                });

        }
    };

    onItemCancel = (itemId, callback) => {

        const items = this.state.items;

        items[itemId] = {...items[itemId], draft: null};

        this.setState({
            items: items
        }, callback);

    };

    onItemToggleOpen = (itemId) => {
        if(this.state.itemsOpen === itemId) {
            this.setState({
                itemsOpen: null,
            })
        } else {
            this.setState({
                itemsOpen: itemId
            });
            this.scrollTo(itemId);
        }
    };

    onMoveItemToDraft = (itemId) => {

        this.setState(({
            activeItems: [...this.state.activeItems.filter(m => m.id !== itemId)],
            inactiveItems: [...this.state.inactiveItems, { id: itemId }],
            itemsOpen: null,
        }));

    };

    /* Milestone Specific States */

    isDefault = () => {
        const defaultItems = this.props.feed.filter(m => isNaN(m.id) && m.id.indexOf('default') > -1);
        return defaultItems.length > 0;
    };

    isItemLoading = (itemId) => {
        return this.state.itemsLoading.includes(itemId);
    };

    isItemOpen = (itemId) => {
        return this.state.itemsOpen === itemId;
    };

    /* Scroll Effect */
    getOffsetTop = (element) => {
        let offsetTop = 0;
        while(element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    };

    scrollTo = (itemId) => {

        if(itemId) {

            setTimeout(() => {

                window.scrollTo({
                    top: this.getOffsetTop(this[`${itemId}_ref`].current) - 70,
                    behavior: 'smooth',
                });

            }, 100);

        } else {

            setTimeout(() => {

                const body = document.body,
                    html = document.documentElement;

                const height = Math.max( body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight );

                window.scrollTo({
                    top: height,
                    behavior: 'smooth',
                })
            }, 100);

        }

    };

    /* Category Panel */
    getCategoryCount(categoryId) {
        const { feed } = this.props;
        return feed.filter(f => f.category_id === categoryId).length;
    }

    openCategoryModal = (category = null) => {

        if(category) {
            this.setState({ categoryModal: { visible: true, category: category, articleCount: this.getCategoryCount(category.id)} })
        } else {
            this.setState({ categoryModal: { visible: true, category: null, articleCount: null} })
        }

    };

    closeCategoryModal = () => {
        this.processItems();
        this.setState({ categoryModal: { visible: false, category: null} })
    };

    render () {

        const { user, categories } = this.props;
        const { loading, items, activeItems, inactiveItems, fetching, categoryModal } = this.state;
        const isEditable = (user.is_parent_provider || (!user.is_parent_provider && !user.provider_id)) && user.active_subscription;

        return (
            <div className="page page-my-app">
                <Row>
                    <Col xs={24} xl={16} className={'column-max-width'}>
                        <div className={'title-module'}>
                            <h1><Icon type="read" /> Content Feed</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <Row gutter={24} className={'page-content-feed'}>
                    {
                        loading &&
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '100px'}}>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                        </div>
                    }
                    {
                        !loading &&
                        <>
                            <Col xs={24} xl={16}>
                                <div>
                                    {
                                        isEditable &&
                                        <div className="button-row">
                                            <div className={`alert ${!this.hasOrderChanged() ? 'disabled' : ''}`}>
                                                <Button type="primary" disabled={!this.hasOrderChanged()} onClick={this.updateItemOrder}>
                                                    Publish Changes
                                                </Button>
                                            </div>
                                            <div className={'add-content'}>
                                                <Search
                                                    loading={fetching}
                                                    disabled={!!(this.hasOrderChanged() || this.state.itemsOpen)}
                                                    placeholder={'Paste your article link here'}
                                                    enterButton={<span><Icon type="plus" /> Add Content</span>}
                                                    onSearch={this.createCustomItem}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <ReactSortable
                                            group="items"
                                            className={`item--list ${this.state.itemsOpen ? 'drag-disabled' : ''}`}
                                            tag="ul"
                                            sort={!this.state.itemsOpen && isEditable}
                                            handle=".icon-drag-grid"
                                            list={activeItems}
                                            setList={this.updateActiveList}
                                        >
                                            {
                                                activeItems.map((m) => {

                                                    return (
                                                        <li
                                                            key={m.id}
                                                            id={`item-${m.id}`}
                                                            className={!this.isItemOpen(m.id) && this.state.itemsOpen ? 'disabled' : ''}
                                                            ref={this[`${m.id}_ref`]}
                                                        >
                                                            <FeedItem
                                                                { ...items[m.id]}
                                                                editable={isEditable}
                                                                locked={this.hasOrderChanged()}
                                                                loading={this.isItemLoading(m.id)}
                                                                open={this.isItemOpen(m.id)}
                                                                onToggleOpen={this.onItemToggleOpen}
                                                                onDelete={this.onItemDelete}
                                                                onUpload={this.props.uploadMilestoneMedia}
                                                                onSave={this.onItemSave}
                                                                onCancel={this.onItemCancel}
                                                                onChange={this.onItemEdit}
                                                                onMoveToDraft={this.onMoveItemToDraft}
                                                                categories={categories}
                                                            />
                                                        </li>
                                                    )

                                                })
                                            }
                                        </ReactSortable>
                                    </div>
                                    <div>
                                        <h3>Drafts</h3>
                                        <ReactSortable
                                            group="items"
                                            className={'item--list'}
                                            tag="ul"
                                            sort={!this.state.itemsOpen && isEditable}
                                            handle=".icon-drag-grid"
                                            list={inactiveItems}
                                            setList={this.updateInactiveList}
                                        >
                                            {
                                                inactiveItems.map((m) => {

                                                    return (
                                                        <li
                                                            key={m.id}
                                                            id={`item-${m.id}`}
                                                            className={!this.isItemOpen(m.id) && this.state.itemsOpen ? 'disabled' : ''}
                                                            ref={this[`${m.id}_ref`]}
                                                        >
                                                            <FeedItem
                                                                { ...items[m.id]}
                                                                editable={isEditable}
                                                                subscribed={user.active_subscription}
                                                                locked={this.hasOrderChanged()}
                                                                loading={this.isItemLoading(m.id)}
                                                                open={this.isItemOpen(m.id)}
                                                                onToggleOpen={this.onItemToggleOpen}
                                                                onDelete={this.onItemDelete}
                                                                onUpload={this.props.uploadMilestoneMedia}
                                                                onSave={this.onItemSave}
                                                                onCancel={this.onItemCancel}
                                                                onChange={this.onItemEdit}
                                                                onMoveToDraft={this.onMoveItemToDraft}
                                                                categories={categories}
                                                            />
                                                        </li>
                                                    )

                                                })
                                            }
                                        </ReactSortable>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Card
                                    title={'About Content Feed'}
                                    bordered={false}
                                >
                                    <div className="card--inner">
                                        <p>Add content by copying and pasting a link from your blog or any article online. <b><i>Please make sure you own the rights to the content before adding.</i></b></p>
                                    </div>
                                </Card>
                                <Card
                                    title={'Categories'}
                                    bordered={false}
                                >
                                    <div className="card--inner">
                                        <ul>
                                            {
                                                isEditable &&
                                                categories.length > 0 &&
                                                categories.map((c) =>  <li key={c.id}>
                                                    <span>{c.name} ({this.getCategoryCount(c.id)})</span><button onClick={() => { this.openCategoryModal(c)}}><Icon type="setting" theme="filled" />Edit</button>
                                                </li>)
                                            }
                                            {
                                                !isEditable &&
                                                categories.length > 0 &&
                                                categories.map((c) =>  <li key={c.id}>
                                                    <span>{c.name} ({this.getCategoryCount(c.id)})</span>
                                                </li>)
                                            }
                                            <li><span>Uncategorized ({this.getCategoryCount(null)})</span></li>
                                        </ul>
                                        {
                                            isEditable &&
                                            <button onClick={() => { this.openCategoryModal()}}>+ Add New Category</button>
                                        }
                                    </div>
                                    <CategoryModal
                                        visible={categoryModal.visible}
                                        category={categoryModal.category}
                                        articleCount={categoryModal.articleCount}
                                        onCancel={this.closeCategoryModal}
                                    />
                                </Card>
                            </Col>
                        </>
                    }
                </Row>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    feed: feedOrderedSelector(state),
    categories: feedCategorySelector(state)

});

const mapDispatchToProps = {
    fetchFeed,
    createFeedItem,
    updateFeedItem,
    deleteFeedItem,
    uploadFeedItemMedia,
    updateFeedOrder,
    resetFeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentFeed)
