import React, {Component} from 'react'
import {Button, Form, Icon, Input, Upload, Select} from 'antd';
import isEqual from 'lodash/isEqual';
import {PropTypes} from "prop-types";
import JSONTree from "react-json-tree";

class FeedItem extends Component {

    static propTypes = {
        title: PropTypes.string,
    };

    constructor (props) {
        super(props);

        this.state = {
            open: false,
            customImageFileSrc: null,
            customImageUrl: !!(props.published && props.published.image_url) ? props.published.image_url : null,
            uploading: false,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(
            prevProps.categories &&
            this.props.categories &&
            (prevProps.categories.length !== this.props.categories.length)) {

            this.props.form.resetFields('category_id');

        }

    }

    onSave = (isDraft = false) => {

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSave(this.props.published ? this.props.published.id : this.props.draft.id, isDraft);
            }
        });

    };

    onCancel = () => {
        const { published } = this.props;
        this.props.onCancel(this.props.published.id, () => {
            this.setState({
                customImageFileSrc: null,
                customImageUrl: !!(this.props.published && this.props.published.image_url) ? this.props.published.image_url : null,
            });
            this.props.form.setFieldsValue(published);
        });
    };

    onUpload(file, onSuccess) {

        const milestoneId = this.props.published ? this.props.published.id : this.props.draft.id;
        const reader = new window.FileReader();

        this.setState({uploading: true});

        reader.readAsDataURL(file);

        reader.onload = (...args) => {

            const formData = new FormData();

            formData.append('file', file);

            this.props.onUpload(milestoneId, formData)
                .then((media) => {
                    this.setState({customImageUrl: media});
                })
                .finally(() => {
                    this.setState({uploading: false});
                    onSuccess('done', file);
                });
        }
    };

    onPreview = (file) => {

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            this.setState({
                customImageFileSrc: reader.result,
            });
            this.props.form.setFieldsValue({
                file: reader.result,
                image_url: null,
            });
        });

        reader.readAsDataURL(file);

        return false;
    };

    generateCategorySelect = () => {
        const { categories } = this.props;

        return (
            <Select>
                {
                    categories.map((c) => (
                        <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
                    ))
                }
            </Select>
        )
    };

    render () {

        const {
            editable,
            subscribed,
            loading,
            locked,
            open,
            onMoveToDraft,
            onToggleOpen,
            onDelete,
        } = this.props;

        const {
            id,
            url,
            published,
            title,
            image_url,
            category_id
        } = this.props.draft ? this.props.draft : this.props.published;

        const { getFieldDecorator } = this.props.form;

        const hasChanged = this.props.draft ? !isEqual(this.props.draft, this.props.published) : false;

        return (
            <div className={`feed-item ${open ? 'open' : ''} ${this.props.draft && hasChanged ? 'changed' : ''} ${!editable ? 'locked' : ''}`}>
                <div className="feed-item__heading">
                    <div className="feed-item__heading--left">
                        {
                            editable &&
                            <i className={'feed-item__heading--handle icon-drag-grid'}></i>
                        }
                        <div className="feed-item--title">{ title }<span>{`${!published ? ' - Draft' : ''}`}</span></div>
                    </div>
                    <div className="feed-item__heading--right">
                        <button className={`feed-item__heading--actions black ${((this.props.draft && hasChanged) || locked) ? 'disabled' : ''}`} onClick={() => { onToggleOpen(id) }}>
                            {
                                !editable &&
                                <>
                                    <Icon type="eye" /> View
                                </>
                            }
                            {
                                editable &&
                                <>
                                    <Icon type="setting" theme="filled" /> Edit
                                </>
                            }
                        </button>
                    </div>
                </div>
                {
                    open &&
                    <div className={'feed-item__inner'}>
                        <div className={`feed-item__status ${this.props.draft && hasChanged && editable ? 'active' : ''} ${!editable ? 'locked' : ''}`}>
                            <div className="feed-item__status--inner">
                                {
                                    this.props.draft && hasChanged && editable &&
                                    <>
                                        <div className={'text'}>This content has unsaved changes.</div>
                                        <div>
                                            {
                                                this.props.published &&
                                                <Button type="default" onClick={() => { this.onCancel() }}>
                                                    Discard Changes
                                                </Button>
                                            }
                                            <Button type="primary" loading={loading} className="update" onClick={() => { this.onSave() }}>
                                                { !this.props.published ? 'Save Draft' : 'Publish Changes'}
                                            </Button>
                                        </div>
                                    </>
                                }
                                {
                                    !editable && subscribed &&
                                    <>
                                        <div className={'text'}><span>You are in read only mode.</span> Only your system admin can edit the content feed.</div>
                                    </>
                                }
                                {
                                    !editable && !subscribed &&
                                    <>
                                        <div className={'text'}><span>You are in read only mode.</span> Upgrade your account to edit.</div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="feed-item__body">
                            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                                <Form.Item label="URL">
                                    {getFieldDecorator('url', {
                                        initialValue: url,
                                    })(<Input disabled={true}/>)}
                                </Form.Item>
                                <Form.Item label="Title">
                                    {getFieldDecorator('title', {
                                        rules: [{ required: true, message: 'Please add a title.' }],
                                        initialValue: title,
                                    })(<Input disabled={!editable}/>)}
                                </Form.Item>
                                <Form.Item label="Image">
                                    {
                                        (this.state.customImageFileSrc || image_url) &&
                                        <div className={'feed-item__thumbnail--container'}>
                                            <div className={'feed-item__thumbnail'}>
                                                <img src={this.state.customImageFileSrc ? this.state.customImageFileSrc : image_url} alt={""}/>
                                            </div>
                                            {
                                                editable &&
                                                <div className={'feed-item__thumbnail--actions'}>
                                                    <a href={this.state.customImageFileSrc} target={'_blank'}>Preview Image</a>
                                                    <Upload
                                                        showUploadList={false}
                                                        beforeUpload={this.onPreview}
                                                    >
                                                        <span className={'replace-image'}>Replace Image</span>
                                                    </Upload>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        !this.state.customImageFileSrc && !image_url &&
                                        <>
                                            <div className={`feed-item__thumbnail--container bordered`}>
                                                <div className={'feed-item__thumbnail'}>
                                                    <Upload
                                                        showUploadList={false}
                                                        beforeUpload={this.onPreview}
                                                    >
                                                        <div className={'upload-button'}>
                                                            <Icon type={this.state.uploading ? 'loading' : 'plus'} /> Upload Image
                                                        </div>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator('file', {
                                            initialValue: null,
                                            validateFirst: true,
                                            rules: [
                                                { validator: (rule, value, callback) => { image_url ? callback() : (this.state.customImageFileSrc ? callback() : callback(false)) }, message: 'Please upload or generate an image'},
                                            ],
                                        })(
                                            <Input disabled={true} style={{display: 'none'}}/>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item label="Category">
                                    {getFieldDecorator('category_id', {
                                        rules: [{ required: true, message: 'Please select a category.' }],
                                        initialValue: category_id || 'Please select a category...',
                                    })(this.generateCategorySelect())}
                                </Form.Item>
                            </Form>
                        </div>
                        {
                            editable &&
                            <div className="feed-item__buttons">
                                <div className={'feed-item__buttons--container'}>
                                    {
                                        !this.props.published &&
                                        <Button type="default" disabled={loading} onClick={() => { onDelete(id) }}>
                                            Discard
                                        </Button>
                                    }
                                    {
                                        !!(this.props.published && !this.props.published.published) &&
                                        <Button type="default" disabled={loading} onClick={() => { onDelete(id) }}>
                                            Delete
                                        </Button>
                                    }
                                    {
                                        !!(this.props.published && this.props.published.published && !hasChanged) &&
                                        <Button type="default" disabled={loading} onClick={() => { onMoveToDraft(id) }}>
                                            Move to Drafts
                                        </Button>
                                    }
                                    {
                                        !!(this.props.published && this.props.published.published && hasChanged) &&
                                        <Button type="default" disabled={loading} onClick={() => { this.onSave(true) }}>
                                            Save & Move to Drafts
                                        </Button>
                                    }
                                </div>
                                <div className={`feed-item__buttons--container ${this.props.draft && hasChanged ? '' : 'disabled'}`}>
                                    {
                                        this.props.published &&
                                        <Button type="default" disabled={loading || !this.props.draft || !hasChanged} onClick={() => { this.onCancel() }}>
                                            Discard Changes
                                        </Button>
                                    }
                                    <Button type="primary" loading={loading} disabled={!this.props.draft || !hasChanged} className="update" onClick={() => { this.onSave() }}>
                                        { !this.props.published ? 'Save Draft' : 'Publish Changes'}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    };

}

export default Form.create({
    onFieldsChange: (props, changedFields, allFields) => props.onChange(props.published ? props.published.id : props.draft.id, changedFields, allFields),
})(FeedItem);
