import {
    CREATE_FEED_ITEM_ERROR,
    CREATE_FEED_ITEM_PENDING,
    CREATE_FEED_ITEM_SUCCESS,
    DELETE_FEED_ITEM_ERROR,
    DELETE_FEED_ITEM_PENDING,
    DELETE_FEED_ITEM_SUCCESS,
    FETCH_FEED_ERROR,
    FETCH_FEED_PENDING,
    FETCH_FEED_SUCCESS,
    RESET_FEED_ERROR,
    RESET_FEED_PENDING,
    RESET_FEED_SUCCESS,
    UPDATE_FEED_ITEM_ERROR,
    UPDATE_FEED_ITEM_PENDING,
    UPDATE_FEED_ITEM_SUCCESS,
    UPDATE_FEED_ORDER_ERROR,
    UPDATE_FEED_ORDER_PENDING,
    UPDATE_FEED_ORDER_SUCCESS,
    CREATE_FEED_CATEGORY_PENDING,
    CREATE_FEED_CATEGORY_ERROR,
    UPDATE_FEED_CATEGORY_PENDING,
    UPDATE_FEED_CATEGORY_SUCCESS,
    UPDATE_FEED_CATEGORY_ERROR,
    DELETE_FEED_CATEGORY_PENDING,
    DELETE_FEED_CATEGORY_ERROR,
    FETCH_FEED_CATEGORIES_SUCCESS
} from '../actions/feed'

import {createSelector} from "reselect";

const defaultState = {
    feed: {},
    categories: {},
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        /* Fetch All Transfers */
        case FETCH_FEED_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_FEED_SUCCESS:
            state.feed = {};
            if(action.payload.length > 0) {
                action.payload.forEach((feedItem) => {
                    state.feed[feedItem.id] = feedItem;
                });
            }
            return { ...state, action, loading: false };
        case FETCH_FEED_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case CREATE_FEED_ITEM_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case CREATE_FEED_ITEM_SUCCESS:
            if(action.payload.feed && action.payload.feed.length > 0) {
                state.feed = {};
                action.payload.feed.forEach((feedItem) => {
                    state.feed[feedItem.id] = feedItem;
                });
            } else {
                state.feed[action.payload['feed_item'].id] = action.payload['feed_item'];
            }
            return { ...state, action, loading: false };
        case CREATE_FEED_ITEM_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_FEED_ITEM_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_FEED_ITEM_SUCCESS:
            state.feed[action.payload.id] = action.payload;
            return { ...state, action, loading: false };
        case UPDATE_FEED_ITEM_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case DELETE_FEED_ITEM_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case DELETE_FEED_ITEM_SUCCESS:
            delete state.feed[action.payload];
            return { ...state, action, loading: false };
        case DELETE_FEED_ITEM_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_FEED_ORDER_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_FEED_ORDER_SUCCESS:
            state.feed = {};
            if(action.payload.length > 0) {
                action.payload.forEach((feedItem) => {
                    state.feed[feedItem.id] = feedItem;
                });
            }
            return { ...state, action, loading: false };
        case UPDATE_FEED_ORDER_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case RESET_FEED_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case RESET_FEED_SUCCESS:
            state.feed = {};
            if(action.payload.length > 0) {
                action.payload.forEach((feedItem) => {
                    state.feed[feedItem.id] = feedItem;
                });
            }
            return { ...state, action, loading: false };
        case RESET_FEED_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FETCH_FEED_CATEGORIES_SUCCESS:
            state.categories = {};
            if(action.payload.length > 0) {
                action.payload.forEach((feedCategory) => {
                    state.categories[feedCategory.id] = feedCategory;
                });
            }
            return { ...state, action, loading: false };
        case CREATE_FEED_CATEGORY_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case CREATE_FEED_CATEGORY_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_FEED_CATEGORY_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_FEED_CATEGORY_SUCCESS:
            state.categories[action.payload.id] = action.payload;
            return { ...state, action, loading: false };
        case UPDATE_FEED_CATEGORY_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case DELETE_FEED_CATEGORY_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case DELETE_FEED_CATEGORY_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};

export const feedSelector = state => Object.keys(state.feed.feed).map(key => state.feed.feed[key]);

export const feedCategorySelector = state => Object.keys(state.feed.categories).map(key => state.feed.categories[key]);

export const feedOrderedSelector = createSelector(
    feedSelector,
    feed => {
        return feed.slice(0).sort((a, b) => a.order - b.order);
    }
);
