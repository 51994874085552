import {
    CREATE_MILESTONE_ERROR,
    CREATE_MILESTONE_PENDING,
    CREATE_MILESTONE_SUCCESS,
    DELETE_MILESTONE_ERROR,
    DELETE_MILESTONE_PENDING,
    DELETE_MILESTONE_SUCCESS,
    FETCH_MILESTONES_ERROR,
    FETCH_MILESTONES_PENDING,
    FETCH_MILESTONES_SUCCESS,
    RESET_MILESTONES_ERROR,
    RESET_MILESTONES_PENDING,
    RESET_MILESTONES_SUCCESS,
    UPDATE_MILESTONE_ERROR,
    UPDATE_MILESTONE_PENDING,
    UPDATE_MILESTONE_SUCCESS,
    UPDATE_MILESTONES_ORDER_ERROR,
    UPDATE_MILESTONES_ORDER_PENDING,
    UPDATE_MILESTONES_ORDER_SUCCESS
} from '../actions/milestones'

import {createSelector} from "reselect";

const defaultState = {
    milestones: {},
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        /* Fetch All Transfers */
        case FETCH_MILESTONES_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_MILESTONES_SUCCESS:
            state.milestones = {};
            if(action.payload.length > 0) {
                action.payload.forEach((milestone) => {
                    state.milestones[milestone.id] = milestone;
                });
            }
            return { ...state, action, loading: false };
        case FETCH_MILESTONES_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case CREATE_MILESTONE_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case CREATE_MILESTONE_SUCCESS:
            if(action.payload.milestones && action.payload.milestones.length > 0) {
                state.milestones = {};
                action.payload.milestones.forEach((milestone) => {
                    state.milestones[milestone.id] = milestone;
                });
            } else {
                state.milestones[action.payload.milestone.id] = action.payload.milestone;
            }
            return { ...state, action, loading: false };
        case CREATE_MILESTONE_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_MILESTONE_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_MILESTONE_SUCCESS:
            state.milestones[action.payload.id] = action.payload;
            return { ...state, action, loading: false };
        case UPDATE_MILESTONE_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case DELETE_MILESTONE_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case DELETE_MILESTONE_SUCCESS:
            delete state.milestones[action.payload];
            return { ...state, action, loading: false };
        case DELETE_MILESTONE_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_MILESTONES_ORDER_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_MILESTONES_ORDER_SUCCESS:
            state.milestones = {};
            if(action.payload.length > 0) {
                action.payload.forEach((milestone) => {
                    state.milestones[milestone.id] = milestone;
                });
            }
            return { ...state, action, loading: false };
        case UPDATE_MILESTONES_ORDER_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case RESET_MILESTONES_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case RESET_MILESTONES_SUCCESS:
            state.milestones = {};
            if(action.payload.length > 0) {
                action.payload.forEach((milestone) => {
                    state.milestones[milestone.id] = milestone;
                });
            }
            return { ...state, action, loading: false };
        case RESET_MILESTONES_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};

export const milestonesSelector = state => Object.keys(state.milestones.milestones).map(key => state.milestones.milestones[key]);

export const milestonesOrderedSelector = createSelector(
    milestonesSelector,
    milestones => {
        return milestones.slice(0).sort((a, b) => a.order - b.order);
    }
);
