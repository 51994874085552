import {FETCH_REFERRALS_ERROR, FETCH_REFERRALS_PENDING, FETCH_REFERRALS_SUCCESS,} from "./types";

import client from './';

export const fetchReferrals = (params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_REFERRALS_PENDING});
        console.log('search data', params);
        return client.get(`/provider/users/referred`, {
            params: {
                ...params,
            }
        })
            .then(response => {
                if(response) {
                    dispatch({
                        type: FETCH_REFERRALS_SUCCESS,
                        payload: response.data
                    })
                } else {
                    dispatch({
                        type: FETCH_REFERRALS_ERROR,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: FETCH_REFERRALS_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
            })
    }
};
