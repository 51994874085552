/* AUTH */
export const REGISTRATION_PENDING = 'REGISTRATION_PENDING';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';

export const PRE_AUTH_PENDING = 'PRE_AUTH_PENDING';
export const PRE_AUTH_SUCCESS = 'PRE_AUTH_SUCCESS';
export const PRE_AUTH_ERROR = 'PRE_AUTH_ERROR';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const CLEAR_USER_ATTRIBUTES = 'CLEAR_USER_ATTRIBUTES';

export const UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const UPLOAD_USER_ASSET_PENDING = 'UPLOAD_USER_ASSET_PENDING';
export const UPLOAD_USER_ASSET_SUCCESS = 'UPLOAD_USER_ASSET_SUCCESS';
export const UPLOAD_USER_ASSET_ERROR = 'UPLOAD_USER_ASSET_ERROR' ;

export const SET_USER_ERRORS = 'SET_USER_ERRORS';

/* PARTNERSHIPS */
export const FETCH_PARTNERSHIPS_PENDING = 'FETCH_PARTNERSHIPS_PENDING';
export const FETCH_PARTNERSHIPS_SUCCESS = 'FETCH_PARTNERSHIPS_SUCCESS';
export const FETCH_PARTNERSHIPS_ERROR = 'FETCH_PARTNERSHIPS_ERROR';
export const SEND_PARTNERSHIP_PENDING = 'SEND_PARTNERSHIP_PENDING';
export const SEND_PARTNERSHIP_SUCCESS = 'SEND_PARTNERSHIP_SUCCESS';
export const SEND_PARTNERSHIP_ERROR = 'SEND_PARTNERSHIP_ERROR';
export const ACCEPT_PARTNERSHIP_PENDING = 'ACCEPT_PARTNERSHIP_PENDING';
export const ACCEPT_PARTNERSHIP_SUCCESS = 'ACCEPT_PARTNERSHIP_SUCCESS';
export const ACCEPT_PARTNERSHIP_ERROR = 'ACCEPT_PARTNERSHIP_ERROR';
export const DELETE_PARTNERSHIP_PENDING = 'DELETE_PARTNERSHIP_PENDING';
export const DELETE_PARTNERSHIP_SUCCESS = 'DELETE_PARTNERSHIP_SUCCESS';
export const DELETE_PARTNERSHIP_ERROR = 'DELETE_PARTNERSHIP_ERROR';

/* INTEGRATIONS */
export const FETCH_INTEGRATIONS_PENDING = 'FETCH_INTEGRATIONS_PENDING';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_ERROR = 'FETCH_INTEGRATIONS_ERROR';

/* SALESFORCE */

export const SALESFORCE_CONNECT_PENDING = 'SALESFORCE_CONNECT_PENDING';
export const SALESFORCE_CONNECT_ERROR = 'SALESFORCE_CONNECT_ERROR';

export const SALESFORCE_DISCONNECT_PENDING = 'SALESFORCE_DISCONNECT_PENDING';
export const SALESFORCE_DISCONNECT_ERROR = 'SALESFORCE_DISCONNECT_ERROR';

export const SALESFORCE_SYNC_PENDING = 'SALESFORCE_SYNC_PENDING';
export const SALESFORCE_SYNC_SUCCESS = 'SALESFORCE_SYNC_SUCCESS';
export const SALESFORCE_SYNC_ERROR = 'SALESFORCE_SYNC_ERROR';

export const SALESFORCE_FETCH_PENDING = 'SALESFORCE_FETCH_PENDING';
export const SALESFORCE_FETCH_SUCCESS = 'SALESFORCE_FETCH_SUCCESS';
export const SALESFORCE_FETCH_ERROR = 'SALESFORCE_FETCH_ERROR';

export const SALESFORCE_CONFIG_PENDING = 'SALESFORCE_CONFIG_PENDING';
export const SALESFORCE_CONFIG_ERROR = 'SALESFORCE_CONFIG_ERROR';

/* FOLLOW UP BOSS */

export const FUB_CONNECT_PENDING = 'FUB_CONNECT_PENDING';
export const FUB_CONNECT_ERROR = 'FUB_CONNECT_ERROR';

export const FUB_DISCONNECT_PENDING = 'FUB_DISCONNECT_PENDING';
export const FUB_DISCONNECT_ERROR = 'FUB_DISCONNECT_ERROR';

export const FUB_SYNC_PENDING = 'FUB_SYNC_PENDING';
export const FUB_SYNC_SUCCESS = 'FUB_SYNC_SUCCESS';
export const FUB_SYNC_ERROR = 'FUB_SYNC_ERROR';

export const FUB_FETCH_PENDING = 'FUB_FETCH_PENDING';
export const FUB_FETCH_SUCCESS = 'FUB_FETCH_SUCCESS';
export const FUB_FETCH_ERROR = 'FUB_FETCH_ERROR';

export const FUB_CONFIG_PENDING = 'FUB_CONFIG_PENDING';
export const FUB_CONFIG_ERROR = 'FUB_CONFIG_ERROR';

/* SOCIAL ACCOUNTS */
export const FETCH_SOCIAL_PENDING = 'FETCH_SOCIAL_PENDING';
export const FETCH_SOCIAL_SUCCESS = 'FETCH_SOCIAL_SUCCESS';
export const FETCH_SOCIAL_ERROR = 'FETCH_SOCIAL_ERROR';
export const UPDATE_SOCIAL_PENDING = 'FETCH_SOCIAL_PENDING';
export const UPDATE_SOCIAL_SUCCESS = 'FETCH_SOCIAL_SUCCESS';
export const UPDATE_SOCIAL_ERROR = 'FETCH_SOCIAL_ERROR';

/* LEAD ROUTING */
export const FETCH_ROUTING_PENDING = 'FETCH_ROUTING_PENDING';
export const FETCH_ROUTING_SUCCESS = 'FETCH_ROUTING_SUCCESS';
export const FETCH_ROUTING_ERROR = 'FETCH_ROUTING_ERROR';
export const ROUTING_UPDATE_PENDING = 'ROUTING_UPDATE_PENDING';
export const ROUTING_UPDATE_SUCCESS = 'ROUTING_UPDATE_SUCCESS';
export const ROUTING_UPDATE_ERROR = 'ROUTING_UPDATE_ERROR';

/* REWARDS */
export const FETCH_REWARDS_PENDING = 'FETCH_REWARDS_PENDING';
export const FETCH_REWARDS_SUCCESS = 'FETCH_REWARDS_SUCCESS';
export const FETCH_REWARDS_ERROR = 'FETCH_REWARDS_ERROR';
export const UPDATE_REWARDS_PENDING = 'UPDATE_REWARDS_PENDING';
export const UPDATE_REWARDS_SUCCESS = 'UPDATE_REWARDS_SUCCESS';
export const UPDATE_REWARDS_ERROR = 'UPDATE_REWARDS_ERROR';


/* CHILD USERS */

export const FETCH_CHILD_PENDING = 'FETCH_CHILDREN_PENDING';
export const FETCH_CHILD_ERROR = 'FETCH_CHILDREN_ERROR';
export const FETCH_CHILD_SUCCESS = 'FETCH_CHILDREN_SUCCESS';

/* REFERRALS */
export const FETCH_REFERRALS_PENDING = 'FETCH_REFERRALS_PENDING';
export const FETCH_REFERRALS_SUCCESS = 'FETCH_REFERRALS_SUCCESS';
export const FETCH_REFERRALS_ERROR = 'FETCH_REFERRALS_ERROR';

/* SUBACCOUNTS */
export const FETCH_SUBACCOUNTS_PENDING = 'FETCH_SUBACCOUNTS_PENDING';
export const FETCH_SUBACCOUNTS_SUCCESS = 'FETCH_SUBACCOUNTS_SUCCESS';
export const FETCH_SUBACCOUNTS_ERROR = 'FETCH_SUBACCOUNTS_SUCCESS';

export const FETCH_SUBACCOUNT_PENDING = 'FETCH_SUBACCOUNT_PENDING';
export const FETCH_SUBACCOUNT_SUCCESS = 'FETCH_SUBACCOUNT_SUCCESS';
export const FETCH_SUBACCOUNT_ERROR = 'FETCH_SUBACCOUNT_ERROR';

export const ADD_SUBACCOUNT_PENDING = 'ADD_SUBACCOUNTS_PENDING';
export const ADD_SUBACCOUNT_ERROR = 'ADD_SUBACCOUNTS_ERROR';
export const ADD_SUBACCOUNT_SUCCESS = 'ADD_SUBACCOUNTS_SUCCESS';

export const UPDATE_SUBACCOUNT_PENDING = 'UPDATE_SUBACCOUNTS_PENDING';
export const UPDATE_SUBACCOUNT_ERROR = 'UPDATE_SUBACCOUNTS_ERROR';
export const UPDATE_SUBACCOUNT_SUCCESS = 'UPDATE_SUBACCOUNTS_SUCCESS';

export const DELETE_SUBACCOUNT_PENDING = 'DELETE_SUBACCOUNTS_PENDING';
export const DELETE_SUBACCOUNT_ERROR = 'DELETE_SUBACCOUNTS_ERROR';
export const DELETE_SUBACCOUNT_SUCCESS = 'DELETE_SUBACCOUNTS_SUCCESS';

/* MESSAGES */
export const FETCH_MESSAGES_PENDING = 'FETCH_MESSAGES_PENDING';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

export const DELETE_MESSAGE_PENDING = 'DELETE_MESSAGE_PENDING';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_PENDING';

/* DASHBOARD */
export const FETCH_DASHBOARD_PENDING = 'FETCH_DASHBOARD_PENDING';
export const FETCH_DASHBOARD_ERROR = 'FETCH_DASHBOARD_ERROR';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';

/* SETTINGS */
export const FETCH_SETTINGS_PENDING = 'FETCH_SETTINGS_PENDING';
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_PENDING = 'UPDATE_SETTINGS_PENDING';
export const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const ADD_MATCHES_PENDING = 'ADD_MATCHES_PENDING';
export const ADD_MATCHES_SUCCESS = 'ADD_MATCHES_SUCCESS';
export const ADD_MATCHES_ERROR = 'ADD_MATCHES_ERROR';
export const RESET_EMAIL_COUNT_PENDING = 'RESET_EMAIL_COUNT_PENDING';
export const RESET_EMAIL_COUNT_SUCCESS = 'RESET_EMAIL_COUNT_SUCCESS';
export const RESET_EMAIL_COUNT_ERROR = 'RESET_EMAIL_COUNT_ERROR';
