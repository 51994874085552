import React, { useState } from 'react';

const CollapsibleFieldList = ({ items }) => {

    const requiredFields = items.filter((item) => item.required);
    const optionalFields = items.filter((item) => !item.required);

    const [toggle, setToggle] = useState(
        false
    );

    return (
        <div className="module--table">
            {
                requiredFields.length > 0 &&
                <>
                    <div className="module--table-row module--table-header">
                        <div className="module--table-column"><span>Required Fields</span></div>
                        <div className="module--table-column"><span>Type</span></div>
                        <div className="module--table-column"><span>Example</span></div>
                    </div>
                    {
                        requiredFields.map(item => (
                            <div key={item.name} className="module--table-row">
                                <div className="module--table-column">
                                    <div className="fields-column">
                                        <span className="field-title">{ item.name }</span>
                                    </div>
                                </div>
                                <div className="module--table-column">
                                    <div className="fields-column">
                                        <span className="field-type">{ item.type }</span>
                                    </div>
                                </div>
                                <div className="module--table-column">
                                    <div className="fields-column">
                                        <span className="field-type">{ item.example }</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        optionalFields.length > 0 &&
                        <>
                            {
                                toggle &&
                                    <>
                                        <div className="module--table-row module--table-header">
                                            <div className="module--table-column"><span>Optional Fields</span></div>
                                            <div className="module--table-column"><span>Type</span></div>
                                            <div className="module--table-column"><span>Example</span></div>
                                        </div>
                                        {
                                            optionalFields.map(item => (
                                                <div key={item.name} className="module--table-row">
                                                    <div className="module--table-column">
                                                        <div className="fields-column">
                                                            <span className="field-title">{ item.name }</span>
                                                        </div>
                                                    </div>
                                                    <div className="module--table-column">
                                                        <div className="fields-column">
                                                            <span className="field-type">{ item.type }</span>
                                                        </div>
                                                    </div>
                                                    <div className="module--table-column">
                                                        <div className="fields-column">
                                                            <span className="field-type">{ item.example }</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                            }
                            <div className="module--table-row module--table-header">
                                <div className={'toggle'} onClick={() => { setToggle(!toggle) }}>
                                    {
                                        !toggle &&
                                        <span>Show Optional Fields</span>
                                    }
                                    {
                                        toggle &&
                                        <span>Hide Optional Fields</span>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
};

export default CollapsibleFieldList;
