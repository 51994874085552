export const reactPlayerConfig = {
    youtube: {
        playerVars: {
            autoplay: 0,
            controls: 1,
            enablejsapi: 1,
            modestbranding: 1,
            host: `${window.location.protocol}//youtube.com`,
            origin: window.location,
        }
    }
};
