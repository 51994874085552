import React, {PureComponent} from 'react';
import {Alert, Button, Card, Col, Collapse, Form, Icon, message, Row, Select, Spin} from 'antd'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as integrationsActions from "../../actions/integrations";
import OAuthPopup from 'react-oauth-popup';
import GenericInactive from './GenericInactive';
import config from '../../config';

import {
    SALESFORCE_CONFIG_PENDING,
    SALESFORCE_CONNECT_PENDING,
    SALESFORCE_DISCONNECT_PENDING,
    SALESFORCE_SYNC_PENDING,
} from '../../actions/types';
import {integrationByService} from "../../reducers/integrations";


const APP_URL = config.REACT_APP_URL ? config.REACT_APP_URL : 'http://localhost:3000';

class Salesforce extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sfObjectsDetected: false,
            isValid: false,
            deploying: false,
        };

        this.clientId = "3MVG9vtcvGoeH2bi8dlM4AtSSOXqvUkuCz6Vw2o63jtYEQCDZpp3zyuwGmYDuMdUNShi.aVKxXgLSFo.wXllx";

        this.redirectUri = `${APP_URL}/integrations/salesforce`;

    }

    componentDidMount() {
        this.confirmSalesforceObjects();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.salesforce && this.props.salesforce) {
            this.confirmSalesforceObjects();
        }
    }

    onSalesforceConnect = (code, params) => {
        this.props.connectSalesforce(code, this.redirectUri)
            .then(() => {
                this.confirmSalesforceObjects();
                message.success('Salesforce successfully connected!');
            });
    };

    confirmSalesforceObjects = () => {

        const { salesforce } = this.props;

        if(salesforce) {

            this.setState({
                sfObjectsDetected: true,
            });

            this.props.confirmSalesforceObjects()
                .then(({ status }) => {
                    this.setState({ isValid: status })
                })
                .finally(() => {
                    this.setState({
                        sfObjectsDetected: false,
                    });
                })

        }

    }

    onSalesforceDeploy = () => {
        this.setState({ deploying: true });
        this.props.deployDigsObjectsPackage()
            .then((data) => {
                this.confirmSalesforceObjects();
            })
            .finally(() => {
                this.setState({ deploying: false });
            })
    }

    onSalesforceDisconnect = () => {
        this.props.disconnectSalesforce()
            .then(() => {
                message.success('Salesforce successfully disconnected!');
            });
    }

    onSalesforceSync = () => {
        this.props.syncSalesforce()
            .then((data) => {
                console.log(data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {

        const { sfObjectsDetected, isValid, deploying } = this.state;
        const { action, isActive, loading } = this.props;
        const type = (action && action.type) ? action.type : null;

        const data = {
            text: 'Connect to your Salesforce account to sync prospects and collect more data about the people in your funnel.',
            list: [
                'Push leads to Salesforce',
                'Sync existing contacts',
                'Update fields'
            ],
            image: '/images/integrations/salesforce-digs-logo.png',
            button: <OAuthPopup
                url={`https://login.salesforce.com/services/oauth2/authorize?display=popup&response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}`}
                onCode={this.onSalesforceConnect}
            >
                <Button
                    className={'button primary'}
                    loading={type === SALESFORCE_CONNECT_PENDING} onClick={() => {
                }}
                >
                    Connect to Salesforce
                </Button>
            </OAuthPopup>
        };

        return (
            <div className={'salesforce-module'}>
                {
                    !isActive &&
                    <Row>
                        <Col xs={24} className={'column-max-width'}>
                            {
                                loading &&
                                <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />}>
                                    <GenericInactive data={data} />
                                </Spin>
                            }
                            {
                                !loading &&
                                <GenericInactive data={data} />
                            }
                        </Col>
                    </Row>
                }
                {
                    isActive &&
                    <Row gutter={24}>
                        <Col xs={24} xl={16}>
                            <
                                Card
                                title={'Integrations Details'}
                                bordered={false}
                            >
                                <div className="card--inner">
                                    {
                                        sfObjectsDetected &&
                                        <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                                    }
                                    {
                                        !sfObjectsDetected && isValid &&
                                        <>
                                            <p><b>Status:</b> Digs models successfully detected in Salesforce!</p>
                                            <p>We've automatically detected that the necessary Digs objects have already been deployed to your Salesforce instance. Digs will automatically sync your users and their associated property data whenever an update takes place.</p>
                                            <p>To force a one-time sync all of your user data, click the "One-time Sync" button below.</p>
                                        </>
                                    }
                                    {
                                        !sfObjectsDetected && !isValid &&
                                        <>
                                            <p><b>Status:</b> Digs has successfully authenticated with Salesforce!</p>
                                            <p>We've automatically detected that the necessary Digs objects are missing from your Salesforce instance. Before we can sync your users and their associated properties, you'll need to deploy them.</p>
                                            <p>To deploy the objects, click the "Deploy" button below. Once complete, Digs will be able to automatically sync when a user or property is updated.</p>
                                        </>
                                    }
                                </div>
                                <Form>
                                    <div className={'module--buttons split'}>
                                        <Button loading={ type === SALESFORCE_DISCONNECT_PENDING } onClick={this.onSalesforceDisconnect}>Disconnect Salesforce</Button>
                                        {
                                            !sfObjectsDetected && !isValid &&
                                            <Button loading={deploying} onClick={this.onSalesforceDeploy}  type={'primary'}>Deploy</Button>
                                        }
                                        {
                                            !sfObjectsDetected && isValid &&
                                            <Button loading={ type === SALESFORCE_SYNC_PENDING } onClick={this.onSalesforceSync} type={'primary'}>One-time Sync</Button>
                                        }
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                        <Col xs={24} xl={8}>
                            <
                                Card
                                title={'About Salesforce + Digs'}
                                bordered={false}
                            >
                                <div className="card--inner">
                                    <p>
                                        { data.text}
                                    </p>
                                    <ul>
                                        {
                                            data.list.map(item => <li>{ item }</li>)
                                        }
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }

}


const mapStateToProps = (state) => ({
    salesforce: integrationByService(state, 'salesforce'),
    action: state.integrations.action
});

const mapDispatchToProps = {
    ...integrationsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(Salesforce)));
