import {
    FETCH_DASHBOARD_ERROR,
    FETCH_DASHBOARD_PENDING,
    FETCH_DASHBOARD_SUCCESS,
} from "./types";
import client from './';

export const fetchEveryone = (params) => {

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_PENDING});
        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/everyone`, {
                params: {
                    ...params
                }
            })
            .then(response => {
                dispatch({
                    type: FETCH_DASHBOARD_SUCCESS,
                });
                resolve(response.data);
            })
            .catch((err) => {
                dispatch({
                    type: FETCH_DASHBOARD_ERROR,
                });
                reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
            })
        });
    };
};

export const fetchEveryoneCount = (filter_value) => {
    return () => {
        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/everyone/count`, {
                params: {
                    filter_value,
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

export const downloadEveryoneExport = () => {

    return (dispatch) => {

        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/everyone/export`, {
                responseType: 'blob'
            })
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

export const fetchSavers = (params) => {

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_PENDING});
        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/savers`, {
                params: {
                    ...params
                }
            })
            .then(response => {
                dispatch({
                    type: FETCH_DASHBOARD_SUCCESS,
                });
                resolve(response.data);
            })
            .catch((err) => {
                dispatch({
                    type: FETCH_DASHBOARD_ERROR,
                });
                reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
            });
        });
    };
};

export const fetchSaversCount = (savers_filter_value, purchasing_filter_value) => {
    return () => {
        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/savers/count`, {
                params: {
                    savers_filter_value,
                    purchasing_filter_value
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

export const downloadSaversExport = () => {

    return (dispatch) => {

        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/savers/export`, {
                responseType: 'blob'
            })
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

export const fetchHomeowners = (params) => {

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_PENDING});
        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/homeowners`, {
                params: {
                    ...params
                }
            })
            .then(response => {
                dispatch({
                    type: FETCH_DASHBOARD_SUCCESS,
                });
                resolve(response.data);
            })
            .catch((err) => {
                dispatch({
                    type: FETCH_DASHBOARD_ERROR,
                });
                reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
            });
        });
    };
};

export const fetchHomeownersCount = (homeowners_filter_value) => {
    return () => {
        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/homeowners/count`, {
                params: {
                    homeowners_filter_value,
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

export const downloadHomeownersExport = () => {

    return (dispatch) => {

        return new Promise ((resolve, reject) => {
            client.get(`/provider/dashboard/homeowners/export`, {
                responseType: 'blob'
            })
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

export const updatePropertyById = (property_id, data) => {
    return () => {
        return new Promise ((resolve, reject) => {
            client.post(`/provider/dashboard/property/${property_id}`, {
                ...data
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        });
    };
};

