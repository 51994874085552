import React, {Component} from "react";
import {connect} from "react-redux";
import * as userActions from "../../actions/user";
import Social from "./Security/Social";
import { Link } from 'react-router-dom';
import ChangePassword from "./Security/ChangePassword";
import {Card, Divider} from "antd";

class Security extends Component {

    render() {

        const { user } = this.props;

        return (
            <div>
                <h2>Security</h2>
                {
                    user.active_subscription &&
                    user.active_subscription.type === 'KELLERWILLIAMS' &&
                    <Card title={'Account Settings'}>
                        <p>To manage your KW account settings, please visit the <a href="https://marketplace.kw.com/account/users">account settings page</a> within the KW Marketplace.</p>
                        <Divider style={{margin: '24px 0', width: '100%'}} />
                        <p>If you're looking to update the information that your customers will see, please visit your Digs profile at <Link to={'/my-app/profile'}>My App > Profile</Link>.</p>
                    </Card>
                }
                {
                    user.active_subscription &&
                    user.active_subscription.type === 'REMAX' &&
                    <Card title={'Account Settings'}>
                        <p>To manage your RE/MAX account settings, please visit the <a href="https://marketplace.remax.com/account/users">account settings page</a> within the RE/MAX Marketplace.</p>
                        <Divider style={{margin: '24px 0', width: '100%'}} />
                        <p>If you're looking to update the information that your customers will see, please visit your Digs profile at <Link to={'/my-app/profile'}>My App > Profile</Link>.</p>
                    </Card>
                }
                {
                    (
                        !user.active_subscription ||
                        (user.active_subscription && user.active_subscription.type !== 'KELLERWILLIAMS') ||
                        (user.active_subscription && user.active_subscription.type !== 'REMAX')
                    ) &&
                    <>
                        <Social />
                        <ChangePassword />
                    </>
                }
                <Card title={'Need Help?'}>
                    <p>If you have questions or need to update anything regarding your account settings, please contact your Digs representative or email <a href="mailto:support@digs.co">support@digs.co</a>.</p>
                </Card>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, action: user.action, errors: user.errors});
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Security);
