import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import generator from "generate-password";

import {Button, Checkbox, Form, Input, message, Modal,} from 'antd';

import {connect} from 'react-redux';
import Errors from "../utils/Errors";

import * as subaccountsActions from "../../actions/subaccounts";
import {ADD_SUBACCOUNT_PENDING} from "../../actions/types";


const FormItem = Form.Item;

class AddForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            generatedPassword: null,
        }
    }

    static propTypes = {
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSuccess: PropTypes.func,
    };

    componentDidMount() {
        this.generatePassword();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.visible !== this.props.visible) {
            this.generatePassword();
        }
    }

    generatePassword = () => {
        const generatedPassword = generator.generate({
            length: 15,
            numbers: true,
            symbols: true,
            exclude: '\'\\<>`"/&;@',
            uppercase: true,
            strict: true,
        });

        this.setState({
            generatedPassword: generatedPassword,
        })
    };

    onSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, fields) => {

            if (!err) {

                this.props.createSubaccount(fields)
                    .then(() => {
                        message.success('New provider has been added');
                        this.props.onSuccess();
                    })
                    .catch()
                    .finally();

            }

        });

    };

    onCancel = e => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    render() {

        const { getFieldDecorator } = this.props.form;
        const errors = this.props.errors;
        const subaccountsAction = this.props.action;
        const subaccountsType = (subaccountsAction && subaccountsAction.type) ? subaccountsAction.type : null;

        return (
            <Modal
                destroyOnClose
                title="Add Team Member"
                visible={this.props.visible}
                onCancel={() => this.onCancel()}
                footer={[
                    <Button key="back" onClick={() => this.onCancel()}>Cancel</Button>,
                    <Button key="submit" type="primary" loading={subaccountsType === ADD_SUBACCOUNT_PENDING} onClick={this.onSubmit}>
                        Submit
                    </Button>,
                ]}
            >
                {errors && <Errors errors={errors} />}
                <FormItem labelCol={{ span: 7 }} wrapperCol={{ span: 12 }} label="Email">
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'please enter email' }],
                    })(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem labelCol={{ span: 7 }} wrapperCol={{ span: 12}} label="First Name">
                    {getFieldDecorator('firstname', {
                        rules: [{ required: true, message: 'please enter first name' }],
                    })(<Input placeholder="First Name" />)}
                </FormItem>
                <FormItem labelCol={{ span: 7 }} wrapperCol={{ span: 12 }} label="Last Name">
                    {getFieldDecorator('lastname', {
                        rules: [{ required: true, message: 'please enter last name' }],
                    })(
                        <Input placeholder="Last Name"/>
                    )}
                </FormItem>
                <FormItem labelCol={{ span: 7 }} wrapperCol={{ span: 12 }} label="Mobile">
                    {getFieldDecorator('mobile', {
                        rules: [{ required: true, message: 'please enter mobile' }],
                    })(<Input placeholder="Mobile" />)}
                </FormItem>
                <FormItem labelCol={{ span: 7 }} wrapperCol={{ span: 12 }} label="Password">
                    {getFieldDecorator('password', {
                        rules: [
                            { min: 6, message: <div>Password must contain at least six characters</div> },
                            { pattern: new RegExp(/\d/), message: <div>Password must contain at least one number</div> },
                            { pattern: new RegExp(/[`~!@#$%^&*()_+=]/),
                                message: <div>Password must contain at least one special character</div>
                            },
                        ],
                        initialValue: this.state.generatedPassword,
                    })(<Input placeholder="Password" />)}
                </FormItem>
                <FormItem labelCol={{ span: 7 }} wrapperCol={{ span: 12 }}>
                    {getFieldDecorator('send_verification_email', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(<Checkbox>Send Welcome Email</Checkbox>)}
                </FormItem>
            </Modal>
        );
    }

}

const mapStateToProps = ({ subaccounts }) => ({ action: subaccounts.action, errors: subaccounts.errors });
const mapDispatchToProps = {
    ...subaccountsActions
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddForm));
