import {
    ADD_SUBACCOUNT_ERROR,
    ADD_SUBACCOUNT_PENDING,
    ADD_SUBACCOUNT_SUCCESS,
    DELETE_SUBACCOUNT_ERROR,
    DELETE_SUBACCOUNT_PENDING,
    DELETE_SUBACCOUNT_SUCCESS,
    FETCH_SUBACCOUNT_ERROR,
    FETCH_SUBACCOUNT_PENDING,
    FETCH_SUBACCOUNT_SUCCESS,
    FETCH_SUBACCOUNTS_ERROR,
    FETCH_SUBACCOUNTS_PENDING,
    FETCH_SUBACCOUNTS_SUCCESS,
    UPDATE_SUBACCOUNT_ERROR,
    UPDATE_SUBACCOUNT_PENDING,
    UPDATE_SUBACCOUNT_SUCCESS,
} from "./types";

import client from './';

export const fetchSubaccounts = (params) => {

    return (dispatch) => {
        dispatch({ type: FETCH_SUBACCOUNTS_PENDING});
        return new Promise ((resolve, reject) => {
            client.get(`/provider/subaccounts`, {
                params: {
                    ...params
                }
            })
                .then(response => {
                    dispatch({
                        type: FETCH_SUBACCOUNTS_SUCCESS,
                    });
                    resolve(response.data);
                })
                .catch((err) => {
                    dispatch({
                        type: FETCH_SUBACCOUNTS_ERROR,
                    });
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                })
        });
    };
};

export const fetchSubaccountById = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_SUBACCOUNT_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/subaccount/${id}`)
                .then(response => {
                    dispatch({
                        type: FETCH_SUBACCOUNT_SUCCESS,
                    })
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: FETCH_SUBACCOUNT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }
};

export const updateSubaccount = (data) => {
    return (dispatch) => {
        dispatch({type: UPDATE_SUBACCOUNT_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/subaccount/${data.id}`, data)
                .then(response => {
                    if (response) {
                        dispatch({
                            type: UPDATE_SUBACCOUNT_SUCCESS,
                        });
                        resolve(response);
                    } else {
                        dispatch({
                            type: UPDATE_SUBACCOUNT_ERROR,
                        });
                        reject();
                    }

                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_SUBACCOUNT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }
};

export const uploadSubaccountMedia = (userId, data) => {
    return (dispatch) => {
        dispatch({type: UPDATE_SUBACCOUNT_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/subaccount/${userId}/upload`, data)
                .then(response => {
                    if (response) {
                        dispatch({
                            type: UPDATE_SUBACCOUNT_SUCCESS,
                        });
                        resolve(response);
                    } else {
                        dispatch({
                            type: UPDATE_SUBACCOUNT_ERROR,
                        });
                        reject();
                    }

                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_SUBACCOUNT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }
};

export const createSubaccount = (data) => {
    return (dispatch) => {
        dispatch({type: ADD_SUBACCOUNT_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/subaccount`, data)
                .then(response => {
                    if (response) {
                        dispatch({
                            type: ADD_SUBACCOUNT_SUCCESS,
                            payload: response.data
                        });
                        resolve();
                    } else {
                        dispatch({
                            type: ADD_SUBACCOUNT_ERROR,
                        });
                        reject();
                    }

                })
                .catch((err) => {
                    dispatch({
                        type: ADD_SUBACCOUNT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }
};

export const deleteSubaccount = (data) => {

    return (dispatch) => {
        dispatch({type: DELETE_SUBACCOUNT_PENDING});
        return client.post(`/provider/users/delete/${data.id}`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: DELETE_SUBACCOUNT_SUCCESS,
                        payload: response.data
                    })
                } else {
                    dispatch({
                        type: DELETE_SUBACCOUNT_ERROR,
                    });
                }

            })
            .catch((err) => {
                dispatch({
                    type: DELETE_SUBACCOUNT_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
            })
    };
}
