import React, {Component} from 'react';
import {connect} from 'react-redux';
import StripeForm from '../../components/signup/StripeForm';
import {Elements} from 'react-stripe-elements';
import * as userActions from "../../actions/user";
import Errors from '../../components/utils/Errors'
import {Card, Col, Divider, Row} from "antd";

import {UPDATE_USER_ERROR,} from '../../actions/types'

class Payment extends Component {

    render() {

        const { action, errors, user } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page page-payment'}>
                <Row>
                    <Col xs={24}>
                        <div className={'title-module'}>
                            <h1>Payment</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <div className="card-list">
                    <Row gutter={24} type="flex">
                        <Col xs={24} sm={{span: 24, offset: 0}} lg={{span: 14, offset: 0}} xl={{span: 9, offset: 3}}>
                            {
                                type === UPDATE_USER_ERROR  && <Errors errors={errors} />
                            }
                            <Card className={'payment-card'}>
                                <Elements>
                                    <StripeForm />
                                </Elements>
                            </Card>
                        </Col>
                        <Col xs={24} sm={{span: 24, offset: 0}} lg={{span: 10, offset: 0}} xl={{span: 7, offset: 0}}>
                            <Card title={'Pro Features'}>
                                {
                                    user.agent_lender === 'Agent' &&
                                    <ul>
                                        <li>150 Active Clients</li>
                                        <li>Custom Branded App</li>
                                        <li>Branded Landing Pages</li>
                                        <li>Advanced Data Insights</li>
                                        <li>CRM Integrations</li>
                                        <li>Custom Milestones</li>
                                        <li>+150 clients for $39</li>
                                    </ul>
                                }
                                {
                                    user.agent_lender === 'Lender' &&
                                    <ul>
                                        <li>500 Active Clients</li>
                                        <li>Custom Branded App</li>
                                        <li>Branded Landing Pages</li>
                                        <li>Advanced Data Insights</li>
                                        <li>CRM Integrations</li>
                                        <li>Custom Milestones</li>
                                        <li>+500 clients for $99</li>
                                    </ul>
                                }
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
