import React, {PureComponent} from 'react';
import {Button, Col, Form, Row} from 'antd'
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import GenericInactive from './GenericInactive';

class Surefire extends PureComponent {

    render() {

        const data = {
            text: 'Connect to Surefire account to sync prospects and collect more data about the people in your funnel.',
            list: [
                'Connect via Zapier',
                'Push leads to Surefire',
                'Sync existing contacts'
            ],
            image: '/images/integrations/surefire-digs-logo.png',
            button: <Button className={'button primary'} disabled>Coming Soon</Button>
        };

        return (
            <div className={'surefire-module'}>
                <Row>
                    <Col xs={24} className={'column-max-width'}>
                        <GenericInactive data={data} />
                    </Col>
                </Row>
            </div>
        );
    }

}


const mapStateToProps = ({ user }) => ({ user: user.user, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Surefire));
