import React, {Component} from 'react';
import {Router, Switch} from 'react-router-dom';
import Analytics from './helpers/analytics';
import History from "./helpers/history";
import Routes from "./routes";
import ScrollToTop from './components/utils/ScrollToTop';

// Setup listener for analytics
History.listen(location => {
    Analytics.set({ page: location.pathname });
    Analytics.pageview(location.pathname);
});

export default class App extends Component {

    componentDidMount() {
        Analytics.pageview();
    }

    render() {

        return (
            <Router history={History}>
                <ScrollToTop>
                    <Routes />
                </ScrollToTop>
            </Router>
        )

    }

}
