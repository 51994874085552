import React, {Component} from 'react';
import {Button, Modal,} from "antd";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

class AvatarUploader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            avatarPreview: null,
            submitting: false,
            fileWithMeta: null,
            uploading: false,
            imageError: null,
            src: null,
            cropModalVisible: false,
            crop: {
                x: 0,
                y: 0,
                unit: '%',
                width: 100,
                height: 100,
                aspect: 1,
            },
        };

    }

    componentDidMount() {

        const { user } = this.props;

        if(user.avatar && user.avatar.file) {
            this.setState({
                avatarPreview: user.avatar.file,
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.user.avatar !== prevProps.user.avatar) {
            this.setState({
                avatarPreview: (this.props.user.avatar && this.props.user.avatar.file ? this.props.user.avatar.file : null),
            });
        }
    }

    handleChangeStatus = (fileWithMeta, status) => {

        if(fileWithMeta.meta.status === 'error_file_size') {
            this.setState({ imageError: 'File size must be less than 5MB!'});
            fileWithMeta.remove();
        }

        if(fileWithMeta.meta.status === 'done') {

            const reader = new FileReader();

            reader.addEventListener('load', () =>
                this.setState({ src: reader.result }),
            );

            reader.readAsDataURL(fileWithMeta.file);

            this.setState({
                fileWithMeta: fileWithMeta,
                cropModalVisible: true
            });

            return false;

        }

    };

    /* Preview Modal */

    generatePreview = () => {

        const { cropModalVisible, src, crop, uploading } = this.state;

        return (
            <div>
                <div className={'avatar-preview'}>
                    <img src={src} alt=""/>
                </div>
                <Modal
                    title="Preview & Adjust Image"
                    visible={cropModalVisible}
                    onOk={this.upload}
                    onCancel={this.cancelPreview}
                    footer={[
                        <Button key="back" onClick={this.cancelPreview} disabled={uploading}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={uploading} onClick={this.handleOnSubmit}>
                            Upload
                        </Button>,
                    ]}
                >
                    {src && (
                        <ReactCrop
                            src={src}
                            crop={crop}
                            minWidth={100}
                            minHeight={100}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    )}
                </Modal>
            </div>
        )

    }

    cancelPreview = () => {

        const { fileWithMeta } = this.state;

        fileWithMeta.remove();

        this.setState({
            fileWithMeta: null,
            cropModalVisible: false,
            src: null,
        });

    };

    /* React Crop */

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, pixelCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    /* Form Handling */

    handleOnSubmit = () => {
        // const { dispatch } = this.props;
        const { user } = this.props;
        const { fileWithMeta: { file, remove }, crop } = this.state;
        const image = this.imageRef;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const formData = new FormData();
        const reader = new window.FileReader();

        this.setState({
            uploading: true,
        });

        reader.readAsDataURL(file);

        reader.onload = () => {

            formData.append('file', file);
            formData.append('type', 'avatar');

            formData.append('crop_x', crop.x * scaleX);
            formData.append('crop_y', crop.y * scaleY);
            formData.append('crop_width', crop.width * scaleX);
            formData.append('crop_height', crop.height * scaleY);
            formData.append('crop_aspect', crop.aspect);

            this.props.onSubmit(user.id, formData)
                .then(() => {
                    remove();

                    this.setState({
                        uploading: false,
                        fileWithMeta: null,
                        cropModalVisible: false,
                        src: null,
                    });
                });

        }

    };

    resetUpload = () => {

        const { user } = this.props;

        this.setState({
            avatarPreview: this.state.avatarPreview ? null : (user.avatar && user.avatar.file ? user.avatar.file : null),
        });
    };

    /* Render */

    render() {

        const { user } = this.props;
        const { avatarPreview } = this.state;

        return (
            <div className={'media-uploader'}>
                {
                    user && !avatarPreview &&
                    <>
                        <Dropzone
                            onChangeStatus={this.handleChangeStatus}
                            onSubmit={true}
                            accept="image/*"
                            maxFiles={1}
                            maxSizeBytes={(1024 * 1024) * 5}
                            PreviewComponent={this.generatePreview}
                            SubmitButtonComponent={null}
                            inputWithFilesContent={null}
                            inputContent={
                                <div className={'upload-cta'} key={'upload-cta'}>
                                    <span>Drag File or Click to Browse</span>
                                    {
                                        !this.state.imageError &&
                                        <span className={'disclaimer'}>Max File Size: 5MB</span>
                                    }
                                    {
                                        this.state.imageError &&
                                        <span className={'disclaimer error'}>Error: { this.state.imageError }</span>
                                    }
                                </div>
                            }
                        />
                        {
                            user.avatar &&
                            user.avatar.file &&
                            <div className="media-uploader--actions">
                                <Button type={'danger'} icon={'close'} onClick={this.resetUpload} ghost>Cancel</Button>
                            </div>
                        }
                    </>
                }
                {
                    user && avatarPreview && user.avatar && user.avatar.file &&
                    <>
                        <div className={'avatar-preview'}>
                            <img key={avatarPreview} src={user.avatar.file} alt=""/>
                        </div>
                        <div className="media-uploader--actions">
                            <Button type={'primary'} icon={'upload'} onClick={this.resetUpload}>Update Avatar</Button>
                        </div>
                    </>
                }
            </div>
        );
    }

}

export default AvatarUploader;
