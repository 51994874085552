import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Col, Divider, Form, Icon, Input, Row, Button} from "antd";
import copy from 'copy-to-clipboard';

const { Search } = Input;

class Share extends Component {

    constructor(props) {
        super(props);

        this.state = {
            copied: false,
        }

    }

    onCopy = (text) => {

        copy(text, {
            format: 'text/plain',
        });

        this.setState({copied: true});

        setTimeout(() => {
            this.setState({copied: false});
        }, 2500);

    };

    render() {

        const { user: { providerUrl } } = this.props;

        return (
            <div className={"page page-share"}>
                <Row>
                    <Col xs={24} xl={16}>
                        <div className={'title-module'}>
                            <h1><Icon type="share-alt" /> Share</h1>
                            <p>Copy and share your link below, or share a custom landing page.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <Row>
                    <Col xs={24} xl={16}>
                        <Form layout="vertical" hideRequiredMark>
                            <Form.Item label={'My Link'}>
                                <div className={'share-link'}>
                                    <Search
                                        value={providerUrl}
                                        enterButton="Copy URL"
                                        onSearch={() => { this.onCopy(providerUrl) }}
                                    />
                                </div>
                                <p className={`clipboard ${this.state.copied ? 'copied' : ''}`}>Your share link has been copied to your clipboard!</p>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <h6>My Share Page</h6>
                        <Row gutter={20} type="flex" className={'integrations-list'}>
                            <Col sm={24} xl={12}>
                                <div size="small" className="preview-card">
                                    <img className="preview-image" src="/images/share-page-preview.png"  alt="share-page-preview" />
                                    <Button type="primary" onClick={() => { this.onCopy(providerUrl) }}><span>Copy my URL</span></Button>         
                                    <Button type="primary" href={providerUrl} target="blank" ghost><span>Preview my Page</span></Button>         
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({ user: state.user.user });

export default connect(mapStateToProps, null)(Form.create()(Share));
