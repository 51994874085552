import React, {PureComponent} from 'react';
import * as userActions from '../actions/user'
import {connect} from 'react-redux';
import Login from "./Login";
import {Button, Card, Icon, Spin} from "antd";
import client from "../actions";
import queryString from "query-string";

class OAuth extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            client: null,
            authorizing: false,
            verifying: false,
            error: false,
        };
    }

    componentDidMount() {
        this.verifyQuery();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.user && !prevProps.user.token && this.props.user.token) {
            this.verifyQuery();
        }

    }

    verifyQuery() {

        const query = queryString.parse(this.props.location.search);
        if(query.client_id) {
            this.setState({ verifying: true });
            client.get(`/provider/oauth/verify-client/${query.client_id}`)
                .then(({ data }) => {
                    this.setState({ client: data.client_name, verifying: false, error: false});
                })
                .catch((err) => {
                    this.setState({ verifying: false, error: true});
                });

        }

    }

    onAuthorize = () => {

        this.setState({authorizing: true});
        client.get('/provider/oauth/authorize' + this.props.location.search)
            .then(({ data }) => {
                if(data.redirect_uri) {
                    window.location.href = data.redirect_uri;
                    return null;
                }
            }).catch(err => {
                this.setState({authorizing: false});
            });

    };

    render() {
        const { client, verifying, authorizing, error,  } = this.state;
        const { user } = this.props;

        if(!user.token) {
            return <Login />
        }

        return (
            <div className={'page-oauth'}>
                {
                    verifying &&
                    <>
                        <h1>Verifying OAuth 2.0 client...</h1>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px 0'}}>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                        </div>
                    </>
                }
                {
                    error &&
                    <>
                        <h1>No valid OAuth 2.0 configured.</h1>
                    </>
                }
                {
                    client &&
                    <>
                        <Card>
                            <img className="logo" src={`/images/integrations/clients/${client}.jpg`} alt={`${client} Logo`}/>
                            <h2>Authorize { client }</h2>
                            <p>{ client } will receive the following information from your Digs account:</p>
                            <div className="list-container">
                                <ul>
                                    <li><Icon type="check-circle" theme="filled" /> Your first name, last name and email</li>
                                    <li><Icon type="check-circle" theme="filled" /> Digs Saver list and associated data</li>
                                </ul>
                            </div>
                            <Button type="primary" size="large" loading={authorizing} onClick={this.onAuthorize} className={'button primary login--button'}>
                                Authorize & Continue
                            </Button>
                        </Card>
                        <p className={'logout'}>Not {user.user.firstname}? <a href={'#'} onClick={(e) => {e.preventDefault(); this.props.logoutUser();}}>Sign in as someone different.</a></p>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ user }, ownProps) => ({ user: user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuth)
