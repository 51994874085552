import React from 'react';

const Pulse = (props) => {

    return (
        <div className="pulse">
            <div className="pulse--dot" />
            <div className="pulse--text">{ props.children }</div>
        </div>
    )

};

export default Pulse;
