import React, {Component} from "react";
import {Button, Card, Table,} from "antd";
import DigsHelper from "../../../helpers/DigsHelper";
import client from '../../../actions'
import moment from 'moment';

class InvoiceModule extends Component {

    constructor(props){
        super(props);

        this.state = {
            invoices: [],
            loadingInvoices: false,
        }
    }

    componentDidMount() {
        this.fetchInvoices();
    }

    fetchInvoices = () => {

        this.setState({ loadingInvoices: true });

        client.get(`/provider/subscription/invoices`)
            .then(({ data }) => {
                console.log(data);
                this.setState({
                    loadingInvoices: false,
                    invoices: data.invoices,
                });

            });

    }

    renderInvoiceTable = () => {

        const columns = [
            {
                title: 'Date',
                dataIndex: 'Date',
                key: 'date',
                render: date => <span>{ moment(date).format('ddd, MMM DD, YYYY')}</span>,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: amount => <span>{ DigsHelper.formatMoney(amount / 100)}</span>,
                align: 'center'
            },
            {
                title: 'Reciept',
                dataIndex: 'download',
                render: (download, record) => <Button onClick={() => { this.downloadInvoice(record) }} >Download</Button>,
                align: 'right'
            },
        ];

        return <Table
            columns={columns}
            dataSource={this.state.invoices}
            pagination={{
                pageSize: 3,
            }}
        />

    }

    downloadInvoice = (record) => {

        const date = moment(record.date).format('YYYY-MM-DD');
        console.log(record);
        client.get(`/provider/subscription/invoice/${record.id}`, {
            responseType: 'blob'
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DigPro-Invoice-${date}.pdf`);
                document.body.appendChild(link);
                link.click();
            });
    };

    render() {

        return (
            <Card title={'Invoices'} className={'invoices'} loading={this.state.loadingInvoices}>
                <h3>Payment History</h3>
                { this.renderInvoiceTable() }
            </Card>
        );
    }
}

export default InvoiceModule;
