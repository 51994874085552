import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Button, Form, Icon, Input, message} from "antd";
import {SEND_PARTNERSHIP_PENDING} from "../../../actions/types";
import * as partnershipsActions from "../../../actions/partnerships";

class LinkWithAgent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            inputFields: [<Input placeholder={'Agent Email'} />],
            success: false,
        }

    }

    onSendInvitation = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const emails = [];
                for (let prop in values) {
                    if(values.hasOwnProperty(prop)) {
                        emails.push(values[prop]);
                    }
                }

                let invites = [];

                emails.forEach((address) => {
                    invites.push({
                        sender_id: this.props.user.id,
                        receiver_email: address,
                    });
                });

                this.props.sendPartnership({
                    invites: invites,
                })
                    .then(() => {
                        message.success('Invite(s) successfully sent.');
                        this.setState({
                            success: invites.length,
                            inputFields: [<Input placeholder={'Agent Email'} />],
                        });
                        this.props.form.resetFields();
                    })
                    .catch()
                    .finally();
            }
        });
    };

    addInputField = (e) => {
        e.preventDefault();
        const { inputFields } = this.state;

        inputFields.push(<Input placeholder={'Agent Email'}/>);

        this.setState({
            inputFields: inputFields,
        })

    };

    removeInputField = (index) => {
        const { inputFields } = this.state;
        inputFields.splice(index, 1);

        this.setState({
            inputFields: inputFields
        });
    };

    generateInputForm = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { inputFields } = this.state;
        const { action } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <Form onSubmit={this.onSendInvitation}>
                {
                    inputFields.map((field, i) => {
                        return (<Form.Item key={i}>
                            {
                                getFieldDecorator(`email_0${i}`, {
                                    rules: [{type: 'email', required: true, message: null}],
                                })(
                                    <Input placeholder={'Agent Email'} />
                                )
                            }
                            {
                                inputFields.length > 1 &&
                                <Icon type="minus-circle" onClick={() => {this.removeInputField(i)}}/>
                            }
                        </Form.Item>)
                    })
                }
                <a href={'#'} onClick={(e) => {this.addInputField(e)}}>+ Add Another</a>
                <Button htmlType={'submit'} loading={type === SEND_PARTNERSHIP_PENDING} className={'button primary'}>Send Invitation(s)</Button>
            </Form>
        )

    };

    render() {

        return (
            <div className={'page-onboarding page-onboarding-link fadeIn'}>
                {
                    !this.state.success &&
                    <div>
                        <div className="title-block max-width">
                            <p>Invite your preferred real estate agents to easily share prospects and help your clients get pre-approved.</p>
                        </div>
                        <div className={'invite-module'}>
                            {
                                this.generateInputForm()
                            }
                        </div>
                        <Link href="#" className={'skip'} to={'/onboarding/prospects'}>Skip this for now</Link>
                    </div>
                }
                {
                    this.state.success &&
                    <div>
                        <div className={'invite-module success'}>
                            <img src='/images/icons/loading-success.svg' alt={""}/>
                            <p style={{ marginBottom: '35px'}}><b>{ this.state.success } Agent(s) successfully invited!</b></p>
                            <Button htmlType={'submit'} onClick={(e) => { e.preventDefault(); this.props.history.push('/onboarding/prospects') }} className={'button primary'}>Continue</Button>
                        </div>
                        <a href="#" className={'skip'} onClick={(e) => { e.preventDefault(); this.setState({ success: false}) }}>Want to invite more agents?</a>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = ({ user, partnerships }) => ({ user: user.user, partnerships: partnerships.list, action: partnerships.action, errors: partnerships.errors });
const mapDispatchToProps = {
    ...partnershipsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(LinkWithAgent)));
