import React, {Component} from 'react';

import {Button, Form, Input, message, Modal, Select} from 'antd';
import {feedCategorySelector, feedOrderedSelector} from "../../reducers/feed";
import {
    createFeedCategory,
    updateFeedCategory,
    deleteFeedCategory, DELETE_FEED_CATEGORY_PENDING, UPDATE_FEED_CATEGORY_PENDING, CREATE_FEED_CATEGORY_PENDING
} from "../../actions/feed";
import {connect} from "react-redux";

class CategoryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmDelete: false,
            categorySelect: null,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.visible && this.props.visible) {
            this.resetModal();
        }
    }

    resetModal = () => {
        this.setState({confirmDelete: false});
    };

    confirmDelete = () => {

        const { articleCount } = this.props;
        if(articleCount && articleCount > 0) {
            this.setState({ confirmDelete: true});
        } else {
            this.onDelete();
        }
    };

    generateCategorySelect = () => {

        const { category, categories } = this.props;

        return (
            <Select
                onChange={(val) => { this.setState({ categorySelect: val }) }}
            >
                {
                    category &&
                    categories.filter((c) => c.id !== category.id).map((c) => (
                        <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
                    ))
                }
                <Select.Option key={'none'} value={null}>Uncategorized</Select.Option>
            </Select>
        )
    };

    generateFooter = () => {

        const { category, action } = this.props;
        const { confirmDelete, categorySelect } = this.state;
        const isEdit = !!category;

        const type = (action && action.type) ? action.type : null;

        if(isEdit) {

            if(confirmDelete) {

                return (
                    <div className={'footer'}>
                        <div className="footer--left">
                            <Button onClick={() => { this.setState({ confirmDelete: false, categorySelect: null })}}>
                                ← Back
                            </Button>
                        </div>
                        <div className="footer--right">
                            {
                                categorySelect &&
                                <Button type="primary" onClick={this.onDelete} loading={type === DELETE_FEED_CATEGORY_PENDING}>
                                    Reassign & Delete
                                </Button>
                            }
                            {
                                !categorySelect &&
                                <Button type="primary" onClick={this.onDelete} loading={type === DELETE_FEED_CATEGORY_PENDING}>
                                    Skip & Delete
                                </Button>
                            }
                        </div>
                    </div>
                )

            }

            return (
                <div className={'footer'}>
                    <div className="footer--left">
                        <Button type="danger" ghost onClick={this.confirmDelete} loading={type === DELETE_FEED_CATEGORY_PENDING} disabled={type === UPDATE_FEED_CATEGORY_PENDING}>
                            Delete
                        </Button>
                    </div>
                    <div className="footer--right">
                        <Button type="primary" onClick={this.onSave} loading={type === UPDATE_FEED_CATEGORY_PENDING} disabled={type === DELETE_FEED_CATEGORY_PENDING}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            )

        }

        return (
            <div className={'footer'}>
                <div className="footer--right">
                    <Button type="primary" onClick={this.onSave} loading={type === CREATE_FEED_CATEGORY_PENDING}>
                        Save Category
                    </Button>
                </div>
            </div>
        )

    };

    // Save

    onSave = () => {

        const { category, form, createFeedCategory, updateFeedCategory } = this.props;

        form.validateFields((err, values) => {
            if (!err) {

                if(category) {

                    const updatedCategory = {
                        ...category,
                        ...values
                    };

                    updateFeedCategory(updatedCategory)
                        .then(() => {
                            this.props.onCancel();
                            message.success('Category updated successfully.');
                        })
                        .catch((err) => {
                            console.log(err)
                        });

                } else {
                    createFeedCategory(values)
                        .then(() => {
                            this.props.onCancel();
                            message.success('Category saved successfully.');
                        })
                        .catch((err) => {
                            console.log(err)
                        });
                }

            }
        });

    };

    onDelete = () => {

        const { category, form, deleteFeedCategory } = this.props;

        form.validateFields((err, values) => {
            if (!err) {

                if(values.hasOwnProperty('category_id')) {

                    deleteFeedCategory(category, values.category_id)
                        .then(() => {
                            this.props.onCancel();
                            message.success('Category deleted successfully.');
                        })
                        .catch((err) => {
                            console.log(err)
                        });

                } else {
                    deleteFeedCategory(category)
                        .then(() => {
                            this.props.onCancel();
                            message.success('Category deleted successfully.');
                        })
                        .catch((err) => {
                            console.log(err)
                        });
                }
            }
        });

    };

    render() {

        const { category, articleCount } = this.props;
        const { confirmDelete } = this.state;
        const { getFieldDecorator } = this.props.form;
        const isEdit = !!category;

        return (
            <Modal
                className={'page-content-feed--modal'}
                destroyOnClose
                title={isEdit ? "Edit Category" : "Add Category"}
                visible={this.props.visible}
                onCancel={() => this.props.onCancel()}
                footer={[this.generateFooter()]}
            >
                <Form colon={false} hideRequiredMark={true}>
                {
                    !confirmDelete &&

                        <Form.Item label="Category Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter a category name.' }],
                                initialValue: isEdit ? category.name : null
                            })(<Input placeholder="My New Category" />)}
                        </Form.Item>
                }
                {
                    confirmDelete &&
                    <>
                        <h2>Are you sure you want to delete this category?</h2>
                        <p>You currently have <b>{ articleCount } articles</b> assigned to this category. You can reassign them below or click "Skip & Delete" to proceed.</p>
                        <Form.Item>
                            {getFieldDecorator('category_id', {
                                initialValue: null,
                            })(this.generateCategorySelect())}
                        </Form.Item>
                    </>
                }
                </Form>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => ({
    action: state.feed.action,
    categories: feedCategorySelector(state)
});

const mapDispatchToProps = {
    createFeedCategory,
    updateFeedCategory,
    deleteFeedCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CategoryModal));
