import React, {PureComponent} from 'react';
import {Col, Divider, Icon, Row} from 'antd'
import UnlockFeature from '../../../components/auth/UnlockFeature';
import IntegrationsGrid from '../../../components/integrations/IntegrationsGrid';

class Index extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    render() {

        return (
            <div className={"page page-integrations"}>
                <Row>
                    <Col xs={24} xl={16}>
                        <div className={'title-module'}>
                            <h1><Icon type="deployment-unit" /> Integrations</h1>
                            <p>Sync prospect data with popular web services to streamline your sales funnel.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <UnlockFeature>
                    <IntegrationsGrid />
                </UnlockFeature>
            </div>
        );
    }

}

export default Index;
