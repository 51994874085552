import {
    FETCH_SOCIAL_ERROR,
    FETCH_SOCIAL_PENDING,
    FETCH_SOCIAL_SUCCESS,
    UPDATE_SOCIAL_ERROR,
    UPDATE_SOCIAL_PENDING,
    UPDATE_SOCIAL_SUCCESS
} from "./types";

import client from './';

export const fetchSocial = () => {

    return (dispatch) => {
        dispatch({type: FETCH_SOCIAL_PENDING});

        return new Promise((resolve, reject) => {
            client.get(`/provider/social`)
                .then(response => {
                    dispatch({type: FETCH_SOCIAL_SUCCESS, payload: response.data.social});
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: FETCH_SOCIAL_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const linkSocial = (data) => {

    return (dispatch) => {
        dispatch({type: UPDATE_SOCIAL_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/social`, { ...data, type: 'provider' })
                .then(response => {
                    dispatch({
                        type: UPDATE_SOCIAL_SUCCESS,
                        payload: response.data.social
                    });
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_SOCIAL_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

