import React, {Component} from 'react'
import {Button, Card, Col, Divider, Icon, message, Row, Spin} from 'antd';
import MilestoneCard from "../../components/milestones/MilestoneCard";
import {ReactSortable} from "react-sortablejs";
import uniqueId from "lodash/uniqueId";
import {connect} from "react-redux";
import isEqual from 'lodash/isEqual';
import {milestonesOrderedSelector} from '../../reducers/milestones';

import {
    createMilestone,
    deleteMilestone,
    fetchMilestones,
    resetMilestones,
    updateMilestone,
    updateMilestonesOrder,
    uploadMilestoneMedia
} from "../../actions/milestones";

const defaultMilestone = {
    title: '',
    published: false,
    type: 'fixed',
    value: 100,
    description: 'This is a custom milestone. Provide details to help inform your clients on an important, cost-specific topic they should be aware of',
    media_type: 'none'
};

class Milestones extends Component {

    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            resetting: false,
            milestones: {},
            historicalOrder: [],
            activeMilestones: [],
            inactiveMilestones: [],
            milestonesLoading: [],
            milestonesOpen: null,
        };

    }

    componentDidMount() {
        this.fetchAndProcessMilestones();
    }

    fetchAndProcessMilestones() {

        this.setState({loading: true});

        this.props.fetchMilestones()
            .then(() => {
                this.processMilestones();
            });

    };

    processMilestones() {

        const active = this.props.milestones.filter((m) => m.published).map(m => ({ id: m.id}));
        const inactive = this.props.milestones.filter((m) => !m.published).map(m => ({ id: m.id }));
        const milestones = {};

        for (let x = 0; x < this.props.milestones.length; x++) {

            this[`${this.props.milestones[x].id}_ref`] = React.createRef();

            milestones[this.props.milestones[x].id] = {
                published: {
                    ...this.props.milestones[x]
                }
            };
        }

        this.setState({
            historicalOrder: active,
            activeMilestones: active,
            inactiveMilestones: inactive,
            milestones: milestones,
            loading: false,
        });

    };

    /* Ordering */

    updateActiveList = (updatedList) => {
        this.setState({activeMilestones: updatedList});
    };

    updateInactiveList = (updatedList) => {
        this.setState({inactiveMilestones: updatedList});
    };

    hasOrderChanged = () => {

        const historicalOrder = this.state.historicalOrder.map(o => o.id);
        const activeOrder = this.state.activeMilestones.map(o => o.id);
        return !isEqual(historicalOrder, activeOrder);

    };

    createCustomMilestone = () => {

        const milestones = this.state.milestones;

        const customId = `custom-${uniqueId()}`;

        milestones[customId] = {
            id: customId,
            draft: {
                id: customId,
                ...defaultMilestone,
                title: `Custom Milestone ${this.state.inactiveMilestones.length + 1}`
            }
        };

        this.setState({
            milestones: milestones,
            inactiveMilestones: [...this.state.inactiveMilestones, { id: customId}],
            milestonesOpen: customId
        }, () => {
            this.scrollTo();
        });

    };

    /* Prop functions */

    updateMilestoneOrder = () => {
        message.loading('Saving milestone order...', 0);
        const milestoneOrder = this.state.activeMilestones.map(m => m.id);

        this.props.updateMilestonesOrder(milestoneOrder)
            .then(() => {
                this.processMilestones();
                message.destroy();
                message.success('Milestone(s) updated successfully.');
            })
            .catch(() => {
                message.destroy();
                message.error('An error occurred.');
            });
    };

    onMilestoneEdit = (milestoneId, payload) => {

        const milestones = this.state.milestones;
        let updatedMilestone = milestones[milestoneId];

        const updates = {};

        for (let key in payload) {
            if (payload.hasOwnProperty(key)) {
                updates[payload[key].name] = payload[key].value;
            }
        }

        if(updatedMilestone.draft) {
            updatedMilestone = { ...updatedMilestone, draft: { ...updatedMilestone.draft, ...updates}}
        } else {
            updatedMilestone = { ...updatedMilestone, draft: { ...updatedMilestone.published, ...updates}}
        }

        milestones[milestoneId] = updatedMilestone;

        this.setState({
            milestones: milestones
        });

    };

    onMilestoneSave = (milestoneId, toDraft = false) => {

        const milestones = this.state.milestones;
        let updatedMilestone = milestones[milestoneId];

        this.setState({milestonesLoading: [...this.state.milestonesLoading, milestoneId]});
        message.loading('Saving milestone(s)...', 0);

        if(updatedMilestone.draft) {
            updatedMilestone = { ...updatedMilestone, published: { ...updatedMilestone.draft }, draft: undefined}
        }

        if(isNaN(updatedMilestone.published.id)) {

            this.props.createMilestone(updatedMilestone.published)
                .then((milestone) => {
                    // Display messages
                    this.processMilestones();
                    message.destroy();
                    message.success('Milestone(s) saved successfully.');

                    this.setState({
                        milestonesOpen: null,
                        milestonesLoading: [...this.state.milestonesLoading.filter(m => m !== milestoneId)],
                    });

                })
                .catch(() => {
                    // Display messages
                    message.destroy();
                    message.error('An error occurred.');

                    this.setState({
                        milestonesLoading: [...this.state.milestonesLoading.filter(m => m !== milestoneId)],
                    });

                });

        } else {

            this.props.updateMilestone({
                    ...updatedMilestone.published,
                    published: toDraft ? false : updatedMilestone.published.published,
                    order: toDraft ? null : updatedMilestone.published.order,
                })
                .then(() => {
                    // Display messages
                    this.processMilestones();
                    message.destroy();
                    message.success('Milestone(s) saved successfully.');

                    this.setState({
                        milestonesLoading: [...this.state.milestonesLoading.filter(m => m !== milestoneId)],
                        milestonesOpen: toDraft ? null : this.state.milestonesOpen,
                    });

                })
                .catch(() => {
                    // Display messages
                    message.destroy();
                    message.error('An error occurred.');

                    this.setState({
                        milestonesLoading: [...this.state.milestonesLoading.filter(m => m !== milestoneId)],
                    });

                });

        }

    };

    onMilestoneDelete = (milestoneId) => {

        if(isNaN(milestoneId)) {

            this.setState(({
                inactiveMilestones: [...this.state.inactiveMilestones.filter(m => m.id !== milestoneId)],
                milestonesOpen: null,
            }));

        } else {

            message.loading('Deleting milestone...', 0);

            this.props.deleteMilestone(milestoneId)
                .then(() => {
                    message.destroy();
                    message.success('Milestone deleted successfully.');
                    this.processMilestones();
                    this.setState(({
                        inactiveMilestones: [...this.state.inactiveMilestones.filter(m => m.id !== milestoneId)],
                        milestonesOpen: null,
                    }));
                })
                .catch(() => {
                    message.destroy();
                    message.error('An error occurred.');
                });

        }
    };

    onMilestoneCancel = (milestoneId, callback) => {

        const milestones = this.state.milestones;

        milestones[milestoneId] = {...milestones[milestoneId], draft: null}

        this.setState({
            milestones: milestones
        }, callback);

    };

    onMilestoneToggleOpen = (milestoneId) => {
        if(this.state.milestonesOpen === milestoneId) {
            this.setState({
                milestonesOpen: null,
            })
        } else {
            this.setState({
                milestonesOpen: milestoneId
            });
            this.scrollTo(milestoneId);
        }
    };

    onResetMilestones = () => {
        message.loading('Resetting milestone(s)...', 0);
        this.setState({resetting: true});
        this.props.resetMilestones()
            .then(() => {
                // Display messages
                this.setState({
                    resetting: false,
                    milestonesLoading: [],
                    milestonesOpen: null,
                });
                this.processMilestones();
                message.destroy();
                message.success('Milestone(s) reset successfully.');
            })
            .catch(() => {
                this.setState({resetting: false});
                message.destroy();
                message.error('An error occurred.');
            });
    };

    onMoveMilestoneToDraft = (milestoneId) => {

        this.setState(({
            activeMilestones: [...this.state.activeMilestones.filter(m => m.id !== milestoneId)],
            inactiveMilestones: [...this.state.inactiveMilestones, { id: milestoneId }],
            milestonesOpen: null,
        }));

    };

    /* Milestone Specific States */

    isDefault = () => {
        const defaultMilestones = this.props.milestones.filter(m => isNaN(m.id) && m.id.indexOf('default') > -1);
        return defaultMilestones.length > 0;
    };

    isMilestoneLoading = (milestoneId) => {
        return this.state.milestonesLoading.includes(milestoneId);
    };

    isMilestoneOpen = (milestoneId) => {
        return this.state.milestonesOpen === milestoneId;
    };

    /* Scroll Effect */
    getOffsetTop = (element) => {
        let offsetTop = 0;
        while(element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    };

    scrollTo = (milestoneId) => {

        if(milestoneId) {

            setTimeout(() => {

                window.scrollTo({
                    top: this.getOffsetTop(this[`${milestoneId}_ref`].current) - 70,
                    behavior: 'smooth',
                });

            }, 100);

        } else {

            setTimeout(() => {

                const body = document.body,
                    html = document.documentElement;

                const height = Math.max( body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight );

                window.scrollTo({
                    top: height,
                    behavior: 'smooth',
                })
            }, 100);

        }

    };

    /* Generate order */

    render () {

        const { user } = this.props;
        const { resetting, loading, milestones, activeMilestones, inactiveMilestones } = this.state;
        const isEditable = (user.is_parent_provider || (!user.is_parent_provider && !user.provider_id)) && user.active_subscription;

        return (
            <div className="page page-my-app">
                <Row>
                    <Col xs={24} xl={16} className={'column-max-width'}>
                        <div className={'title-module'}>
                            <h1><Icon type="trophy" /> Milestones</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <Row gutter={24} className={'page-milestones'}>
                    {
                        loading &&
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '100px'}}>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                        </div>
                    }
                    {
                        !loading &&
                        <>
                            <Col xs={24} xl={16}>
                                <div>
                                    {
                                        isEditable &&
                                        <div className="button-row">
                                            <div className={`alert ${!this.hasOrderChanged() ? 'disabled' : ''}`}>
                                                <Button type="primary" disabled={!this.hasOrderChanged()} onClick={this.updateMilestoneOrder}>
                                                    Publish Changes
                                                </Button>
                                            </div>
                                            <Button icon="plus" type="primary" disabled={this.hasOrderChanged() || this.state.milestonesOpen} onClick={this.createCustomMilestone}>
                                                Add Milestone
                                            </Button>
                                        </div>
                                    }
                                    <div>
                                        <ReactSortable
                                            group="milestones"
                                            className={`milestone--list ${this.state.milestonesOpen ? 'drag-disabled' : ''}`}
                                            tag="ul"
                                            sort={!this.state.milestonesOpen && isEditable}
                                            handle=".icon-drag-grid"
                                            list={activeMilestones}
                                            setList={this.updateActiveList}
                                        >
                                            {
                                                activeMilestones.map((m) => {

                                                    return (
                                                        <li
                                                            key={m.id}
                                                            id={`item-${m.id}`}
                                                            className={!this.isMilestoneOpen(m.id) && this.state.milestonesOpen ? 'disabled' : ''}
                                                            ref={this[`${m.id}_ref`]}
                                                        >
                                                            <MilestoneCard
                                                                { ...milestones[m.id]}
                                                                editable={isEditable}
                                                                locked={this.hasOrderChanged()}
                                                                loading={this.isMilestoneLoading(m.id)}
                                                                open={this.isMilestoneOpen(m.id)}
                                                                onToggleOpen={this.onMilestoneToggleOpen}
                                                                onDelete={this.onMilestoneDelete}
                                                                onUpload={this.props.uploadMilestoneMedia}
                                                                onSave={this.onMilestoneSave}
                                                                onCancel={this.onMilestoneCancel}
                                                                onChange={this.onMilestoneEdit}
                                                                onMoveToDraft={this.onMoveMilestoneToDraft}
                                                            />
                                                        </li>
                                                    )

                                                })
                                            }
                                        </ReactSortable>
                                    </div>
                                    <div>
                                        <h3>Drafts</h3>
                                        <ReactSortable
                                            group="milestones"
                                            className={'milestone--list'}
                                            tag="ul"
                                            sort={!this.state.milestonesOpen && isEditable}
                                            handle=".icon-drag-grid"
                                            list={inactiveMilestones}
                                            setList={this.updateInactiveList}
                                        >
                                            {
                                                inactiveMilestones.map((m) => {

                                                    return (
                                                        <li
                                                            key={m.id}
                                                            id={`item-${m.id}`}
                                                            className={!this.isMilestoneOpen(m.id) && this.state.milestonesOpen ? 'disabled' : ''}
                                                            ref={this[`${m.id}_ref`]}
                                                        >
                                                            <MilestoneCard
                                                                { ...milestones[m.id]}
                                                                editable={isEditable}
                                                                subscribed={user.active_subscription}
                                                                locked={this.hasOrderChanged()}
                                                                loading={this.isMilestoneLoading(m.id)}
                                                                open={this.isMilestoneOpen(m.id)}
                                                                onToggleOpen={this.onMilestoneToggleOpen}
                                                                onDelete={this.onMilestoneDelete}
                                                                onUpload={this.props.uploadMilestoneMedia}
                                                                onSave={this.onMilestoneSave}
                                                                onCancel={this.onMilestoneCancel}
                                                                onChange={this.onMilestoneEdit}
                                                                onMoveToDraft={this.onMoveMilestoneToDraft}
                                                            />
                                                        </li>
                                                    )

                                                })
                                            }
                                        </ReactSortable>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Card
                                    title={'About Milestones'}
                                    bordered={false}
                                >
                                    <div className="card--inner">
                                        <p>Drag and drop milestones to re-order or to move a draft to published. You can also move published to drafts.</p>
                                        <Button type="primary"
                                                disabled={this.isDefault() || resetting || !isEditable }
                                                loading={resetting}
                                                icon="sync"
                                                onClick={this.onResetMilestones}
                                        >
                                            Reset to Defaults
                                        </Button>
                                        <p className="subtext">Resetting to Digs’ default milestones will cause you to lose any customizations or additions you’ve made.</p>
                                    </div>
                                </Card>
                            </Col>
                        </>
                    }
                </Row>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    milestones: milestonesOrderedSelector(state)
});

const mapDispatchToProps = {
    fetchMilestones,
    createMilestone,
    updateMilestone,
    deleteMilestone,
    uploadMilestoneMedia,
    updateMilestonesOrder,
    resetMilestones
};

export default connect(mapStateToProps, mapDispatchToProps)(Milestones)
