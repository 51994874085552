import {
    ACCEPT_PARTNERSHIP_ERROR,
    ACCEPT_PARTNERSHIP_PENDING,
    ACCEPT_PARTNERSHIP_SUCCESS,
    DELETE_PARTNERSHIP_ERROR,
    DELETE_PARTNERSHIP_PENDING,
    DELETE_PARTNERSHIP_SUCCESS,
    FETCH_PARTNERSHIPS_ERROR,
    FETCH_PARTNERSHIPS_PENDING,
    FETCH_PARTNERSHIPS_SUCCESS,
    SEND_PARTNERSHIP_ERROR,
    SEND_PARTNERSHIP_PENDING,
    SEND_PARTNERSHIP_SUCCESS,
} from '../actions/types'

const defaultState = {
    list: [],
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_PARTNERSHIPS_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_PARTNERSHIPS_SUCCESS:
            return { ...state, action, list: action.payload, errors: {}, loading: false };
        case FETCH_PARTNERSHIPS_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SEND_PARTNERSHIP_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case SEND_PARTNERSHIP_SUCCESS:
            return { ...state, action, list: action.payload, errors: {}, loading: false };
        case SEND_PARTNERSHIP_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case ACCEPT_PARTNERSHIP_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case ACCEPT_PARTNERSHIP_SUCCESS:
            return { ...state, action, list: action.payload, errors: {}, loading: false };
        case ACCEPT_PARTNERSHIP_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case DELETE_PARTNERSHIP_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case DELETE_PARTNERSHIP_SUCCESS:
            return { ...state, action, list: action.payload, errors: {}, loading: false };
        case DELETE_PARTNERSHIP_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};
