import React from "react";
import {Helmet} from "react-helmet";
import {Button, Icon} from "antd";

const DefaultErrorComponent = ({ componentStack, error }) => (
    <div>
        <Helmet>
            <title>Digs | An error has occurred...</title>
        </Helmet>
        <div className={'default-error'}>
            <h1>Whoops!</h1>
            <p>It looks like an error has occurred. If the error persists, please <a href={'https://help.digs.co/en/'}>contact support.</a></p>
            <Button className={'button primary'} href={"/login"}><Icon type="arrow-left" /> Go Back</Button>
        </div>
    </div>
);

export default DefaultErrorComponent;
