import React, {PureComponent} from 'react';
import * as userActions from '../actions/user'
import {connect} from 'react-redux';
import { withRouter} from 'react-router-dom';
import {Icon, Spin } from 'antd';

class Logout extends PureComponent {

    componentDidMount() {
        const { logoutUser, user, token } = this.props;
        let redirectToMarketplace = null;

        if(!token) {
            this.props.history.push('/');
        } else {

            if(!!(user.active_subscription && user.active_subscription.type === 'KELLERWILLIAMS')) {
                redirectToMarketplace = `https://marketplace.kw.com/applogout?openid=${user.active_subscription.meta}`;
            }

            if(!!(user.active_subscription && user.active_subscription.type === 'REMAX')) {
                redirectToMarketplace = `https://marketplace.remax.com/applogout?openid=${user.active_subscription.meta}`;
            }

            logoutUser()
                .then(() => {
                    if(redirectToMarketplace) {
                        window.location.href = redirectToMarketplace;
                        return null;
                    } else {
                        this.props.history.push('/');
                    }
                });
        }

    }

    render() {

        return (
            <div className={'page-login'}>
                <h1>Securely logging out...</h1>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px 0'}}>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ user }, ownProps) => ({ user: user.user, token: user.token, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout))
