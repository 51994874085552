import React, {Component} from "react";
import {Button, Card, Form, Input, message} from "antd";
import Errors from '../../utils/Errors';
import {connect} from "react-redux";
import { UPDATE_PASSWORD_PENDING } from "../../../actions/types";

import * as userActions from "../../../actions/user";

const FormItem = Form.Item;

class ChangePassword extends Component {

    constructor(props){

        super(props);
        this.state = {
            submitting: false,
            provider: null,
        };

    }

    handleSubmit(e) {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                this.props.updatePassword(values)
                    .then(() => {
                        this.props.form.resetFields();
                        message.success('Password updated')
                    })
                    .catch(() => {
                        message.error('Error.')
                    });
            }
        });

    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords entered do not match!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password_confirmation'], { force: true });
        }
        callback();
    };

    render() {
        const {
            user,
            action,
            form: { getFieldDecorator }
        } = this.props;

        const type = (action && action.type) ? action.type : null;

        return (
            <Card title={'Change Password'}>
                <Form layout="vertical">
                    {user.errors && <Errors errors={user.errors}/>}
                    <FormItem label={'Current Password'}>
                        {getFieldDecorator('current_password', {
                            rules: [{ required: true, message: 'please enter current password' }],
                        })(<Input type={'password'} />)}
                    </FormItem>
                    <FormItem label={'Password'}>
                        {getFieldDecorator('password', {
                            rules: [
                                { required: true, message: 'please enter password' },
                                { min: 6, message: <div>Password must contain at least six characters</div> },
                                { pattern: new RegExp(/\d/), message: <div>Password must contain at least one number</div> },
                                { pattern: new RegExp(/[`~!@#$%^&*()_+=]/),
                                    message: <div>Password must contain at least one special character</div>
                                },
                                {
                                    validator: this.validateToNextPassword,
                                }
                            ],
                        })(<Input type={'password'} />)}
                    </FormItem>
                    <FormItem label={'Password Confirmation'}>
                        {getFieldDecorator('password_confirmation', {
                            rules: [
                                { required: true, message: 'Please confirm your Password!' },
                                {
                                    validator: this.compareToFirstPassword,
                                }
                            ],
                        })(<Input type={'password'} />)}
                    </FormItem>
                    <Button type="primary" onClick={e => this.handleSubmit(e)} loading={type === UPDATE_PASSWORD_PENDING}>
                        Update
                    </Button>
                </Form>
            </Card>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, action: user.action, errors: user.errors});
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChangePassword));
