import React, {PureComponent} from 'react';
import {Card} from 'antd'

class IntegrationsGrid extends PureComponent {

    render() {

        const { data } = this.props;

        return (<Card
            className={'generic-inactive'}
            bordered={false}
        >
            <div className={'generic-inactive--inner'}>
                <div>
                    <h4>Integration Details</h4>
                    <p>{ data.text }</p>
                    {
                        data.list.length > 0 &&
                        <ul>
                            {
                                data.list.map((item, i) => {
                                    return (<li key={i}>{ item }</li>)
                                })
                            }
                        </ul>
                    }
                    {
                        data.button
                    }
                </div>
                <div>
                    <img src={data.image} alt=""/>
                </div>
            </div>
        </Card>);
    }

}

export default IntegrationsGrid;
