import React, {Component} from 'react'
import {PropTypes} from "prop-types";

class AppPreview extends Component {

    static propTypes = {
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        shortBio: PropTypes.string,
        avatar: PropTypes.string,
        logo: PropTypes.string,
        location: PropTypes.string,
        brandColor: PropTypes.string,
    };

    static defaultProps = {
        firstname: 'Jamie',
        lastname: 'Doe',
        shortBio: 'This is a short bio',
        avatar: 'https://picsum.photos/200/200',
        logo: 'https://picsum.photos/100/200',
        location: 'All 50 States',
        brandColor: '#222222',
    };

    constructor (props) {
        super(props);

        this.state = {
            open: false,
        };

    }

    render () {

        const { brandColor, firstname, lastname, location, shortBio, avatar, logo, keywords } = this.props;

        return (
            <div className="app-preview">
                <div className="app-preview__container">
                    <div className="app-preview__shell--underlay">
                        <img src="/images/my-app/iphone-shell.png" alt=""/>
                    </div>
                    <div className="app-preview__screen" style={{ backgroundColor: brandColor }}>
                        <div className="app-preview__screen--top">
                            <div className="app-preview__screen--top-bar">
                                <div className={'arrow'}>
                                    <i className={'icon-arrow-left'}></i>
                                </div>
                                <div className={'heading'}>My Contact</div>
                                <div className={'arrow empty'}>
                                    <i className={'icon-arrow-left'}></i>
                                </div>
                            </div>
                            <div className="app-preview__screen--top-contact">
                                <div className="app-preview__screen--top-avatar">
                                    <img src={avatar} alt=""/>
                                </div>
                                <div className="app-preview__screen--top-name">
                                    { firstname } {  lastname }
                                </div>
                                <div className="app-preview__screen--top-buttons">
                                    <div className="app-preview__screen--top-button">
                                        <i className={'icon-phone'}></i>
                                    </div>
                                    <div className="app-preview__screen--top-button">
                                        <i className={'icon-chat'}></i>
                                    </div>
                                    <div className="app-preview__screen--top-button">
                                        <i className={'icon-mail'}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app-preview__screen--bar">
                            <img src={logo} className="logo" alt=""/>
                            <div className="location">
                                <div className="title">{ location }</div>
                                <div className="label">Location</div>
                            </div>
                        </div>
                        <div className="app-preview__screen--content">
                            <div className="app-preview__screen--content-inner">
                                <div className="title">About { firstname }</div>
                                <div className="text">{ shortBio }</div>
                                <ul className="keywords">
                                    {
                                        keywords && keywords.length > 0 &&
                                        keywords.map(k => <li key={k}>{ k }</li>)
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="app-preview__screen--bottom">
                            <div className="app-preview__screen--bottom-buttons">
                                <div className="app-preview__screen--bottom-button">
                                    <i className={'icon-digs-home'}></i>
                                </div>
                                <div className="app-preview__screen--bottom-button">
                                    <i className={'icon-financials'}></i>
                                </div>
                                <div className="app-preview__screen--bottom-button">
                                    <i className={'icon-icon-hamburger'}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-preview__shell--overlay">
                        <img src="/images/my-app/iphone-shell.png" alt=""/>
                    </div>
                </div>
            </div>
        )
    }

}

export default AppPreview;
