import React, {Component} from 'react';
import {Card, Modal, Button, DatePicker, Form, InputNumber} from "antd";
import DigsHelper from "../../helpers/DigsHelper";
import moment from "moment";

class ValuationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            overwrite_avm: props.property.overwrite_avm,
            /* Form */
            loading: false,
        };

    }

    onSave = () => {

        const { form, property, toggleValuation } = this.props;
        const { overwrite_avm } = this.state;

        if(overwrite_avm) {

            form.validateFields((err, fieldsValue) => {
                if (!err) {

                    this.setState({ loading: true });

                    const values = {
                        custom_valuation_date: moment(fieldsValue.custom_valuation_date).format('YYYY-MM-DD'),
                        custom_valuation_price: fieldsValue.custom_valuation_price,
                        overwrite_avm: overwrite_avm,
                    };

                    this.props.handleSave(property.id, values)
                        .then(() => {
                            this.setState({loading: false});
                            toggleValuation();
                        });
                }
            });

        } else {

            this.setState({ loading: true });

            this.props.handleSave(property.id, { overwrite_avm: overwrite_avm })
                .then(() => {
                    this.setState({loading: false});
                    toggleValuation();
                });

        }
    };

    render() {

        const {
            visible,
            toggleValuation,
            form,
            property
        } = this.props;

        const {
            overwrite_avm
        } = this.state;

        const propertyDetails = {
          customValuationPrice: property.custom_valuation_price ? property.custom_valuation_price : null,
          customValuationDate: property.custom_valuation_date ? moment(property.custom_valuation_date) : null,
          zillowValuationPrice:  property.zillow_valuation_price ? DigsHelper.formatMoney(property.zillow_valuation_price, 0) : '-',
          corelogicValuationPrice: property.corelogic_valuation_price ? DigsHelper.formatMoney(property.corelogic_valuation_price, 0) : '-',
        };

        return (
            <Modal
                maskClosable={false}
                width={675}
                className={'valuation-modal'}
                title="Edit Home Value"
                visible={visible}
                onOk={toggleValuation}
                onCancel={toggleValuation}
                footer={[
                    <Button key="back" disabled={this.state.loading} onClick={toggleValuation}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.loading} onClick={this.onSave}>
                        Save
                    </Button>,
                ]}
            >
                <p>Digs uses Zillow Zestimates&reg; and CoreLogic Data to calculate a home’s value. The Zestimate&reg; and CoreLogic home valuation models are estimates only. They are not appraisals.</p>
                <div className={'valuation-modal--row'}>
                    <Card
                        onClick={() => { this.setState({ overwrite_avm: false })}}
                        className={`valuation-select ${overwrite_avm ? '' : 'active'}`}
                    >
                        <span className={'checkmark'}>
                            <i className={'icon-checkmark'}></i>
                        </span>
                        <h3>Use AVMs</h3>
                        <span className={'avm-price'}>{ propertyDetails.zillowValuationPrice }</span>
                        <img src={'/images/profile/zillow-logo.png'} />
                        <span className={'avm-price'}>{ propertyDetails.corelogicValuationPrice }</span>
                        <img src={'/images/profile/corelogic-logo.jpg'}/>
                    </Card>
                    <Card
                        onClick={() => { this.setState({ overwrite_avm: true })}}
                        className={`valuation-select ${overwrite_avm ? 'active' : ''}`}
                    >
                        <span className={'checkmark'}>
                            <i className={'icon-checkmark'}></i>
                        </span>
                        <h3>Manually Update Home Value</h3>
                        <Form hideRequiredMark={true} colon={false}>
                            <Form.Item label={'Home Value'}>
                                {
                                    form.getFieldDecorator('custom_valuation_price', {
                                        initialValue: propertyDetails.customValuationPrice,
                                        rules: [{ required: true, message: 'Please input a valuation amount.' }],
                                    })(<InputNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />)
                                }
                            </Form.Item>
                            <Form.Item label={'Valuation Date'}>
                                {
                                    form.getFieldDecorator('custom_valuation_date', {
                                        initialValue: propertyDetails.customValuationDate,
                                        rules: [{ required: true, message: 'Please select a valuation date.' }],
                                    })(<DatePicker allowClear={false} format={'MMMM Do YYYY'}/>)
                                }
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Modal>
        );

    }

}

export default Form.create()(ValuationModal);
