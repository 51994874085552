import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import {Form, Steps} from "antd";
/* Onboarding Steps */
import LinkWithLender from '../../components/onboarding/partners/LinkWithLender';
import LinkWithAgent from '../../components/onboarding/partners/LinkWithAgent';

const { Step } = Steps;

class Partnerships extends Component {

    render() {

        const { user } = this.props;

        return (
            <div className={'page-onboarding'}>
                <h1>{ user.agent_lender === 'Agent' ? 'Invite a Partner Lender' : 'Invite Partner Agent(s)'}</h1>
                <div className={'steps'} >
                    <Steps current={1} labelPlacement={'vertical'}>
                        <Step title="Edit Your Profile" />
                        <Step title={`Invite ${user.agent_lender === 'Agent' ? 'a Lender' : 'Agent(s)'}`} />
                        <Step title="Sync Prospects" />
                    </Steps>
                </div>
                {
                    user.agent_lender === 'Agent' &&
                    <LinkWithLender />
                }
                {
                    user.agent_lender !== 'Agent' &&
                    <LinkWithAgent />
                }
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Partnerships));
