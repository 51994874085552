import {
    CLEAR_USER_ATTRIBUTES,
    FETCH_USER_ERROR,
    FETCH_USER_PENDING,
    FETCH_USER_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_PENDING,
    FORGOT_PASSWORD_SUCCESS,
    FUB_CONNECT_PENDING,
    LOGIN_ERROR,
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_PENDING,
    LOGOUT_SUCCESS,
    REGISTRATION_ERROR,
    REGISTRATION_PENDING,
    REGISTRATION_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_PENDING,
    RESET_PASSWORD_SUCCESS,
    SET_USER_ERRORS,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_PENDING,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_USER_ATTRIBUTES,
    UPDATE_USER_ERROR,
    UPDATE_USER_PENDING,
    UPDATE_USER_SUCCESS,
    UPLOAD_USER_ASSET_ERROR,
    UPLOAD_USER_ASSET_PENDING,
    UPLOAD_USER_ASSET_SUCCESS,
} from "./types";
import client from './';

export const loginUser = (data) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_PENDING});
        client.post(`/provider/login`, { ...data, type: 'provider' })
            .then((response) => {
                dispatch({ type: LOGIN_SUCCESS, token: response.data.token, user: response.data.user });
            }).catch((err) => {
            dispatch({
                type: LOGIN_ERROR,
                payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
            });
        })
    }
};

export const loginSocialUser = (data) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_PENDING});
        client.post(`/provider/login/social`, { ...data, type: 'provider' })
            .then((response) => {
                dispatch({ type: LOGIN_SUCCESS, token: response.data.token, user: response.data.user });
            }).catch((err) => {
            dispatch({
                type: LOGIN_ERROR,
                payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
            });
        })
    }
};

export const loginToken = (token) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/login/token`, { token: token, purpose: 'EXTERNAL_AUTH'})
                .then((response) => {
                    dispatch({ type: LOGIN_SUCCESS, token: response.data.token, user: response.data.user });
                    resolve();
                }).catch((err) => {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                reject();
            })
        });
    }
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_PENDING });
        return new Promise((resolve, reject) => {
            client.get(`/provider/logout`)
                .then(() => {
                    dispatch({ type: LOGOUT_SUCCESS });
                    resolve();
                }).catch((err) => {
                    dispatch({
                        type: LOGOUT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
            })
        });
    }
};

export const registerAction = (data) => {

    return (dispatch) => {
        dispatch({type: REGISTRATION_PENDING});
        return new Promise ((resolve, reject) => {
            client.post(`/provider/register`, { ...data, type: 'provider' })
                .then(response => {
                    dispatch({ type: REGISTRATION_SUCCESS, token: response.data.token, user: response.data.user });
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: REGISTRATION_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

};

export const registerSocialAction = (data) => {

    return (dispatch) => {
        dispatch({type: REGISTRATION_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/register/social`, { ...data, type: 'provider' })
                .then(response => {
                    dispatch({ type: REGISTRATION_SUCCESS, token: response.data.token, user: response.data.user });
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: REGISTRATION_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

};

export const fetchUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_PENDING});
    return client.get(`/provider/me`)
        .then(response => {
          if(response) {
            dispatch({
              type: FETCH_USER_SUCCESS,
              payload: response.data.user
            })
          } else {
            dispatch({
              type: FETCH_USER_ERROR,
              // payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
            });
          }

        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: FETCH_USER_ERROR,
            payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
          });
        })
  }
};

export const updateUser = (params) => {

    return (dispatch) => {
        dispatch({type: UPDATE_USER_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/me`, params)
                .then(response => {
                    dispatch({
                        type: UPDATE_USER_SUCCESS,
                        payload: response.data.user
                    });
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_USER_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                });
        });
    }

};

export const updateUserAttributes = (data) => {
    return (dispatch) => {
        return new Promise(function(resolve, reject) {
            if(data === null) {
                dispatch({ type: CLEAR_USER_ATTRIBUTES });
            } else {
                dispatch({ type: UPDATE_USER_ATTRIBUTES, payload: data});
            }
            resolve();
        });
    }
};

export const updatePassword = (params) => {

    return (dispatch) => {
        dispatch({type: UPDATE_PASSWORD_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/update-password`, params)
                .then(response => {
                    dispatch({
                        type: UPDATE_PASSWORD_SUCCESS,
                        payload: response.data.user
                    });
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_PASSWORD_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });

        });
    }
}

export const forgotPasswordAction = (email) => {

    return (dispatch) => {
        dispatch({type: FORGOT_PASSWORD_PENDING});

        return client.post('/provider/password/email', email)
            .then((response) => {
                dispatch({
                    type:FORGOT_PASSWORD_SUCCESS,
                    payload: response.data
                })
            })
            .catch((err) => {
                dispatch({
                    type: FORGOT_PASSWORD_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
            })
    }

};

export const resetPassword = (params) => {

    return (dispatch) => {
        dispatch({type: RESET_PASSWORD_PENDING});

        return client.post('/provider/password/reset', params)
            .then((response) => {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: response.data
                })
            })
            .catch((err) => {
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
            })
    }

};

export const setUserErrors = (errors) => {
    return (dispatch) => {
        dispatch({type: SET_USER_ERRORS, payload: errors});
    }
};

/* Consumer Invites */
export const importSubscribers = (data) => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return client.post(`/provider/subscribers/import`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

};

export const importSingleSaver = (data) => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return client.post(`/provider/subscribers/saver`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

};

export const importSingleHomeowner = (data) => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return client.post(`/provider/subscribers/homeowner`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

};

/* Image Upload */
export const s3Upload = (params) => {
    return (dispatch) => {
        dispatch({type: UPLOAD_USER_ASSET_PENDING});
        return client.post(`/provider/media/upload`, params)
            .then(response => {
                if (response) {
                    dispatch({
                        type: UPLOAD_USER_ASSET_SUCCESS,
                        payload: response.data.media
                    })
                } else {
                    dispatch({
                        type: UPLOAD_USER_ASSET_ERROR,
                    });
                }

            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: UPLOAD_USER_ASSET_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
            })
    }
};

/* Pre Authorization */

