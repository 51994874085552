import React, {PureComponent} from 'react';
import {Col, Divider, Form, Icon, Row, Switch} from 'antd'
import {connect} from 'react-redux';
import SalesforceComponent from '../../../components/integrations/Salesforce';
import history from "../../../helpers/history";
import moment from 'moment';
import * as integrationsActions from "../../../actions/integrations";
import {integrationByService} from "../../../reducers/integrations";

class Salesforce extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            switchDisabled: false,
        }
    }

    componentDidMount() {
        this.props.fetchIntegrations()
            .then(() => {
                this.setState({ loading: false });
            });
    }

    onChange = (checked) => {
        this.setState({
            switchDisabled: true,
        });

        this.props.saveSalesforceConfig({ is_active: checked })
            .finally(() => {
                this.setState({
                    switchDisabled: true,
                });
            });
    };

    render() {

        const salesforce = this.props.salesforce;

        return (
            <div className={"page page-integrations page-integrations-salesforce"}>
                <Row gutter={24}>
                    <Col xs={24} xl={16}>
                        <div className={'title-module with-back'}>
                            <div className={'title-module--container'}>
                                <Icon type="arrow-left" style={{ fontSize: '18px'}} onClick={() => { history.push('/my-app/integrations') }}/>
                                <h1>Salesforce</h1>
                            </div>
                            {
                                salesforce &&
                                <div className={'title-module--switch'}>
                                    <Switch
                                        disabled={false}
                                        defaultChecked={salesforce.is_active}
                                        onChange={this.onChange}
                                    />
                                    <div className={'details'}>
                                        <span className={'status'}>{ salesforce.is_active ? `Active` : `Inactive`}</span>
                                        <span className={'sync'}>Last synced { moment(salesforce.updated_at).fromNow() }</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <SalesforceComponent isActive={!!salesforce} />
            </div>
        );
    }

}

 const mapStateToProps = (state) => ({
     salesforce: integrationByService(state, 'salesforce'),
     action: state.integrations.action
 });

 const mapDispatchToProps = {
     ...integrationsActions,
 };
 export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Salesforce));
