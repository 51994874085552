import {
  FETCH_DASHBOARD_ERROR,
  FETCH_DASHBOARD_PENDING,
  FETCH_DASHBOARD_SUCCESS
} from '../actions/types';

const defaultState = {
  data: {},
  loading: false,
  errors: {},
  action: null
};

export const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case FETCH_DASHBOARD_PENDING:
      return { ...state, action: action.type, loading: true, errors: {} };
    case FETCH_DASHBOARD_SUCCESS:
      return { ...state, action: action.type, errors: {}, loading: false };
    case FETCH_DASHBOARD_ERROR:
      return { ...state, action: action.type, errors: action.payload, loading: false };
    default:
      return state
  }

};
