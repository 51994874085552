export default class DigsHelper {

    static formatMoney (amount = 0, toFixed = 2, withoutCurrency = false) {
        // All money is dollars with two fractional digits...right?
        amount = parseFloat(amount);

        if (!amount) { // check for db null value
            amount = 0
        }
        // return "$" + amount.toFixed(toFixed);
        return `${ withoutCurrency ? '' : '$'}${amount.toLocaleString(undefined, { minimumFractionDigits: toFixed, maximumFractionDigits: toFixed })}`
    }

    static formatPhoneNumber(phoneNumberString) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        let mask = cleaned.match(/^(\d{3})$/)

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        } else if (mask) {
            return '(' + mask[1] + ') ***-****'
        }
        return null
    }

    static validateEmail (email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

}
