export default class StorageService {

  static set(key, data){

    try {
      const serializedState = JSON.stringify(data);
      localStorage.setItem(key, serializedState);
    } catch(err) {
      // Ignore write errors
    }

  }
  static get(key){

    try {

      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);

    } catch (err) {
      return undefined;
    }

  }

  static clearAll(){
    localStorage.clear()
  }
}