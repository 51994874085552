import React, {Component} from 'react';
import queryString from 'query-string';
import {Button, Card, Col, Divider, Form, Icon, Input, Row, Table, Select, Rate, Spin, Tooltip} from 'antd';
import {connect} from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import history from '../../helpers/history';
import DigsHelper from '../../helpers/DigsHelper';

import * as dashboardActions from '../../actions/dashboard';
import { FETCH_DASHBOARD_PENDING } from "../../actions/types";
import {Link, withRouter} from "react-router-dom";

const FormItem = Form.Item;
const { Meta } = Card;

class Everyone extends Component {

    constructor(props) {
        super(props);

        this.state = {
            /* Tile Data */
            everyoneCount: 0,
            /* Table Data */
            tableData: {},
            /* Filter tiles */
            filter: 'everyone',
            filter_value: '',
            /* Search & Filters */
            search: '',
            sort_by: 'created_at',
            sort_order: 'descend',
            /* Table defaults */
            page_size: 20,
        }

    }

    componentDidMount() {
        this.processUrl()
            .then(() => {
                this.fetchItems();
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.location.search !== this.props.location.search) {
            this.processUrl()
                .then(() => {
                    this.fetchItems();
                });
        }

    }

    processUrl = async () => {
        const values = queryString.parse(this.props.location.search);

        return new Promise((resolve, reject) => {

            const validatedValues = {
                filter: 'everyone',
                filter_value: '',
                search: '',
                page: 1,
                sort_by: 'created_at',
                sort_order: 'descend',
                ...values,
            };

            if(values.filter && values.filter_value) {

                if(values.filter === 'everyone') {
                    this.props.form.setFieldsValue({everyone_select: values.filter_value})
                }

            }

            this.setState({ ...validatedValues }, () => { resolve() });

        });

    };

    fetchItems = () => {

        const { search, filter, filter_value, page, sort_by, sort_order } = this.state;

        const params = {
            search,
            page,
            filter,
            filter_value,
            sort_by,
            sort_order,
        };

        this.props.fetchEveryone({ ...params })
            .then((data) => {
                this.setState({ tableData: { ...data }});
            });

        this.props.fetchEveryoneCount(filter_value)
            .then(({everyone_count}) => {
                this.setState({ everyoneCount: everyone_count});
            });

    };

    /* Filter handling */
    updateFilters = (data) => {

        const currentQueryValues = queryString.parse(this.props.location.search);
        const searchParameters = { ...currentQueryValues, ...data};

        for (let propName in searchParameters) {
            if (searchParameters[propName] === null || searchParameters[propName] === undefined) {
                delete searchParameters[propName];
            }
        }

        this.props.history.replace({
            search: "?" + new URLSearchParams(searchParameters).toString()
        });

    };

    handleStandardTableChange = (pagination, filtersArg, sorter) => {

        this.updateFilters({
            page: pagination.current,
            sort_by: sorter.field,
            sort_order: sorter.order,
        });

    };

    handleSearch = e => {
        e.preventDefault();

        this.props.form.validateFields((err, fieldsValue) => {

            if (err) return;

            this.updateFilters({ search: fieldsValue['search'] });

        });
    };

    onDropdownChange = (filter, filter_value) => {
        this.updateFilters({filter, filter_value});
    };

    /* Render Table */

    renderColumns() {

        const { user } = this.props;

        let columns = [
            {
                title: 'Name',
                dataIndex: 'firstname',
                render: (val, record) => {

                    const user_type = record.user_type === 'subscriber' ? 'subscriber' : 'user';

                    return (
                        <div className={'avatar--container'}>
                            <div className={'avatar'}>
                                    <span>
                                        { record.firstname ? record.firstname.charAt(0) : '*' }
                                        { record.lastname ? record.lastname.charAt(0) : '*' }
                                    </span>
                                {
                                    record.through_partnership &&
                                    <div className={'avatar--partnership'}>
                                        <Icon type="link" />
                                    </div>
                                }
                            </div>
                            <div>
                                {
                                    record.through_partnership &&
                                    <span>{record.firstname ? record.firstname : 'null'} {record.lastname ? record.lastname : 'null'}</span>
                                }
                                {
                                    !record.through_partnership &&
                                    <Link to={`/view/${user_type}/${record.id}`}>{record.firstname ? record.firstname : 'null'} {record.lastname ? record.lastname : 'null'}</Link>
                                }
                            </div>
                        </div>
                    )
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: 'Phone',
                dataIndex: 'mobile',
                render: (val, record) => {
                    return val ? (
                        <div className={'mobile'}>
                            <span className={'number'}>{ DigsHelper.formatPhoneNumber(val) }</span>
                            {
                                record.user_type === 'consumer' &&
                                <span className={'verified'}><img src="/images/icons/verified-check.svg" alt=""/></span>
                            }
                        </div>
                    ) : '-';
                },
            },
            {
                title:'# of Properties',
                sorter: true,
                dataIndex: 'properties_count',
                sortOrder: (this.state.sort_by === 'properties_count') && this.state.sort_order,
                render: (val) => user.active_subscription ? val : <i className="icon-lock" />
            },
            {
                title: 'Owned By',
                dataIndex: 'provider',
                render: (val, record) => {
                    if(user.id === record.provider.id) {
                        return 'Me';
                    } else {
                        return `${record.provider.firstname} ${record.provider.lastname}`
                    }
                },
            },
            {
                title:'Added On',
                sorter: true,
                dataIndex: 'created_at',
                render: val => moment(val).format('MMM DD, YYYY'),
                sortOrder: (this.state.sort_by === 'created_at') && this.state.sort_order,
                defaultSortOrder: 'descend',
            },
            {
                title: 'Type',
                dataIndex: 'user_type',
                render: val => val ? (val === 'consumer' ? 'User' : 'Subscriber') : '-'
            }
        ];

        return columns;

    }

    renderEmpty() {

        const { dashboard } = this.props;
        const action = dashboard.action;

        const { filter_value } = this.state;

        if(action === FETCH_DASHBOARD_PENDING) {

            return <div className={'table-empty-state'}></div>

        } else {

            switch (filter_value) {
                case 'total':
                    return (
                        <div className={'table-empty-state'}>
                            <p>No one is using your app yet</p>
                            <Link to={'/import'} className={'button primary small'}>Import Contacts</Link>
                        </div>
                    );
                case 'new':
                    return (
                        <div className={'table-empty-state'}>
                            <p>No new prospects in the last 30 days</p>
                            <Link to={'/import'} className={'button primary small'}>Import Contacts</Link>
                        </div>
                    );
                case 'soon':
                    return (
                        <div className={'table-empty-state'}>
                            <p>No prospects purchasing in the next 6 months</p>
                            <Link to={'/import'} className={'button primary small'}>Import Contacts</Link>
                        </div>
                    );
                default:
                    return (
                        <div className={'table-empty-state'}>
                            <p>No one is using your app yet</p>
                            <Link to={'/import'} className={'button primary small'}>Import Contacts</Link>
                        </div>
                    );
            }

        }



    }

    /* Download CSV */

    downloadCsv = () => {
        this.props.downloadEveryoneExport()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'everyone.xlsx');
                document.body.appendChild(link);
                link.click();
            });
    };

    render() {

        const { user, dashboard, form: { getFieldDecorator} } = this.props;
        const { tableData, search, page, everyoneCount, page_size } = this.state;

        const action = dashboard.action;

        return (
            <div className="page page-dashboard">
                <Row>
                    <Col xs={24} xl={16} className={'column-max-width'}>
                        <div className={'title-module'}>
                            <div className="title-module--row">
                                <h1>Everyone</h1>
                                <Tooltip overlayClassName={'dashboard-tooltip'} placement="right" title={<span><b><i>Everyone</i></b> includes all users who are using your app, but may not have created a property.</span>}>
                                    <span className={'info'}><i className={'icon-info'} /></span>
                                </Tooltip>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    dashboard &&
                    <Row gutter={20} style={{marginBottom: -12}}>
                        <Col xs={24} md={12}>
                            <Card
                                className={'active'}
                                bordered={false}
                            >
                                  <span className={'checkmark'}>
                                      <i className={'icon-checkmark'}></i>
                                  </span>
                                <Meta
                                    title={<>
                                        Everyone&nbsp;
                                        {
                                            getFieldDecorator('everyone_select', {
                                                initialValue: 'total',
                                                onChange: (val) => this.onDropdownChange('everyone', val)
                                            })(
                                                <Select
                                                    dropdownMatchSelectWidth={false}
                                                    dropdownClassName={'dashboard-dropdown'}
                                                >
                                                    <Select.Option value="total"><i className="icon-checkmark" /> Total</Select.Option>
                                                    <Select.Option value="today"><i className="icon-checkmark" /> Today</Select.Option>
                                                    <Select.Option value="last-7"><i className="icon-checkmark" /> Last 7 Days</Select.Option>
                                                    <Select.Option value="last-30"><i className="icon-checkmark" /> Last 30 Days</Select.Option>
                                                </Select>
                                            )
                                        }
                                    </>}
                                    description={numeral(everyoneCount).format('0,0')}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card onClick={() => { history.push('/import') }} className={'dark'} bordered={false}>
                                <Meta
                                    title={<div className={'circle'}><Icon type="cloud-upload" /></div>}
                                    description={'Import Contacts'}
                                />
                            </Card>
                        </Col>
                    </Row>
                }
                <Divider />
                <div className={'digs-table'}>
                    <div className='table-form'>
                        <Form onSubmit={this.handleSearch} layout="inline">
                            <FormItem>
                                {getFieldDecorator('search', {
                                    initialValue: search,
                                })(
                                    <Input
                                        className={'search'}
                                        placeholder="Search Everyone..."
                                        size="large"
                                        prefix={<Icon type="search" style={{ color: '#222222' }} />}
                                        suffix={<Button htmlType="submit"><Icon type="arrow-right" style={{ color: '#5285FF' }} /></Button>}
                                    />
                                )}
                            </FormItem>
                        </Form>
                        <Button icon={user.active_subscription ? "download" : "lock"} type="primary" onClick={() => { this.downloadCsv() }} disabled={tableData.total < 1 || !user.active_subscription} ghost>Export Everyone</Button>
                    </div>
                    <Table
                        showHeader={tableData.total > 0}
                        loading={{
                            spinning: action === FETCH_DASHBOARD_PENDING,
                            indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                        }}
                        dataSource={tableData.data}
                        columns={this.renderColumns()}
                        rowKey={(record) => record.id}
                        pagination={user.active_subscription ? {
                            defaultCurrent: 1,
                            current: tableData.current_page ? tableData.current_page : 1,
                            pageSize: page_size,
                            total: tableData.total,
                            showSizeChanger: false,
                            showQuickJumper: false,
                        } : {
                            defaultCurrent: 1,
                            current: tableData.current_page ? tableData.current_page : 1,
                            pageSize: page_size,
                            total: tableData.total,
                            showSizeChanger: false,
                            showQuickJumper: false,
                        }}
                        scroll={{x: true}}
                        onChange={this.handleStandardTableChange}
                        locale={{ emptyText: this.renderEmpty() }}
                    />
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ dashboard, user }) => ({ dashboard, user: user.user });
const mapDispatchToProps = {
    ...dashboardActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(Everyone)));
