import store from '../store'
import axios from 'axios'
import config from '../config'
import {LOGOUT_SUCCESS} from "./types";

const ROOT_URL = config.REACT_APP_API_URL || 'http://digs:8888';

const client = axios.create({
  baseURL: ROOT_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

client.interceptors.request.use((config) => {
  if (store.getState().user.token) {
    console.log('token is available for this call');
    config.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.getState().user.token}`
    }
  }
  return config
});

client.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    store.dispatch({ type: LOGOUT_SUCCESS });
    console.log('401: Permission denied');
  } else {
    return Promise.reject(error);
  }
});

export default client
