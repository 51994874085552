import React, {Component} from 'react';
import {Card, Col, Row} from "antd";

class CollapsibleCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
            count: props.count ? props.count : props.data.length,
        }
    }

    onShowMore(e) {
        const { showMore } = this.state;

        e.preventDefault();
        this.setState({ showMore: !showMore })
    }

    render() {

        const { showMore, count } = this.state;
        const { data, title } = this.props;

        return (
            <Card
                className={'card'}
                bordered={false}
            >
                <Row>
                    <Col xs={24}>
                        <h2>{ title }</h2>
                        <ul className={'card-details'}>
                            {
                                data.map((detail, i) => (
                                    <li className={`card-details--row ${(i > count - 1) ? (showMore ? '' : 'hidden') : ''}`} key={i}>
                                        <div className="card-details--left">
                                            { detail.title }
                                        </div>
                                        <div className="card-details--right">
                                            { detail.value }
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                        {
                            count !== data.length &&
                            <a href={"#"} className="card-details--action" onClick={(e) => { this.onShowMore(e) }}>{ showMore ? 'See less' : 'See more'}</a>
                        }
                    </Col>
                </Row>
            </Card>
        )
    }

}
export default CollapsibleCard;
