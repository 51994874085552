import React, {Component} from 'react'
import {Button, Form, Icon, Input, Radio, Select, Upload} from 'antd';
import isEqual from 'lodash/isEqual';
import {PropTypes} from "prop-types";
import ReactPlayer from 'react-player'
import {reactPlayerConfig} from "../../constants/reactPlayerConfig";

const { TextArea } = Input;
const { Option } = Select;

class MilestoneCard extends Component {

    static propTypes = {
        title: PropTypes.string,
    };

    constructor (props) {
        super(props);

        this.state = {
            open: false,
            customImageFileSrc: null,
            customImageUrl: !!(props.published && props.published.custom_image_url) ? props.published.custom_image_url : null,
            thumbnailImageUrl: !!(props.published && props.published.image_url) ? props.published.image_url : null,
            uploading: false,
        };

        this.videoRef = React.createRef();

    }

    onSave = (isDraft = false) => {

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSave(this.props.published ? this.props.published.id : this.props.draft.id, isDraft);
            }
        });

    };

    onCancel = () => {
        const { published } = this.props;
        this.props.onCancel(this.props.published.id, () => {
            this.setState({
                customImageFileSrc: null,
                customImageUrl: !!(this.props.published && this.props.published.custom_image_url) ? this.props.published.custom_image_url : null,
                thumbnailImageUrl: !!(this.props.published && this.props.published.image_url) ? this.props.published.image_url : null,
            });
            this.props.form.setFieldsValue(published);
        });
    };

    onUpload(file, onSuccess) {

        const milestoneId = this.props.published ? this.props.published.id : this.props.draft.id;
        const reader = new window.FileReader();

        this.setState({uploading: true});

        reader.readAsDataURL(file);

        reader.onload = (...args) => {

            const formData = new FormData();

            formData.append('file', file);

            this.props.onUpload(milestoneId, formData)
                .then((media) => {
                    this.setState({customImageUrl: media});
                })
                .finally(() => {
                    this.setState({uploading: false});
                    onSuccess('done', file);
                });
        }
    };

    onPreview = (file) => {

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            this.setState({
                customImageFileSrc: reader.result,
                thumbnailImageUrl: null,
            });
            this.props.form.setFieldsValue({
                file: reader.result,
                image_url: null,
            });
        });

        reader.readAsDataURL(file);

        return false;
    };

    /* Video Specific */

    validateVideoUrl = (url, callback) => {
        const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        const youtubeMatch = url.match(youtubeRegExp);

        const vimeoRegExp = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
        const vimeoMatch = url.match(vimeoRegExp);

        let isValid = false;

        if (youtubeMatch && youtubeMatch[2].length === 11) {
            isValid = true;
        }

        if (vimeoMatch && vimeoMatch[1]) {
            isValid = true;
        }

        if(callback) {
            isValid ? callback() : callback(false);
        }

        return isValid;

    };

    populateVideoThumbnail = (mediaUrl) => {

        const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        const youtubeMatch = mediaUrl.match(youtubeRegExp);

        const vimeoRegExp = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
        const vimeoMatch = mediaUrl.match(vimeoRegExp);

        if (youtubeMatch && youtubeMatch[2].length === 11) {
            this.setState({
                customImageFileSrc: null,
                thumbnailImageUrl: `https://img.youtube.com/vi/${youtubeMatch[2]}/maxresdefault.jpg`
            });
            this.props.form.setFieldsValue({
                image_url: `https://img.youtube.com/vi/${youtubeMatch[2]}/maxresdefault.jpg`,
                file: null,
            });
        }

        if(vimeoMatch && vimeoMatch[1]) {
            // console.log(this.videoRef.current);
        }


    };

    renderVideo = (url) => {

        const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        const youtubeMatch = url.match(youtubeRegExp);

        const vimeoRegExp = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
        const vimeoMatch = url.match(vimeoRegExp);

        if (youtubeMatch || vimeoMatch) {
            return <ReactPlayer
                ref={this.videoRef}
                url={url}
                controls={true}
                config={reactPlayerConfig}
            />
        } else {
            return '';
        }

    };

    render () {

        const {
            editable,
            subscribed,
            loading,
            locked,
            open,
            onMoveToDraft,
            onToggleOpen,
            onDelete,
        } = this.props;

        const {
            id,
            published,
            title,
            type,
            value,
            media_type,
            media_url,
            image_url,
            custom_image_url,
            description,
        } = this.props.draft ? this.props.draft : this.props.published;

        const { getFieldDecorator } = this.props.form;

        const hasChanged = this.props.draft ? !isEqual(this.props.draft, this.props.published) : false;

        return (
            <div className={`milestone-card ${open ? 'open' : ''} ${this.props.draft && hasChanged ? 'changed' : ''} ${!editable ? 'locked' : ''}`}>
                <div className="milestone-card__heading">
                    <div className="milestone-card__heading--left">
                        {
                            editable &&
                            <i className={'milestone-card__heading--handle icon-drag-grid'}></i>
                        }
                        <div className="milestone-card--title">{ title }<span>{`${!published ? ' - Draft' : ''}`}</span></div>
                    </div>
                    <div className="milestone-card__heading--right">
                        <button className={`milestone-card__heading--actions black ${((this.props.draft && hasChanged) || locked) ? 'disabled' : ''}`} onClick={() => { onToggleOpen(id) }}>
                            {
                                !editable &&
                                <>
                                    <Icon type="eye" /> View
                                </>
                            }
                            {
                                editable &&
                                <>
                                    <Icon type="setting" theme="filled" /> Edit
                                </>
                            }
                        </button>
                    </div>
                </div>
                {
                    open &&
                    <div className={'milestone-card__inner'}>
                        <div className={`milestone-card__status ${this.props.draft && hasChanged && editable ? 'active' : ''} ${!editable ? 'locked' : ''}`}>
                            <div className="milestone-card__status--inner">
                                {
                                    this.props.draft && hasChanged && editable &&
                                    <>
                                        <div className={'text'}>This milestone has unsaved changes.</div>
                                        <div>
                                            {
                                                this.props.published &&
                                                <Button type="default" onClick={() => { this.onCancel() }}>
                                                    Discard Changes
                                                </Button>
                                            }
                                            <Button type="primary" loading={loading} className="update" onClick={() => { this.onSave() }}>
                                                { !this.props.published ? 'Save Draft' : 'Publish Changes'}
                                            </Button>
                                        </div>
                                    </>
                                }
                                {
                                    !editable && subscribed &&
                                    <>
                                        <div className={'text'}><span>You are in read only mode.</span> Only your system admin can edit milestones.</div>
                                    </>
                                }
                                {
                                    !editable && !subscribed &&
                                    <>
                                        <div className={'text'}><span>You are in read only mode.</span> Upgrade your account to edit.</div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="milestone-card__body">
                            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                                <Form.Item label="Title">
                                    {getFieldDecorator('title', {
                                        rules: [{ required: true, message: 'Please add a title.' }],
                                        initialValue: title,
                                    })(<Input disabled={!editable}/>)}
                                </Form.Item>
                                <Form.Item label="Type">
                                    {getFieldDecorator('type', {
                                        rules: [{ required: true, message: 'Please select a type.' }],
                                        initialValue: type
                                    })(
                                        <Select disabled={!editable}>
                                            <Option value="fixed">Fixed</Option>
                                            <Option value="popp">Percent of Purchase Price</Option>
                                            <Option value="pola">Percent of Loan Amount</Option>
                                            <Option value="dp">Down Payment Amount</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                {
                                    (!type || type === 'fixed') &&
                                    <Form.Item label="Amount">
                                        {getFieldDecorator('value', {
                                            rules: [{ required: true, message: 'Please enter an amount.' }],
                                            initialValue: value,
                                        })(<Input prefix="$" disabled={!editable}/>)}
                                    </Form.Item>
                                }
                                {
                                    (type && (type === 'popp' || type === 'pola')) &&
                                    <Form.Item label="Percent">
                                        {getFieldDecorator('value', {
                                            rules: [{ required: true, message: 'Please enter a percentage.' }],
                                            initialValue: value,
                                        })(<Input suffix="%" disabled={!editable}/>)}
                                    </Form.Item>
                                }
                                <Form.Item label="Description">
                                    {getFieldDecorator('description', {
                                        rules: [{ required: true, message: 'Please add a description.' }],
                                        initialValue: description,
                                    })(<TextArea rows={8} disabled={!editable}/>)}
                                </Form.Item>
                                <Form.Item label="Media">
                                    {getFieldDecorator('media_type', {
                                        rules: [{ required: true, message: 'Please select a media type.' }],
                                        initialValue: media_type,
                                    })(
                                        <Radio.Group disabled={!editable}>
                                            <Radio value={'image'}>Image</Radio>
                                            <Radio value={'video'}>Video</Radio>
                                            <Radio value={'none'}>None</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                {
                                    /* Video */
                                    media_type === 'video' &&
                                    <>
                                        <Form.Item label="Video">
                                            {getFieldDecorator('media_url', {
                                                validateFirst: true,
                                                rules: [
                                                    { required: true, message: 'Please provide a Vimeo or YouTube url.'},
                                                    { validator: (rule, value, callback) => { this.validateVideoUrl(value, callback) }, message: 'Please provide a valid Vimeo or YouTube url.'}
                                                ],
                                                initialValue: media_url
                                            })(
                                                <Input disabled={!editable}/>
                                            )}
                                        </Form.Item>
                                        {
                                            media_url && this.validateVideoUrl(media_url) &&
                                            <>
                                                <Form.Item label="Preview">
                                                    <div className={'milestone-card__video--container'}>
                                                        <div className={'milestone-card__video'}>
                                                            { this.renderVideo(media_url)}
                                                        </div>
                                                    </div>
                                                    {
                                                        editable &&
                                                        <div className={'milestone-card__video--actions'}>
                                                            <span className={'generate-thumbnail'} onClick={() => { this.populateVideoThumbnail(media_url)}}>Generate Thumbnail From Video</span>
                                                        </div>
                                                    }
                                                </Form.Item>
                                                <Form.Item label="Thumbnail">
                                                    {
                                                        (this.state.thumbnailImageUrl || this.state.customImageUrl || this.state.customImageFileSrc) &&
                                                        <div className={'milestone-card__thumbnail--container'}>
                                                            <div className={'milestone-card__thumbnail'}>
                                                                <img src={this.state.thumbnailImageUrl ? this.state.thumbnailImageUrl  : (this.state.customImageFileSrc ? this.state.customImageFileSrc : this.state.customImageUrl)} alt={""}/>
                                                            </div>
                                                            {
                                                                editable &&
                                                                <div className={'milestone-card__thumbnail--actions'}>
                                                                    <a href={'#'} target={'_blank'}>Preview Image</a>
                                                                    <Upload
                                                                        showUploadList={false}
                                                                        beforeUpload={this.onPreview}
                                                                    >
                                                                        <span className={'replace-image'}>Replace Image</span>
                                                                    </Upload>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        (!this.state.customImageFileSrc && !custom_image_url && !this.state.thumbnailImageUrl && !image_url) &&
                                                        <div className={`milestone-card__thumbnail--container bordered`}>
                                                            <div className={'milestone-card__thumbnail'}>
                                                                <Upload
                                                                    showUploadList={false}
                                                                    beforeUpload={this.onPreview}
                                                                >
                                                                    <div className={'upload-button'}>
                                                                        <Icon type={this.state.uploading ? 'loading' : 'plus'} /> Upload Image
                                                                    </div>
                                                                </Upload>
                                                            </div>
                                                        </div>
                                                    }
                                                </Form.Item>
                                                <Form.Item>
                                                    {
                                                        getFieldDecorator('file', {
                                                            initialValue: null,
                                                            validateFirst: true,
                                                            rules: [
                                                                { validator: (rule, value, callback) => { image_url ? callback() : (this.state.customImageFileSrc ? callback() : callback(false)) }, message: 'Please upload or generate an image'},
                                                            ],
                                                        })(
                                                            <Input disabled={true} style={{display: 'none'}}/>
                                                        )
                                                    }
                                                </Form.Item>
                                                <Form.Item>
                                                    {
                                                        getFieldDecorator('image_url', {
                                                            initialValue: image_url,
                                                        })(
                                                            <Input disabled={true} style={{display: 'none'}}/>
                                                        )
                                                    }
                                                </Form.Item>
                                            </>
                                        }
                                    </>
                                }
                                {
                                    /* Image only */
                                    media_type === 'image' &&
                                    <Form.Item label="Image">
                                        {
                                            (this.state.customImageFileSrc || custom_image_url) &&
                                            <div className={'milestone-card__thumbnail--container'}>
                                                <div className={'milestone-card__thumbnail'}>
                                                    <img src={this.state.customImageFileSrc ? this.state.customImageFileSrc : custom_image_url} alt={""}/>
                                                </div>
                                                {
                                                    editable &&
                                                    <div className={'milestone-card__thumbnail--actions'}>
                                                        <a href={this.state.customImageFileSrc} target={'_blank'}>Preview Image</a>
                                                        <Upload
                                                            showUploadList={false}
                                                            beforeUpload={this.onPreview}
                                                        >
                                                            <span className={'replace-image'}>Replace Image</span>
                                                        </Upload>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            !this.state.customImageFileSrc && !custom_image_url &&
                                            <div className={`milestone-card__thumbnail--container bordered`}>
                                                <div className={'milestone-card__thumbnail'}>
                                                    <Upload
                                                        showUploadList={false}
                                                        beforeUpload={this.onPreview}
                                                    >
                                                        <div className={'upload-button'}>
                                                            <Icon type={this.state.uploading ? 'loading' : 'plus'} /> Upload Image
                                                        </div>
                                                    </Upload>
                                                </div>
                                            </div>
                                        }
                                        {
                                            getFieldDecorator('file', {
                                                initialValue: null,
                                                rules: [
                                                    { required: true, message: 'Please upload an image'},
                                                ],
                                            })(
                                                <Input disabled={true} style={{display: 'none'}}/>
                                            )
                                        }
                                    </Form.Item>
                                }
                            </Form>
                        </div>
                        {
                            editable &&
                            <div className="milestone-card__buttons">
                                <div className={'milestone-card__buttons--container'}>
                                    {
                                        !this.props.published &&
                                        <Button type="default" disabled={loading} onClick={() => { onDelete(id) }}>
                                            Discard
                                        </Button>
                                    }
                                    {
                                        !!(this.props.published && !this.props.published.published) &&
                                        <Button type="default" disabled={loading} onClick={() => { onDelete(id) }}>
                                            Delete
                                        </Button>
                                    }
                                    {
                                        !!(this.props.published && this.props.published.published && !hasChanged) &&
                                        <Button type="default" disabled={loading} onClick={() => { onMoveToDraft(id) }}>
                                            Move to Drafts
                                        </Button>
                                    }
                                    {
                                        !!(this.props.published && this.props.published.published && hasChanged) &&
                                        <Button type="default" disabled={loading} onClick={() => { this.onSave(true) }}>
                                            Save & Move to Drafts
                                        </Button>
                                    }
                                </div>
                                <div className={`milestone-card__buttons--container ${this.props.draft && hasChanged ? '' : 'disabled'}`}>
                                    {
                                        this.props.published &&
                                        <Button type="default" disabled={loading || !this.props.draft || !hasChanged} onClick={() => { this.onCancel() }}>
                                            Discard Changes
                                        </Button>
                                    }
                                    <Button type="primary" loading={loading} disabled={!this.props.draft || !hasChanged} className="update" onClick={() => { this.onSave() }}>
                                        { !this.props.published ? 'Save Draft' : 'Publish Changes'}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    };

}

export default Form.create({
    onFieldsChange: (props, changedFields, allFields) => props.onChange(props.published ? props.published.id : props.draft.id, changedFields, allFields),
})(MilestoneCard);
