import React, {Component} from 'react'
import {Button, Col, Divider, Form, Icon, Input, message, Radio, Row, Select} from 'antd';
import * as userActions from "../../actions/user";
import {connect} from "react-redux";
import Errors from "../../components/utils/Errors";
import {UPDATE_USER_PENDING} from "../../actions/types";
import AppPreview from "../../components/milestones/AppPreview";
import ColorInputField from "../../components/ui/ColorInputField";
import AvatarUploader from "../../components/ui/AvatarUploader";
import LogoUploader from "../../components/ui/LogoUploader";
import Milestones from "./Milestones";

class Profile extends Component {

    constructor(props){
        super(props);

        this.state = {
            submitting: false,
            file: null,
            uploading: false,
            imageError: null,
            src: null,
            crop_area_visible: false,
            crop: {
                x: 0,
                y: 0,
                unit: 'px',
                width: 100,
                height: 100,
                aspect: 1,
            },
            draft: null,
        };

    }

    onUploadMedia = (userId, data) => {

        return this.props.s3Upload(data)
            .then(() => {
                this.props.fetchUser();
            });

    };

    handleInputChange = (key, value) => {
        this.onFormChange();
    };

    onSelectChange = (values) => {

        const { draft } = this.state;

        if(!draft) {

            const formFields = this.props.form.getFieldsValue();

            this.setState({
                draft: {
                    ...formFields,
                    keywords: values,
                }
            });

        } else {

            this.setState({
                draft: {
                    ...this.state.draft,
                    keywords: values,
                }
            });

        }

    };

    onFormChange = () => {
        const formFields = this.props.form.getFieldsValue();
        this.setState({
            draft: formFields
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        const { user } = this.props;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.id = user.id;

                this.props.updateUser(values)
                    .then(() => {
                        message.success('Profile successfully updated')
                    })
            }
        });

    };

    /* Scroll Listeners */

    render () {

        const {
            user,
            action,
            form: { getFieldDecorator }
        } = this.props;

        const type = (action && action.type) ? action.type : null;

        const {  draft } = this.state;

        const appPreview = draft ?
            {
                logo: user.logo ? user.logo : null,
                avatar: user.avatar ? user.avatar.file : null,
                brandColor: draft.brand_color,
                firstname: draft.firstname,
                lastname: draft.lastname,
                location: draft.location,
                shortBio: draft.short_bio,
                tags: draft.keywords
            } :
            {
                logo: user.logo ? user.logo : null,
                avatar: user.avatar ? user.avatar.file : null,
                brandColor: user.brand_color,
                firstname: user.firstname,
                lastname: user.lastname,
                location: 'All 50 States',
                shortBio: user.short_bio,
                tags: null
            };

        const shouldDisableParentFields = !user.is_parent_provider && user.provider_id && user.is_rocket;
        const shouldDisableKellerFields = user.active_subscription && (user.active_subscription.type === 'KELLERWILLIAMS' || user.active_subscription.type === 'REMAX');

        return (
            <div className="page page-my-app">
                <Row>
                    <Col xs={24} xl={16} className={'column-max-width'}>
                        <div className={'title-module'}>
                            <h1><Icon type="user" /> Profile</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <div className="edit-provider-profile">
                    <Row gutter={24}>
                        <Col xs={24} xl={12} >
                            <Form layout="vertical" hideRequiredMark onChange={this.onFormChange}>
                                <Row gutter={24}>
                                    {
                                        user.errors &&
                                        <Col span={24}>
                                            <Errors errors={user.errors}/>
                                        </Col>
                                    }
                                    <Col span={24}>
                                        <div className="upload-block">
                                            <div className="upload-block--images">
                                                <div className="upload-block--label">Avatar</div>
                                                <AvatarUploader user={user} onSubmit={this.onUploadMedia}/>
                                            </div>
                                            <div className="upload-block--images">
                                                <div className="upload-block--label">Company Logo</div>
                                                <LogoUploader user={user} onSubmit={this.onUploadMedia}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label={'Brand Color'}>
                                            {getFieldDecorator('brand_color', {
                                                rules: [
                                                    { required: true, message: 'Please enter a brand color' },
                                                    { pattern: new RegExp(/^#[a-f0-9]{6}$/i), message: 'Please enter a valid color' },
                                                ],
                                                initialValue: user.brand_color ? user.brand_color : '#222222',
                                                onChange: this.handleInputChange
                                            })(<ColorInputField disabled={shouldDisableParentFields} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'First Name'}>
                                            {getFieldDecorator('firstname', {
                                                rules: [{ required: true, message: 'Please enter first name' }],
                                                initialValue: user.firstname ? user.firstname : null,
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Last Name'}>
                                            {getFieldDecorator('lastname', {
                                                rules: [{ required: true, message: 'Please enter last name' }],
                                                initialValue: user.lastname ? user.lastname : null,
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Company Name'}>
                                            {getFieldDecorator('company_name', {
                                                rules: [{ required: true, message: 'Please enter company name' }],
                                                initialValue: user.company_name ? user.company_name : null,
                                            })(<Input disabled={shouldDisableParentFields}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Location'}>
                                            {getFieldDecorator('location', {
                                                rules: [{ required: true, message: 'Please enter a location' }],
                                                initialValue: user.location ? user.location : null,
                                            })(<Input />)}
                                            <p className={'sublabel'}>If located in multiple or all states, simply write "Multiple or All 50 States"</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <h3>Contact Information & Biography</h3>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Email'}>
                                            {getFieldDecorator('email', {
                                                rules: [{ required: true, message: 'Please enter email' }],
                                                initialValue: user.email ? user.email : null,
                                            })(<Input disabled={shouldDisableKellerFields}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Phone No.'}>
                                            {getFieldDecorator('mobile', {
                                                initialValue: user.mobile ? user.mobile : null,
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'About You'}>
                                            {getFieldDecorator('short_bio', {
                                                initialValue: user.short_bio ? user.short_bio : null,
                                            })(<Input.TextArea rows={6} disabled={shouldDisableParentFields} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Keyword Tags'}>
                                            {getFieldDecorator('tags', {
                                                onChange: this.onSelectChange,
                                                initialValue: user.tags ? user.tags : [],
                                            })(<Select
                                                mode="multiple"
                                                placeholder={'Please select...'}
                                                style={{ width: '100%' }}
                                            >
                                                <Select.Option value={'FHA'}>FHA</Select.Option>
                                                <Select.Option value={'VA'}>VA</Select.Option>
                                                <Select.Option value={'USDA'}>USDA</Select.Option>
                                                <Select.Option value={'First-time Homebuyers'}>First-time Homebuyers</Select.Option>
                                                <Select.Option value={'Conventional'}>Conventional</Select.Option>
                                                <Select.Option value={'Jumbo'}>Jumbo</Select.Option>
                                                <Select.Option value={'Refinance'}>Refinance</Select.Option>
                                                <Select.Option value={'Fixed'}>Fixed</Select.Option>
                                                <Select.Option value={'Adjustable'}>Adjustable</Select.Option>
                                                <Select.Option value={'Purchase'}>Purchase</Select.Option>
                                                <Select.Option value={'Sell'}>Sell</Select.Option>
                                                <Select.Option value={'Home Equity'}>Home Equity</Select.Option>
                                            </Select>)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <h3>Optional Information</h3>
                                    </Col>
                                    <Col span={24}>

                                    </Col>
                                    <Col span={24}>
                                        <Form.Item>
                                            {getFieldDecorator('mls_nmls', {
                                                rules: [{ required: false, message: 'Please select mls or nmls' }],
                                                initialValue: user.mls_nmls ? user.mls_nmls : null,
                                            })(<Radio.Group>
                                                <Radio value="MLS#">MLS Number</Radio>
                                                <Radio value="NMLS#">NMLS Number</Radio>
                                            </Radio.Group>)}
                                        </Form.Item>
                                    </Col>
                                    {
                                        this.props.form.getFieldValue('mls_nmls') &&
                                        <Col span={24}>
                                            <Form.Item label={this.props.form.getFieldValue('mls_nmls')}>
                                                {getFieldDecorator('mls', {
                                                    rules: [{required: false, message: 'Please enter mls or nmls'}],
                                                    initialValue: user.mls ? user.mls : null,
                                                })(<Input/>)}
                                            </Form.Item>
                                        </Col>
                                    }
                                    <Col span={24}>
                                        <Form.Item label={'LinkedIn Profile URL'}>
                                            {getFieldDecorator('linkedin_url', {
                                                initialValue: user.linkedin_url ? user.linkedin_url : null,
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Zillow Profile URL'}>
                                            {getFieldDecorator('zillow_url', {
                                                initialValue: user.zillow_url ? user.zillow_url : null,
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button type="primary" onClick={e => this.handleSubmit(e)} loading={type === UPDATE_USER_PENDING}>
                                    Save
                                </Button>
                            </Form>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Row gutter={24}>
                                <Col span={24} >
                                    <h4>App Preview</h4>
                                    <AppPreview
                                        {...appPreview}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, action: user.action, errors: user.errors });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Profile));
