import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as userActions from "../../../actions/user";
import {Button, Form} from "antd";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

class Headshot extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showUploader: false,
            avatarPreview: null,
            submitting: false,
            file: null,
            uploading: false,
            imageError: null,
            src: null,
            crop_area_visible: false,
            crop: {
                x: 0,
                y: 0,
                unit: '%',
                width: 100,
                height: 100,
                aspect: 1,
            },
        };

    }

    componentDidMount() {

        const { user } = this.props;

        if(user.avatar && user.avatar.file) {
            this.setState({
                avatarPreview: user.avatar.file,
            });
        }

    }

    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file, cancel, remove, restart}, status) => {
        if(meta.status === 'error_file_size') {
            this.setState({ imageError: 'File size must be less than 5MB!'});
            remove();
        }

        if(meta.status === 'done') {

            this.setState(state => ({
                imageError: null,
                file: file,
            }));

            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result }),
            );
            reader.readAsDataURL(file);

            this.setState({crop_area_visible: true});
            return false;

        }
    };

    generateInputContent = () => {

        return (
            <div className={'upload-cta'} key={'upload-cta'}>
                <span>Drop an image here</span>
                <span>or</span>
                <span className={'button primary'}>Upload an image</span>
                {
                    !this.state.imageError &&
                    <span className={'disclaimer'}>Max File Size: 5MB</span>
                }
                {
                    this.state.imageError &&
                    <span className={'disclaimer error'}>Error: { this.state.imageError }</span>
                }
            </div>
        )

    };

    generatePreview = () => {

        const { src, crop } = this.state;

        return (
            <div>
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        minWidth={100}
                        minHeight={100}
                        style={{width: '100%'}}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                )}
            </div>
        )

    };

    generateActions = (props) => {
        const { uploading } = this.state;
        return (
            <div className={'actions'}>
                <Button onClick={props.files[0].remove}>&lt; Cancel</Button>
                <Button type={'primary'} loading={uploading} onClick={() => { this.handleOnSubmit() }}>Crop Image & Save</Button>
            </div>
        )

    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, pixelCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    handleOnSubmit = () => {
        // const { dispatch } = this.props;
        const { file, crop } = this.state;
        const image = this.imageRef;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const formData = new FormData();

        const reader = new window.FileReader();
        reader.readAsDataURL(file);

        reader.onload = (...args) => {

            formData.append('file', file);
            formData.append('type', 'avatar');

            formData.append('crop_x', crop.x * scaleX);
            formData.append('crop_y', crop.y * scaleY);
            formData.append('crop_width', crop.width * scaleX);
            formData.append('crop_height', crop.height * scaleY);
            formData.append('crop_aspect', crop.aspect);

            this.setState({
                uploading: true,
            });

            this.props.s3Upload(formData)
                .then(() => {
                    this.props.fetchUser()
                        .then(() => {
                            if(this.props.user.avatar && this.props.user.avatar.file) {
                                this.setState({
                                    avatarPreview: this.props.user.avatar.file,
                                });
                            }
                        });
                    this.setState({crop_area_visible: false, uploading: false, showUploader: false});
                });

        }

    };

    goToNextStep = (e = null) => {
        if(e) {
            e.preventDefault();
        }
        this.props.history.push('/onboarding/profile/logo');
    };

    render() {

        const { user } = this.props;
        const { showUploader, avatarPreview } = this.state;

        return (
            <div className={'page-onboarding page-onboarding-headshot fadeIn'}>
                {
                    (showUploader || !avatarPreview) &&
                    <div>
                        <div className="title-block">
                            <h2>Upload Your Headshot</h2>
                            <p>Upload your headshot so your prospects know who you are. This will also make messages more personal.</p>
                        </div>
                        <Dropzone
                            onChangeStatus={this.handleChangeStatus}
                            onSubmit={true}
                            accept="image/*"
                            maxFiles={1}
                            maxSizeBytes={(1024 * 1024) * 5}
                            PreviewComponent={this.generatePreview}
                            SubmitButtonComponent={this.generateActions}
                            inputContent={this.generateInputContent}
                            inputWithFilesContent={null}
                        />
                        {
                            avatarPreview &&
                            <div className="avatar-preview--actions">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ showUploader: false }) }}>Back to preview</a>
                            </div>
                        }
                        {
                            !avatarPreview &&
                            <div className="avatar-preview--actions">
                                <a href="#" onClick={this.goToNextStep}>Skip this for now</a>
                            </div>
                        }
                    </div>
                }
                {
                    !showUploader && avatarPreview &&
                    <div>
                        <div className="title-block">
                            <h2>Your Current Headshot</h2>
                            <p>Preview your headshot below.</p>
                        </div>
                        <div className={'avatar-preview'}>
                            <img src={user.avatar.file} alt=""/>
                        </div>
                        <div className="avatar-preview--actions">
                            <Button type={'primary'} className={'button primary'} onClick={this.goToNextStep}>Continue with selection</Button>
                            <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ showUploader: true }) }}>Upload a different image</a>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(Headshot)));
