import React, {Component} from 'react';
import {Card, Modal, Button, DatePicker, Form, InputNumber, Row, Col} from "antd";
import moment from "moment";

class MortgageModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            /* Form */
            loading: false,
        };

    }

    onSave = () => {

        const { form, property, toggleMortgage } = this.props;

        form.validateFields((err, fieldsValue) => {
            if (!err) {

                this.setState({ loading: true });

                const values = {
                    mortgage: {
                        loan_amount: fieldsValue.loan_amount,
                        loan_origination_date: moment(fieldsValue.loan_origination_date).format('YYYY-MM-DD'),
                        loan_term_length: fieldsValue.loan_term_length,
                        loan_interest_rate: fieldsValue.loan_interest_rate
                    }
                };

                this.props.handleSave(property.id, values)
                    .then(() => {
                        this.setState({loading: false});
                        toggleMortgage();
                    });
            }
        });

    };

    render() {

        const {
            visible,
            toggleMortgage,
            form,
            property
        } = this.props;

        const {
            overwrite_avm
        } = this.state;

        const propertyDetails = {
            loan_amount: property.mortgage &&property.mortgage.loan_amount ? property.mortgage.loan_amount : null,
            loan_term_length: property.mortgage && property.mortgage.loan_term_length ? property.mortgage.loan_term_length : null,
            loan_interest_rate: property.mortgage && property.mortgage.loan_interest_rate ? `${property.mortgage.loan_interest_rate}%` : null,
            loan_origination_date: property.mortgage && property.mortgage.loan_origination_date ? moment(property.mortgage.loan_origination_date) : null,
        };

        return (
            <Modal
                maskClosable={false}
                width={675}
                className={'mortgage-modal'}
                title="Edit Mortgage Details"
                visible={visible}
                onOk={toggleMortgage}
                onCancel={toggleMortgage}
                footer={[
                    <Button key="back" disabled={this.state.loading} onClick={toggleMortgage}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.loading} onClick={this.onSave}>
                        Save
                    </Button>,
                ]}
            >
                <Form hideRequiredMark={true} colon={false}>
                    <Row gutter={20}>
                        <Col xs={24} md={12} lg={12}>
                            <Form.Item label={'Loan Amount'}>
                                {
                                    form.getFieldDecorator('loan_amount', {
                                        initialValue: propertyDetails.loan_amount,
                                        rules: [{ required: true, type: 'number', message: 'Please input a loan amount.' }],
                                    })(<InputNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    />)
                                }
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                            <Form.Item label={'Interest Rate'}>
                                {
                                    form.getFieldDecorator('loan_interest_rate', {
                                        initialValue: propertyDetails.loan_interest_rate,
                                        rules: [{ required: true, type: 'float', message: 'Please input a loan interest rate.' }],
                                    })(<InputNumber
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />)
                                }
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                            <Form.Item label={'Loan Term (in years)'}>
                                {
                                    form.getFieldDecorator('loan_term_length', {
                                        initialValue: propertyDetails.loan_term_length,
                                        rules: [{ required: true, type: 'number', message: 'Please input a loan term.' }],
                                    })(<InputNumber />)
                                }
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Form.Item label={'Origination Date'}>
                                {
                                    form.getFieldDecorator('loan_origination_date', {
                                        initialValue: propertyDetails.loan_origination_date,
                                        rules: [{ required: true, message: 'Please select a loan origination date.' }],
                                    })(<DatePicker allowClear={false} format={'MMMM Do YYYY'}/>)
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );

    }

}

export default Form.create()(MortgageModal);
