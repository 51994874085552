import React, {Component} from 'react';
import {Card, Col, Row} from "antd";

class NoPropertiesCard extends Component {

    render() {

        return (
            <Card
                className={'card dark'}
                bordered={false}
            >
                <Row>
                    <Col xs={24} className={'card--full'}>
                        <img src={'/images/icons/no-property.svg'} alt={'No Properties'}/>
                        <p>This user has not setup any properties yet.</p>
                    </Col>
                </Row>
            </Card>
        );

    }

}

export default NoPropertiesCard;
