const goals = [
    {
        id: 'saver_property_goal',
        milestones: [
            {
                meta: {
                    title: 'Home Appraisal',
                    description: '<b>Your first savings milestone is a Home Appraisal.</b> The lender will require a home appraisal in order to know that the home is worth at least the value of the loan they are providing. You will also want the peace of mind knowing the home is a good value.',
                    range: '$400-$600',
                    videoId: 'SHS9P_uNvj4',
                },
                calculation: {
                    type: 'fixed',
                    value: 400,
                },
            },
            {
                meta: {
                    title: 'Title Insurance',
                    description: 'Title insurance uncovers any ownership issues or liens before you buy a home and will protect you of any issues that come up later. The lender will want know title is clean and insured before they provide a&nbsp;mortgage.',
                    range: '$500-$1000',
                    videoId: 'GbtpRiElpto',
                },
                calculation: {
                    type: 'fixed',
                    value: 500,
                },
            },
            {
                meta: {
                    title: 'Real Estate Lawyer',
                    description: 'Some states require an attorney in a real estate transaction; others do not. They will look out for your best interest when buying a home. They prepare and review documents and provide legal&nbsp;guidance.',
                    range: '$500-$1000',
                    videoId: 'SXbaUkoEfv8'
                },
                calculation: {
                    type: 'fixed',
                    value: 900,
                },
            },
            {
                meta: {
                    title: 'Loan Origination Fee',
                    description: 'The loan origination fee is the fee the lender charges for processing your loan application. Usually paid upfront, it is their compensation for their time they spend getting you a home loan.',
                    videoId: 'DvzVem5_ruM'
                },
                calculation: {
                    type: 'variable',
                    value: 0.01,
                    property: 'purchase_price',
                },
            },
            {
                meta: {
                    title: 'Closing Costs',
                    description: 'A home appraisal and title insurance are both examples of closing costs, but are not the only costs out there. There are a number of things you need to budget for outside of the down&nbsp;payment.',
                    videoId: '48qL84-BmQU'
                },
                calculation: {
                    type: 'variable',
                    value: 0.03,
                    property: 'purchase_price',
                },
            },
            {
                meta: {
                    title: 'Down Payment',
                    description: 'A down payment is a deposit towards the purchase price of your home. It lowers the principal that you owe on your mortgage. Learn more about the pros and cons of certain down payment amounts in <a href="#/"><b>your buyer&nbsp;profile.</b></a>',
                    videoId: 'ZRwvM9_BP48'
                },
                calculation: {
                    type: 'variable',
                    value: 'down_payment_percentage',
                    property: 'purchase_price'
                },
            },
        ]
    },
];

const getCalculationValue = (calculation, property) => {

    let t = 0;

    if(!calculation) {
        return 0;
    }

    if (Array.isArray(calculation)) {

        calculation.forEach(calc => {

            /* To allow for integer value of percent */
            const value = (typeof calc.value === 'string' ? property[calc.value] : calc.value);

            switch (calc.type) {
                case 'variable':
                    const percentageValue = value  / 100;
                    const dynamicValue = (percentageValue * property[calc.property]);
                    t = calc.negative ? t - dynamicValue : t + dynamicValue;
                    break;
                case 'fixed':
                    t = calc.negative ? t - value : t + value;
                    break;
                default:
                    break;
            }

        });

    } else {

        /* To allow for integer value of percent */
        const value = (typeof calculation.value === 'string' ? property[calculation.value] : calculation.value);

        switch (calculation.type) {
            case 'variable':
                const percentageValue = value  / 100;
                const dynamicValue = (percentageValue * property[calculation.property]);
                t = calculation.negative ? t - dynamicValue : t + dynamicValue;
                break;
            case 'fixed':
                t = calculation.negative ? t - value : t + value;
                break;
            default:
                break;
        }

    }

    return t;

};

const getMilestoneAmount = (milestones, property) => {

    let total = 0;

    if(Array.isArray(milestones)) {

        if(milestones && milestones.length > 0) {

            for (let x = 0; x < milestones.length; x++) {

                const value = getCalculationValue(milestones[x].calculation, property);
                total += value;

            }

        }

    } else {

        total += getCalculationValue(milestones.calculation, property);

    }

    return total;

};

const getDistributedMilestoneValues = (milestones, property, balance, milestone) => {

    let currentBalance = balance;
    let results = [];

    const index = milestone ? milestones.findIndex((m) => m.id === milestone.id) : -1;

    for(let x = 0; x < milestones.length; x++) {

        const savingsGoal = getMilestoneAmount(milestones[x], property);

        if(currentBalance >= savingsGoal) {
            results.push(savingsGoal);
            currentBalance = currentBalance - savingsGoal;
        } else if(currentBalance < savingsGoal && currentBalance > 0) {
            results.push(currentBalance);
            currentBalance = 0;
        } else {
            results.push(0);
        }
    }

    return index > -1 ? results[index] : results;



};

export default { goals, getMilestoneAmount, getCalculationValue, getDistributedMilestoneValues }
