import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import RequireAuth from '../components/auth/require_auth';

import DashboardLayout from "../layouts/DashboardLayout";
import GenericLayout from "../layouts/GenericLayout";

import Login from '../pages/Login';
import Logout from '../pages/Logout';
import PreAuthorized from "../pages/PreAuthorized";
import Signup from '../pages/Signup/Index';
import SignupDetails from '../pages/Signup/Details';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import OAuth from '../pages/OAuth';

/* Dashboard */
import SaversDashboard from '../pages/Dashboard/Savers';
import HomeownersDashboard from '../pages/Dashboard/Homeowners';
import EveryoneDashboard from '../pages/Dashboard/Everyone';

import Onboarding from "../pages/Onboarding/Index";
import OnboardingProfile from "../pages/Onboarding/Profile";
import OnboardingPartnerships from "../pages/Onboarding/Partnerships";
import OnboardingProspects from "../pages/Onboarding/Prospects";

/* Upgrade */
import Upgrade from '../pages/Upgrade/Index';
import UpgradePayment from '../pages/Upgrade/Payment';

import ImportContacts from "../pages/ImportContacts";
import InboundReferrals from "../pages/InboundReferrals";
import TeamManage from "../pages/Team/Manage";
import TeamRouting from "../pages/Team/LeadRouting";
import ProviderProfile from "../pages/Team/ProviderProfile";

import Settings from '../pages/Settings';

import UserProfile from "../pages/View/User";
import SubscriberProfile from "../pages/View/Subscriber";

/* My App */
import Profile from '../pages/MyApp/Profile';
import Milestones from '../pages/MyApp/Milestones';
import Rewards from '../pages/MyApp/Rewards';
import ContentFeed from "../pages/MyApp/ContentFeed";
import Integrations from "../pages/MyApp/Integrations/Index";
import FollowUpBossIntegration from "../pages/MyApp/Integrations/FollowUpBoss";
import SalesforceIntegration from "../pages/MyApp/Integrations/Salesforce";
import SurefireIntegration from "../pages/MyApp/Integrations/Surefire";
import VelocifyIntegration from "../pages/MyApp/Integrations/Velocify";
import ZapierIntegration from "../pages/MyApp/Integrations/Zapier";

import Share from '../pages/Share';

import {AUTH_TYPE_AUTHORIZED, AUTH_TYPE_GUEST,} from "../helpers/constants";
/* Global Styles */
import 'antd/dist/antd.css';
import 'ant-design-pro/dist/ant-design-pro.css';
import '../assets/scss/app.scss';
import EmbeddedApp from "../pages/EmbeddedApp";

const Routes = () => {
    return (
        <Switch>
            <GenericLayout exact path="/" component={RequireAuth(Login, AUTH_TYPE_GUEST)} />
            <GenericLayout exact path="/logout" component={Logout} />
            <GenericLayout exact path="/preauth/:token" component={RequireAuth(PreAuthorized, AUTH_TYPE_GUEST)} />
            <GenericLayout exact path="/signup" component={RequireAuth(Signup, AUTH_TYPE_GUEST)} />
            <GenericLayout exact path="/signup/details" component={RequireAuth(SignupDetails, AUTH_TYPE_AUTHORIZED)} />
            <GenericLayout exact path="/oauth" component={OAuth} />

            <GenericLayout exact path="/forgot-password" component={RequireAuth(ForgotPassword, AUTH_TYPE_GUEST)} />
            <GenericLayout path="/reset-password/:token" component={RequireAuth(ResetPassword, AUTH_TYPE_GUEST)} />
            {/* Dashboard */}
            <Redirect exact from='/dashboard' to='/dashboard/all'/>
            <DashboardLayout exact path="/dashboard/all" component={RequireAuth(EveryoneDashboard, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/dashboard/savers" component={RequireAuth(SaversDashboard, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/dashboard/homeowners" component={RequireAuth(HomeownersDashboard, AUTH_TYPE_AUTHORIZED)} />

            <DashboardLayout exact path="/messages" component={() => <Redirect to="/dashboard" />} />
            <DashboardLayout path="/inbound-referrals" component={RequireAuth(InboundReferrals, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/partners" component={() => <Redirect to="/dashboard" />} />

            {/* Subaccounts */}
            <DashboardLayout exact path="/team/manage" component={RequireAuth(TeamManage, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/team/manage/:id" component={RequireAuth(ProviderProfile, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/team/routing" component={RequireAuth(TeamRouting, AUTH_TYPE_AUTHORIZED)} />
            <Redirect from='/team' to='/team/manage'/>

            {/* My App */}
            <Redirect exact from='/my-app' to='/my-app/profile'/>
            <DashboardLayout exact path="/my-app/profile" component={RequireAuth(Profile, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/my-app/milestones" component={RequireAuth(Milestones, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/my-app/rewards" component={RequireAuth(Rewards, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/my-app/content-feed" component={RequireAuth(ContentFeed, AUTH_TYPE_AUTHORIZED)} />

            {/* Integrations */}
            <DashboardLayout path="/my-app/integrations/follow-up-boss" component={RequireAuth(FollowUpBossIntegration, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/my-app/integrations/salesforce" component={RequireAuth(SalesforceIntegration, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/my-app/integrations/surefire" component={RequireAuth(SurefireIntegration, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/my-app/integrations/velocify" component={RequireAuth(VelocifyIntegration, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/my-app/integrations/zapier" component={RequireAuth(ZapierIntegration, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/my-app/integrations/:service" component={RequireAuth(Integrations, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/my-app/integrations" component={RequireAuth(Integrations, AUTH_TYPE_AUTHORIZED)} />

            {/* Share */}
            <DashboardLayout path="/share" component={RequireAuth(Share, AUTH_TYPE_AUTHORIZED)} />

            {/* Onboarding */}
            <DashboardLayout exact path="/onboarding" component={RequireAuth(Onboarding, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/onboarding/profile/:step?" component={RequireAuth(OnboardingProfile, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/onboarding/partnerships" component={RequireAuth(OnboardingPartnerships, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/onboarding/prospects" component={RequireAuth(OnboardingProspects, AUTH_TYPE_AUTHORIZED)} />

            {/* Import Contacts */}
            <DashboardLayout path="/import" component={RequireAuth(ImportContacts, AUTH_TYPE_AUTHORIZED)} />

            {/* Upgrade */}
            <DashboardLayout exact path="/upgrade" component={RequireAuth(Upgrade, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout exact path="/upgrade/payment" component={RequireAuth(UpgradePayment, AUTH_TYPE_AUTHORIZED)} />

            {/* Settings */}
            <Redirect exact from='/settings' to='/settings/security'/>
            <DashboardLayout path="/settings" component={RequireAuth(Settings, AUTH_TYPE_AUTHORIZED)} />

            {/* User View */}
            <DashboardLayout path="/view/user/:id" component={RequireAuth(UserProfile, AUTH_TYPE_AUTHORIZED)} />
            <DashboardLayout path="/view/subscriber/:id" component={RequireAuth(SubscriberProfile, AUTH_TYPE_AUTHORIZED)} />
            <Route exact path='/brokers-are-better' component={() => {
                window.location.href = 'https://digs.co/brokers-are-better';
                return null;
             }}/>
             <Route exact path={'/embed'} component={EmbeddedApp} />
            <Redirect exact from='/login' to='/'/>
        </Switch>
    );
};

export default Routes;
