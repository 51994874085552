import React, {PureComponent} from 'react';
import * as userActions from '../actions/user'
import {connect} from 'react-redux';
import { withRouter} from 'react-router-dom';
import {Icon, Spin, message} from 'antd';
import config from '../config';

class PreAuthorized extends PureComponent {

    constructor(props) {
        super(props);

        this.linkedInRedirectUri = config.REACT_APP_URL ? `${config.REACT_APP_URL}/signup` : '';
        this.linkedInClientId = '78xknhv1qruuqm';
    }

    componentDidMount() {

        const token = this.props.match.params.token;
        if(token) {
            this.props.loginToken(token)
                .catch(() => {
                    this.props.history.push('/')
                });
        } else {
            this.props.history.push('/')
        }


    }

    render() {

        return (
            <div className={'page-login'}>
                <h1>Authorizing...</h1>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px 0'}}>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }, ownProps) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreAuthorized))
