import React, {Component} from 'react';
import {Button} from "antd";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import 'react-image-crop/lib/ReactCrop.scss';

class LogoUploader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logoPreview: null,
            submitting: false,
            fileWithMeta: null,
            uploading: false,
            imageError: null,
            src: null,
            cropModalVisible: false,
            crop: {
                x: 0,
                y: 0,
                unit: '%',
                width: 100,
                height: 100,
                aspect: 1,
            },
        };

    }

    componentDidMount() {

        const { user } = this.props;

        if(user.logo && user.logo) {
            this.setState({
                logoPreview: user.logo,
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.user.logo !== prevProps.user.logo) {
            this.setState({
                logoPreview: (this.props.user.logo ? this.props.user.logo : null),
            });
        }
    }

    handleChangeStatus = (fileWithMeta, status) => {

        if(fileWithMeta.meta.status === 'error_file_size') {
            this.setState({ imageError: 'File size must be less than 5MB!'});
            fileWithMeta.remove();
        }

        if(fileWithMeta.meta.status === 'done') {

            const reader = new FileReader();

            reader.addEventListener('load', () =>
                this.setState({ src: reader.result }),
            );

            reader.readAsDataURL(fileWithMeta.file);

            this.setState({
                fileWithMeta: fileWithMeta,
            });

            return false;

        }

    };

    generatePreview = () => {

        const { src } = this.state;

        return (
            <div>
                <div className={'logo-preview'}>
                    <img src={src} alt=""/>
                </div>
            </div>
        )

    };

    // submit everything
    handleOnSubmit = () => {

        const { user } = this.props;
        const { fileWithMeta: { file, remove } } = this.state;

        const formData = new FormData();
        const reader = new window.FileReader();

        this.setState({
            uploading: true,
        });

        reader.readAsDataURL(file);

        reader.onload = () => {

            formData.append('file', file);
            formData.append('type', 'logo');

            this.props.onSubmit(user.id, formData)
                .then(() => {
                    remove();
                    this.setState({
                        uploading: false,
                        fileWithMeta: null,
                        cropModalVisible: false,
                        src: null,
                    });
                });
        }
    }

    resetUpload = () => {

        const { user } = this.props;

        this.setState({
            logoPreview: this.state.logoPreview ? null : (user.logo ? user.logo : null),
        });
    };

    /* Render */

    render() {

        const { user } = this.props;
        const { logoPreview, src, uploading } = this.state;

        return (
            <div className={'media-uploader'}>
                {
                    user && !logoPreview &&
                    <>
                        <Dropzone
                            onChangeStatus={this.handleChangeStatus}
                            accept="image/*"
                            maxFiles={1}
                            maxSizeBytes={(1024 * 1024) * 5}
                            inputWithFilesContent={null}
                            PreviewComponent={this.generatePreview}
                            inputContent={
                                <div className={'upload-cta'} key={'upload-cta'}>
                                    <span>Drag File or Click to Browse</span>
                                    {
                                        !this.state.imageError &&
                                        <span className={'disclaimer'}>Max File Size: 5MB</span>
                                    }
                                    {
                                        this.state.imageError &&
                                        <span className={'disclaimer error'}>Error: { this.state.imageError }</span>
                                    }
                                </div>
                            }
                        />
                        {
                            user.logo && !src &&
                            <div className="media-uploader--actions">
                                <Button type={'danger'} icon={'close'} onClick={this.resetUpload} ghost>Cancel</Button>
                            </div>
                        }
                        {
                            src &&
                            <div className="media-uploader--actions apart">
                                <Button type={'danger'} icon={'close'} disabled={uploading} onClick={this.resetUpload} ghost>Cancel</Button>
                                <Button type={'primary'} icon={'upload'} onClick={this.handleOnSubmit} loading={uploading}>Submit</Button>
                            </div>
                        }
                    </>
                }
                {
                    user && logoPreview &&
                    <>
                        <div className={'logo-preview'}>
                            <img key={logoPreview} src={src ? src : user.logo} alt=""/>
                        </div>
                        <div className="media-uploader--actions">
                            <Button type={'primary'} icon={'upload'} onClick={this.resetUpload}>Update Logo</Button>
                        </div>
                    </>
                }
            </div>
        );
    }

}

export default LogoUploader;
