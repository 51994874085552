import {
    FETCH_ROUTING_ERROR,
    FETCH_ROUTING_PENDING,
    FETCH_ROUTING_SUCCESS,
    ROUTING_UPDATE_ERROR,
    ROUTING_UPDATE_PENDING,
    ROUTING_UPDATE_SUCCESS,
} from '../actions/types'

const defaultState = {
    config: [],
    count: 0,
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_ROUTING_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_ROUTING_SUCCESS:
            return { ...state, action, config: action.payload.config ? action.payload.config : [], count: action.payload.count, errors: {}, loading: false };
        case FETCH_ROUTING_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case ROUTING_UPDATE_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case ROUTING_UPDATE_SUCCESS:
            return { ...state, action, config: action.payload.config ? action.payload.config : [], count: action.payload.count, errors: {}, loading: false };
        case ROUTING_UPDATE_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};
