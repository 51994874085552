import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import Errors from '../../components/utils/Errors'
import {Button, Card, Col, Divider, Form, Icon, Input, Row} from "antd";
import Analytics from '../../helpers/analytics';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import OAuthPopup from 'react-oauth-popup';
import config from '../../config';
import {REGISTRATION_ERROR, REGISTRATION_PENDING} from '../../actions/types'
import {Link} from "react-router-dom";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password_confirmationDirty: false,
            isValid: false
        };

        this.linkedInRedirectUri = config.REACT_APP_URL ? `${config.REACT_APP_URL}/signup` : '';
        this.linkedInClientId = '78xknhv1qruuqm';

    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords entered do not match!')
        } else {
            callback()
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.password_confirmationDirty) {
            form.validateFields(['password_confirmation'], { force: true })
        }
        callback()
    };

    handleOnSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.type = 'provider';
                this.props.registerAction(values)
                    .then(() => {
                        Analytics.set({
                            event: 'CompleteRegistration',
                        })
                    });
            }
        })
    };

    handleOnGoogleRegister = (response) => {

        if (response.accessToken) {

            const values = {
                social_provider: 'google',
                access_token: response.accessToken,
            };

            this.props.registerSocialAction(values)
                .then(() => {
                    Analytics.set({
                        event: 'CompleteRegistration',
                    })
                });

        }
    }

    handleOnFacebookRegister = (response) => {

        if (response.accessToken) {

            const values = {
                social_provider: 'facebook',
                access_token: response.accessToken,
            };

            this.props.registerSocialAction(values)
                .then(() => {
                    Analytics.set({
                        event: 'CompleteRegistration',
                    })
                });

        }

    }

    handleOnLinkedInRegister = (code) => {

        if (code) {

            const values = {
                social_provider: 'linkedin',
                access_token: code,
            };

            this.props.registerSocialAction(values)
                .then(() => {
                    Analytics.set({
                        event: 'CompleteRegistration',
                    })
                });

        }

    }

    render() {

        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { action, errors } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page-signup'}>
                <h1>Create Your Account</h1>
                {
                    type === REGISTRATION_ERROR  && <Errors errors={errors} />
                }
                <Card>
                    <Form onSubmit={this.handleOnSubmit} colon={false} hideRequiredMark={true}>
                        <Form.Item label={'Email'}>
                            {
                                getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Please input your email!' }],
                                })(
                                    <Input
                                        size={'large'}
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'Password'}>
                            {
                                getFieldDecorator('password', {
                                    rules: [
                                        { required: true, message: 'Please input your password!' },
                                        { min: 6, message: <div>Password must contain at least six characters</div> },
                                        { pattern: new RegExp(/\d/), message: <div>Password must contain at least one number</div> },
                                        { pattern: new RegExp(/[`~!@#$%^&*()_+=]/),
                                            message: <div>Password must contain at least one special character</div>
                                        },
                                        {
                                            validator: this.validateToNextPassword
                                        }
                                    ]
                                })(
                                    <Input
                                        size={'large'}
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type={'password'}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'Confirm Password'}>
                            {
                                getFieldDecorator('password_confirmation', {
                                    rules: [
                                        { required: true, message: 'Please confirm your password!' },
                                        {
                                            validator: this.compareToFirstPassword
                                        }
                                    ]
                                })(
                                    <Input
                                        size={'large'}
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type={'password'}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item className={'form-button'}>
                            <Button htmlType={'submit'} type={'primary'} className={'button primary'} loading={ type === REGISTRATION_PENDING }>Create My Account</Button>
                        </Form.Item>
                    </Form>
                    <Divider />
                    <GoogleLogin
                        clientId="498863115396-6p53odlqc25luvjtmjrakboa71bbquah.apps.googleusercontent.com"
                        render={renderProps => (
                            <Button className={'button social'} onClick={renderProps.onClick}>
                                <img src="/images/icons/google.svg" alt=""/>
                                <span>Sign up with Google</span>
                            </Button>
                        )}
                        buttonText="Login"
                        onSuccess={this.handleOnGoogleRegister}
                        onFailure={this.handleOnGoogleRegister}
                        cookiePolicy={'single_host_origin'}
                    />
                    <FacebookLogin
                        appId="461558117795162"
                        callback={this.handleOnFacebookRegister}
                        fields="name,email,picture"
                        render={renderProps => (
                            <Button className={'button social'}  onClick={renderProps.onClick}>
                                <img src="/images/icons/facebook.svg" alt=""/>
                                <span>Sign up with Facebook</span>
                            </Button>
                        )}
                    />
                    <OAuthPopup
                        url={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.linkedInClientId}&redirect_uri=${this.linkedInRedirectUri}&scope=r_liteprofile%20r_emailaddress%20w_member_social`}
                        onCode={this.handleOnLinkedInRegister}
                        width={600}
                        height={600}
                    >
                        <Button className={'button social'}
                        >
                            <img src="/images/icons/linkedin.svg" alt=""/>
                            <span>Sign up with LinkedIn</span>
                        </Button>
                    </OAuthPopup>
                </Card>
                <p className={'login'}>Already have an account? <Link to={'/login'}>Sign In</Link></p>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
