import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import {Form, Steps} from "antd";
import CSVComponent from "../../components/integrations/CSV";

const { Step } = Steps;

class Prospects extends Component {

    render() {

        return (
            <div className={'page-onboarding page-onboarding-prospects'}>
                <h1>Import Contacts</h1>
                <div className={'steps'} >
                    <Steps current={2} labelPlacement={'vertical'}>
                        <Step title="Customize App" />
                        <Step title="Import Contacts" />
                    </Steps>
                </div>
                <div className="integrations-list--container">
                    <CSVComponent withOffset={true} />
                    <Link to={`/dashboard`} style={{ marginTop: '35px', textAlign: 'center', display: 'block'}}>Skip this for now</Link>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Prospects));
