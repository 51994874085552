import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import {Card, Col, Icon, Row} from "antd";

class Onboarding extends Component {

    componentDidMount() {
        this.props.updateUser({
            completed_onboarding: true
        })
    }

    render() {

        return (
            <div className={'page-onboarding'}>
                <div className="title-block home">
                    <h1>Welcome to <b>Digs!</b></h1>
                    <p>For Digs to work properly, <b>please complete the steps below:</b></p>
                </div>
                <div className="steps-list">
                    <Row gutter={24} type="flex">
                        <Col xs={24} sm={{span: 24, offset: 0}} lg={{span: 8, offset: 4}}>
                            <Card
                                title={'Customize Your App'}
                            >
                                <p>Upload your company logo, personal headshot and fill in professional details for prospects to see.</p>
                                <Link to={'/onboarding/profile'}>Customize App <Icon type="arrow-right" /></Link>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card title={'Import Contacts'}>
                                <p>Import contacts so they can start using your app, or send us a spreadsheet for bulk imports.</p>
                                <Link to={'/onboarding/prospects'}>Import Contacts <Icon type="arrow-right" /></Link>
                            </Card>
                        </Col>
                    </Row>
                    <div className="steps-list--actions">
                        <Link to={'/onboarding/profile'} className={'button primary'}>Walk me through the steps</Link>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
