import React, {PureComponent} from 'react';
import {Helmet} from "react-helmet";

class EmbeddedApp extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Helmet>
                    <script type="text/javascript" src="https://eia.followupboss.com/embeddedApps-v1.0.0.js"></script>
                </Helmet>
                <h1>Hello World</h1>
            </>);
    }
}

export default EmbeddedApp;
