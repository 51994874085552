import React from "react";
import {Helmet} from "react-helmet";
import {Button, Icon} from "antd";

const DashboardErrorComponent = ({ componentStack, error }) => (
    <div className='fadeIn page full-height'>
        <Helmet>
            <title>Digs | An error has occurred...</title>
        </Helmet>
        <div className="container">
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <div className={'default-error dashboard-error'}>
                        <h1>Whoops!</h1>
                        <p>It looks like an error has occurred. If the error persists, please <a href={'https://help.digs.co/en/'}>contact support.</a></p>
                        <Button className={'button primary'} href={"/dashboard"}><Icon type="arrow-left" /> Go Back</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default DashboardErrorComponent;
