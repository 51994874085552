import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import {Card, Col, Divider, Row} from "antd";

class Onboarding extends Component {

    componentDidMount() {
        this.props.updateUser({
            completed_onboarding: true
        })
    }

    render() {

        const { user } = this.props;

        return (
            <div className={'page page-upgrade'}>
                <Row>
                    <Col xs={24}>
                        <div className={'title-module'}>
                            <h1>Upgrade</h1>
                            {
                                user.provider_id && !user.is_parent_provider &&
                                <p>You are currently using a sub-account. Please contact your primary account administrator to upgrade.</p>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <div className={`card-list ${(user.provider_id && !user.is_parent_provider) ? 'inactive' : ''}`}>
                    <Row gutter={24} type="flex">
                        <Col xs={24} sm={{span: 24, offset: 0}} md={{span: 12, offset: 0}} lg={{span: 10, offset: 2}} xl={{span: 8, offset: 4}}>
                            <Card>
                                <div className="pricing">
                                    <div className="pricing--plan">Free</div>
                                    <div className="pricing--amount">$0</div>
                                    <div className="pricing--subtitle">Per Month</div>
                                </div>
                                <ul>
                                    <li>20 Clients</li>
                                    <li>Custom Branded App</li>
                                    <li>Branded Landing Pages</li>
                                </ul>
                                <span className={'button social'}>Current</span>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={10} xl={8}>
                            {
                                user.agent_lender === 'Agent' &&
                                <Card>
                                    <div className="pricing">
                                        <div className="pricing--plan">Pro</div>
                                        <div className="pricing--amount">$39</div>
                                        <div className="pricing--subtitle">Per Month</div>
                                    </div>
                                    <ul>
                                        <li>150 Active Clients</li>
                                        <li>Custom Branded App</li>
                                        <li>Branded Landing Pages</li>
                                        <li>Advanced Data Insights</li>
                                        <li>CRM Integrations</li>
                                        <li>Custom Milestones</li>
                                        <li>+150 clients for $39</li>
                                    </ul>
                                    <Link to={'/upgrade/payment'} className={'button primary'}>Upgrade</Link>
                                </Card>
                            }
                            {
                                user.agent_lender === 'Lender' &&
                                <Card>
                                    <div className="pricing">
                                        <div className="pricing--plan">Pro</div>
                                        <div className="pricing--amount">$99</div>
                                        <div className="pricing--subtitle">Per Month</div>
                                    </div>
                                    <ul>
                                        <li>500 Active Clients</li>
                                        <li>Custom Branded App</li>
                                        <li>Branded Landing Pages</li>
                                        <li>Advanced Data Insights</li>
                                        <li>CRM Integrations</li>
                                        <li>Custom Milestones</li>
                                        <li>+500 clients for $99</li>
                                    </ul>
                                    <Link to={'/upgrade/payment'} className={'button primary'}>Upgrade</Link>
                                </Card>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
