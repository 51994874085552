import {
    FETCH_ROUTING_ERROR,
    FETCH_ROUTING_PENDING,
    FETCH_ROUTING_SUCCESS,
    ROUTING_UPDATE_ERROR,
    ROUTING_UPDATE_PENDING,
    ROUTING_UPDATE_SUCCESS
} from "./types";

import client from './';

export const fetchLeadRouting = () => {

    return (dispatch) => {
        dispatch({type: FETCH_ROUTING_PENDING});

        return new Promise((resolve, reject) => {
            client.get(`/provider/routing`)
                .then(response => {
                    dispatch({type: FETCH_ROUTING_SUCCESS, payload: response.data});
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: FETCH_ROUTING_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const saveLeadRouting = (data) => {

    return (dispatch) => {
        dispatch({type: ROUTING_UPDATE_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/routing`, data)
                .then(response => {
                    dispatch({type: ROUTING_UPDATE_SUCCESS, payload: response.data});
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: ROUTING_UPDATE_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

};

export const deleteLeadRouting = (data) => {

    return (dispatch) => {
        dispatch({type: ROUTING_UPDATE_PENDING});
        return new Promise((resolve, reject) => {
            return client.delete(`/provider/routing`)
                .then(response => {
                    dispatch({type: ROUTING_UPDATE_SUCCESS, payload: response.data});
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: FETCH_ROUTING_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

};

export const distributeLeadsOnce = (data) => {

    return (dispatch) => {
        dispatch({type: ROUTING_UPDATE_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/routing/distribute`, data)
                .then(response => {
                    dispatch({type: ROUTING_UPDATE_SUCCESS});
                    resolve();
                })
                .catch((err) => {
                    dispatch({type: ROUTING_UPDATE_ERROR});
                    reject();
                })
        });
    }

}
