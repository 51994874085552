import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as userActions from "../../actions/user";
import Errors from '../../components/utils/Errors'
import {Button, Card, Col, Form, Icon, Input, message, Row, Select, Tooltip} from "antd";

import {UPDATE_USER_ERROR, UPDATE_USER_PENDING,} from '../../actions/types'

class Details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password_confirmationDirty: false,
            isValid: false
        };

    }

    handleOnSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.updateUser(values)
                    .then(() => {
                        console.log('then got fired');
                        this.props.history.push('/dashboard');
                        message.success('Registration completed successfully!');
                    })
                    .catch(() => {
                        console.log('catch got fired');
                    })
                    .finally(() => {
                        console.log('finally got fired');
                    });
            }
        })
    };

    render() {

        const { user, form } = this.props;
        const { getFieldDecorator } = form;
        const { action, errors } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page-signup'}>
                <h1>Tell us a bit more about yourself</h1>
                <Card>
                    {
                        type === UPDATE_USER_ERROR  && <Errors errors={errors} />
                    }
                    <Form onSubmit={this.handleOnSubmit} colon={false} hideRequiredMark={true}>
                        <Form.Item label={'What best describes you?'}>
                            {
                                getFieldDecorator('agent_lender', {
                                    rules: [{ required: true, message: 'Please select an account type!' }],
                                })(
                                    <Select placeholder={'Please select one...'} size={'large'}>
                                        <Select.Option value={'Agent'}>Agent</Select.Option>
                                        <Select.Option value={'Lender'}>Lender</Select.Option>
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'First Name'}>
                            {
                                getFieldDecorator('firstname', {
                                    rules: [{ required: true, message: 'Please input your first name!' }],
                                    initialValue: user.firstname ? user.firstname : null
                                })(
                                    <Input placeholder={'Jamie'} size={'large'}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'Last Name'}>
                            {
                                getFieldDecorator('lastname', {
                                    rules: [{ required: true, message: 'Please input your last name!' }],
                                    initialValue: user.lastname ? user.lastname : null
                                })(
                                    <Input placeholder={'Smith'} size={'large'}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'Company'}>
                            {
                                getFieldDecorator('company_name', {
                                    rules: [{ required: true, message: 'Please input your company name!' }],
                                    initialValue: user.company_name ? user.company_name : null
                                })(
                                    <Input size={'large'} />
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'Phone Number'}>
                            {
                                getFieldDecorator('mobile', {
                                    rules: [{ required: true, message: 'Please input your phone number!' }],
                                    initialValue: user.mobile ? user.mobile : null
                                })(
                                    <Input placeholder={'(000) 000-0000'} size={'large'}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item className={'form-button'}>
                            <Button htmlType={'submit'} type={'primary'} className={'button primary'} loading={ type === UPDATE_USER_PENDING }>Save My Information</Button>
                        </Form.Item>
                    </Form>
                </Card>
                <p className={'login'}>Already have an account? <a href={'#'} onClick={(e) => {e.preventDefault(); this.props.logoutUser();}}>Sign In</a></p>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(Details)));
