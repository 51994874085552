import {combineReducers} from 'redux';
import {reducer as userReducer} from './user';
import {reducer as dashboardReducer} from './dashboard';
import {reducer as subaccountsReducer} from './subaccounts';
import {reducer as milestonesReducer} from './milestones';
import {reducer as feedReducer} from './feed';
import {reducer as referralsReducer} from './referrals';
import {reducer as messagesReducer} from './messages';
import {reducer as settingsReducer} from './settings';
import {reducer as partnershipsReducer} from './partnerships';
import {reducer as routingReducer} from './routing';
import {reducer as integrationsReducer} from './integrations';
import {reducer as socialReducer} from './social';

const rootReducer = combineReducers({
    user: userReducer,
    dashboard: dashboardReducer,
    subaccounts: subaccountsReducer,
    milestones: milestonesReducer,
    feed: feedReducer,
    messages: messagesReducer,
    settings: settingsReducer,
    referrals: referralsReducer,
    partnerships: partnershipsReducer,
    routing: routingReducer,
    integrations: integrationsReducer,
    social: socialReducer,
});

export default rootReducer;
