import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {StripeProvider} from 'react-stripe-elements';
import {BrowserRouter} from 'react-router-dom';
import store from './store';
import App from './app';
import StorageService from './helpers/storageService';
import Analytics from './helpers/analytics';
import * as Sentry from '@sentry/browser';
import {unregister} from './registerServiceWorker';
import config from './config'

const STRIPE_KEY = config.REACT_APP_STRIPE_KEY || "";

// Unregister service worker
unregister();

// Initialize Analytics
Analytics.initialize();

// Initialize Sentry
Sentry.init({
    dsn: 'https://b6c5ad2786934f79b59ab1ab9e8aa07b@sentry.io/3497443',
    environment: config.REACT_APP_SENTRY_ENVIRONMENT || 'unknown',
});

// Setup listener for saving state to local storage
store.subscribe(() => {

    const loggedInUser = store.getState().user.user ? store.getState().user.user : null;

    if(loggedInUser) {
        Analytics.set({
            userId: loggedInUser.id,
            user: {
                id: loggedInUser.id,
                name: `${loggedInUser.firstname} ${loggedInUser.lastname}`,
                email: loggedInUser.email,
            }
        })
    }

    StorageService.set('pro.digs', {
        user: {
            token: store.getState().user.token,
            user: store.getState().user.user,
            attributes: store.getState().user.attributes,
        },
        integrations: store.getState().integrations
    })
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <StripeProvider apiKey={STRIPE_KEY}>
                <App />
            </StripeProvider>
        </BrowserRouter>
    </Provider>, document.getElementById('root'));
