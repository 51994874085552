import React, {PureComponent} from 'react';
import {Col, Divider, Form, Icon, Row} from 'antd'
import {connect} from 'react-redux';
import history from "../../../helpers/history";
import ZapierComponent from "../../../components/integrations/Zapier";
import * as integrationsActions from "../../../actions/integrations";

class Zapier extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        this.props.fetchIntegrations();
    }

    getIntegration(name) {
        const { integrations } = this.props;

        if(integrations && integrations.length) {

            const search = integrations.find(integration => integration.service === name);
            return search ? search : false;
        }

        return false;

    }

    render() {

        return (
            <div className={"page page-integrations-zapier"}>
                <Row gutter={24}>
                    <Col xs={24} xl={16}>
                        <div className={'title-module with-back'}>
                            <div className={'title-module--container'}>
                                <Icon type="arrow-left" style={{ fontSize: '18px'}} onClick={() => { history.push('/my-app/integrations') }}/>
                                <h1>Zapier</h1>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <ZapierComponent isActive={this.getIntegration('zapier')} />
            </div>
        );
    }

}

const mapStateToProps = ({ integrations }) => ({ integrations: integrations.list, action: integrations.action });

const mapDispatchToProps = {
    ...integrationsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Zapier));
