const prodConfig = {
    ENVIRONMENT: "production",
    REACT_APP_URL: "http://pro.digs.co",
    REACT_APP_API_URL: "https://api.homeowner.ojo.com",
    REACT_APP_INTERCOM_API_ID: "xg3mpr25",
    REACT_APP_GOOGLE_TAG_MANAGER_ID: "GTM-KZNHF3K",
    REACT_APP_GOOGLE_TAG_MANAGER_AUTH: "DUGimOXaWGJBxu-M0KBX_g",
    REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW: "env-2",
    REACT_APP_STRIPE_KEY: "pk_live_yIRHFY7ozrcWbZ1O2wFgqUek",
    REACT_APP_SENTRY_ENVIRONMENT: "production"
};

export default prodConfig;