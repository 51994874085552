import React, {Component} from "react";
import {Card} from "antd";
import * as userActions from "../../../actions/user";
import {connect} from "react-redux";
import InvoiceModule from './InvoiceModule';
import SubscriptionModule from "./SubscriptionModule";
import {Redirect} from "react-router-dom";
import KellerWilliamsModule from "./KellerWilliamsModule";
import RemaxModule from "./RemaxModule";

class Index extends Component {

    constructor(props){
        super(props);

        this.state = {
            subscription: {},
            pauseSubscriptionModal: false,
            updatingSubscription: false,
            loadingSubscriptions: false,
            upcoming: [],
            invoices: [],
            loadingInvoices: false,
        }
    }

    render() {
        const { user } = this.props;

        if(user.override_billing) {
            return <Redirect to={'/settings'} />
        }

        return (
            <div>
                <h2>Billing</h2>
                {
                    (user.is_parent_provider || !user.provider_id) &&
                    user.active_subscription &&
                    user.active_subscription.type === 'STRIPE' &&
                    <>
                        <SubscriptionModule />
                        <InvoiceModule />
                    </>
                }
                {
                    user.active_subscription &&
                    user.active_subscription.type === 'KELLERWILLIAMS' &&
                    <KellerWilliamsModule />
                }
                {
                    user.active_subscription &&
                    user.active_subscription.type === 'REMAX' &&
                    <RemaxModule />
                }
                <Card title={'Need Help?'}>
                    <p>If you have questions or need to update anything regarding billing, please contact your Digs representative or email <a href="mailto:support@digs.co">support@digs.co</a>.</p>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user: user.user });
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
