import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Avatar, Dropdown, Icon, Layout, Menu, Tag, Button} from 'antd';
import NoticeIcon from 'ant-design-pro/lib/NoticeIcon';
import {Link, Route, withRouter} from 'react-router-dom'
import * as userActions from "../actions/user";
import DashboardErrorComponent from './error/DashboardErrorComponent';
import ErrorBoundary from 'react-error-boundary';

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

class DashboardLayout extends Component {

  constructor(props) {
      super(props);

      this.state = {
          collapsed: false,
          selectedRows: [],
          activeHeader: false,
      };

  }

  toggle = (val) => {
    this.setState({
      collapsed: val ? val : !this.state.collapsed,
    });
  };

  generateNotifications = () => {

      const data = [
          {
              id: '000000001',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
              title: 'Lorem ipsum dolor sit amet.',
              datetime: '2017-08-09',
              type: 'notification',
          },
          {
              id: '000000002',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png',
              title: 'Lorem ipsum dolor sit amet.',
              datetime: '2017-08-08',
              type: 'notification',
          },
          {
              id: '000000003',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png',
              title: 'Lorem ipsum dolor sit amet.',
              datetime: '2017-08-07',
              read: true,
              type: 'notification',
          },
          {
              id: '000000004',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/GvqBnKhFgObvnSGkDsje.png',
              title: 'Lorem ipsum dolor sit amet.',
              datetime: '2017-08-07',
              type: 'notification',
          },
          {
              id: '000000005',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
              title: 'Lorem ipsum dolor sit amet.',
              datetime: '2017-08-07',
              type: 'notification',
          },
          {
              id: '000000006',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              datetime: '2017-08-07',
              type: 'message',
              clickClose: true,
          },
          {
              id: '000000007',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              datetime: '2017-08-07',
              type: 'message',
              clickClose: true,
          },
          {
              id: '000000008',
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              datetime: '2017-08-07',
              type: 'message',
              clickClose: true,
          },
          {
              id: '000000009',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              extra: '未开始',
              status: 'todo',
              type: 'event',
          },
          {
              id: '000000010',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              extra: '马上到期',
              status: 'urgent',
              type: 'event',
          },
          {
              id: '000000011',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              extra: '已耗时 8 天',
              status: 'doing',
              type: 'event',
          },
          {
              id: '000000012',
              title: 'Lorem ipsum dolor sit amet.',
              description: 'Brute suavitate sed te, ut mel delenit erroribus temporibus.',
              extra: '进行中',
              status: 'processing',
              type: 'event',
          },
      ];

      function onItemClick(item, tabProps) {
          console.log(item, tabProps);
      }

      function onClear(tabTitle) {
          console.log(tabTitle);
      }

      function getNoticeData(notices) {
          if (notices.length === 0) {
              return {};
          }
          const newNotices = notices.map(notice => {
              const newNotice = { ...notice };
              // transform id to item key
              if (newNotice.id) {
                  newNotice.key = newNotice.id;
              }
              if (newNotice.extra && newNotice.status) {
                  const color = {
                      todo: '',
                      processing: 'blue',
                      urgent: 'red',
                      doing: 'gold',
                  }[newNotice.status];
                  newNotice.extra = (
                      <Tag color={color} style={{ marginRight: 0 }}>
                          {newNotice.extra}
                      </Tag>
                  );
              }
              return newNotice;
          });
          return newNotices.reduce((pre, data) => {
              if (!pre[data.type]) {
                  pre[data.type] = [];
              }
              pre[data.type].push(data);
              return pre;
          }, {});
      }

      const noticeData = getNoticeData(data);

      return (
          <NoticeIcon className="notice-icon" count={5} onItemClick={onItemClick} onClear={onClear}>
              <NoticeIcon.Tab
                  list={noticeData.notification}
                  title="Notifications"
                  emptyText="No notifications"
                  emptyImage="https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg"
              />
              <NoticeIcon.Tab
                  list={noticeData.message}
                  title="Partnerships"
                  emptyText="您已读完所有消息"
                  emptyImage="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
              />
              <NoticeIcon.Tab
                  list={noticeData.event}
                  title="Messages"
                  emptyText="你已完成所有待办"
                  emptyImage="https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg"
              />
          </NoticeIcon>
      );

  };

  generateAvatar = () => {
      const { user } = this.props;

      if(user.avatar && user.avatar.file) {
          return user.avatar.file
      } else {
          return '/images/empty-avatar.svg';
      }

  }

  generateAuthMenu = () => {
    const { onMenuClick, user } = this.props;

    const menu = (
      <Menu className={'menu'} selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item key="userinfo">
            <Link to="/settings">
                <Icon type="setting" />&nbsp;
                <span>Account Settings</span>
            </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={this.onLogout}>
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <span className={'action account'}>
              <span>{ user && `${user.firstname} ${user.lastname}` }</span>
              <Avatar className={'avatar'} size="small" src={this.generateAvatar()} />
            </span>
        </Dropdown>
    );
  };

  componentDidMount () {

    this.props.fetchUser();

    window.addEventListener('scroll', this.handleScroll);

      const rootPath = this.props.location.pathname.split("/")[1];
      if(rootPath === 'onboarding') {
          this.setState({
              collapsed: true,
          })
      }

  };

  componentDidUpdate (prevProps) {

      if(prevProps.location.pathname !== this.props.location.pathname) {
          const rootPath = this.props.location.pathname.split("/")[1];
          if(rootPath === 'onboarding') {
              this.setState({
                  collapsed: true,
              })
          }
      }

  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  };

  handleScroll = (e) => {
        let distance = window.scrollY;

        if (distance > 7) {
            if (!this.state.activeHeader) {
                this.setState({
                    activeHeader: true
                })
            }
        } else {
            if (this.state.activeHeader) {
                this.setState({
                    activeHeader: false
                })
            }
        }
    };

  onLogout = () => {
      this.props.history.push('/logout');
  };

  render() {
    const { user } = this.props;
    const {pathname} = this.props.location;
    const rootPath = `/${pathname.split("/")[1]}`;

    return (
      <Layout className={'dashboard-layout'}>
        <Sider
          trigger={null}
          width={256}
          className={'sidebar'}
          breakpoint="lg"
          collapsible
          onCollapse={this.toggle}
          collapsed={this.state.collapsed}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <Link to={'/'} className={'logo'}>
            <img className={'digspro-icon'} src="/images/digspro-icon.svg" alt=""/>
            <img className={'digspro-text'} src="/images/digs-text.svg" alt=""/>
          </Link>
          <Menu
            style={{ padding: '16px 0', width: '100%' }}
            theme="dark"
            mode="inline"
            selectedKeys={[pathname, rootPath]}
            defaultOpenKeys={[rootPath]}
          >
              <SubMenu
                  key="/dashboard"
                  title={
                      <span>
                        <Icon type="dashboard" />
                        <span className="nav-text">Dashboard</span>
                      </span>
                  }
              >
                  <Menu.Item key="/dashboard/all">
                      <Link to="/dashboard/all">
                          Everyone
                      </Link>
                  </Menu.Item>
                  <Menu.Item key="/dashboard/savers">
                      <Link to="/dashboard/savers">
                          Savers
                      </Link>
                  </Menu.Item>
                  <Menu.Item key="/dashboard/homeowners">
                      <Link to="/dashboard/homeowners">
                          Homeowners
                      </Link>
                  </Menu.Item>
            </SubMenu>
            {user.referral_code === "project-own" ? (
                <SubMenu
                    key="/my-app"
                    title={
                        <span>
                        <Icon type="mobile" />
                        <span className="nav-text">My App</span>
                        </span>
                    }
                >
                    <Menu.Item key="/my-app/profile">
                        <Link to="/my-app/profile">
                            Profile
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/my-app/milestones">
                        <Link to="/my-app/milestones">
                            Milestones
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/my-app/content-feed">
                        <Link to="/my-app/content-feed">
                            Content Feed
                        </Link>
                    </Menu.Item>
                </SubMenu>
            ) : (
                <Menu.Item key="/my-app/profile">
                    <Link to="/my-app/profile">
                        <Icon type="mobile" />
                        <span className="nav-text">My App</span>
                    </Link>
                </Menu.Item>
            )}
              <Menu.Item key="/share">
                  <Link to="/share">
                      <Icon type="share-alt" />
                      <span className="nav-text">Share</span>
                  </Link>
              </Menu.Item>
              {
                  user.is_parent_provider &&
                  <SubMenu
                      key="/team"
                      title={
                          <span>
                            <Icon type="team" />
                          <span className="nav-text">Team</span>
                          </span>
                      }
                  >
                      <Menu.Item key="/team/manage">
                          <Link to="/team/manage">
                              Manage
                          </Link>
                      </Menu.Item>
                      <Menu.Item key="/team/routing">
                          <Link to="/team/routing">
                              Lead Routing
                          </Link>
                      </Menu.Item>
                  </SubMenu>
              }
              <Button type="primary" href="/import">
                <Icon type="cloud-upload"  />
                <span className="nav-text">Import</span>                
              </Button>
          </Menu>
        </Sider>
        <Layout
            style={{paddingLeft: this.state.collapsed ? 80 : 256}}
        >
            <Header
                className={`header ${this.state.activeHeader ? ' active' : ''}`}
                style={{width: this.state.collapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)'}}
            >
                <div className={'header--inner'}>
                    <Icon
                        className="trigger"
                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => { this.toggle() }}
                    />
                    <div className={'right'}>
                        {
                            /*
                            <div>
                                <Icon type="question-circle" />
                                { this.generateNotifications() }
                            </div>
                             */
                        }
                        {
                            !user.active_subscription &&
                            <Link to={'/upgrade'} className="button primary upgrade-button">Upgrade</Link>
                        }
                        { this.generateAuthMenu() }
                    </div>
                </div>
            </Header>
            <Content>
                <ErrorBoundary FallbackComponent={DashboardErrorComponent}>
                    <Route {...this.props} />
                </ErrorBoundary>
            </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ user }, ownProps) => ({ user: user.user });
const mapDispatchToProps = {
  ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardLayout))
