import {
    ADD_MATCHES_ERROR,
    ADD_MATCHES_PENDING,
    ADD_MATCHES_SUCCESS,
    FETCH_SETTINGS_ERROR,
    FETCH_SETTINGS_PENDING,
    FETCH_SETTINGS_SUCCESS,
    RESET_EMAIL_COUNT_ERROR,
    RESET_EMAIL_COUNT_PENDING,
    RESET_EMAIL_COUNT_SUCCESS,
    UPDATE_SETTINGS_ERROR,
    UPDATE_SETTINGS_PENDING,
    UPDATE_SETTINGS_SUCCESS,
} from '../actions/types'

const defaultState = {
    data: {},
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_SETTINGS_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_SETTINGS_SUCCESS:
            return { ...state, action, data: { ...state.data, ...action.payload}, errors: {}, loading: false };
        case FETCH_SETTINGS_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_SETTINGS_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_SETTINGS_SUCCESS:
            return { ...state, action, data: { ...state.data, ...action.payload}, errors: {}, loading: false };
        case UPDATE_SETTINGS_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case ADD_MATCHES_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case ADD_MATCHES_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case ADD_MATCHES_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case RESET_EMAIL_COUNT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case RESET_EMAIL_COUNT_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case RESET_EMAIL_COUNT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};
