import {
    ADD_SUBACCOUNT_ERROR,
    ADD_SUBACCOUNT_PENDING,
    ADD_SUBACCOUNT_SUCCESS,
    DELETE_SUBACCOUNT_ERROR,
    DELETE_SUBACCOUNT_PENDING,
    DELETE_SUBACCOUNT_SUCCESS,
    FETCH_SUBACCOUNTS_ERROR,
    FETCH_SUBACCOUNTS_PENDING,
    FETCH_SUBACCOUNTS_SUCCESS,
    UPDATE_SUBACCOUNT_ERROR,
    UPDATE_SUBACCOUNT_PENDING,
    UPDATE_SUBACCOUNT_SUCCESS
} from '../actions/types'

const defaultState = {
    data: {},
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_SUBACCOUNTS_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_SUBACCOUNTS_SUCCESS:
            return { ...state, action, data: action.payload, errors: {}, loading: false };
        case FETCH_SUBACCOUNTS_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case ADD_SUBACCOUNT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case ADD_SUBACCOUNT_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case ADD_SUBACCOUNT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_SUBACCOUNT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_SUBACCOUNT_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case UPDATE_SUBACCOUNT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case DELETE_SUBACCOUNT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case DELETE_SUBACCOUNT_SUCCESS:
            return { ...state, action, data: action.payload, errors: {}, loading: false };
        case DELETE_SUBACCOUNT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};
