import React, {Component} from "react";
import {Card, Form, message, Switch} from "antd";
import * as userActions from "../../actions/user";
import {connect} from "react-redux";

class Notifications extends Component {

    onSwitch(val, field) {
        const { user } = this.props;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.id = user.id;
                values.email = user.email;
                values.firstname = user.firstname;
                values.lastname = user.lastname;
                values[field] = val;

                this.props.updateUser(values)
                    .then(() => {
                        message.success('Profile successfully updated')
                    })
            }
        });

    }

    render() {
        const {
            user,
        } = this.props;

        return (
            <div>
                <h2>Notifications</h2>
                <Card title={'Email'}>
                    <Form hideRequiredMark>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <span style={{fontWeight: 600, color: 'rgba(0, 0, 0, 0.85)'}}>Weekly Reports</span>
                            <Switch defaultChecked={!!user.weekly_report_notification} onChange={(val) => { this.onSwitch(val, 'weekly_report_notification')}}/>
                        </div>
                    </Form>
                </Card>
                <Card title={'Need Help?'}>
                    <p>If you have questions or need to update anything regarding your account notification settings, please contact your Digs representative or email <a href="mailto:support@digs.co">support@digs.co</a>.</p>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user: user.user });
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Notifications));
