import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import history from '../../helpers/history';
import {Col, Divider, Icon, message, Rate, Row, Spin} from "antd";
import CollapsibleCard from '../../components/ui/CollapsibleCard';
import DigsHelper from '../../helpers/DigsHelper';
import {fetchSubscriberById} from "../../actions/children";
import {fetchMilestones} from "../../actions/milestones";
import {updatePropertyById} from "../../actions/dashboard";
import {milestonesOrderedSelector} from "../../reducers/milestones";
import SaverPropertyCard from "../../components/profile/SaverPropertyCard";
import OwnerPropertyCard from "../../components/profile/OwnerPropertyCard";
import NoPropertiesCard from "../../components/profile/NoPropertiesCard";

class Subscriber extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };

    }

    componentDidMount() {

        const { fetchMilestones, fetchSubscriberById } = this.props;

        if(this.props.match.params.id) {

            fetchMilestones()
                .then(() => {

                    fetchSubscriberById(this.props.match.params.id)
                        .then(({ data: { user }}) => {
                            this.setState({
                                user: user,
                            });
                        });

                });

        } else {
            history.push('/');
        }
    }

    handleSaveField = (property_id, data) => {

        return new Promise((resolve, reject) => {

            this.props.updatePropertyById(property_id, { ...data })
                .then(({ property }) => {
                    message.success('Property updated successfully.');
                    const user = { ...this.state.user };
                    const properties = user.properties.map((p) => {
                        if(p.id === property.id) {
                            return property;
                        }
                        return p;
                    });

                    this.setState({ user: { ...user, properties: properties }});
                    resolve();
                })
                .catch(() => {
                    message.error('Whoops! An error has occurred.');
                    reject();
                });

        });

    };

    render() {

        const { user } = this.state;
        const { milestones } = this.props;
        const provider = this.props.user;

        const locked = provider && !provider.active_subscription;

        const personalDetails = user ? [
            {
                title: 'Email',
                value: user.email ? <a href={`mailto:${ user.email }`}>{ user.email }</a> : '-',
            },
            {
                title: 'Phone',
                value: user.mobile ? <span>{DigsHelper.formatPhoneNumber(user.mobile)} <span className="verified"><img src="/images/icons/verified-check.svg" alt="" /></span></span> : '-',
            },
            {
                title: 'Date of Birth',
                value: locked ? <i className="icon-lock" /> : (user.birthday ? user.birthday : '-'),
            },
            {
                title: 'Ethnicity',
                value: locked ? <i className="icon-lock" /> : (user.ethnicity ? user.ethnicity : '-'),
            },
            {
                title: 'Zip Code',
                value: locked ? <i className="icon-lock" /> : (user.zip_code ? user.zip_code : '-'),
            },
            {
                title: 'Employment',
                value: locked ? <i className="icon-lock" /> : (user.employment_status ? user.employment_status : '-'),
            },
            {
                title: 'Marital Status',
                value: locked ? <i className="icon-lock" /> : ((user.marital_status === null) ? '-' : (user.marital_status ? 'Married' : 'Single')),
            },
            {
                title: 'Military Status',
                value: locked ? <i className="icon-lock" /> : ((user.military_status === null) ? '-' : (user.military_status ? 'Yes' : 'No')),
            },
        ] : [];

        const financialDetails = user ? [
            {
                title: 'Credit Score',
                value: locked ? <i className="icon-lock" /> : (user.credit_score ? user.credit_score : '-')
            },
            {
                title: 'Credit Monitoring',
                value: locked ? <i className="icon-lock" /> : (user.credit_monitoring_status ? user.credit_monitoring_status : 'Not Enrolled')
            },
            {
                title: 'Yearly Income',
                value: locked ? <i className="icon-lock" /> : (user.combined_income ? DigsHelper.formatMoney(user.combined_income, 0) : '-')
            },
            {
                title: 'Monthly Rent',
                value: locked ? <i className="icon-lock" /> : (user.current_rent ? DigsHelper.formatMoney(user.current_rent, 0) : '-')
            },
            {
                title: 'Linked Bank Accounts',
                value: locked ? <i className="icon-lock" /> : (user.linked_accounts ? user.linked_accounts : '0')
            },
            {
                title: 'Enrolled in Digs Saver',
                value: locked ? <i className="icon-lock" /> : (user.has_default_account ? 'Enrolled' : 'Not Enrolled')
            }
        ] : [];

        return (
            <div className="page page-user-profile">
                {
                    !user &&
                    <div className={'loading'}>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                    </div>
                }
                {
                    user &&
                    <div>
                        <div className={'user-details'}>
                            <div className="user-details--type">
                                <i className={'icon-mail-outline'} style={{ fontSize: 10 }}/> Subscriber
                            </div>
                            <div className={'user-details--name'}>
                                <h1>{user.firstname ? user.firstname : 'null'} {user.lastname ? user.lastname : 'null'}</h1>
                            </div>
                        </div>
                        <Divider />
                        <Row gutter={20}>
                            <Col xs={24} md={24} lg={8}>
                                <CollapsibleCard
                                    title={'Personal Details'}
                                    data={personalDetails}
                                    count={2}
                                />
                                <CollapsibleCard
                                    title={'Financial Details'}
                                    data={financialDetails}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={16}>
                                {
                                    user.properties.length < 1 &&
                                    <NoPropertiesCard />
                                }
                                {
                                    user.properties.length > 0 &&
                                    user.properties.map((property, i) => {
                                        switch(property.type) {
                                            case 'owner':
                                                return <OwnerPropertyCard key={i} property={property} locked={locked} handleSave={this.handleSaveField}/>;
                                            case 'saver':
                                                return <SaverPropertyCard key={i} property={property} milestones={milestones} locked={locked}/>;
                                            default:
                                                break;
                                        }
                                    })
                                }
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    milestones: milestonesOrderedSelector(state)
});

const mapDispatchToProps = {
    fetchMilestones,
    fetchSubscriberById,
    updatePropertyById,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Subscriber));
