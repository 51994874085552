import React, {PureComponent} from 'react';
import * as userActions from '../actions/user'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Card, Checkbox, Divider, Form, Icon, Input} from 'antd';
import { LOGIN_PENDING } from "../actions/types";
import Errors from "../components/utils/Errors";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import OAuthPopup from "react-oauth-popup";
import queryString from "query-string";
import config from '../config'

class Login extends PureComponent {

    constructor(props) {
        super(props);

        this.linkedInRedirectUri = config.REACT_APP_URL ? `${config.REACT_APP_URL}/signup` : '';
        this.linkedInClientId = '78xknhv1qruuqm';
    }

    componentDidMount() {
        if(this.props.location) {

            const query = queryString.parse(this.props.location.search);
            if(query.preauth) {
                this.props.loginToken(query.preauth);
            }

        }
    }

    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, inputs) => {
        if (!err) {

          const values = {
              email: inputs.email,
              password: inputs.password,
          };

          this.props.loginUser(values);
        }
      });
    }

    handleOnGoogleLogin = (response) => {

        if(response.accessToken) {

            const values = {
                social_provider: 'google',
                access_token: response.accessToken,
            };

            this.props.loginSocialUser(values);

        }
    }

    handleOnFacebookLogin = (response) => {

        if (response.accessToken) {

            const values = {
                social_provider: 'facebook',
                access_token: response.accessToken,
            };

            this.props.loginSocialUser(values);

        }

    }

    handleOnLinkedInLogin = (code) => {

        if (code) {

            const values = {
                social_provider: 'linkedin',
                access_token: code,
            };

            this.props.loginSocialUser(values);

        }

    }

    render() {

        const {getFieldDecorator} = this.props.form;

        const { action, errors } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page-login'}>
                <h1>Login</h1>
                <Card>
                    {errors && <Errors errors={errors} />}
                    <Form onSubmit={this.handleSubmit} colon={false} hideRequiredMark={true}>
                        <Form.Item label={'Email'}>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please input your email!' }],
                            })(
                                <Input
                                    size={'large'}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label={'Password'}>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    size={'large'}
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(<Checkbox>Remember me</Checkbox>)}
                            <Link className={'login--forgot'} to={"/forgot-password"}>
                                Forgot your password?
                            </Link>
                            <Button type="primary" size="large" htmlType="submit" loading={type === LOGIN_PENDING} className={'button primary login--button'}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                    <Divider />
                    <GoogleLogin
                        clientId="498863115396-6p53odlqc25luvjtmjrakboa71bbquah.apps.googleusercontent.com"
                        render={renderProps => (
                            <Button className={'button social'} onClick={renderProps.onClick}>
                                <img src="/images/icons/google.svg" alt=""/>
                                <span>Sign in with Google</span>
                            </Button>
                        )}
                        buttonText="Login"
                        onSuccess={this.handleOnGoogleLogin}
                        onFailure={this.handleOnGoogleLogin}
                        cookiePolicy={'single_host_origin'}
                    />
                    <FacebookLogin
                        appId="461558117795162"
                        callback={this.handleOnFacebookLogin}
                        render={renderProps => (
                            <Button className={'button social'}  onClick={renderProps.onClick}>
                                <img src="/images/icons/facebook.svg" alt=""/>
                                <span>Sign in with Facebook</span>
                            </Button>
                        )}
                    />
                    <OAuthPopup
                        url={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.linkedInClientId}&redirect_uri=${this.linkedInRedirectUri}&scope=r_liteprofile%20r_emailaddress%20w_member_social`}
                        onCode={this.handleOnLinkedInLogin}
                        width={600}
                        height={600}
                    >
                        <Button className={'button social'}
                        >
                            <img src="/images/icons/linkedin.svg" alt=""/>
                            <span>Sign in with LinkedIn</span>
                        </Button>
                    </OAuthPopup>
                </Card>
                <p className={'signup'}>Don't have an account? <Link to={'/signup'}>Sign Up</Link></p>
            </div>
        );
    }
}

const mapStateToProps = ({ user }, ownProps) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login))
