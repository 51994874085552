import React, {Component} from 'react';
import {Icon, Layout} from 'antd';
import {Link, Route, withRouter} from 'react-router-dom';
import ErrorBoundary from 'react-error-boundary';
import DefaultErrorComponent from './error/DefaultErrorComponent';

const { Content } = Layout;

class GenericLayout extends Component {

  render() {

    return (
      <Layout className={'generic-layout'}>
        <Content className={'generic-layout--content'}>
            <div className="container">
                <Link to={"/login"} className={'generic-layout--logo'}>
                    <img src="/images/home/digs_logo.svg" alt="Digs Pro Logo" />
                </Link>
                <ErrorBoundary FallbackComponent={DefaultErrorComponent}>
                    <Route
                        {...this.props}
                    />
                </ErrorBoundary>
            </div>
        </Content>
      </Layout>
    );
  }
}


export default withRouter(GenericLayout);
