import React, {PureComponent} from 'react';
import {Col, Divider, Icon, Row} from 'antd'
import CSVComponent from '../components/integrations/CSV';

class ImportContacts extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    render() {

        return (
            <div className={"page page-integrations-csv"}>
                <Row>
                    <Col xs={24} xl={16}>
                        <div className={'title-module'}>
                            <h1><Icon type="cloud-upload" /> Import Contacts</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <CSVComponent />
            </div>
        );
    }

}

export default ImportContacts;
