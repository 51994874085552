import React, {PureComponent} from 'react';

class LeadRoutingItem extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

    };

    render() {

        const { user, status, length, onRemove, isProvider } = this.props;

        return (
            <li className={`${status}`}>
                <i className={'handle icon-drag-grid'}></i>
                <div>
                    <span className={'badge next'}>Up Next</span>
                    <span className={'badge previous'}>Previous</span>
                    <div className={'avatar--container'}>
                        <div className={'avatar'}>
                            {
                                user.avatar && user.avatar.file &&
                                <img src={user.avatar.file} alt=""/>
                            }
                            {
                                (!user.avatar || !user.avatar.file) &&
                                <span>
                                    { user.firstname.charAt(0) }
                                    { user.lastname.charAt(0) }
                                </span>
                            }

                        </div>
                        <div>{user.firstname} {user.lastname}{ isProvider ? ' (You)' : ''}</div>
                    </div>
                    <div>
                        <span>
                            { `${(100 / length).toFixed(2)}%`}
                        </span>
                        {
                            onRemove &&
                            <a href="#" onClick={(e) => { e.preventDefault(); onRemove()}}>Remove</a>
                        }
                    </div>
                </div>
            </li>
        );
    }

}

export default LeadRoutingItem;
