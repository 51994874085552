const getConfig = () => {
    const { hostname } = window.location;
    if (hostname === "pro.digs.co") {
        return require("./prod").default;
    }

    if (hostname === "digs-pro-stage.digs.co") {
        return require("./staging").default;
    }

    if (hostname.endsWith("digs-pro-dev.digs.co")) {
        return require("./dev").default;
    }

    return require("./local").default;
};

export default getConfig();
