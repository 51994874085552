import client from './'

/* action types */

export const FETCH_MILESTONES_PENDING = "FETCH_MILESTONES_PENDING";
export const FETCH_MILESTONES_SUCCESS = "FETCH_MILESTONES_SUCCESS";
export const FETCH_MILESTONES_ERROR = "FETCH_MILESTONES_ERROR";

export const CREATE_MILESTONE_PENDING = "CREATE_MILESTONE_PENDING";
export const CREATE_MILESTONE_SUCCESS = "CREATE_MILESTONE_SUCCESS";
export const CREATE_MILESTONE_ERROR = "CREATE_MILESTONE_ERROR";

export const UPDATE_MILESTONE_PENDING = "UPDATE_MILESTONE_PENDING";
export const UPDATE_MILESTONE_SUCCESS = "UPDATE_MILESTONE_SUCCESS";
export const UPDATE_MILESTONE_ERROR = "UPDATE_MILESTONE_ERROR";

export const DELETE_MILESTONE_PENDING = "DELETE_MILESTONE_PENDING";
export const DELETE_MILESTONE_SUCCESS = "DELETE_MILESTONE_SUCCESS";
export const DELETE_MILESTONE_ERROR = "DELETE_MILESTONE_ERROR";

export const RESET_MILESTONES_PENDING = "FETCH_MILESTONES_PENDING";
export const RESET_MILESTONES_SUCCESS = "FETCH_MILESTONES_SUCCESS";
export const RESET_MILESTONES_ERROR = "FETCH_MILESTONES_ERROR";

export const UPDATE_MILESTONES_ORDER_PENDING = "UPDATE_MILESTONE_ORDER_PENDING";
export const UPDATE_MILESTONES_ORDER_SUCCESS = "UPDATE_MILESTONE_ORDER_SUCCESS";
export const UPDATE_MILESTONES_ORDER_ERROR = "UPDATE_MILESTONE_ORDER_ERROR";

export const UPLOAD_MILESTONES_MEDIA_PENDING = "UPLOAD_MILESTONES_MEDIA_PENDING";
export const UPLOAD_MILESTONES_MEDIA_SUCCESS = "UPLOAD_MILESTONES_MEDIA_SUCCESS";
export const UPLOAD_MILESTONES_MEDIA_ERROR = "UPLOAD_MILESTONES_MEDIA_ERROR";

/* action functions */

export const fetchMilestones = () => {
    return (dispatch) => {
        dispatch({type: FETCH_MILESTONES_PENDING});
        return new Promise((resolve, reject) => {
            client.get('/provider/milestones')
                .then(response => {
                    dispatch({
                        type: FETCH_MILESTONES_SUCCESS,
                        payload: response.data.milestones
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: FETCH_MILESTONES_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const createMilestone = (milestone) => {
    return (dispatch) => {
        dispatch({type: CREATE_MILESTONE_PENDING});
        return new Promise((resolve, reject) => {
            client.post('/provider/milestone', milestone)
                .then(response => {
                    dispatch({
                        type: CREATE_MILESTONE_SUCCESS,
                        payload: response.data
                    });
                    resolve(response.data.milestone);
                })
                .catch(err => {
                    dispatch({
                        type: CREATE_MILESTONE_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const updateMilestone = (milestone) => {
    return (dispatch) => {
        dispatch({type: UPDATE_MILESTONE_PENDING});
        return new Promise((resolve, reject) => {
            client.patch(`/provider/milestone/${milestone.id}`, milestone)
                .then(response => {
                    dispatch({
                        type: UPDATE_MILESTONE_SUCCESS,
                        payload: response.data.milestone
                    });
                    resolve(response.data.milestone);
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_MILESTONE_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const deleteMilestone = (milestoneId) => {
    return (dispatch) => {
        dispatch({type: DELETE_MILESTONE_PENDING});
        return new Promise((resolve, reject) => {
            client.delete(`/provider/milestone/${milestoneId}`)
                .then(response => {
                    dispatch({
                        type: DELETE_MILESTONE_SUCCESS,
                        payload: milestoneId
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_MILESTONE_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const updateMilestonesOrder = (orderArray) => {
    return (dispatch) => {
        dispatch({type: UPDATE_MILESTONES_ORDER_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/milestones/order`, { order: orderArray })
                .then(response => {
                    dispatch({
                        type: UPDATE_MILESTONES_ORDER_SUCCESS,
                        payload: response.data.milestones
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_MILESTONES_ORDER_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const resetMilestones = () => {
    return (dispatch) => {
        dispatch({type: RESET_MILESTONES_PENDING});
        return new Promise((resolve, reject) => {
            client.delete(`/provider/milestones`)
                .then(response => {
                    dispatch({
                        type: RESET_MILESTONES_SUCCESS,
                        payload: response.data.milestones
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: RESET_MILESTONES_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const uploadMilestoneMedia = (milestoneId, data) => {
    return (dispatch) => {
        dispatch({type: UPLOAD_MILESTONES_MEDIA_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/milestone/${milestoneId}/media`, data)
                .then(response => {
                    dispatch({
                        type: UPLOAD_MILESTONES_MEDIA_SUCCESS,
                        payload: response.data.media
                    });
                    resolve(response.data.media);
                })
                .catch(err => {
                    dispatch({
                        type: UPLOAD_MILESTONES_MEDIA_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};
