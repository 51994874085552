import React, {PureComponent} from 'react';
import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Select,
    Steps,
    Table,
    message,
    Input,
    Divider,
    InputNumber,
    DatePicker,
    Checkbox
} from 'antd'
import {CSVReader} from 'react-papaparse';
import {connect} from 'react-redux';
import { importSubscribers, importSingleSaver, importSingleHomeowner } from "../../actions/import";
import DigsHelper from "../../helpers/DigsHelper";
import {withRouter} from "react-router-dom";
import moment from "moment";
import Fuse from 'fuse.js';
import CollapsibleFieldList from "../ui/CollapsibleFieldList";
import Errors from "../utils/Errors";

const { Panel } = Collapse;
const { Option } = Select;
const { Step } = Steps;

const homeOwnerFieldRequirements = [
    {
        name: 'Email',
        type: 'String',
        example: 'john.appleseed@digs.co',
        required: true
    },
    {
        name: 'Street Address',
        type: 'String',
        example: '122 Main St Unit 3N',
        required: true
    },
    {
        name: 'City',
        type: 'String',
        example: 'Chicago',
        required: true
    },
    {
        name: 'State',
        type: 'String',
        example: '"IL" or "Illinois"',
        required: true
    },
    {
        name: 'Zip Code',
        type: 'Numeric',
        example: '60647',
        required: true
    },
    {
        name: 'First Name',
        type: 'String',
        example: 'John',
        required: false
    },
    {
        name: 'Last Name',
        type: 'String',
        example: 'Appleseed',
        required: false
    },
    {
        name: 'Phone Number',
        type: 'String / Numeric',
        example: '(555) 321-1235 or 5553211235',
        required: false
    },
    {
        name: 'Purchase Price',
        type: 'Numeric',
        example: '225,000 or 225000',
        required: false
    },
    {
        name: 'Purchase Date',
        type: 'Date',
        example: '2010-01-05',
        required: false
    },
    {
        name: 'Interest Rate',
        type: 'Numeric',
        example: '3.875',
        required: false
    },
    {
        name: 'Loan Term',
        type: 'Numeric',
        example: '30',
        required: false
    },
];

class CSV extends PureComponent {

    constructor(props) {

        super(props);

        this.fileInput = React.createRef();

        this.state = {
            /* Step 0 */
            audience: "homeowners",
            /* Step 1 */
            importMethod: null,
            importMethodSelection: null,
            singleAddFormData: null,
            singleErrors: null,
            importingSingle: false,
            /* */
            success: false,
            loading: false,
            rows: [],
            meta: null,
            currentStep: 1,
            map: {},
            confirmSendModal: false,
            /* Results */
            results: {
                message: null,
                validation: {
                    valid: 0,
                    invalid: [],
                },
            },
            sendWelcomeEmail: true,
        };

        this.homeownersFields = [
            {
                'text': 'First Name',
                'lookup': 'first name',
                'value': 'firstname',
                'type': 'string',
                'required': false,
            },
            {
                'text': 'Last Name',
                'lookup': 'last name',
                'value': 'lastname',
                'type': 'string',
                'required': false,
            },
            {
                'text': 'Email',
                'lookup': 'email',
                'value': 'email',
                'type': 'string',
                'required': true,
            },
            {
                'text': 'Phone',
                'lookup': 'phone',
                'value': 'mobile',
                'type': 'string',
                'required': false,
            },
            {
                'text': 'Street Address',
                'lookup': 'street address',
                'value': 'street_address',
                'type': 'string',
                'required': true,
            },
            {
                'text': 'Apt or Unit #',
                'lookup': 'apt unit',
                'value': 'apt_or_unit',
                'type': 'string',
                'required': false,
            },
            {
                'text': 'City',
                'lookup': 'city',
                'value': 'city',
                'type': 'string',
                'required': true,
            },
            {
                'text': 'State',
                'lookup': 'state',
                'value': 'state',
                'type': 'string',
                'required': true,
            },
            {
                'text': 'Zip Code',
                'lookup': 'zip',
                'value': 'zip_code',
                'type': 'number',
                'required': true,
            },
            {
                'text': 'Purchase Price',
                'lookup': 'purchase price',
                'value': 'purchase_price',
                'type': 'number',
                'required': false,
            },
            {
                'text': 'Purchase Date',
                'lookup': 'purchase date',
                'value': 'purchase_date',
                'type': 'date',
                'required': false,
            },
            {
                'text': 'Interest Rate',
                'lookup': 'interest rate',
                'value': 'loan_interest_rate',
                'type': 'number',
                'required': false,
            },
            {
                'text': 'Loan Term',
                'lookup': 'loan term',
                'value': 'loan_term_length',
                'type': 'number',
                'required': false,
            },
        ]

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevState.currentStep !== this.state.currentStep &&
            this.state.currentStep === 2 &&
            this.state.importMethod === 'single'
        ) {
            console.log('triggered');
            this.props.form.validateFields();
        }
    }

    /* CSV Handling */

    handleImportOffer = () => {
        this.fileInput.current.click();
    };

    handleReadCSV = (data) => {
        message.success('CSV file successfully loaded!');
        this.setState({
            rows: data.data,
            meta: data.meta,
        });
        this.goToStep(2);
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    /* Review and Import */
    handleOnReviewAndImport = () => {

        this.props.form.validateFields((err, values) => {
            if (!err) {

                for (let property in values) {

                    if(values.hasOwnProperty(property)) {

                        if(this.state.meta.fields.indexOf(values[property]) < 0) {
                            delete values[property];
                        }
                    }

                }

                this.setState({
                    map: values,
                });
                this.goToStep(3);
            }
        });
    };

    canProceedToImport = () => {
        const formValues = this.props.form.getFieldsValue();
        let canProceed = true;

        for (let prop in formValues) {
            if(formValues.hasOwnProperty(prop) && !formValues[prop]) {
                canProceed = false;
            }
        }
        return canProceed;
    };

    /* */

    goToStep = (step) => {

        this.setState({
            currentStep: step
        });

    };

    importMethodSelect = (method) => {

        if(method === 'single') {
            this.setState({
                importMethod: 'single',
                currentStep: 2
            });
        } else {
            this.setState({
                importMethod: 'bulk',
            });
        }

    };

    generateFieldMapTable() {

        const mappableFields = this.homeownersFields;

        return mappableFields.map(field => {

            return (
                <div className="module--table-row" key={`${field.text}`}>
                    <div className="module--table-column">
                        <div className={'fields-column'}>
                            <span className={'field-title'}>{ field.text }{ field.required ? <span>*</span> : ''}</span>
                            <span className={'field-type'}>type: { field.type }</span>
                        </div>
                    </div>
                    <div className="module--table-column">
                        <div className={'fields-column'}>
                            { this.generateCSVFields(field) }
                        </div>
                    </div>
                </div>
            )

        });

    }

    generateCSVFields(field) {

        const { getFieldDecorator } = this.props.form;

        let fields = [];

        if(this.state.meta.fields) {

            fields = this.state.meta.fields.map((field, i) => {
                return <Option value={field} key={i}>{ field }</Option>
            });

        }

        const fuse = new Fuse(this.state.meta.fields);
        const fuseResult = fuse.search(field.lookup);

        return (
            getFieldDecorator(field.value, {
                initialValue: fuseResult.length > 0 ? this.state.meta.fields[fuseResult[0].refIndex] : null
            })(
                <Select
                    placeholder="Please select..."
                >
                    { fields }
                </Select>
            ));

    }

    generateValidRowsPreview() {

        const dataSource = this.validRows();

        const mappableFields = this.homeownersFields;

        const columns = mappableFields.map(field =>  {
            return {
                title: field.text,
                dataIndex: field.value,
                key: field.value
            }
        });

        columns.push({
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: 'right'
        });

        return (
            <Table
                pagination={{
                    defaultPageSize: 5
                }}
                dataSource={dataSource}
                columns={columns}
                scroll={{x: true}}
            />
        )
    }

    removeItem = i => {
        let rows = [...this.state.rows];
        rows.splice(i, 1);
        this.setState({ rows: rows });
    };

    /* AJAX CALLS */
    importContacts = () => {

        this.setState({
            loading: true,
        });

        const ownersRaw = this.validRows();

        const owners = ownersRaw.map(o => {
            return {
                ...o,
                loan_amount: o.loan_amount ? parseFloat(o.loan_amount.replace(/\$|,/g, '')) : null,
                loan_origination_date: o.loan_origination_date ? moment(o.loan_origination_date).format('YYYY-MM-DD') : null,
                purchase_price: o.purchase_price ? parseFloat(o.purchase_price.replace(/\$|,/g, '')) : null,
                purchase_date: o.purchase_date ? moment(o.purchase_date).format('YYYY-MM-DD') : null,
                valuation_price: o.valuation_price ? parseFloat(o.valuation_price.replace(/\$|,/g, '')) : null,
                valuation_date: o.valuation_date ? moment(o.valuation_date).format('YYYY-MM-DD') : null,
            }
        });

        this.props.importSubscribers({ import_type: 'owner', owners: owners, send_welcome_email: this.state.sendWelcomeEmail })
            .then((response) => {
                setTimeout(() => {

                    this.setState({ results: response });

                    this.setState({
                        loading: false,
                        success: true,
                    });
                }, 100);
            })
            .catch((err) => {
                let response = err.response.data;

                console.log(response);
                this.setState({ results: response });

                setTimeout(() => {
                    this.setState({
                        loading: false,
                        success: true,
                    });
                }, 100);
            });
    };

    importSingleContact = () => {

        const { singleAddFormData } = this.state;

        this.setState({importingSingle: true});

        this.props.importSingleHomeowner(singleAddFormData)
            .then((response) => {
                message.success('Homeowner successfully invited.');
                if(response.subscriber_id) {
                    this.props.history.push(`/view/subscriber/${response.subscriber_id}`)
                } else if(response.user_id) {
                    this.props.history.push(`/view/user/${response.user_id}`)
                } else {
                    this.reset();
                }
            })
            .catch((err) => {

                const errors = err.response.data.errors ? err.response.data.errors : err.response.data.message;

                this.setState({
                    currentStep: 2,
                    singleErrors: errors,
                    importingSingle: false
                });

            });

    };

    reset = () => {
        this.setState({
            importingSingle: false,
            importMethod: null,
            importMethodSelection: null,
            singleAddFormData: null,
            success: false,
            loading: false,
            rows: [],
            meta: null,
            currentStep: 0,
            map: {},
            confirmSendModal: false,
        });

        this.fileInput = React.createRef();

    };

    generateModalContent = () => {

        const { results } = this.state;
        const valid = results.validation.valid;
        const invalid = results.validation.invalid.length;
        const inviteText = 'Import';
        const successfulText = 'imported';

        const modalContent = [
            {
                title: `Confirm ${inviteText.toLowerCase()}(s)`,
                text: (<p>You are about to {inviteText.toLowerCase()} <b>{ this.validRows().length } {this.state.audience}.</b> Do you want to continue?</p>),
                footer: [
                    <Button key="back" onClick={ () => { this.setState({ confirmSendModal: false}) } }>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.loading} onClick={ () => { this.importContacts() }} >
                        {`Confirm and ${inviteText}`}
                    </Button>,
                ]
            },
            {
                title: `${inviteText} in progress...`,
                text: (<div style={{ textAlign: 'center' }}>
                    <img style={{ display: 'block', width: '100%', margin: '0 auto 25px auto', maxWidth: '100px' }} src={'/images/icons/loading.svg'} alt={""}/>
                    <p>Please do not refresh the page.</p>
                </div>),
                footer: null,
            },
            {
                title: invalid > 0 ? (valid > 0 ? `${inviteText} successful with errors!` : `${inviteText} failed!`) : `${inviteText} successful!`,
                text: (<div style={{ textAlign: 'center' }}>
                    <img style={{ display: 'block', width: '100%', margin: '0 auto 25px auto', maxWidth: '100px' }} src={valid > 0 ? '/images/icons/loading-success.svg' : '/images/icons/loading-failed.svg'} alt={""}/>
                    {
                        valid < 1 &&
                        <p>Failed to {inviteText.toLowerCase()} new {this.state.audience}, see errors below.</p>
                    }
                    {
                        valid > 0 && invalid > 0 &&
                        <div>
                            <p><b>{ valid } {this.state.audience} {successfulText}.</b></p>
                            <p>{ invalid } failed, see errors below.</p>
                        </div>
                    }
                    {
                        valid > 0 && invalid < 1  &&
                        <p><b>{ valid } {this.state.audience} {successfulText}.</b></p>
                    }
                    {
                        invalid > 0 &&
                        this.generateErrorTable(results.validation.invalid)
                    }
                </div>),
                footer: (invalid > 0 && valid < 1) ? [
                    <Button key="submit" type="primary" onClick={ () => { this.reset() }} >
                        Try again
                    </Button>
                ] : [
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Button type="default" href={'/dashboard'}>
                            Return to dashboard
                        </Button>
                        <Button key="submit" type="primary" onClick={ () => { this.reset() }} >
                            Import another file
                        </Button>
                    </div>
                ]
            },
        ];

        if(this.state.loading && !this.state.success) {
            return modalContent[1];
        }

        if(!this.state.loading && this.state.success) {
            return modalContent[2];
        }

        return modalContent[0];

    };

    generateErrorTable = (rows) => {

        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: 'Error',
                dataIndex: 'errors',
                render: (val, record) => record.errors[0],
            },
        ];

        return (
            <Collapse accordion className={'csv-module-error-table'}>
                <Panel header="View Errors" key="1">
                    <Table columns={columns} dataSource={rows} size="small" />
                </Panel>
            </Collapse>
        )
    };

    generateStateSelect = () => {

        const stateAbbreviations = [
            'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
            'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
            'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
            'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
            'VT','VI','VA','WA','WV','WI','WY'
        ];

        return (
            <Select placeholder={'---'}>
                {
                    stateAbbreviations.map((state) => (
                        <Select.Option key={state} value={state}>{ state}</Select.Option>
                    ))
                }
            </Select>
        )

    };

    /* Dynamic Lists */

    deDuper = () => {

        const { rows } = this.state;
        const mappableFields = this.homeownersFields;
        const squashed = [];
        const valid = [];
        const duplicates = [];

        const items = rows.map((item, i) => {

            let composite = {
                key: i,
                actions: (<Button onClick={() => {this.removeItem(i)}}>Remove</Button>)
            };

            mappableFields.forEach(field => {
                if (this.state.map.hasOwnProperty(field.value)) {
                    composite[field.value] = item[this.state.map[field.value]];
                }
            });

            return composite;

        });

        const validItems = items.length > 0 ? items.filter((item) => {
            return DigsHelper.validateEmail(item.email) && item.email !== 'johnny.appleseed@digs.co';
        }) : [];

        if(validItems.length > 0) {

            for (let x = 0; x < validItems.length; x++) {

                let condensed = `${validItems[x].email}`;

                if(squashed.indexOf(condensed) > -1) {
                    duplicates.push(validItems[x]);
                } else {
                    valid.push(validItems[x]);
                    squashed.push(condensed);
                }
            }

        }

        return {
            valid: valid,
            duplicates: duplicates
        }

    };

    validRows = () => {

        const deduped = this.deDuper();
        const rows = deduped.valid;

        return rows.length > 0 ? rows.filter((item) => {
            return DigsHelper.validateEmail(item.email);
        }) : [];

    };

    duplicateRows = () => {

        const deduped = this.deDuper();
        const rows = deduped.duplicates.map((item) => {
            return {
                key: item.key,
                email: item.email,
                firstname: item.firstname,
                lastname: item.lastname
            }
        });

        return rows.length > 0 ? rows : [];

    };

    errorRows = () => {

        const { rows } = this.state;
        const mappableFields = this.homeownersFields;

        const items = rows.map((item, i) => {

            let composite = {};

            mappableFields.forEach(field => {
                if (this.state.map.hasOwnProperty(field.value)) {
                    composite[field.value] = item[this.state.map[field.value]];
                }
            });

            return composite;

        });

        const filtered = items.length > 0 ? items.filter((item) => {
            return !DigsHelper.validateEmail(item.email);
        }) : [];


        return filtered.length > 0 ? filtered.map((item, i) => {
            return {
                key: i,
                email: DigsHelper.validateEmail(item.email) ? item.email : (item.email ? <b className={'error-red'}>`Invalid: ${item.email}`</b> : <b className={'error-red'}>Missing</b>),
                firstname: item.firstname ? item.firstname : <b className={'error-red'}>Missing</b>,
                lastname: item.lastname ? item.lastname : <b className={'error-red'}>Missing</b>
            }
        }) : [];

    };

    /* End Dynamic Lists */

    onSingleFormSubmit = () => {

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    singleAddFormData: values,
                    currentStep: 3,
                });
            }
        });

    };

    hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    };

    render() {

        const { singleAddFormData, importingSingle } = this.state;
        const { withOffset, form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className={'csv-module'}>
                <Row gutter={24}>
                    <Col xs={24} xl={16}>
                        <Card
                            className={'steps-card'}
                            bordered={false}
                        >
                            <Steps current={this.state.currentStep - 1}>                                
                                <Step title={this.state.currentStep === 1 ? "Import Method" : null} />
                                <Step title={this.state.currentStep === 2 ? (this.state.importMethod === 'single' ? 'Add Homeowner' : 'Match') : null} />
                                <Step title={this.state.currentStep === 3 ? "Review" : null} />
                            </Steps>
                        </Card>
                        {
                            /* AUDIENCE === homeowners */
                            this.state.audience === 'homeowners' &&
                            <>
                                {
                                    /* Choose import method */
                                    this.state.currentStep === 1 &&
                                    !this.state.importMethod &&
                                    <Card
                                        className={'card-content'}
                                        bordered={false}
                                        title={'Choose how you\'d like to import homeowners...'}
                                    >
                                        <div className={'card--inner audience'}>
                                            <Row gutter={10}>
                                                <Col xs={12}>
                                                    <Card
                                                        onClick={() => { this.setState({importMethodSelection: 'bulk'})}}
                                                        className={`audience-select ${this.state.importMethodSelection === 'bulk' ? 'active' : ''}`}
                                                    >
                                                <span className={'checkmark'}>
                                                    <i className={'icon-checkmark'}></i>
                                                </span>
                                                        <i className={'icon-csv'} />
                                                        <h3>Bulk Upload</h3>
                                                        <p>Upload a CSV with many contacts</p>
                                                    </Card>
                                                </Col>
                                                <Col xs={12}>
                                                    <Card
                                                        onClick={() => { this.setState({importMethodSelection: 'single'})}}
                                                        className={`audience-select ${this.state.importMethodSelection === 'single' ? 'active' : ''}`}
                                                    >
                                                <span className={'checkmark'}>
                                                    <i className={'icon-checkmark'}></i>
                                                </span>
                                                        <i className={'icon-single'} />
                                                        <h3>One at a Time</h3>
                                                        <p>Manually add one contact</p>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="card--footer" style={{justifyContent: 'space-between'}}>
                                            <div className="card--footer-left">
                                                <Button onClick={() => { this.setState({importMethodSelection: null, importMethod: null, currentStep: 0})}}>&lt; Back</Button>
                                            </div>
                                            <div className="card--footer-right">
                                                <Button disabled={!this.state.importMethodSelection} type={'primary'} onClick={() => { this.importMethodSelect(this.state.importMethodSelection) }}>Continue</Button>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    /* Bulk Homeowner Upload */
                                    this.state.currentStep === 1 &&
                                    this.state.importMethod === 'bulk' &&
                                    <Card
                                        className={'card-content'}
                                        bordered={false}
                                        title={'Upload a CSV file of homeowners...'}
                                    >
                                        <div className={'card--inner'}>
                                            <p>Before uploading your list of homeowners, please reference the table below and validate that all of the required headers are present in your CSV. If you are having trouble, you may also <a
                                                href="/downloads/Digs_Pro-Import_CSV_Templates.zip">download our CSV templates.</a></p>
                                            <CollapsibleFieldList items={homeOwnerFieldRequirements} />
                                        </div>
                                        <div className="card--footer">
                                            <div className="card--footer-left">
                                                <Button onClick={() => { this.setState({importMethod: null})}}>&lt; Back</Button>
                                            </div>
                                            <div className="card--footer-right">
                                                <Button type={'primary'} onClick={this.handleImportOffer}>Select file...</Button>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    /* Bulk Homeowner Review */
                                    this.state.currentStep === 2 &&
                                    this.state.importMethod === 'bulk' &&
                                    <Card
                                        className={'card-content'}
                                        bordered={false}
                                        title={'Match your data with the fields in Digs'}
                                    >
                                        <div className={'card--inner'}>
                                            <p>Use the table below to match your uploaded data with the corresponding fields in Digs.</p>
                                            {
                                                this.state.meta.fields &&
                                                <Form>
                                                    <div className="module--table">
                                                        <div className="module--table-row module--table-header">
                                                            <div className="module--table-column">
                                                                <span>Digs Fields</span>
                                                            </div>
                                                            <div className="module--table-column">
                                                                <span>Your Fields</span>
                                                            </div>
                                                        </div>
                                                        { this.generateFieldMapTable() }
                                                    </div>
                                                </Form>
                                            }
                                        </div>
                                        <div className="card--footer">
                                            <div className="card--footer-left">
                                                <Button onClick={() => { this.goToStep(1)}}>&lt; Back</Button>
                                            </div>
                                            <div className="card--footer-right">
                                                <Button disabled={!this.canProceedToImport()} onClick={this.handleOnReviewAndImport} type={'primary'}>Review Homeowners</Button>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    /* Single Homeowner Upload */
                                    this.state.currentStep === 2 &&
                                    this.state.importMethod === 'single' &&
                                    <Form
                                        layout="vertical"
                                        hideRequiredMark
                                        onSubmit={this.onSingleFormSubmit}
                                    >
                                        <Card
                                            className={'card-content'}
                                            bordered={false}
                                            title={'Add a single homeowner...'}
                                        >
                                            <div className={'card--inner'}>
                                                {
                                                    this.state.singleErrors &&
                                                    <Errors errors={this.state.singleErrors} closable={false}/>
                                                }
                                                <Row gutter={24}>
                                                    <Col sm={24}>
                                                        <h4>Personal Details</h4>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item
                                                            label={'First Name'}
                                                            validateStatus={(form.isFieldTouched('firstname') && form.getFieldError('firstname')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('firstname') && form.getFieldError('firstname')) || ''}
                                                        >
                                                            {getFieldDecorator('firstname', {
                                                                initialValue: (singleAddFormData && singleAddFormData.firstname) ? singleAddFormData.firstname : null,
                                                                rules: [{ required: true, message: 'Please enter first name', whitespace: true }],
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item
                                                            label={'Last Name'}
                                                            validateStatus={(form.isFieldTouched('lastname') && form.getFieldError('lastname')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('lastname') && form.getFieldError('lastname')) || ''}
                                                        >
                                                            {getFieldDecorator('lastname', {
                                                                initialValue: (singleAddFormData && singleAddFormData.lastname) ? singleAddFormData.lastname : null,
                                                                rules: [{ required: true, message: 'Please enter last name', whitespace: true }],
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item
                                                            label={'Email'}
                                                            validateStatus={(form.isFieldTouched('email') && form.getFieldError('email')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('email') && form.getFieldError('email')) || ''}
                                                        >
                                                            {getFieldDecorator('email', {
                                                                initialValue: (singleAddFormData && singleAddFormData.email) ? singleAddFormData.email : null,
                                                                rules: [{ required: true, type: 'email', message: 'Please enter an email address', whitespace: true }],
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item label={<>Phone Number <span>(Optional)</span></>}>
                                                            {getFieldDecorator('mobile', {
                                                                initialValue: (singleAddFormData && singleAddFormData.mobile) ? singleAddFormData.mobile : null
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24}>
                                                        <Form.Item>
                                                            {getFieldDecorator('send_welcome_email', {
                                                                initialValue: (singleAddFormData && singleAddFormData.send_welcome_email) ? singleAddFormData.send_welcome_email : true,
                                                                valuePropName: 'checked'
                                                            })(<Checkbox><span style={{color: 'rgba(0, 0, 0, 0.85)'}}>Send Welcome Email?</span></Checkbox>)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Row gutter={24}>
                                                    <Col sm={24}>
                                                        <h4>Property Details</h4>
                                                    </Col>
                                                    <Col sm={24} md={16}>
                                                        <Form.Item
                                                            label={'Street Address'}
                                                            validateStatus={(form.isFieldTouched('street_address') && form.getFieldError('street_address')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('street_address') && form.getFieldError('street_address')) || ''}
                                                        >
                                                            {getFieldDecorator('street_address', {
                                                                initialValue: (singleAddFormData && singleAddFormData.street_address) ? singleAddFormData.street_address : null,
                                                                rules: [{ required: true, message: 'Please enter a street address', whitespace: true }],
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={8}>
                                                        <Form.Item label={<>Apt or Unit # <span>(Optional)</span></>}>
                                                            {getFieldDecorator('apt_or_unit')(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item
                                                            label={'City'}
                                                            validateStatus={(form.isFieldTouched('city') && form.getFieldError('city')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('city') && form.getFieldError('city')) || ''}
                                                        >
                                                            {getFieldDecorator('city', {
                                                                initialValue: (singleAddFormData && singleAddFormData.city) ? singleAddFormData.city : null,
                                                                rules: [{ required: true, message: 'Please enter a city', whitespace: true }],
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={4}>
                                                        <Form.Item
                                                            label={'State'}
                                                            validateStatus={(form.isFieldTouched('state') && form.getFieldError('state')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('state') && form.getFieldError('state')) || ''}
                                                        >
                                                            {getFieldDecorator('state', {
                                                                initialValue: (singleAddFormData && singleAddFormData.state) ? singleAddFormData.state : null,
                                                                rules: [{ required: true, message: 'Please select a state', whitespace: true }],
                                                            })(
                                                                this.generateStateSelect()
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={8}>
                                                        <Form.Item
                                                            label={<>Zip Code <span>(5 Digits)</span></>}
                                                            validateStatus={(form.isFieldTouched('zip_code') && form.getFieldError('zip_code')) ? 'error' : ''}
                                                            help={(form.isFieldTouched('zip_code') && form.getFieldError('zip_code')) || ''}
                                                        >
                                                            {getFieldDecorator('zip_code', {
                                                                initialValue: (singleAddFormData && singleAddFormData.zip_code) ? singleAddFormData.zip_code : null,
                                                                rules: [{ required: true, message: 'Please enter a zip code', whitespace: true }],
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Row gutter={24}>
                                                    <Col sm={24}>
                                                        <h4>Loan Details</h4>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item label={<>Purchase Price <span>(Optional)</span></>}>
                                                            {getFieldDecorator('purchase_price', {
                                                                initialValue: (singleAddFormData && singleAddFormData.purchase_price) ? singleAddFormData.purchase_price : null,
                                                            })(<InputNumber
                                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item label={<>Purchase Date <span>(Optional)</span></>}>
                                                            {getFieldDecorator('purchase_date', {
                                                                initialValue: (singleAddFormData && singleAddFormData.purchase_date) ? singleAddFormData.purchase_date : null,
                                                            })(<DatePicker allowClear={false} format={'MMMM Do YYYY'}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item label={<>Interest Rate <span>(Optional)</span></>}>
                                                            {getFieldDecorator('loan_interest_rate', {
                                                                initialValue: (singleAddFormData && singleAddFormData.loan_interest_rate) ? singleAddFormData.loan_interest_rate : null,
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <Form.Item label={<>Loan Term <span>(Optional)</span></>}>
                                                            {getFieldDecorator('loan_term_length', {
                                                                initialValue: (singleAddFormData && singleAddFormData.loan_term_length) ? singleAddFormData.loan_term_length : null,
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="card--footer">
                                                <div className="card--footer-left">
                                                    <Button onClick={() => { this.setState({importMethod: null, currentStep: 1, singleAddFormData: null})}}>&lt; Back</Button>
                                                </div>
                                                <div className="card--footer-right">
                                                    <Button
                                                        disabled={this.hasErrors(form.getFieldsError())}
                                                        htmlType="submit"
                                                        type={'primary'}
                                                    >
                                                        Review Homeowner
                                                    </Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </Form>
                                }
                                {
                                    this.state.currentStep === 3 &&
                                    this.state.importMethod === 'bulk' &&
                                    <Card
                                        className={'card-content'}
                                        bordered={false}
                                        title={'Review & Send Invites'}
                                    >
                                        <div className="card--inner">
                                            <p>Review and edit your uploaded homeowners below. When you are satisfied, select "Import Homeowners".</p>
                                            <div className="summary">
                                                <div className={'summary--item'}>
                                                    <div className="summary--item-title green">{ this.validRows().length }</div>
                                                    <div className="summary--item-subtitle">Ready to import</div>
                                                </div>
                                                <div className={'summary--item'}>
                                                    <div className="summary--item-title orange">{ this.duplicateRows().length }</div>
                                                    <div className="summary--item-subtitle">Duplicate Emails</div>
                                                </div>
                                                <div className={'summary--item'}>
                                                    <div className="summary--item-title red">{ this.errorRows().length }</div>
                                                    <div className="summary--item-subtitle">Errors</div>
                                                </div>
                                            </div>
                                            {
                                                this.generateValidRowsPreview()
                                            }
                                            <div className="card--checkbox">
                                                <Checkbox
                                                    defaultChecked={this.state.sendWelcomeEmail}
                                                    checked={this.state.sendWelcomeEmail}
                                                    onChange={(e) => this.setState({sendWelcomeEmail: e.target.checked})}
                                                >
                                                    <span style={{color: 'rgba(0, 0, 0, 0.85)'}}>Send Welcome Email?</span>
                                                </Checkbox>
                                            </div>
                                        </div>
                                        <div className="card--footer">
                                            <div className="card--footer-left">
                                                <Button onClick={() => { this.goToStep(2)}}>&lt; Back</Button>
                                            </div>
                                            <div className="card--footer-right">
                                                <Button type={'primary'} onClick={() => { this.setState({ confirmSendModal: true}) }}>Import Homeowners</Button>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    this.state.currentStep === 3 &&
                                    this.state.importMethod === 'single' &&
                                    <Card
                                        className={'card-content'}
                                        bordered={false}
                                        title={'Review & Import Homeowner'}
                                    >
                                        <div className="card--inner">
                                            <p>Please verify that the information provided below is accurate. If everything looks good, click "Import Homeowner" to finish the import process.</p>
                                            <div className="review">
                                                <Row gutter={24}>
                                                    <Col sm={24}>
                                                        <h4>Personal Details</h4>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            First Name
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.firstname }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Last Name
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.lastname }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Email
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.email }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Phone Number <span>(Optional)</span>
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.mobile ? DigsHelper.formatPhoneNumber(singleAddFormData.mobile) : '-'}
                                                        </div>
                                                    </Col>
                                                    <Col sm={24}>
                                                        <div className="review-label">
                                                            Send Welcome Email?
                                                        </div>
                                                        <div className="review-value" style={{marginBottom: 0}}>
                                                            { singleAddFormData.send_welcome_email ? 'Yes' : 'No'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Row gutter={24}>
                                                    <Col sm={24}>
                                                        <h4>Property Details</h4>
                                                    </Col>
                                                    <Col sm={24} md={16}>
                                                        <div className="review-label">
                                                            Street Address
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.street_address }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={8}>
                                                        <div className="review-label">
                                                            Apt or Unit # <span>(Optional)</span>
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.apt_or_unit ? singleAddFormData.apt_or_unit : '-'}
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            City
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.city }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={4}>
                                                        <div className="review-label">
                                                            State
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.state }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={8}>
                                                        <div className="review-label">
                                                            Zip Code
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.zip_code }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Row gutter={24}>
                                                    <Col sm={24}>
                                                        <h4>Loan Details</h4>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Purchase Price <span>(Optional)</span>
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.purchase_price ? DigsHelper.formatMoney( singleAddFormData.purchase_price, 0) : '-' }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Purchase Date <span>(Optional)</span>
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.purchase_date ? moment(singleAddFormData.purchase_date).format('YYYY-MM-DD') : '-' }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Interest Rate <span>(Optional)</span>
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.loan_interest_rate ? singleAddFormData.loan_interest_rate : '-' }
                                                        </div>
                                                    </Col>
                                                    <Col sm={24} md={12}>
                                                        <div className="review-label">
                                                            Loan Term <span>(Optional)</span>
                                                        </div>
                                                        <div className="review-value">
                                                            { singleAddFormData.loan_term_length ? singleAddFormData.loan_term_length : '-' }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="card--footer">
                                            <div className="card--footer-left">
                                                <Button disabled={importingSingle} onClick={() => { this.goToStep(2)}}>&lt; Back</Button>
                                            </div>
                                            <div className="card--footer-right">
                                                <Button type={'primary'} loading={importingSingle} disabled={importingSingle} onClick={this.importSingleContact}>Import Homeowner</Button>
                                            </div>
                                        </div>
                                    </Card>
                                }
                            </>
                        }
                    </Col>
                    <Col xs={24} xl={8}>
                        <
                            Card
                            title={'Need help?'}
                            bordered={false}
                        >
                            <div className="card--inner">
                                <p>
                                    If you need help importing your contacts, send your spreadsheet to us at <a href={'mailto:support@digs.co?subject=Import help'}>support@digs.co</a>.
                                </p>
                                <p><b>You can also download our CSV template below:</b></p>
                                <Button href="/downloads/Homeowners - Import File.csv" icon="download" type="primary" style={{width: '100%'}}>Download Template</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {
                    this.state.confirmSendModal &&
                    <Modal
                        title={this.generateModalContent().title}
                        visible={this.state.confirmSendModal}
                        onOk={ () => { this.importContacts() }}
                        onCancel={ () => { this.setState({ confirmSendModal: false}) } }
                        footer={this.generateModalContent().footer}
                    >
                        { this.generateModalContent().text }
                    </Modal>
                }
                <CSVReader
                    key={this.state.currentStep}
                    onFileLoaded={this.handleReadCSV}
                    inputRef={this.fileInput}
                    style={{display: 'none'}}
                    configOptions={{header: true /* Header row support */ }}
                    onError={this.handleOnError}
                />
            </div>
        );
    }

}


const mapStateToProps = ({ user }) => ({ user: user.user, action: user.action });
const mapDispatchToProps = {
    importSubscribers,
    importSingleSaver,
    importSingleHomeowner
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(CSV)));
