import React, {Component} from 'react';
import queryString from 'query-string';
import {Button, Card, Col, Divider, Form, Icon, Input, Row, Table, Select, Rate, message, Spin, Tooltip} from 'antd';
import {connect} from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import history from '../../helpers/history';
import DigsHelper from '../../helpers/DigsHelper';

import * as dashboardActions from '../../actions/dashboard';
import { FETCH_DASHBOARD_PENDING } from "../../actions/types";
import {Link, withRouter} from "react-router-dom";
import {EditableCell, EditableFormRow} from "../../components/dashboard/table/EditableRow";

const FormItem = Form.Item;
const { Meta } = Card;

class Savers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      /* Tile Data */
      saversCount: 0,
      purchasingCount: 0,
      /* Table Data */
      tableData: {},
      /* Filter tiles */
      filter: 'savers',
      filter_value: '',
      /* Search & Filters */
      search: '',
      page: 1,
      sort_by: 'created_at',
      sort_order: 'descend',
      /* Table defaults */
      page_size: 20,
    }

  }

  componentDidMount() {
    this.processUrl()
        .then(() => {
          this.fetchItems();
        });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if(prevProps.location.search !== this.props.location.search) {
      this.processUrl()
          .then(() => {
            this.fetchItems();
          });
    }

  }

  processUrl = async () => {
    const values = queryString.parse(this.props.location.search);

    return new Promise((resolve, reject) => {

      const validatedValues = {
        filter: 'savers',
        filter_value: '',
        search: '',
        page: 1,
        sort_by: 'created_at',
        sort_order: 'descend',
        ...values,
      };

      if(values.filter && values.filter_value) {

        if(values.filter === 'saver') {
          this.props.form.setFieldsValue({savers_select: values.filter_value})
        }

        if(values.filter === 'purchasing') {
          this.props.form.setFieldsValue({purchasing_select: values.filter_value})
        }

      }

      this.setState({ ...validatedValues }, () => { resolve() });

    });

  };

  fetchItems = () => {

    const { search, filter, filter_value, page, sort_by, sort_order } = this.state;
    const savers_filter_value = this.props.form.getFieldValue('savers_select');
    const purchasing_filter_value = this.props.form.getFieldValue('purchasing_select');

    const params = {
      search,
      page,
      filter,
      filter_value,
      sort_by,
      sort_order,
    };

    this.props.fetchSavers({ ...params })
        .then((data) => {
          this.setState({ tableData: { ...data }});
        });

    this.props.fetchSaversCount(savers_filter_value, purchasing_filter_value)
        .then(({savers_count, purchasing_count }) => {
          this.setState({ saversCount: savers_count, purchasingCount: purchasing_count});
        });

  };

  /* Filter handling */
  updateFilters = (data) => {

    const currentQueryValues = queryString.parse(this.props.location.search);
    const searchParameters = { ...currentQueryValues, ...data};

    for (let propName in searchParameters) {
      if (searchParameters[propName] === null || searchParameters[propName] === undefined) {
        delete searchParameters[propName];
      }
    }

    this.props.history.replace({
      search: "?" + new URLSearchParams(searchParameters).toString()
    });

  };

  handleStandardTableChange = (pagination, filtersArg, sorter) => {

    console.log(pagination);

    this.updateFilters({
      page: pagination.current,
      sort_by: sorter.field,
      sort_order: sorter.order,
    });

  };

  handleSearch = e => {
    e.preventDefault();

    this.props.form.validateFields((err, fieldsValue) => {

      if (err) return;

      this.updateFilters({ search: fieldsValue['search'] });

    });
  };

  onFilterClick = (e, filterName) => {

    e.persist();

    if(
        !e.target.classList.contains('ant-select-selection-selected-value') &&
        !e.target.classList.contains('ant-select-dropdown-menu-item')
    ) {

      if(filterName === 'savers') {

        this.updateFilters({
          filter: filterName,
          filter_value: this.props.form.getFieldValue('savers_select'),
          sort_by: 'created_at',
          sort_order: 'descend'
        });

      }

      if(filterName === 'purchasing') {

        this.updateFilters({
          filter: filterName,
          filter_value: this.props.form.getFieldValue('purchasing_select'),
          sort_by: 'purchase_date',
          sort_order: 'ascend'
        });

      }
    }

  };

  onDropdownChange = (filter, filter_value) => {
    this.updateFilters({filter, filter_value});
  };

  /* Render Table */

  renderColumns() {

    const { user } = this.props;

    let columns = [
      {
        title: 'Name',
        dataIndex: 'firstname',
        render: (val, record) => {
          return (
              <div className={'avatar--container'}>
                <div className={'avatar'}>
                  <span>
                      { record.firstname ? record.firstname.charAt(0) : '*' }
                    { record.lastname ? record.lastname.charAt(0) : '*' }
                  </span>
                  {
                    record.through_partnership &&
                    <div className={'avatar--partnership'}>
                      <Icon type="link" />
                    </div>
                  }
                </div>
                <div>
                  {
                    record.through_partnership &&
                    <span>{record.firstname ? record.firstname : 'null'} {record.lastname ? record.lastname : 'null'}</span>
                  }
                  {
                    !record.through_partnership &&
                    <Link to={`/view/user/${record.owner_id}`}>{record.firstname ? record.firstname : 'null'} {record.lastname ? record.lastname : 'null'}</Link>
                  }
                </div>
              </div>
          )
        },
      },
      {
        title: 'ID',
        dataIndex: 'custom_id',
        editable: true,
        render: (val, record) => record.through_partnership ? '-' : (val ? val : '-')
      },
      {
        title:'Home Use',
        sorter: true,
        dataIndex: 'property_use',
        render: (val) => user.active_subscription ? (val ? val : '-') : <i className="icon-lock" />,
        sortOrder: (this.state.sort_by === 'property_use') && this.state.sort_order,
      },
      {
        title:'Purchase Date',
        sorter: true,
        dataIndex: 'purchase_date',
        render: (val) => user.active_subscription ? (val ? moment(val).format('MMM DD, YYYY') : '-') : <i className="icon-lock" />,
        sortOrder: (this.state.sort_by === 'purchase_date') && this.state.sort_order,
      },
      {
        title:'Budget',
        sorter: true,
        dataIndex: 'purchase_price',
        render: (val) => user.active_subscription ? (val ? DigsHelper.formatMoney(val, 0) : '-') : <i className="icon-lock" />,
        sortOrder: (this.state.sort_by === 'purchase_price') && this.state.sort_order,
      },
      {
        title:'Linked Financials',
        sorter: true,
        dataIndex: 'accounts_count',
        render: (val) => user.active_subscription ? (val > 0 ? 'Yes' : 'No') : <i className="icon-lock" />,
        sortOrder: (this.state.sort_by === 'accounts_count') && this.state.sort_order,
      },
      {
        title:'Rewards Earned',
        sorter: false,
        dataIndex: 'rewards_earned',
        render: (val) => user.active_subscription ? (val > 0 ? 'Yes' : 'No') : <i className="icon-lock" />,
      },
      {
        title: 'Owned By',
        dataIndex: 'provider_id',
        render: (val, record) => {
          if(user.id === record.provider_id) {
            return 'Me';
          } else {
            return `${record.provider_firstname} ${record.provider_lastname}`
          }
        },
      },
      {
        title:'Added On',
        sorter: true,
        dataIndex: 'created_at',
        render: val => moment(val).format('MMM DD, YYYY'),
        sortOrder: (this.state.sort_by === 'created_at') && this.state.sort_order,
        defaultSortOrder: 'descend',
      }
    ];

    return columns;

  }

  renderEmpty() {

    const { dashboard } = this.props;
    const action = dashboard.action;

    const { filter, filter_value } = this.state;

    let emptyText = '';

    if(action === FETCH_DASHBOARD_PENDING) {

      return <div className={'table-empty-state'}></div>

    } else {

      switch (filter) {
        case 'savers':
          switch (filter_value) {
            case 'today':
              emptyText = 'No new savers today';
              break;
            case 'last-7':
              emptyText = 'No new savers in the last 7 days';
              break;
            case 'last-30':
              emptyText = 'No new savers in the last 30 days';
              break;
            default:
              emptyText = 'No savers are using your app yet';
              break;
          }
          break;
        case 'purchasing':
          switch (filter_value) {
            case 'today':
              emptyText = 'No savers purchasing today';
              break;
            case 'next-month':
              emptyText = 'No savers purchasing in the next month';
              break;
            case 'next-3-months':
              emptyText = 'No savers purchasing in the next 3 months';
              break;
            case 'next-6-months':
              emptyText = 'No savers purchasing in the next 6 months';
              break;
            case 'next-year':
              emptyText = 'No savers purchasing in the next year';
              break;
            default:
              emptyText = 'No savers are using your app yet';
              break;
          }
          break;
        default:
          emptyText = 'No savers are using your app yet';
          break;
      }

      return (
          <div className={'table-empty-state'}>
            <p>{ emptyText }</p>
          </div>
      )

    }



  }

  /* Save Custom Id */
  handleSaveCustomId = (data) => {

    const tableData = this.state.tableData;
    const updatedData = tableData.data.map((item) => {
      if(item.id === data.id) {
        return {
          ...item,
          custom_id: data.custom_id
        }
      }
      return item;
    });

    this.setState({ tableData: {
        ...tableData,
        data:  updatedData
      }});

    this.props.updatePropertyById(data.id, { custom_id: data.custom_id})
        .then(({ property }) => {
          message.success('Custom ID updated successfully.');
        })
        .catch(() => {
          message.error('Whoops! An error has occurred.');
          const tableData = this.state.tableData;
          const updatedData = tableData.data.map((item) => {
            if(item.id === data.id) {
              return {
                ...item,
                custom_id: data.original.custom_id
              }
            }
            return item;
          });

          this.setState({ tableData: {
              ...tableData,
              data:  updatedData
            }});
        });
  };

  /* Download CSV */

  downloadCsv = () => {
    this.props.downloadSaversExport()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'savers.xlsx');
          document.body.appendChild(link);
          link.click();
        });
  };

  render() {

    const { user, dashboard, form: { getFieldDecorator} } = this.props;
    const { tableData, filter, search, page, saversCount, purchasingCount, page_size } = this.state;

    const action = dashboard.action;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.renderColumns().map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: record.through_partnership ? false : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSaveCustomId,
        }),
      };
    });

    const showHeader = !!(tableData.meta && tableData.meta.total && tableData.meta.total > 0);

    return (
        <div className="page page-dashboard">
          <Row>
            <Col xs={24} xl={16} className={'column-max-width'}>
              <div className={'title-module'}>
                <div className="title-module--row">
                  <h1>Savers</h1>
                  <Tooltip overlayClassName={'dashboard-tooltip'} placement="right" title={<span><b><i>Savers</i></b> is a list of unique saver properties. If you see someone listed more than once, that means they have created multiple saver properties.</span>}>
                    <span className={'info'}><i className={'icon-info'} /></span>
                  </Tooltip>
                </div>
              </div>
            </Col>
          </Row>
          {
            dashboard &&
            <Row gutter={20} style={{marginBottom: -12}}>
              <Col xs={24} md={12}>
                <Card
                    className={filter === 'savers' ? 'active' : ''}
                    bordered={false}
                    onClick={(e) => { this.onFilterClick(e, 'savers')}}
                >
                  <span className={'checkmark'}>
                      <i className={'icon-checkmark'}></i>
                  </span>
                  <Meta
                      title={<>
                        Savers&nbsp;
                        {
                          getFieldDecorator('savers_select', {
                            initialValue: 'total',
                            onChange: (val) => this.onDropdownChange('savers', val)
                          })(
                              <Select
                                  dropdownMatchSelectWidth={false}
                                  dropdownClassName={'dashboard-dropdown'}
                              >
                                <Select.Option value="total"><i className="icon-checkmark" /> Total</Select.Option>
                                <Select.Option value="today"><i className="icon-checkmark" /> Today</Select.Option>
                                <Select.Option value="last-7"><i className="icon-checkmark" /> Last 7 Days</Select.Option>
                                <Select.Option value="last-30"><i className="icon-checkmark" /> Last 30 Days</Select.Option>
                              </Select>
                          )
                        }
                      </>}
                      description={numeral(saversCount).format('0,0')}
                  />
                </Card>
              </Col>
              <Col xs={24} md={12}>
                <Card
                    className={filter === 'purchasing' ? 'active' : ''}
                    bordered={false}
                    onClick={(e) => { this.onFilterClick(e, 'purchasing')}}
                >
                  <span className={'checkmark'}>
                      <i className={'icon-checkmark'}></i>
                  </span>
                  <Meta
                      title={<>
                        Purchasing&nbsp;
                        {
                          getFieldDecorator('purchasing_select', {
                            initialValue: 'next-6-months',
                            onChange: (val) => this.onDropdownChange('purchasing', val)
                          })(
                              <Select
                                  dropdownMatchSelectWidth={false}
                                  dropdownClassName={'dashboard-dropdown'}
                              >
                                <Select.Option value="today"><i className="icon-checkmark" /> Today</Select.Option>
                                <Select.Option value="next-month"><i className="icon-checkmark" /> Next Month</Select.Option>
                                <Select.Option value="next-3-months"><i className="icon-checkmark" /> Next 3 Months</Select.Option>
                                <Select.Option value="next-6-months"><i className="icon-checkmark" /> Next 6 Months</Select.Option>
                                <Select.Option value="next-year"><i className="icon-checkmark" /> Next Year</Select.Option>
                                <Select.Option value="all"><i className="icon-checkmark" /> All Time</Select.Option>
                              </Select>
                          )
                        }
                      </>}
                      description={numeral(purchasingCount).format('0,0')}
                  />
                </Card>
              </Col>
            </Row>
          }
          <Divider />
          <div className={'digs-table'}>
            <div className='table-form'>
              <Form onSubmit={this.handleSearch} layout="inline">
                <FormItem>
                  {getFieldDecorator('search', {
                    initialValue: search,
                  })(
                      <Input
                          className={'search'}
                          placeholder="Search Savers..."
                          size="large"
                          prefix={<Icon type="search" style={{ color: '#222222' }} />}
                          suffix={<Button htmlType="submit"><Icon type="arrow-right" style={{ color: '#5285FF' }} /></Button>}
                      />
                  )}
                </FormItem>
              </Form>
              <Button icon={user.active_subscription ? "download" : "lock"} type="primary" onClick={() => { this.downloadCsv() }} disabled={!tableData.meta || tableData.meta.total < 1 || !user.active_subscription} ghost>Export Savers</Button>
            </div>
            <Table
                showHeader={showHeader}
                loading={{
                  spinning: action === FETCH_DASHBOARD_PENDING,
                  indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                }}
                dataSource={tableData.data}
                components={components}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={user.active_subscription ? {
                  defaultCurrent: 1,
                  current: (tableData.meta && tableData.meta.current_page) ? tableData.meta.current_page : 1,
                  pageSize: page_size,
                  total: (tableData.meta && tableData.meta.total) ? tableData.meta.total : 0,
                  showSizeChanger: false,
                  showQuickJumper: false,
                } : {
                  defaultCurrent: 1,
                  current: (tableData.meta && tableData.meta.current_page) ? tableData.meta.current_page : 1,
                  pageSize: page_size,
                  total: (tableData.meta && tableData.meta.total) ? tableData.meta.total : 0,
                  showSizeChanger: false,
                  showQuickJumper: false,
                }}
                scroll={{x: true}}
                onChange={this.handleStandardTableChange}
                locale={{ emptyText: this.renderEmpty() }}
            />
          </div>
        </div>
    );
  }

}

const mapStateToProps = ({ dashboard, user }) => ({ dashboard, user: user.user });
const mapDispatchToProps = {
  ...dashboardActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(Savers)));
