import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Button, Form, Icon, Input, message} from "antd";
import {ACCEPT_PARTNERSHIP_PENDING, DELETE_PARTNERSHIP_PENDING, SEND_PARTNERSHIP_PENDING} from "../../../actions/types";
import moment from "moment";
import * as partnershipsActions from "../../../actions/partnerships";

class LinkWithLender extends Component {

    constructor(props) {
        super(props);

        this.state = {
            success: false,
        }

    }

    handleOnSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.sendPartnership({
                    invites: [
                        {
                            sender_id: this.props.user.id,
                            receiver_email: values.email,
                        }
                    ]
                })
                    .then(() => {
                        message.success('Invite successfully sent.');
                        this.setState({success: true})
                    });
            }
        });
    }
    onDeleteInvitation = (e, id) => {
        e.preventDefault();
        this.setState({
            deleteId: id
        });
        this.props.deletePartnership(id)
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                this.setState({
                    deleteId: null
                });
            });
    };

    onAcceptInvitation = (e, id) => {
        e.preventDefault();
        this.setState({
            acceptId: id
        });
        this.props.acceptPartnership(id)
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                this.setState({
                    acceptId: null
                });
            });
    };

    generatePendingRequests = () => {

        const { partnerships, user, action } = this.props;
        const type = (action && action.type) ? action.type : null;

        const request = partnerships.filter(invite => {

            return invite.status === 'pending' && (invite.receiver_id === user.id || invite.receiver_email === user.email)

        });

        return (
            <ul className={'item-list'}>
                {
                    request.map((invite) => {
                        return (
                            <li>
                                <span className={'email'}><Icon type="clock-circle"/>{invite.sender_details.firstname} {invite.sender_details.lastname}&nbsp;<span>from</span>&nbsp;{ invite.sender_details.company_name }</span>
                                <div>
                                    <a href={'#'} onClick={(e) => this.onDeleteInvitation(e, invite.id)}>Ignore</a>
                                    <Button loading={type === ACCEPT_PARTNERSHIP_PENDING && this.state.acceptId === invite.id} onClick={(e) => this.onAcceptInvitation(e, invite.id)}>Accept</Button>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        )

    };

    generateSentInvites = () => {
        const { user, partnerships } = this.props;

        const sent = partnerships.filter(invite => {
            return invite.status === 'pending' && invite.sender_id === user.id
        });

        return (
            <ul className={'item-list'}>
                {
                    sent.map((invite) => {
                        return (
                            <li>
                                <span className={'email'}><Icon type="clock-circle"/> { invite.receiver_email }</span>
                                <span className={'date'}>Invite sent { moment(invite.created_at).format('M/D') }</span>
                                <div>
                                    <a href={'#'} onClick={(e) => this.onDeleteInvitation(e, invite.id)}>Delete</a>
                                    <Button>Resend</Button>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        )

    };

    generateLinkedAccounts = () => {

        const { partnerships, user, action } = this.props;
        const type = (action && action.type) ? action.type : null;

        const linked = partnerships.filter(invite => invite.status === 'linked');

        return (
            <ul className={'item-list'}>
                {
                    linked.map((invite) => {

                        let partner;

                        if (invite.receiver_id === user.id ) {
                            partner = invite.sender_details;
                        } else {
                            partner = invite.receiver_details;
                        }

                        return (
                            <li>
                                <span className={'email'}><Icon type="check" />{ partner.firstname } {partner.lastname}</span>
                                <span className={'date'}>Accepted on { moment(invite.updated_at).format('M/D') }</span>
                                <Button loading={type === DELETE_PARTNERSHIP_PENDING  && this.state.deleteId === invite.id} onClick={e => { this.onDeleteInvitation(e, invite.id)}}>Unlink</Button>
                            </li>
                        )
                    })
                }
            </ul>
        )

    };

    generateAlternativeView = () => {

        const { user, partnerships  } = this.props;

        const request = partnerships.length > 0 ? partnerships.filter(invite => {
            return invite.status === 'pending' && (invite.receiver_id === user.id || invite.receiver_email === user.email)
        }) : [];
        const sent = partnerships.length > 0 ? partnerships.filter(invite => {
            return invite.status === 'pending' && invite.sender_id === user.id
        }) : [];
        const linked = partnerships.length > 0 ? partnerships.filter(invite => invite.status === 'linked') : [];

        if(!this.success && linked.length > 0) {
            return (
                <div>
                    <div className="title-block max-width">
                        <p><b>It looks like you already have a partnership in place:</b></p>
                    </div>
                    <div className="linked-module">
                        {
                            this.generateLinkedAccounts()
                        }
                    </div>
                    <div className="max-width">
                        <Button htmlType={'submit'} onClick={(e) => { e.preventDefault(); this.props.history.push('/onboarding/prospects') }} className={'button primary'}>Continue</Button>
                    </div>
                </div>
            )
        }

        if(!this.success && request.length > 0) {
            return (
                <div>
                    <div className="title-block max-width">
                        <p><b>It looks like you already have an invite from a lender pending:</b></p>
                    </div>
                    <div className={'request-module'}>
                        {
                            this.generatePendingRequests()
                        }
                    </div>
                    <div className="max-width">
                        <Button htmlType={'submit'} onClick={(e) => { e.preventDefault(); this.props.history.push('/onboarding/prospects') }} className={'button primary'}>Continue</Button>
                    </div>
                </div>
            )
        }

        if(!this.success && sent.length > 0) {
            return (
                <div>
                    <div className="title-block max-width">
                        <p>It looks like you've already sent an invite.</p>
                    </div>
                    <div className={'invite-module'}>
                        {
                            this.generateSentInvites()
                        }
                    </div>
                    <div className="max-width">
                        <Button htmlType={'submit'} onClick={(e) => { e.preventDefault(); this.props.history.push('/onboarding/prospects') }} className={'button primary'}>Continue</Button>
                    </div>
                </div>
            )
        }

        return '';

    };

    render() {

        const { partnerships, user, form } = this.props;
        const { getFieldDecorator } = form;
        const { action } = this.props;
        const type = (action && action.type) ? action.type : null;

        const request = partnerships.length > 0 ? partnerships.filter(invite => {
            return invite.status === 'pending' && (invite.receiver_id === user.id || invite.receiver_email === user.email)
        }) : [];
        const sent = partnerships.length > 0 ? partnerships.filter(invite => {
            return invite.status === 'pending' && invite.sender_id === user.id
        }) : [];
        const linked = partnerships.length > 0 ? partnerships.filter(invite => invite.status === 'linked') : [];


        return (
            <div className={'page-onboarding page-onboarding-link fadeIn'}>
                {
                    !this.state.success &&
                    this.generateAlternativeView()
                }
                {
                    !this.state.success && request.length < 1 && sent.length < 1 && linked.length < 1 &&
                    <div className={' max-width'}>
                        <div className="title-block">
                            <p>Invite your preferred lender to easily share prospects and help your clients get pre-approved.</p>
                        </div>
                        <Form onSubmit={(e) => { this.handleOnSubmit(e) }} colon={false}>
                            <Form.Item>
                                {
                                    getFieldDecorator('email', {
                                        rules: [{ required: true, type: 'email', message: 'Please enter a valid email!' }],
                                    })(
                                        <Input placeholder={'Lender Email'}/>
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button loading={type === SEND_PARTNERSHIP_PENDING} htmlType={'submit'} type={'primary'} className={'button primary'}>Link with lender</Button>
                            </Form.Item>
                        </Form>
                        <Link href="#" className={'skip'} to={'/onboarding/prospects'}>Skip this for now</Link>
                    </div>
                }
                {
                    this.state.success &&
                    <div className={'max-width'}>
                        <div className={'invite-module success'}>
                            <img src='/images/icons/loading-success.svg' alt={""}/>
                            <p style={{ marginBottom: '35px'}}><b>Invite sent successfully.</b></p>
                            <Button htmlType={'submit'} onClick={(e) => { e.preventDefault(); this.props.history.push('/onboarding/prospects') }} className={'button primary'}>Continue</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = ({ user, partnerships }) => ({ user: user.user, partnerships: partnerships.list, action: partnerships.action, errors: partnerships.errors });
const mapDispatchToProps = {
    ...partnershipsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(LinkWithLender)));
