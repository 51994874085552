import React, {Component} from 'react';
import {Card, Col, Row} from "antd";
import RadialProgress from "../ui/RadialProgress";
import moment from 'moment';
import DigsHelper from '../../helpers/DigsHelper';
import GoalHelper from '../../helpers/GoalHelper';

class SaverPropertyCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMore: false,
        }
    }

    onShowMore(e) {
        const { showMore } = this.state;

        e.preventDefault();
        this.setState({ showMore: !showMore })
    };

    render() {

        const { showMore } = this.state;
        const { property, milestones, locked } = this.props;

        const propertyDetails = {
            name: property.name ? property.name : 'My First Home',
            purchase_date: locked ? <i className="icon-lock" /> : (property.purchase_date ? moment(property.purchase_date).format('MMM DD, YYYY') : '-'),
            purchase_price:  locked ? <i className="icon-lock" /> : (property.purchase_price ? DigsHelper.formatMoney(property.purchase_price, 0) : '-'),
            savings_goal: locked ? <i className="icon-lock" /> : (property.purchase_price ? DigsHelper.formatMoney(GoalHelper.getMilestoneAmount(milestones, property), 0) :'-'),
            home_use: locked ? <i className="icon-lock" /> : (property.property_use ? property.property_use : '-'),
            home_type: locked ? <i className="icon-lock" /> : (property.property_type ? property.property_type : '-'),
            zip_code: locked ? <i className="icon-lock" /> : (property.zip_code ? property.zip_code : '-'),
            savings_progress: locked ? <i className="icon-lock" /> : (Math.floor((property.balance) / GoalHelper.getMilestoneAmount(milestones, property) * 100)),
        };

        return (
            <Card
                className={'card dark'}
                bordered={false}
            >
                <div className="card--inner">
                    <div className={'card--left'}>
                        <h2>{ propertyDetails.name }</h2>
                        <ul className={'card-details'}>
                            <li className="card-details--row">
                                <div className="card-details--left">
                                    Property Type
                                </div>
                                <div className="card-details--right green">
                                    Saver
                                </div>
                            </li>
                            <li className="card-details--row">
                                <div className="card-details--left">
                                    Est. Purchase Date
                                </div>
                                <div className="card-details--right">
                                    { propertyDetails.purchase_date }
                                </div>
                            </li>
                            <li className="card-details--row">
                                <div className="card-details--left">
                                    Est. Purchase Price
                                </div>
                                <div className="card-details--right">
                                    { propertyDetails.purchase_price }
                                </div>
                            </li>
                            <li className="card-details--row">
                                <div className="card-details--left">
                                    Savings Goal
                                </div>
                                <div className="card-details--right">
                                    { propertyDetails.savings_goal }
                                </div>
                            </li>
                            <li className="card-details--row">
                                <div className="card-details--left">
                                    Zip Code
                                </div>
                                <div className="card-details--right">
                                    { propertyDetails.zip_code }
                                </div>
                            </li>
                        </ul>
                        {
                            showMore &&
                            <ul className={'card-details'}>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Home Use
                                    </div>
                                    <div className="card-details--right">
                                        { propertyDetails.home_use }
                                    </div>
                                </li>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Home Type
                                    </div>
                                    <div className="card-details--right">
                                        { propertyDetails.home_type }
                                    </div>
                                </li>
                            </ul>
                        }
                        <a href={"#"} className="card-details--action" onClick={(e) => { this.onShowMore(e) }}>{ showMore ? 'See less' : 'See more'}</a>
                    </div>
                    <div className={'card--right'}>
                        <h2>Savings Progress</h2>
                        <div className={'card--progress'}>
                            <div className="card--progress-overlay">
                                <div className="card--progress-percent">
                                    {propertyDetails.savings_progress}
                                    {
                                        !locked &&
                                        <span>%</span>
                                    }
                                </div>
                                {
                                    !locked &&
                                    <div className="card--progress-label">To Goal</div>
                                }
                            </div>
                            <RadialProgress progress={locked ? 0 : propertyDetails.savings_progress} />
                        </div>
                        <p><b>Savings Progress</b> is defined as the percent someone is to saving for their down payment and closing costs. Any rewards that you give are included in this number.</p>
                    </div>
                </div>
            </Card>
        );

    }

}

export default SaverPropertyCard;
