import React, {Component} from 'react';
import {Button, Card, Col, Divider, Form, Icon, message, Modal, Row, Select, Skeleton, Spin} from 'antd'
import {connect} from 'react-redux';
import {ReactSortable} from 'react-sortablejs'
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import {ROUTING_UPDATE_PENDING} from "../../actions/types";

import * as subaccountsActions from "../../actions/subaccounts";
import * as userActions from "../../actions/user";
import * as routingActions from "../../actions/routing";
import UnlockFeature from "../../components/auth/UnlockFeature";
import LeadRoutingItem from "../../components/team/LeadRoutingItem";

class LeadRouting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            routed: [],
            historical: [],
            loading: false,
            leadCount: 0,
            retroModal: false,
            modalLoading: false,
            distributeLoading: false,
            breakout: [],
            /* Subaccounts */
            subaccounts: {}
        };

    }

    componentDidMount() {

        const { fetchLeadRouting } = this.props;

        const promises = [fetchLeadRouting(), this.fetchSubaccounts()];

        this.setState({
            loading: true,
        });

        Promise.all(promises)
            .then(() => {
                this.setItems();
                this.setState({
                    loading: false,
                });
            });

    }

    fetchSubaccounts = async () => {

        return new Promise((resolve, reject) => {

            this.props.fetchSubaccounts({})
                .then((data) => {
                    this.setState({ subaccounts: { ...data }}, () => {
                        resolve();
                    });
                })
                .catch(() => {
                    reject();
                });

        });

   };

    setItems() {

        const { routing } = this.props;

        this.setState({
            routed: routing.config ? routing.config : [],
            historical: routing.config ? routing.config : [],
            leadCount: routing.count
        })
    }

    /* CRUD */

    saveConfiguration() {

        const { user } = this.props;
        const { routed } = this.state;

        if(routed.length > 0 && (routed.length === 1 ? (routed[0].id !== user.id) : true)) {

            console.log(routed);

            this.props.saveLeadRouting({
                config: this.cleanRouteConfig(),
            })
            .then(() => {
                message.success('Routing configuration saved successfully!');
                this.setItems();
            });

        } else {

            console.log('deleting');

            this.deleteConfiguration();

        }

    };

    deleteConfiguration() {

        this.props.deleteLeadRouting()
            .then(() => {
                message.success('Routing configuration saved successfully!');
                this.setItems();
            });
    };

    cleanRouteConfig() {

        return this.state.routed.length > 0 ? this.state.routed.map((r) => {

            const item = {
                id: r.id,
            };

            if (r.last_routed) {
                item['last_routed'] = r.last_routed;
            }

            return item;

        }) : [];

    };

    /* Add / Remove Team Member */

    addTeamMember(e) {
        e.preventDefault();
        const { user } = this.props;
        const value = this.props.form.getFieldValue('team_member');

        if(value) {
            const providers = this.state.routed.slice();

            if(providers.length < 1) {
                providers.push({ id: user.id});
            }

            providers.push({ id: value });

            this.setState({ routed: providers});
            this.props.form.resetFields()
        }

    }

    removeTeamMember(id) {
        const providers = this.state.routed.slice();
        providers.splice(id, 1);
        this.setState({ routed: providers});
    }

    generateProviderList() {

        const { user, form } = this.props;
        const { subaccounts } = this.state;
        const { getFieldDecorator } = form;

        let providers = [];

        if(subaccounts.data) {
            providers = subaccounts.data
                .filter((provider) => {
                    return this.state.routed.findIndex(routed => routed.id === provider.id) < 0
                })
                .map((provider, i) => {
                return (
                    <Select.Option className="provider-select--option" key={i} value={provider.id}>
                        <div className="provider-select--item">
                            {
                                provider.avatar && provider.avatar.file &&
                                <div className="provider-select--image">
                                    <img src={provider.avatar.file} alt={""}/>
                                </div>
                            }

                            {
                                (!provider.avatar || !provider.avatar.file) &&
                                <div className={'provider-select--avatar'}>
                                <span>
                                    {provider.firstname.charAt(0)}
                                    {provider.lastname.charAt(0)}
                                </span>
                                </div>
                            }

                            <div className="provider-select--details">
                                {provider.firstname} {provider.lastname}
                            </div>
                        </div>
                    </Select.Option>
                );
            });
        }

        if(this.state.routed.length > 0 && this.state.routed.findIndex(provider => provider.id === user.id) < 0) {
            providers.push(
                <Select.Option className="provider-select--option" key={providers.length} value={user.id}>
                    <div className="provider-select--item">
                        {
                            user.avatar && user.avatar.file &&
                            <div className="provider-select--image">
                                <img src={user.avatar.file} alt={""}/>
                            </div>
                        }

                        {
                            (!user.avatar || !user.avatar.file) &&
                            <div className={'provider-select--avatar'}>
                                <span>
                                    {user.firstname.charAt(0)}
                                    {user.lastname.charAt(0)}
                                </span>
                            </div>
                        }

                        <div className="provider-select--details">
                            {user.firstname} {user.lastname} (You)
                        </div>
                    </div>
                </Select.Option>
            )
        }

        return(
            <div className={'provider-select'} key={this.state.routed.length}>
                {
                    getFieldDecorator('team_member')(
                        <Select
                            placeholder="Select an team member..."
                            style={{width: '100%'}}
                            loading={this.state.loading}
                        >
                            { providers }
                        </Select>
                    )
                }
            </div>
        )

    }

    /* */

    hasChanged() {
        return !isEqual(this.cleanRouteConfig(), this.state.historical);
    }

    notJustParent() {
        const { user } = this.props;
        const filtered = this.state.routed.filter((r) => r.id !== user.id);
        return filtered.length > 0;
    }

    routeLeadsRetroactively(e) {

        if(!this.state.retroModal) {

            this.setState({
                retroModal: true,
                modalLoading: true,
            });

            const predictions = this.predictDistributions();
            const breakout = this.state.routed.map((sub) => {

                if (sub.id !== this.props.user.id) {

                    const search = this.props.subaccounts.data.data.find((ref) => ref.id === sub.id);

                    return {
                        name: `${search.firstname} ${search.lastname}`,
                        count: predictions[sub.id]
                    }

                } else {

                    return {
                        name: `${this.props.user.firstname} ${this.props.user.lastname}`,
                        count: predictions[this.props.user.id]
                    }

                }

            });

            this.setState({
                breakout: breakout,
                modalLoading: false
            });

        } else {
            this.setState({
                distributeLoading: true
            });

            this.props.distributeLeadsOnce()
                .then(() => {
                    this.setState({
                        distributeLoading: false,
                        retroModal: false
                    });
                    message.success('Prospects have been distributed successfully!');

                    this.props.fetchLeadRouting();

                })
        }

    }

    predictDistributions() {

        const count = this.state.leadCount ? this.state.leadCount : 0;
        const search = this.state.routed.findIndex((sub) => { return this.generateStatus(sub.id) === 'next' });

        let distributions = {};
        let currentIndex = search;

        this.state.routed.forEach((sub) => {
            distributions[sub.id] = 0;
        });

        for(let x = 0; x < count; x++) {

            distributions[this.state.routed[currentIndex].id] = distributions[this.state.routed[currentIndex].id] + 1;

            if(currentIndex < this.state.routed.length - 1) {
                currentIndex++;
            } else {
                currentIndex = 0;
            }

        }

        return distributions;

    }

    /* List */

    userList = () => {

        const { routed, subaccounts } = this.state;
        const { user} = this.props;

        const list = routed.map((r, i) => {

            const search = (user && r.id === user.id) ?
                user :
                ((subaccounts && subaccounts.data) ? subaccounts.data.find(provider => provider.id === r.id) : null);

            if(search) {
                return {
                    id: search.id,
                    user: search,
                    status: this.generateStatus(search.id),
                    isProvider: search.id === user.id,
                    onRemove: () => { this.removeTeamMember(i) }
                }
            }

        }).filter(x => !!x);

        return list.length > 0 ? list : [{
            id: user.id,
            user: user,
            status: this.generateStatus(user.id),
            isProvider: user.id === user.id,
        }];

    };

    generateStatus = (id) => {

        const { routed } = this.state;

        if(routed.length === 0) {
            return 'next';

        } else {

            if(routed.length === 1) {
                return 'next';
            }

            const providers = routed.filter(routed => routed.last_routed);

            if(providers.length > 0) {

                providers.sort(function (left, right) {
                    return moment(left.last_routed).diff(moment(right.last_routed));
                }).reverse();

                const index = this.state.routed.findIndex(routed => routed.id === providers[0].id);
                const currentItemIndex = this.state.routed.findIndex(routed => routed.id === id);

                if(index === currentItemIndex) {
                    return 'previous';
                }

                if(index + 1 === currentItemIndex) {
                    return 'next';
                }

                if((index === this.state.routed.length - 1) && currentItemIndex === 0) {
                    return 'next';
                }

                return '';

            } else {

                const index = routed.findIndex(routed => routed.id === id);

                return index > -1 && index === 0 ? 'next' : '';

            }

        }

    };

    updateList = (updatedList) => {
        this.setState({routed: updatedList});
    };

    render() {

        const { routing, user } = this.props;
        const { routed } = this.state;

        const action = routing.action;
        const type = (action && action.type) ? action.type : null;

        const userList = this.userList();

        return (
            <div className={"page page-lead-routing"}>
                <Row>
                    <Col xs={24} xl={16} className={'column-max-width'}>
                        <div className={'title-module'}>
                            <h1><Icon type="swap" /> Prospect Routing</h1>
                            <p>Assign existing and incoming prospects to team members using routing rules.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <UnlockFeature>
                    <Row>
                        <Col xs={24} className={'column-max-width'}>
                            <Card
                                title={<h4>Current Routing Rules</h4>}
                                bordered={false}
                            >
                                <Spin spinning={this.state.loading} indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />}>
                                    {
                                        (user && routed.length > 0 && ((routed.length === 1 ? (userList[0].id !== user.id) : true))) &&
                                        <ReactSortable
                                            className={'item-list'}
                                            tag="ul"
                                            handle=".icon-drag-grid"
                                            list={routed}
                                            setList={this.updateList}
                                        >
                                            {
                                                userList.length > 0 &&
                                                userList.map((r) => {

                                                    return (
                                                        <LeadRoutingItem
                                                            key={r.id}
                                                            user={r.user}
                                                            status={r.status}
                                                            length={userList.length}
                                                            isProvider={r.isProvider}
                                                            onRemove={r.onRemove}
                                                        />
                                                    )

                                                })
                                            }
                                        </ReactSortable>
                                    }
                                    {
                                        (routed.length === 0 || (user && routed.length === 1 && userList[0].id === user.id)) &&
                                            <ul className={'item-list'}>
                                                <LeadRoutingItem
                                                    key={user.id}
                                                    user={user}
                                                    status={'next'}
                                                    length={1}
                                                    isProvider={true}
                                                />
                                            </ul>
                                    }
                                </Spin>
                            </Card>
                            <div className="actions">
                                {
                                    this.hasChanged() &&
                                    <Button className={'actions--save button'} type={'primary'} loading={ type === ROUTING_UPDATE_PENDING } onClick={() => { this.saveConfiguration() }}>Save Routing Configuration</Button>
                                }
                                {
                                    !this.hasChanged() &&
                                    this.notJustParent() &&
                                    this.state.leadCount > 0 &&
                                    <a href="#"
                                       onClick={(e) => { e.preventDefault(); this.routeLeadsRetroactively(); }}
                                       className={'actions--distribute '}
                                    >
                                        <i className={'icon-distribute'}></i> One-Time Distribution ({this.state.leadCount} Prospects)
                                    </a>
                                }
                            </div>
                        </Col>
                        <Modal
                            visible={this.state.retroModal}
                            className={'page-lead-routing--modal'}
                            title="One-Time Prospect Distribution"
                            onOk={this.handleOk}
                            onCancel={() => { this.setState( { retroModal: false }) }}
                            footer={[
                                <Button key="back" disabled={this.state.distributeLoading} onClick={() => { this.setState( { retroModal: false })}}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" loading={this.state.distributeLoading} disabled={this.state.modalLoading} onClick={ () => { this.routeLeadsRetroactively()} }>
                                    Confirm Distribution
                                </Button>,
                            ]}
                        >
                            <p>One-time prospect distribution is perfect for when you want to apply rules retroactively or have recently converted your single-user account to a team account.</p>
                            <Skeleton loading={this.state.modalLoading} active={this.state.modalLoading}>
                                <div className="distributions">
                                    <div className="distributions--title">Distribution Breakout</div>
                                    <div className="distributions--row header">
                                        <div className="distributions--column">Name</div>
                                        <div className="distributions--column"># of Leads/Prospects</div>
                                    </div>
                                    {
                                        this.state.breakout.map((sub, i) => {
                                            return (
                                                <div className="distributions--row" key={i}>
                                                    <div className="distributions--column">{ sub.name }</div>
                                                    <div className="distributions--column">{ sub.count }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Skeleton>
                            <p>If you're happy with the distributions above, select <b>Confirm Distribution</b> below to apply.</p>
                        </Modal>
                    </Row>
                    <Row>
                        <Divider />
                    </Row>
                    <Row>
                        <Col xs={24} className={'column-max-width'}>
                            <h6>Add a team member</h6>
                            <p>To add a new team member to your routing rules, select from the list below and click <b>+ Add</b> when you are ready.</p>
                            <Form onSubmit={this.addTeamMember}>
                                <Form.Item>
                                    {
                                        this.generateProviderList()
                                    }
                                </Form.Item>
                                <Form.Item>
                                    <Button type={'primary'} htmlType={'submit'} onClick={(e) => { this.addTeamMember(e) }}>+ Add Team Member</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </UnlockFeature>
            </div>
        );
    }

}

const mapStateToProps = ({ routing, subaccounts, user }) => ({ routing, subaccounts, user: user.user });
const mapDispatchToProps = {
    ...userActions,
    ...routingActions,
    ...subaccountsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(LeadRouting));
