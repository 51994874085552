import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CardCvcElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';
import * as userActions from "../../actions/user";
import creditCardImages from "react-payment-inputs/es/images";
import {Alert, Button, Col, Form, Input, message, Row} from "antd";
import client from '../../actions'

const { Search } = Input;

message.config({
    maxCount: 1,
});

class StripeForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password_confirmationDirty: false,
            isValid: false,
            cardBrand: 'placeholder',
            intentSecret: null,
            loading: false,
            card_number_error: null,
            card_expiry_error: null,
            card_cvc_error: null,
            error: null,
            validCoupons: {
                agent: [
                    'DIGSPARTNER10OFF',
                    'DIGSPARTNER20OFF',
                    'DIGSPARTNER2020FREE',
                    'OJOSELECT'
                ],
                lender: [
                    'AIME2020',
                    'DIGSPARTNER10OFF',
                    'DIGSPARTNER20OFF',
                    'DIGSPARTNER50OFF',
                    'DIGSPARTNER2020FREE',
                    'DIGSNMBD2020'
                ]
            },
            appliedCoupon: '',
        };

    }

    componentDidMount() {
        const { attributes } = this.props;

        if(attributes && attributes.coupon) {
            this.applyCoupon(attributes.coupon);
        }
    }

    handleOnSubmit = (e) => {
        e.preventDefault();

        this.setState({ loading: true });

        if(this.state.appliedCoupon === 'DIGSPARTNER2020FREE' || this.state.appliedCoupon === 'OJOSELECT') {

            message.loading('Contacting Stripe servers...');

            client.post(`/provider/subscription/free`)
                .then(() => {

                    message.success('Thanks for signing up!');
                    this.props.fetchUser();

                })
                .catch((err) => {

                    console.log(err);

                    message.error('An error has occurred');

                    this.setState({
                        loading: false,
                    });

                });

        } else {

            if (this.props.stripe) {

                message.loading('Contacting Stripe servers...');

                client.get(`/provider/subscription/intent`)
                    .then(({ data }) => {
                        const intentSecret = data.intent.client_secret;

                        message.loading('Verifying payment details...');
                        this.props.stripe.handleCardSetup(intentSecret)
                            .then((response) => {

                                if(response.error) {

                                    this.setState({
                                        loading: false,
                                        error: `${response.error.message} ${response.error.decline_code ? `[Code: ${response.error.decline_code}]` : ``}`
                                    });
                                    message.error('An error has occurred.');

                                } else {

                                    const { setupIntent } = response;

                                    message.loading('Processing payment...', 0);

                                    client.post(`/provider/subscription`, { paymentMethod: setupIntent.payment_method, coupon: this.state.appliedCoupon })
                                        .then(response => {

                                            this.props.fetchUser()
                                                .then(() => {
                                                    message.destroy();
                                                    message.success('Account successfully upgraded!');
                                                });

                                        })
                                        .catch(err => err);
                                }

                            })
                    })
                    .catch((err) => {

                        console.log(err);

                        message.error('An error has occurred');

                        this.setState({
                            loading: false,
                        });

                    });

            } else {
                console.log("Stripe.js hasn't loaded yet.");
            }

        }

    };

    handleCardNumberChange = (event) => {

        if(event.error) {
            this.setState({ card_number_error: event.error.message });
        } else {
            this.setState({ card_number_error: null });
        }

        if (event.brand) {

            const brand = event.brand;

            let cardBrand = 'placeholder';

            if (brand in creditCardImages) {
                cardBrand = brand;
            }

            this.setState({
                cardBrand: cardBrand
            })
        }

    };

    handleCardExpiryChange = (event) => {

        if(event.error) {
            this.setState({ card_expiry_error: event.error.message });
        } else {
            this.setState({ card_expiry_error: null });
        }

        console.log(event);

    };

    handleCardCvcChange = (event) => {

        if(event.error) {
            this.setState({ card_cvc_error: event.error.message });
        } else {
            this.setState({ card_cvc_error: null });
        }

    }

    getCardImageProps = (cardBrand) => {

        const images = creditCardImages || {};

        return {
            'aria-label': cardBrand ? `${cardBrand[0].toUpperCase() + cardBrand.slice(1)} card` : 'Placeholder card',
            children: images[cardBrand],
            width: '1.5em',
            height: '1em',
            viewBox: '0 0 24 16',
        };
    };

    generateStyles = () => {
        return {
            style: {
                base: {
                    fontSize: '14px',
                    color: '#000000',
                    letterSpacing: 'normal',
                    fontFamily: 'Montserrat, Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif',
                    '::placeholder': {
                        color: '#bfbfbf',
                    },
                },
                invalid: {
                    color: '#f5222d',
                },
            },
        };
    };

    applyCoupon = (value) => {

        const coupon = value.trim().toUpperCase();
        const { form, user } = this.props;

        if(user && user.agent_lender && user.agent_lender === 'Agent') {

            if(this.state.validCoupons.agent.indexOf(coupon) > -1) {
                message.success(`Coupon code ${coupon} applied successfully!`);
                this.setState({ appliedCoupon: coupon});
            } else {
                message.error(`Coupon code ${coupon} not valid.`);
                this.setState({ appliedCoupon: ''});
                form.resetFields();
            }

        } else if(user && user.agent_lender && user.agent_lender === 'Lender') {

            if(this.state.validCoupons.lender.indexOf(coupon) > -1) {
                message.success(`Coupon code ${coupon} applied successfully!`);
                this.setState({ appliedCoupon: coupon});
            } else {
                message.error(`Coupon code ${coupon} not valid.`);
                this.setState({ appliedCoupon: ''});
                form.resetFields();
            }

        } else {

            message.error(`Coupon code ${coupon} not valid.`);
            this.setState({ appliedCoupon: ''});
            form.resetFields();

        }


    };

    generateInvoice = () => {

        const { user } = this.props;

        if(user && user.agent_lender && user.agent_lender === 'Agent') {

            switch (this.state.appliedCoupon) {
                case 'DIGSPARTNER10OFF':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$39</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER10OFF</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($10 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$29.00</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSPARTNER20OFF':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$39</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER20OFF</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($20 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$19.00</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSPARTNER2020FREE':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$39</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER2020FREE</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription fee waived
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$0</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'OJOSELECT':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$39</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>OJOSELECT</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription fee waived
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$0</span>
                                </div>
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$39</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="total">
                                    Today's Total: <span>$39</span>
                                </div>
                            </div>
                        </div>
                    )
            }

        } else if (user && user.agent_lender && user.agent_lender === 'Lender'){

            switch (this.state.appliedCoupon) {
                case 'AIME2020':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>AIME2020</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($50 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$49.00</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSPARTNER50OFF':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER50OFF</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($50 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$49.00</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSPARTNER10OFF':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER10OFF</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($10 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$89.00</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSPARTNER20OFF':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER20OFF</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($20 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$79.00</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSPARTNER2020FREE':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSPARTNER2020FREE</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription fee waived
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$0</span>
                                </div>
                            </div>
                        </div>
                    );
                case 'DIGSNMBD2020':
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="item">
                                    Coupon <span>DIGSNMBD2020</span> applied
                                </div>
                                <div className="item">
                                    Monthly subscription discount ($50 Off)
                                </div>
                                <div className="item">
                                    One-time discount ($49 Off)
                                </div>
                                <div className="total w-discount">
                                    Today's Total: <span>$0.00</span>
                                </div>
                                <div className="item">
                                    Subscription will revert to $49.00 at next billing interval.
                                </div>
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div className="invoice">
                            <div className="invoice--top">
                                <div className="item">
                                    Monthly subscription fee: <span>$99</span>
                                </div>
                            </div>
                            <div className="invoice--bottom">
                                <div className="total">
                                    Today's Total: <span>$99</span>
                                </div>
                            </div>
                        </div>
                    )
            }

        }

        return '';

    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                {
                    this.state.error &&
                    <Alert
                        closable
                        message={this.state.error}
                        type="error"
                        style={{marginBottom: 25}}
                    />
                }
                <Form onSubmit={this.handleOnSubmit} colon={false} hideRequiredMark={true} className={'payment-form'}>
                    <Row gutter={24}>
                        {
                            this.state.appliedCoupon !== 'DIGSPARTNER2020FREE' && this.state.appliedCoupon !== 'OJOSELECT' &&
                            <div>
                                <Col xs={24}>
                                    <Form.Item
                                        label={'Card Details'}
                                        validateStatus={this.state.card_number_error ? 'error' : null}
                                        help={this.state.card_number_error ? this.state.card_number_error : null}
                                    >
                            <span className={'ant-input-affix-wrapper ant-input-affix-wrapper-lg'}>
                                <span className={'ant-input-prefix'}>
                                    <svg {...this.getCardImageProps(this.state.cardBrand)} />
                                </span>
                                <CardNumberElement
                                    className={'ant-input ant-input-lg cc-number'}
                                    onChange={this.handleCardNumberChange}
                                    {...this.generateStyles()}
                                />
                            </span>
                                    </Form.Item>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item
                                        label={'Expiration'}
                                        validateStatus={this.state.card_expiry_error ? 'error' : null}
                                        help={this.state.card_expiry_error ? this.state.card_expiry_error : null}
                                    >
                                        <CardExpiryElement
                                            className={'ant-input ant-input-lg'}
                                            onChange={this.handleCardExpiryChange}
                                            {...this.generateStyles()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item
                                        label={'CVC'}
                                        validateStatus={this.state.card_cvc_error ? 'error' : null}
                                        help={this.state.card_cvc_error ? this.state.card_cvc_error : null}
                                    >
                                        <CardCvcElement
                                            className={'ant-input ant-input-lg'}
                                            onChange={this.handleCardCvcChange}
                                            {...this.generateStyles()}
                                        />
                                    </Form.Item>
                                </Col>
                            </div>
                        }
                        <Col xs={24}>
                            <Form.Item label={'Coupon Code'}>
                                {
                                    getFieldDecorator('coupon', {})(
                                        <Search
                                            enterButton="Apply"
                                            size="large"
                                            onSearch={(value) => this.applyCoupon(value)}
                                        />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            { this.generateInvoice() }
                        </Col>
                        <Col xs={24}>
                            <Form.Item>
                                <Button
                                    className={'button primary'}
                                    htmlType={'submit'}
                                    disabled={this.state.loading}
                                    loading={this.state.loading}
                                >{'Submit Payment'}</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action, attributes: user.attributes });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(injectStripe(StripeForm)));
