import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CardCvcElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';
import * as userActions from "../../../actions/user";
import creditCardImages from "react-payment-inputs/es/images";
import {Alert, Button, Col, Form, message, Row} from "antd";

import client from '../../../actions'

message.config({
    maxCount: 1,
});

class StripeForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password_confirmationDirty: false,
            isValid: false,
            cardBrand: 'placeholder',
            loading: false,
            updatingPaymentMethod: false,
            card_number_error: null,
            card_expiry_error: null,
            card_cvc_error: null,
            error: null,
        };

    }

    handleOnSubmit = (e) => {
        e.preventDefault();

        if (this.props.stripe) {

            this.setState({
                updatingPaymentMethod: true,
            });

            client.get(`/provider/subscription/intent`)
                .then(({ data }) => {
                    const intentSecret = data.intent.client_secret;

                    this.props.stripe.handleCardSetup(intentSecret)
                        .then((response) => {

                            if(response.error) {

                                this.setState({
                                    updatingPaymentMethod: false,
                                    error: `${response.error.message} ${response.error.decline_code ? `[Code: ${response.error.decline_code}]` : ``}`
                                });
                                message.error('An error has occurred.');

                            } else {

                                const { setupIntent } = response;

                                client.post(`/provider/subscription/payment`, { paymentMethod: setupIntent.payment_method })
                                    .then(() => {
                                        this.props.fetchUser()
                                            .then(() => {

                                                message.success('Payment method updated successfully!');

                                                this.setState({
                                                    updatingPaymentMethod: false,
                                                });
                                            });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });

                            }

                        })
                        .catch(() => {});
                })


        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }

    };

    handleCardNumberChange = (event) => {

        if(event.error) {
            this.setState({ card_number_error: event.error.message });
        } else {
            this.setState({ card_number_error: null });
        }

        if (event.brand) {

            const brand = event.brand;

            let cardBrand = 'placeholder';

            if (brand in creditCardImages) {
                cardBrand = brand;
            }

            this.setState({
                cardBrand: cardBrand
            })
        }

    };

    handleCardExpiryChange = (event) => {

        if(event.error) {
            this.setState({ card_expiry_error: event.error.message });
        } else {
            this.setState({ card_expiry_error: null });
        }

        console.log(event);

    }

    handleCardCvcChange = (event) => {

        if(event.error) {
            this.setState({ card_cvc_error: event.error.message });
        } else {
            this.setState({ card_cvc_error: null });
        }

    }

    getCardImageProps = (cardBrand) => {

        const images = creditCardImages || {};

        return {
            'aria-label': cardBrand ? `${cardBrand[0].toUpperCase() + cardBrand.slice(1)} card` : 'Placeholder card',
            children: images[cardBrand],
            width: '1.5em',
            height: '1em',
            viewBox: '0 0 24 16',
        };
    };

    generateStyles = () => {
        return {
            style: {
                base: {
                    fontSize: '14px',
                    color: '#000000',
                    letterSpacing: 'normal',
                    fontFamily: 'Montserrat, Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif',
                    '::placeholder': {
                        color: '#bfbfbf',
                    },
                },
                invalid: {
                    color: '#f5222d',
                },
            },
        };
    };

    render() {

        return (
            <div>
                {
                    this.state.error &&
                    <Alert
                        closable
                        message={this.state.error}
                        type="error"
                        style={{marginBottom: 25}}
                    />
                }
                <Form onSubmit={this.handleOnSubmit} colon={false} hideRequiredMark={true}>
                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                label={'Card Details'}
                                validateStatus={this.state.card_number_error ? 'error' : null}
                                help={this.state.card_number_error ? this.state.card_number_error : null}
                            >
                            <span className={'ant-input-affix-wrapper ant-input-affix-wrapper-lg'}>
                                <span className={'ant-input-prefix'}>
                                    <svg {...this.getCardImageProps(this.state.cardBrand)} />
                                </span>
                                <CardNumberElement
                                    className={'ant-input ant-input-lg cc-number'}
                                    onChange={this.handleCardNumberChange}
                                    {...this.generateStyles()}
                                />
                            </span>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label={'Expiration'}
                                validateStatus={this.state.card_expiry_error ? 'error' : null}
                                help={this.state.card_expiry_error ? this.state.card_expiry_error : null}
                            >
                                <CardExpiryElement
                                    className={'ant-input ant-input-lg'}
                                    onChange={this.handleCardExpiryChange}
                                    {...this.generateStyles()}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label={'CVC'}
                                validateStatus={this.state.card_cvc_error ? 'error' : null}
                                help={this.state.card_cvc_error ? this.state.card_cvc_error : null}
                            >
                                <CardCvcElement
                                    className={'ant-input ant-input-lg'}
                                    onChange={this.handleCardCvcChange}
                                    {...this.generateStyles()}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item>
                                <Button type={'primary'} loading={this.state.updatingPaymentMethod} htmlType={'submit'}>{'Update Payment Method'}</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(StripeForm));
