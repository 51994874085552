import React, {PureComponent} from 'react';
import {Button, Col, Form, Row} from 'antd'
import {connect} from 'react-redux';
import * as userActions from "../../actions/user";
import GenericInactive from './GenericInactive';

class Zapier extends PureComponent {

    render() {

        const data = {
            text: 'Digs integrates with Zapier to connect with over 1500 technology services. Signup with Zapier today.',
            list: [
                'Requires Zapier account',
                'Sync existing contacts',
                'Update fields'
            ],
            image: '/images/integrations/zapier-digs-logo.png',
            button: <Button className={'button primary'} disabled>Coming Soon</Button>
        };

        return (
            <div className={'zapier-module'}>
                <Row>
                    <Col xs={24} className={'column-max-width'}>
                        <GenericInactive data={data} />
                    </Col>
                </Row>
            </div>
        );
    }

}


const mapStateToProps = ({ user }) => ({ user: user.user, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Zapier));
