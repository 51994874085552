import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {AUTH_TYPE_AUTHORIZED, AUTH_TYPE_GUEST} from '../../helpers/constants'


function validateRegistration(props) {
    let valid = true;
    let { user } = props;

    if(!user.agent_lender) {
        valid = false;
    }

    return valid;
}

function validateOnboarding(props) {
    let valid = true;
    let { user } = props;

    if(!user.completed_onboarding) {
        valid = false;
    }

    return valid;
}

export default function (ComposedComponent, authType = AUTH_TYPE_AUTHORIZED, roles = []) {

    class Authentication extends Component {
        static contextTypes = {
            router: PropTypes.object
        };

        componentWillMount () {
            this.validateRoutePermissions(this.props)
        }

        componentWillUpdate (nextProps) {
            this.validateRoutePermissions(nextProps)
        }

        validateRoutePermissions (props) {

            const { match } = props;

            if (authType === AUTH_TYPE_GUEST) {
                if (props.token && match.path !== '/signup') {
                    props.history.push('/dashboard')
                }

                if(props.token && match.path === '/signup') {
                    props.history.push('/signup/details')
                }

            } else {
                if (!props.token) {
                    // If user has no API token, send them to login
                    props.history.push('/')

                } else {

                    // If user hasn't completed registration
                    if(
                        !validateRegistration(props) &&
                        match.path !== '/signup/details'
                    ) {
                        props.history.push('/signup/details')
                    }

                    // If user has not completed onboarding
                    if(
                        validateRegistration(props) &&
                        !validateOnboarding(props) &&
                        match.path !== '/onboarding'
                    ) {
                        props.history.push('/onboarding');
                    }

                    // If user goes to upgrade page but already has subscription
                    if((match.path === '/upgrade' || match.path === '/upgrade/payment') && props.user.active_subscription) {
                        props.history.push('/dashboard')
                    }

                    if (roles.length > 0) {
                        if (roles.indexOf(props.user.type) === -1) {
                            props.history.push('/dashboard')
                        }
                    }
                }
            }
        }

        render () {
            return <ComposedComponent {...this.props} />
        }
    }

    const mapStateToProps = ({ user }, ownProps) => ({ token: user.token, user: user.user });

    return connect(mapStateToProps)(Authentication)
}
