import client from './';

/* Consumer Invites */
export const importSubscribers = (data) => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return client.post(`/provider/subscribers/import`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

};

export const importSingleSaver = (data) => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return client.post(`/provider/subscribers/saver`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

};

export const importSingleHomeowner = (data) => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return client.post(`/provider/subscribers/homeowner`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

};
