import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as subaccountsActions from "../../actions/subaccounts";
import history from '../../helpers/history';
import {Button, Col, Divider, Form, Icon, Input, message, Radio, Row, Select, Spin} from "antd";
import Errors from "../../components/utils/Errors";
import ColorInputField from "../../components/ui/ColorInputField";
import {UPDATE_USER_PENDING} from "../../actions/types";
import AvatarUploader from "../../components/ui/AvatarUploader";
import LogoUploader from "../../components/ui/LogoUploader";

class ProviderProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            /* */
            submitting: false,
            file: null,
            uploading: false,
            imageError: null,
            src: null,
            crop_area_visible: false,
            crop: {
                x: 0,
                y: 0,
                unit: 'px',
                width: 100,
                height: 100,
                aspect: 1,
            },
            draft: null,
        };

    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.props.fetchSubaccountById(this.props.match.params.id)
                .then(({ data: { user }}) => {
                    this.setState({
                        user: user,
                    })
                });
        } else {
            history.push('/');
        }
    }

    /* From Profile */
    handleInputChange = (key, value) => {
        this.onFormChange();
    };

    onSelectChange = (values) => {

        const { draft } = this.state;

        if(!draft) {

            const formFields = this.props.form.getFieldsValue();

            this.setState({
                draft: {
                    ...formFields,
                    keywords: values,
                }
            });

        } else {

            this.setState({
                draft: {
                    ...this.state.draft,
                    keywords: values,
                }
            });

        }

    };

    onFormChange = () => {
        const formFields = this.props.form.getFieldsValue();
        this.setState({
            draft: formFields
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        const { user } = this.state;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.id = user.id;

                this.props.updateSubaccount(values)
                    .then(() => {
                        message.success('Profile successfully updated')
                    })
            }
        });

    };

    /* Subaccount Media Upload */

    onUploadMedia = (userId, data) => {

        return this.props.uploadSubaccountMedia(userId, data)
            .then((response) => {
                this.setState({
                    user: response.data.user,
                });
            });

    };

    render() {

        const { user } = this.state;
        const { action, form: { getFieldDecorator } } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className="page page-provider-profile">
                {
                    !user &&
                    <div className={'loading'}>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                    </div>
                }
                {
                    user &&
                    <div className={'edit-provider-profile'}>
                        <div className={'user--name'}>
                            <h1>{user.firstname} {user.lastname}</h1>
                        </div>
                        <Divider />
                        <Row gutter={20}>
                            <Col xs={24} md={24} lg={16}>
                                <Form layout="vertical" hideRequiredMark onChange={this.onFormChange}>
                                    <Row gutter={24}>
                                        {
                                            user.errors &&
                                            <Col span={24}>
                                                <Errors errors={user.errors}/>
                                            </Col>
                                        }
                                        <Col span={24}>
                                            <div className="upload-block">
                                                <div className="upload-block--images">
                                                    <div className="upload-block--label">Avatar</div>
                                                    <AvatarUploader user={user} onSubmit={this.onUploadMedia}/>
                                                </div>
                                                <div className="upload-block--images">
                                                    <div className="upload-block--label">Company Logo</div>
                                                    <LogoUploader user={user} onSubmit={this.onUploadMedia}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item label={'Brand Color'}>
                                                {getFieldDecorator('brand_color', {
                                                    rules: [
                                                        { required: true, message: 'Please enter a brand color' },
                                                        { pattern: new RegExp(/^#[a-f0-9]{6}$/i), message: 'Please enter a valid color' },
                                                    ],
                                                    initialValue: user.brand_color ? user.brand_color : '#222222',
                                                    onChange: this.handleInputChange
                                                })(<ColorInputField />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={'First Name'}>
                                                {getFieldDecorator('firstname', {
                                                    rules: [{ required: true, message: 'Please enter first name' }],
                                                    initialValue: user.firstname ? user.firstname : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={'Last Name'}>
                                                {getFieldDecorator('lastname', {
                                                    rules: [{ required: true, message: 'Please enter last name' }],
                                                    initialValue: user.lastname ? user.lastname : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'Company Name'}>
                                                {getFieldDecorator('company_name', {
                                                    rules: [{ required: true, message: 'Please enter company name' }],
                                                    initialValue: user.company_name ? user.company_name : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'Location'}>
                                                {getFieldDecorator('location', {
                                                    rules: [{ required: true, message: 'Please enter a location' }],
                                                    initialValue: user.location ? user.location : null,
                                                })(<Input />)}
                                                <p className={'sublabel'}>If located in multiple or all states, simply write "Multiple or All 50 States"</p>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <h3>Contact Information & Biography</h3>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'Email'}>
                                                {getFieldDecorator('email', {
                                                    rules: [{ required: true, message: 'Please enter email' }],
                                                    initialValue: user.email ? user.email : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'Phone No.'}>
                                                {getFieldDecorator('mobile', {
                                                    initialValue: user.mobile ? user.mobile : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'About You'}>
                                                {getFieldDecorator('short_bio', {
                                                    initialValue: user.short_bio ? user.short_bio : null,
                                                })(<Input.TextArea rows={6} />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'Keyword Tags'}>
                                                {getFieldDecorator('tags', {
                                                    onChange: this.onSelectChange,
                                                    initialValue: user.tags ? user.tags : [],
                                                })(<Select
                                                    mode="multiple"
                                                    placeholder={'Please select...'}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Select.Option value={'FHA'}>FHA</Select.Option>
                                                    <Select.Option value={'VA'}>VA</Select.Option>
                                                    <Select.Option value={'First-time Homebuyers'}>First-time Homebuyers</Select.Option>
                                                </Select>)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <h3>Optional Information</h3>
                                        </Col>
                                        <Col span={24}>

                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                {getFieldDecorator('mls_nmls', {
                                                    rules: [{ required: false, message: 'Please select mls or nmls' }],
                                                    initialValue: user.mls_nmls ? user.mls_nmls : null,
                                                })(<Radio.Group>
                                                    <Radio value="MLS#">MLS Number</Radio>
                                                    <Radio value="NMLS#">NMLS Number</Radio>
                                                </Radio.Group>)}
                                            </Form.Item>
                                        </Col>
                                        {
                                            this.props.form.getFieldValue('mls_nmls') &&
                                            <Col span={24}>
                                                <Form.Item label={this.props.form.getFieldValue('mls_nmls')}>
                                                    {getFieldDecorator('mls', {
                                                        rules: [{required: false, message: 'Please enter mls or nmls'}],
                                                        initialValue: user.mls ? user.mls : null,
                                                    })(<Input/>)}
                                                </Form.Item>
                                            </Col>
                                        }
                                        <Col span={24}>
                                            <Form.Item label={'LinkedIn Profile URL'}>
                                                {getFieldDecorator('linkedin_url', {
                                                    initialValue: user.linkedin_url ? user.linkedin_url : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={'Zillow Profile URL'}>
                                                {getFieldDecorator('zillow_url', {
                                                    initialValue: user.zillow_url ? user.zillow_url : null,
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button type="primary" onClick={e => this.handleSubmit(e)} loading={type === UPDATE_USER_PENDING}>
                                        Save
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = ({ subaccounts }) => ({ action: subaccounts.action, errors: subaccounts.errors });
const mapDispatchToProps = {
    ...subaccountsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(ProviderProfile)));
