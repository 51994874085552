import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as userActions from "../../../actions/user";
import {Button, Form} from "antd";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import 'react-image-crop/lib/ReactCrop.scss';

class Logo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            /* Actual Source */
            logo: null,
            showUploader: false,
            file: null,
            uploading: false,
            imageError: null,
            src: null,
        };

    }

    componentDidMount() {

        const { user } = this.props;

        if(user.logo) {
            console.log(user.logo);
            this.setState({
                logo: user.logo,
            });
        }

    }

    handleChangeStatus = ({ meta, file, cancel, remove, restart}, status) => {

        if(meta.status === 'error_file_size') {
            this.setState({ imageError: 'File size must be less than 5MB!'});
            remove();
        }

        if(meta.status === 'done') {

            this.setState(state => ({
                imageError: null,
                file: file,
            }));

            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    showUploader: false,
                }),
            );
            reader.readAsDataURL(file);

            return false;

        }
    };

    // submit everything
    onUpload(file, type, onSuccess) {

        var reader = new window.FileReader();
        reader.readAsDataURL(file);
        reader.onload = (...args) => {

            const formData = new FormData();

            formData.append('file', file);
            formData.append('type', type);

            if (file.size > 1048576) {
                this.props.setUserErrors({errors: {[type]: ["Image is too large to upload, max size 1 MB"]}})

            }else {

            }

            onSuccess('done', file);
        }
    }

    generateInputContent = () => {

        return (
            <div className={'upload-cta'} key={'upload-cta'}>
                <span>Drop an image here</span>
                <span>or</span>
                <span className={'button primary'}>Upload an image</span>
                {
                    !this.state.imageError &&
                    <span className={'disclaimer'}>Max File Size: 5MB</span>
                }
                {
                    this.state.imageError &&
                    <span className={'disclaimer error'}>Error: { this.state.imageError }</span>
                }
            </div>
        )

    };

    handleOnSubmit = () => {

        const { logo, file } = this.state;

        if(logo && !file) {

            this.goToNextStep();

        } else {

            this.setState({uploading: true});

            const formData = new FormData();

            const reader = new window.FileReader();
            reader.readAsDataURL(file);

            reader.onload = (...args) => {

                formData.append('file', file);
                formData.append('type', 'logo');

                this.props.s3Upload(formData)
                    .then((media) => {

                        this.setState({
                            logo: media
                        });

                        this.props.fetchUser()
                            .then(() => {
                                this.setState({
                                    uploading: false,
                                }, () => {
                                    this.goToNextStep();
                                });
                            });
                    });
            }

        }



    };

    goToNextStep = (e = null) => {
        if(e) {
            e.preventDefault();
        }
        this.props.history.push('/onboarding/profile/bio');
    };

    render() {

        const { logo, src, showUploader, uploading } = this.state;

        return (
            <div className={'page-onboarding page-onboarding-logo fadeIn'}>
                {
                    (showUploader || !logo) && !uploading &&
                    <div>
                        <h2>Your Company Logo</h2>
                        <p>Please upload a high quality image of your logo for branding purposes.</p>
                        <Dropzone
                            onChangeStatus={this.handleChangeStatus}
                            accept="image/*"
                            maxFiles={1}
                            maxSizeBytes={(1024 * 1024) * 5}
                            PreviewComponent={null}
                            SubmitButtonComponent={null}
                            inputContent={this.generateInputContent}
                            inputWithFilesContent={null}
                        />
                        {
                            !logo &&
                            <div className="logo-preview--actions">
                                <a href="#" onClick={this.goToNextStep}>Skip this for now</a>
                            </div>
                        }
                        {
                            logo &&
                            <div className="logo-preview--actions">
                                <a href="#" onClick={(e) => { e.preventDefault(); this.setState({showUploader: false })}}>Back to preview</a>
                            </div>
                        }
                    </div>
                }
                {
                    (logo || src) && !showUploader &&
                    <div>
                        <div className="title-block">
                            <h2>Your Current Company Logo</h2>
                            <p>Preview your company logo below.</p>
                        </div>
                        <div className={'logo-preview'}>
                            <img src={src ? src : logo} alt=""/>
                        </div>
                        <div className="logo-preview--actions">
                            <Button type={'primary'} loading={uploading} className={'button primary'} onClick={(e) => { this.handleOnSubmit(e) }}>Continue with selection</Button>
                            <a href="#"  onClick={(e) => { e.preventDefault(); this.setState({showUploader: true })}}>Upload a different image</a>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Logo));
