import TagManager from 'react-gtm-module';
import store from "../store";
import config from '../config';

const GOOGLE_TAG_MANAGER_ID = config.REACT_APP_GOOGLE_TAG_MANAGER_ID || '';
const GOOGLE_TAG_MANAGER_AUTH = config.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || '';
const GOOGLE_TAG_MANAGER_PREVIEW = config.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW || '';

const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID,
    auth: GOOGLE_TAG_MANAGER_AUTH,
    preview: GOOGLE_TAG_MANAGER_PREVIEW
};

export default class Analytics {

    static initialize = () => {
        TagManager.initialize(tagManagerArgs);
    };

    static pageview = (path) => {
        const pathname = path ? path : window.location.pathname;
        const user = store.getState().user.user;

        const args = {
            ...tagManagerArgs,
            dataLayer: {
                event: 'Pageview',
                pagePath: pathname,
            }
        };

        if(user && user.id) {

            if(user.id) {
                args.dataLayer.userId = user.id;
            }

            if(user.provider_id) {
                args.dataLayer.providerId = user.provider_id;
            }

        }

        TagManager.dataLayer(args);

    };

    static set = (values) => {

        const user = store.getState().user.user;

        const args = {
            ...tagManagerArgs,
            dataLayer: {
                event: 'CustomEvent',
                ...values,
            }
        };

        if(user && user.id) {

            if(user.id) {
                args.dataLayer.userId = user.id;
            }

            if(user.provider_id) {
                args.dataLayer.providerId = user.provider_id;
            }

        }

        TagManager.dataLayer(args);

    }

}
