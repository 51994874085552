import {FETCH_MESSAGES_ERROR, FETCH_MESSAGES_PENDING, FETCH_MESSAGES_SUCCESS,} from '../actions/types'

const defaultState = {
    data: {},
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_MESSAGES_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_MESSAGES_SUCCESS:
            return { ...state, action, data: action.payload, errors: {}, loading: false };
        case FETCH_MESSAGES_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        default:
            return state
    }
};
