const devConfig = {
    ENVIRONMENT: "development",
    REACT_APP_URL: window.location.origin || "",
    REACT_APP_API_URL: "https://digs-api.dev.aws.us-east-1.ojocore.com",
    REACT_APP_INTERCOM_APP_ID: "a4pm5o1z",
    REACT_APP_GOOGLE_TAG_MANAGER_ID: "GTM-KZNHF3K",
    REACT_APP_GOOGLE_TAG_MANAGER_AUTH: "zPJH0Fx999VMRRujy9lmsA",
    REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW: "env-5",
    REACT_APP_STRIPE_KEY: "pk_test_pdCqAUHyHdtNDQRsHZMEurmC",
    REACT_APP_SENTRY_ENVIRONMENT: "development"
};

export default devConfig;
