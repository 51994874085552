import React, {Component} from 'react';

const RADIUS = 25;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

class MiniRadialProgress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
        }
    }

    componentDidMount() {

        setTimeout(() => {

            const { progress } = this.props;
            this.setState({progress: progress ? progress : 0});

        }, 0);

    }

    render() {

        const { inverted } = this.props;
        const progress = this.state.progress / 100;
        const dashoffset = CIRCUMFERENCE * (1 - progress);

        const styles = {
            strokeDasharray: CIRCUMFERENCE,
            strokeDashoffset: dashoffset
        };

        return (
            <div className="mini-radial-progress--container">
                <svg className={`mini-radial-progress ${inverted ? 'inverted' : ''}`} viewBox={`30 30 60 60`}>
                    <circle className="mini-radial-progress--meter" cx="60" cy="60" r={RADIUS} strokeWidth="8"/>
                    <circle
                        className="mini-radial-progress--value"
                        style={styles}
                        cx="60" cy="60" r={RADIUS}
                        strokeWidth={inverted ? 9 : 8}
                    />
                </svg>
            </div>
        )
    }

}
export default MiniRadialProgress;
