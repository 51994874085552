import {
    CLEAR_USER_ATTRIBUTES,
    FETCH_USER_ERROR,
    FETCH_USER_PENDING,
    FETCH_USER_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_PENDING,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_ERROR,
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_PENDING,
    LOGOUT_SUCCESS,
    REGISTRATION_ERROR,
    REGISTRATION_PENDING,
    REGISTRATION_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_PENDING,
    RESET_PASSWORD_SUCCESS,
    SET_USER_ERRORS,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_PENDING,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_USER_ATTRIBUTES,
    UPDATE_USER_ERROR,
    UPDATE_USER_PENDING,
    UPDATE_USER_SUCCESS,
} from '../actions/types'

const defaultState = {
    user: {},
    attributes: {},
    token: null,
    loading: false,
    errors: {},
    action: null
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case LOGIN_SUCCESS:
            return { ...state, action, token: action.token, user: action.user, errors: {}, loading: false };
        case LOGIN_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case LOGOUT_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case LOGOUT_SUCCESS:
            return { ...state, action, user: {}, token: null, type: null, errors: {}, loading: false };
        case LOGOUT_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case REGISTRATION_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case REGISTRATION_SUCCESS:
            return { ...state, action, token: action.token, user: action.user, errors: {}, loading: false };
        case REGISTRATION_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FETCH_USER_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FETCH_USER_SUCCESS:
            return { ...state, action, user: action.payload, errors: {}, loading: false };
        case FETCH_USER_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_USER_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_USER_SUCCESS:
            return { ...state, action, user: action.payload, errors: {}, loading: false };
        case UPDATE_USER_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case UPDATE_USER_ATTRIBUTES:
            return { ...state, action, attributes: { ...state.attributes, ...action.payload }, errors: {}, loading: false };
        case CLEAR_USER_ATTRIBUTES:
            return { ...state, action, attributes: {}, errors: {}, loading: false };
        case UPDATE_PASSWORD_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case UPDATE_PASSWORD_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case UPDATE_PASSWORD_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case FORGOT_PASSWORD_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case RESET_PASSWORD_PENDING:
            return { ...state, action, loading: true, errors: {} };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, action, errors: {}, loading: false };
        case RESET_PASSWORD_ERROR:
            return { ...state, action, errors: action.payload, loading: false };
        case SET_USER_ERRORS:
            return { ...state, errors: action.payload, loading: false };
        default:
            return state
    }
};
