import React, {Component} from 'react';

const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

class RadialProgress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
        }
    }

    componentDidMount() {

        setTimeout(() => {

            const { progress } = this.props;
            this.setState({progress: progress ? progress : 0});

        }, 0);

    }

    render() {

        const progress = this.state.progress / 100;
        const dashoffset = CIRCUMFERENCE * (1 - progress);

        const styles = {
            strokeDasharray: CIRCUMFERENCE,
            strokeDashoffset: dashoffset
        };

        return (
            <div className="radial-progress--container">
                <svg className="radial-progress" viewBox="0 0 120 120">
                    <circle className="radial-progress--meter" cx="60" cy="60" r={RADIUS} strokeWidth="8"/>
                    <circle
                        className="radial-progress--value"
                        style={styles}
                        cx="60" cy="60" r={RADIUS}
                        strokeWidth="8"
                    />
                </svg>
            </div>
        )
    }

}
export default RadialProgress;
