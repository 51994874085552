import React from 'react';
import { Input, Form, message } from 'antd';
import DatePicker from 'react-datepicker';
import moment from "moment";

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <Input
            ref={ref}
            className={"uk-input"}
            onClick={props.onClick}
            value={ props.value ? moment(props.value).format('MMM D, YYYY') : '-'}
            type="text"
            readOnly={true}
            style={{ cursor: 'default' }}
        />
    )
});

const formatValues = {
  number: (e) => {
      const value = e.currentTarget.value;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((value && !isNaN(value) && reg.test(value))) {
          return parseInt(value);
      }
      return value;
  },
  float: (e) => {
      const value = e.currentTarget.value;
      const reg = /^[+-]?\d+(\.\d+)?$/;
      if ((value && !isNaN(value) && reg.test(value))) {
          return parseFloat(e.currentTarget.value);
      }
      return value;
  },
  string: (e) => {
      return e.currentTarget.value;
  }
};

class InlineEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
        };
        this.input = React.createRef();
    }

    toggleEdit = () => {
        const { date } = this.props;
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                if(!date) {
                    this.input.current.focus();
                } else {
                    this.input.current.setOpen(true);
                }
            }
        });
    };

    save = e => {
        const { form, fieldName, value, handleSave } = this.props;
        form.validateFields({force: true},(error, values) => {
            if (error && error[e.currentTarget.id]) {
                switch(this.props.type) {
                    case 'number':
                        message.error('Value must be a number');
                        break;
                    case 'float':
                        message.error('Value must be a decimal');
                        break;
                    default:
                        message.error('Not a valid value');
                        break;
                }
                this.toggleEdit();
            } else {
                this.toggleEdit();
                if(values[fieldName] !== value) {
                    handleSave(this.props.propertyId, { ...values });
                }
            }

        });
    };

    saveDate = val => {
        const { value, fieldName, handleSave } = this.props;
        const selectedDate = moment(val).format('YYYY-MM-DD');
        if(value !== selectedDate) {
            handleSave(this.props.propertyId, { [fieldName]: selectedDate });
        }
        this.toggleEdit();
    };

    render() {
        const { form, value, fieldName, date, children, type } = this.props;
        const { editing } = this.state;

        const fieldType = type ? type : 'string';

        return editing ? (
            <div className={'inline-editor--focused'}>
                {
                    date ?
                        <DatePicker
                            onClickOutside={this.toggleEdit}
                            dateFormat={'MM-dd-yyyy'}
                            selected={value ? new Date(moment(value).format()) : new Date() }
                            ref={this.input}
                            onSelect={this.saveDate}
                            customInput={<CustomInput />}
                        /> :
                        <Form.Item>
                            {
                                form.getFieldDecorator(fieldName, {
                                    initialValue: value,
                                    rules: [{ required: true, type: fieldType }],
                                    getValueFromEvent: formatValues[fieldType]
                                })(<Input ref={this.input} onPressEnter={this.save} onBlur={this.save} />)
                            }
                        </Form.Item>
                }
            </div>
        ) : (
            <div
                className={'inline-editor'}
            >
                <i className={'icon-pencil'} onClick={this.toggleEdit} />&nbsp;
                { children }
            </div>
        );
    }
}

export default Form.create()(InlineEditor);
