import {
    FETCH_CHILD_ERROR,
    FETCH_CHILD_PENDING,
    FETCH_CHILD_SUCCESS,
} from "./types";

import client from './';

/* */

export const fetchUserById = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_CHILD_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/user/${id}`)
                .then(response => {
                    dispatch({
                        type: FETCH_CHILD_SUCCESS,
                    })
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: FETCH_CHILD_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }
};

export const fetchSubscriberById = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_CHILD_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/subscriber/${id}`)
                .then(response => {
                    dispatch({
                        type: FETCH_CHILD_SUCCESS,
                    })
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: FETCH_CHILD_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }
};
