const localConfig = {
    ENVIRONMENT: "local",
    REACT_APP_URL: "http://localhost:3000",
    REACT_APP_API_URL: "http://localhost:8080",
    REACT_APP_INTERCOM_API_ID: "a4pm5o1z",
    REACT_APP_GOOGLE_TAG_MANAGER_ID: "GTM-KZNHF3K",
    REACT_APP_GOOGLE_TAG_MANAGER_AUTH: "zPJH0Fx999VMRRujy9lmsA",
    REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW: "env-5",
    REACT_APP_STRIPE_KEY: "pk_test_pdCqAUHyHdtNDQRsHZMEurmC",
    REACT_APP_SENTRY_ENVIRONMENT: "development"
};

export default localConfig;
