import {
    ACCEPT_PARTNERSHIP_ERROR,
    ACCEPT_PARTNERSHIP_PENDING,
    ACCEPT_PARTNERSHIP_SUCCESS,
    DELETE_PARTNERSHIP_ERROR,
    DELETE_PARTNERSHIP_PENDING,
    DELETE_PARTNERSHIP_SUCCESS,
    FETCH_PARTNERSHIPS_ERROR,
    FETCH_PARTNERSHIPS_PENDING,
    FETCH_PARTNERSHIPS_SUCCESS,
    SEND_PARTNERSHIP_ERROR,
    SEND_PARTNERSHIP_PENDING,
    SEND_PARTNERSHIP_SUCCESS
} from "./types";

import client from './';

export const fetchPartnerships = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_PARTNERSHIPS_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/partnerships`)
                .then(response => {
                    dispatch({ type: FETCH_PARTNERSHIPS_SUCCESS, payload: response.data.partnerships });
                    resolve();
                }).catch((err) => {
                dispatch({
                    type: FETCH_PARTNERSHIPS_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
                reject();
            });
        });
    };
};

export const sendPartnership = (data) => {
    return (dispatch) => {
        dispatch({ type: SEND_PARTNERSHIP_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/partnership`, data)
                .then(response => {
                    dispatch({ type: SEND_PARTNERSHIP_SUCCESS, payload: response.data.partnerships });
                    resolve();
                }).catch((err) => {
                dispatch({
                    type: SEND_PARTNERSHIP_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
                reject();
            });
        });
    };
};

export const acceptPartnership = (id) => {
    return (dispatch) => {
        dispatch({ type: ACCEPT_PARTNERSHIP_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/partnership/${id}`)
                .then(response => {
                    dispatch({ type: ACCEPT_PARTNERSHIP_SUCCESS, payload: response.data.partnerships });
                    resolve();
                }).catch((err) => {
                dispatch({
                    type: ACCEPT_PARTNERSHIP_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
                reject();
            });
        });
    };
};

export const deletePartnership = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_PARTNERSHIP_PENDING});
        return new Promise((resolve, reject) => {
            client.delete(`/provider/partnership/${id}`)
                .then(response => {
                    dispatch({ type: DELETE_PARTNERSHIP_SUCCESS, payload: response.data.partnerships });
                    resolve();
                }).catch((err) => {
                dispatch({
                    type: DELETE_PARTNERSHIP_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
                reject();
            });
        });
    };
};
