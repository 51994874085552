import client from './'

/* action types */

export const FETCH_FEED_PENDING = "FETCH_FEED_PENDING";
export const FETCH_FEED_SUCCESS = "FETCH_FEED_SUCCESS";
export const FETCH_FEED_ERROR = "FETCH_FEED_ERROR";

export const CREATE_FEED_ITEM_PENDING = "CREATE_FEED_ITEM_PENDING";
export const CREATE_FEED_ITEM_SUCCESS = "CREATE_FEED_ITEM_SUCCESS";
export const CREATE_FEED_ITEM_ERROR = "CREATE_FEED_ITEM_ERROR";

export const UPDATE_FEED_ITEM_PENDING = "UPDATE_FEED_ITEM_PENDING";
export const UPDATE_FEED_ITEM_SUCCESS = "UPDATE_FEED_ITEM_SUCCESS";
export const UPDATE_FEED_ITEM_ERROR = "UPDATE_FEED_ITEM_ERROR";

export const DELETE_FEED_ITEM_PENDING = "DELETE_FEED_ITEM_PENDING";
export const DELETE_FEED_ITEM_SUCCESS = "DELETE_FEED_ITEM_SUCCESS";
export const DELETE_FEED_ITEM_ERROR = "DELETE_FEED_ITEM_ERROR";

export const RESET_FEED_PENDING = "FETCH_FEED_PENDING";
export const RESET_FEED_SUCCESS = "FETCH_FEED_SUCCESS";
export const RESET_FEED_ERROR = "FETCH_FEED_ERROR";

export const UPDATE_FEED_ORDER_PENDING = "UPDATE_FEED_ITEM_ORDER_PENDING";
export const UPDATE_FEED_ORDER_SUCCESS = "UPDATE_FEED_ITEM_ORDER_SUCCESS";
export const UPDATE_FEED_ORDER_ERROR = "UPDATE_FEED_ITEM_ORDER_ERROR";

export const UPLOAD_FEED_MEDIA_PENDING = "UPLOAD_FEED_MEDIA_PENDING";
export const UPLOAD_FEED_MEDIA_SUCCESS = "UPLOAD_FEED_MEDIA_SUCCESS";
export const UPLOAD_FEED_MEDIA_ERROR = "UPLOAD_FEED_MEDIA_ERROR";

/* Categories */

export const FETCH_FEED_CATEGORIES_SUCCESS = "FETCH_FEED_CATEGORIES_SUCCESS";

export const CREATE_FEED_CATEGORY_PENDING = "CREATE_FEED_CATEGORY_PENDING";
export const CREATE_FEED_CATEGORY_ERROR = "CREATE_FEED_CATEGORY_ERROR";

export const UPDATE_FEED_CATEGORY_PENDING = "UPDATE_FEED_CATEGORY_PENDING";
export const UPDATE_FEED_CATEGORY_SUCCESS = "UPDATE_FEED_CATEGORY_SUCCESS";
export const UPDATE_FEED_CATEGORY_ERROR = "UPDATE_FEED_CATEGORY_ERROR";

export const DELETE_FEED_CATEGORY_PENDING = "DELETE_FEED_CATEGORY_PENDING";
export const DELETE_FEED_CATEGORY_ERROR = "DELETE_FEED_CATEGORY_ERROR";

/* action functions */

export const fetchFeed = () => {
    return (dispatch) => {
        dispatch({type: FETCH_FEED_PENDING});
        return new Promise((resolve, reject) => {
            client.get('/provider/feed')
                .then(response => {
                    dispatch({
                        type: FETCH_FEED_SUCCESS,
                        payload: response.data.feed
                    });
                    dispatch({
                        type: FETCH_FEED_CATEGORIES_SUCCESS,
                        payload: response.data.feed_categories
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: FETCH_FEED_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const createFeedItem = (feedItem) => {
    return (dispatch) => {
        dispatch({type: CREATE_FEED_ITEM_PENDING});
        return new Promise((resolve, reject) => {
            client.post('/provider/feed', feedItem)
                .then(response => {
                    dispatch({
                        type: FETCH_FEED_SUCCESS,
                        payload: response.data.feed
                    });
                    dispatch({
                        type: FETCH_FEED_CATEGORIES_SUCCESS,
                        payload: response.data.feed_categories
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: CREATE_FEED_ITEM_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const updateFeedItem = (feedItem) => {
    return (dispatch) => {
        dispatch({type: UPDATE_FEED_ITEM_PENDING});
        return new Promise((resolve, reject) => {
            client.patch(`/provider/feed/${feedItem.id}`, feedItem)
                .then(response => {
                    dispatch({
                        type: UPDATE_FEED_ITEM_SUCCESS,
                        payload: response.data.feed_item
                    });
                    resolve(response.data.feed_item);
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_FEED_ITEM_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const deleteFeedItem = (feedItemId) => {
    return (dispatch) => {
        dispatch({type: DELETE_FEED_ITEM_PENDING});
        return new Promise((resolve, reject) => {
            client.delete(`/provider/feed/${feedItemId}`)
                .then(response => {
                    dispatch({
                        type: DELETE_FEED_ITEM_SUCCESS,
                        payload: feedItemId
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_FEED_ITEM_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const updateFeedOrder = (orderArray) => {
    return (dispatch) => {
        dispatch({type: UPDATE_FEED_ORDER_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/feed/order`, { order: orderArray })
                .then(response => {
                    dispatch({
                        type: UPDATE_FEED_ORDER_SUCCESS,
                        payload: response.data.feed
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_FEED_ORDER_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const resetFeed = () => {
    return (dispatch) => {
        dispatch({type: RESET_FEED_PENDING});
        return new Promise((resolve, reject) => {
            client.delete(`/provider/feed`)
                .then(response => {
                    dispatch({
                        type: RESET_FEED_SUCCESS,
                        payload: response.data.feed
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: RESET_FEED_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const uploadFeedItemMedia = (feedItemId, data) => {
    return (dispatch) => {
        dispatch({type: UPLOAD_FEED_MEDIA_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/feed/${feedItemId}/media`, data)
                .then(response => {
                    dispatch({
                        type: UPLOAD_FEED_MEDIA_SUCCESS,
                        payload: response.data.media
                    });
                    resolve(response.data.media);
                })
                .catch(err => {
                    dispatch({
                        type: UPLOAD_FEED_MEDIA_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const createFeedCategory = (feedCategory) => {
    return (dispatch) => {
        dispatch({type: CREATE_FEED_CATEGORY_PENDING});
        return new Promise((resolve, reject) => {
            client.post('/provider/feed/category', feedCategory)
                .then(response => {
                    dispatch({
                        type: FETCH_FEED_SUCCESS,
                        payload: response.data.feed
                    });
                    dispatch({
                        type: FETCH_FEED_CATEGORIES_SUCCESS,
                        payload: response.data.feed_categories
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: CREATE_FEED_CATEGORY_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const updateFeedCategory = (feedCategory) => {
    return (dispatch) => {
        dispatch({type: UPDATE_FEED_CATEGORY_PENDING});
        return new Promise((resolve, reject) => {
            client.patch(`/provider/feed/category/${feedCategory.id}`, feedCategory)
                .then(response => {
                    dispatch({
                        type: UPDATE_FEED_CATEGORY_SUCCESS,
                        payload: response.data.feed_item_category
                    });
                    resolve(response.data.feed_item_category);
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_FEED_CATEGORY_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};

export const deleteFeedCategory = (feedCategory, reassignment = null) => {
    return (dispatch) => {
        dispatch({type: DELETE_FEED_CATEGORY_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/feed/category/${feedCategory.id}`, {
                reassignment: reassignment
            })
                .then(response => {
                    dispatch({
                        type: FETCH_FEED_SUCCESS,
                        payload: response.data.feed
                    });
                    dispatch({
                        type: FETCH_FEED_CATEGORIES_SUCCESS,
                        payload: response.data.feed_categories
                    });
                    resolve();
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_FEED_CATEGORY_ERROR,
                        payload: 'An error occurred'
                    });
                    reject();
                });
        });
    }
};
