import React, {PureComponent} from 'react';
import {Button, Col, Divider, Form, Icon, Input, message, Row, Spin, Table} from 'antd'
import {connect} from 'react-redux';
import * as subaccountsActions from "../../actions/subaccounts";

import AddForm from '../../components/team/AddForm';

import {
    DELETE_SUBACCOUNT_PENDING, FETCH_DASHBOARD_PENDING,
    FETCH_SUBACCOUNTS_PENDING,
    UPDATE_SUBACCOUNT_PENDING
} from "../../actions/types";
import UnlockFeature from "../../components/auth/UnlockFeature";
import queryString from "query-string";
import {Link} from "react-router-dom";

const FormItem = Form.Item;

const getValue = obj =>
    Object.keys(obj)
        .map(key => obj[key])
        .join(',');

class Manage extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            /* Add Modal */
            addModalVisible: false,
            /* Table Data */
            tableData: {},
            /* Search & Filters */
            search: '',
            page: 1,
            sort_by: 'total_savers',
            sort_order: 'descend',
            /* Table defaults */
            page_size: 20,
        }

    }

    componentDidMount() {
        this.processUrl()
            .then(() => {
                this.fetchItems();
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.location.search !== this.props.location.search) {
            this.processUrl()
                .then(() => {
                    this.fetchItems();
                });
        }

    }

    processUrl = async () => {

        const values = queryString.parse(this.props.location.search);

        return new Promise((resolve, reject) => {

            const validatedValues = {
                search: '',
                page: 1,
                sort_by: 'created_at',
                sort_order: 'descend',
                ...values,
            };

            this.setState({ ...validatedValues }, () => { resolve() });

        });

    };

    fetchItems = () => {

        const { search, page, sort_by, sort_order } = this.state;

        const params = {
            search,
            page,
            sort_by,
            sort_order,
        };

        this.props.fetchSubaccounts({ ...params })
            .then((data) => {
                this.setState({ tableData: { ...data }});
            });
    };

    /* Filter handling */

    updateFilters = (data) => {

        const currentQueryValues = queryString.parse(this.props.location.search);
        const searchParameters = { ...currentQueryValues, ...data};

        for (let propName in searchParameters) {
            if (searchParameters[propName] === null || searchParameters[propName] === undefined) {
                delete searchParameters[propName];
            }
        }

        this.props.history.replace({
            search: "?" + new URLSearchParams(searchParameters).toString()
        });

    };

    handleStandardTableChange = (pagination, filtersArg, sorter) => {

        this.updateFilters({
            page: pagination.current,
            sort_by: sorter.field,
            sort_order: sorter.order,
        });

    };

    handleSearch = e => {
        e.preventDefault();

        this.props.form.validateFields((err, fieldsValue) => {

            if (err) return;

            this.updateFilters({ search: fieldsValue['search'] });

        });
    };

    handleDelete = e => { //delete selected user

        this.props.deleteSubaccount({
                id: e.id
            })
            .then(() => {
                message.success('Provider has been deleted.');
                this.fetchItems();
            })
            .catch()
            .finally();
    };

    /* Render Table */

    renderColumns = () => {

        return [
            {
                title: 'Name',
                dataIndex: 'firstname',
                render: (val, record) => {
                    return (
                        <div className={'avatar--container'}>
                            <div className={`avatar ${(record.avatar && record.avatar.file) ? 'image' : ''}`}>
                                {
                                    record.avatar && record.avatar.file &&
                                    <img src={record.avatar.file} alt=""/>
                                }
                                {
                                    (!record.avatar || !record.avatar.file) &&
                                    <span>
                                        { record.firstname.charAt(0) }
                                        { record.lastname.charAt(0) }
                                    </span>
                                }

                            </div>
                            <Link to={`/team/manage/${record.id}`}>{record.firstname} {record.lastname}</Link>
                        </div>
                    )
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: "Total Savers",
                defaultSortOrder: 'descend',
                dataIndex: 'total_savers',
                sorter: true,
            },
        ];

    };

    /* Modals */

    openAddModal = () => {
        this.setState({addModalVisible: true});
    };

    closeAddModal = () => {
        this.setState({addModalVisible: false});
    };

    onAddSuccess = () => {
        this.closeAddModal();
        this.fetchItems();
    };

    render() {

        const { subaccounts, form: { getFieldDecorator} } = this.props;
        const { tableData, search, page, page_size } = this.state;

        const action = subaccounts.action;
        const type = (action && action.type) ? action.type : null;

        const showHeader = !!(tableData.meta && tableData.meta.total && tableData.meta.total > 0);

        return (
            <div className={"page page-subaccounts"}>
                <Row>
                    <Col xs={24} xl={16}>
                        <div className={'title-module'}>
                            <h1><Icon type="team" /> Manage Team</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <UnlockFeature>
                    <Row>
                        <Col xs={24}>
                            <div className={'digs-table'}>
                                <div className='table-form'>
                                    <Form onSubmit={this.handleSearch} layout="inline">
                                        <FormItem>
                                            {getFieldDecorator('search', {
                                                initialValue: search,
                                            })(
                                                <Input
                                                    className={'search'}
                                                    placeholder="Search Everyone..."
                                                    size="large"
                                                    prefix={<Icon type="search" style={{ color: '#222222' }} />}
                                                    suffix={<Button htmlType="submit"><Icon type="arrow-right" style={{ color: '#5285FF' }} /></Button>}
                                                />
                                            )}
                                        </FormItem>
                                    </Form>
                                    <Button icon="plus" type="primary" onClick={() => this.openAddModal()}>
                                        Add Team Member
                                    </Button>
                                </div>
                                <Table
                                    showHeader={showHeader}
                                    loading={{
                                        spinning: (
                                            type === FETCH_SUBACCOUNTS_PENDING ||
                                            type === UPDATE_SUBACCOUNT_PENDING ||
                                            type === DELETE_SUBACCOUNT_PENDING
                                        ),
                                        indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                                    }}
                                    dataSource={tableData.data}
                                    columns={this.renderColumns()}
                                    rowKey={(record) => record.id}
                                    pagination={{
                                        defaultCurrent: 1,
                                        current: (tableData.meta && tableData.meta.current_page) ? tableData.meta.current_page : 1,
                                        pageSize: page_size,
                                        total: (tableData.meta && tableData.meta.total) ? tableData.meta.total : 0,
                                        showSizeChanger: false,
                                        showQuickJumper: false,
                                    }}
                                    scroll={{x: true}}
                                    onChange={this.handleStandardTableChange}
                                />
                            </div>
                            <AddForm
                                visible={this.state.addModalVisible}
                                onCancel={this.closeAddModal}
                                onSuccess={this.onAddSuccess}
                            />
                        </Col>
                    </Row>
                </UnlockFeature>
            </div>
        );
    }

}

const mapStateToProps = ({ subaccounts, user }) => ({ subaccounts, user: user.user });
const mapDispatchToProps = {
    ...subaccountsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Manage));
