import React, {Component} from 'react'
import {Card, Col, Divider, Form, Icon, Input, Row, Radio, Button, Spin, Switch, message} from 'antd';
import { fetchRewards, saveRewards } from "../../actions/rewards";
import {connect} from "react-redux";
import isEqual from 'lodash/isEqual';
import UnlockFeature from "../../components/auth/UnlockFeature";
import {milestonesOrderedSelector} from "../../reducers/milestones";

const DEFAULT_CONFIG = {
    name: 'Homebuyer Credits',
    type: 'ONE_TIME',
    reward_total: 500,
    balance_minimum: 0,
    amount_saved: 1,
    amount_rewarded: 2,
    active: false,
    disclaimer: 'These are funds that you have earned from Digs towards your future home purchase. They are reserved for your real estate transaction only and can be redeemed at closing through our network of lenders.'
};

class Rewards extends Component {

    constructor(props) {
        super(props);

        this.state = {
            config: DEFAULT_CONFIG,
            historical: DEFAULT_CONFIG,
            loading: false,
            saving: false,
        };

    }

    componentDidMount() {

        this.setState({ loading: true });

        this.props.fetchRewards()
            .then(config => {
                if(config) {
                    this.setState({ config, historical: config });
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.setState({ loading: false });
            })

    }

    handleOnSubmit = () => {
        this.props.form.validateFields((err, fields) => {
            if (!err) {

                this.setState({ saving: true });

                const validConfig = { ...DEFAULT_CONFIG, ...fields};
                message.loading('Updating rewards settings..', 0);

                this.props.saveRewards(validConfig)
                    .then(config => {
                        this.setState({ config, historical: config }, () => {
                            this.props.form.setFieldsValue(config);
                            message.destroy();
                            message.success('Rewards saved successfully!');
                        });
                    })
                    .catch(err => {
                        message.destroy();
                        message.error('An error has occurred.');
                    })
                    .finally(() => {
                        this.setState({ saving: false });
                    });

            }
        });
    };

    handleOnReset = () => {
        this.setState({ config: this.state.historical }, () => {
            this.props.form.setFieldsValue(this.state.historical);
        });
    };

    hasChanged = () => {
        const fieldValues = this.props.form.getFieldsValue();
        const alteredConfig = { ...this.state.config, ...fieldValues};
        return !isEqual(alteredConfig, this.state.historical);
    };

    isValid = () => {

        const fieldErrors = this.props.form.getFieldsError();
        let valid = true;

        for(const error in fieldErrors) {
            if(fieldErrors.hasOwnProperty(error)) {
                if(fieldErrors[error]) {
                    valid = false;
                }
            }

        }


        return valid;

    };

    render () {

        const { config: { name, type, reward_total, balance_minimum, amount_saved, amount_rewarded, active, disclaimer }, loading, saving } = this.state;
        const { form: { getFieldDecorator }, user } = this.props;
        const hasChanged = this.hasChanged();
        const isValid = this.isValid();
        const isSubaccount = !user.is_parent_provider && user.provider_id;

        return (
            <Form colon={false} hideRequiredMark={true} className="page page-rewards">
                <Row>
                    <Col xs={24} xl={16} className={'column-max-width'}>
                        <div className={'title-module'}>
                            <h1><Icon type="star" /> Rewards</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <UnlockFeature>
                    <Row gutter={24}>
                        {
                            loading &&
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '100px'}}>
                                <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#00E2A7' }} spin />} />
                            </div>
                        }
                        {
                            !loading &&
                            <>
                                <Col xs={24} xl={16}>
                                    <
                                        Card
                                        className={isSubaccount ? 'locked' : ''}
                                        title={
                                            <div className={'card--header'}>
                                                <span>Reward Settings</span>
                                                <span className={'switch'}>
                                                {
                                                    active ? 'Enabled' : 'Disabled'
                                                }
                                                    {
                                                        getFieldDecorator('active', {
                                                            initialValue: active,
                                                            onChange: (val) => { this.setState({ config: { ...this.state.config, active: val} }) }
                                                        })(<Switch checked={active} disabled={isSubaccount}/>)
                                                    }
                                            </span>
                                            </div>
                                        }
                                        bordered={false}
                                    >
                                        {
                                            isSubaccount &&
                                            <div className="card--inner-status">
                                                <div className={'text'}><span>You are in read only mode.</span> Only your system admin can edit rewards.</div>
                                            </div>
                                        }
                                        <div className="card--inner">
                                            <Form.Item>
                                                {getFieldDecorator('type', {
                                                    rules: [{ required: true, message: 'Please input your email!' }],
                                                    initialValue: type,
                                                    onChange: (e) => { this.setState({ config: { ...this.state.config, type: e.target.value} }) }
                                                })(
                                                    <Radio.Group disabled={saving || isSubaccount}>
                                                        <Radio value={'ONE_TIME'}>One-Time Reward</Radio>
                                                        <Radio value={'MATCHING'}>Matching</Radio>
                                                    </Radio.Group>
                                                )}
                                                {
                                                    type === 'ONE_TIME' &&
                                                    <p className={'subtext'} style={{marginBottom: 0}}>A one-time reward is given to your client when they sign up for Digs. However, it can only be redeemed via the terms you set with your client.</p>
                                                }
                                                {
                                                    type === 'MATCHING' &&
                                                    <p className={'subtext'} style={{marginBottom: 0}}>Matching is a great way to increase engagement and incentivize savings. </p>
                                                }
                                            </Form.Item>
                                            {
                                                type === 'ONE_TIME' &&
                                                <div className={'match--container solo'}>
                                                    <div className={'match--column'}>
                                                        <div className="ant-form-item-label">
                                                            <label className="ant-form-item-no-colon" title="Reward Amount">Reward Amount</label>
                                                        </div>
                                                        <div className="match--row">
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator('reward_total', {
                                                                        rules: [{ required: true, type: 'number', min: 0.01, message: 'Please provide a reward amount.', transform: (val) => Number(val), whitespace: true }],
                                                                        initialValue: reward_total,
                                                                    })(<Input prefix="$" style={{maxWidth: '100%'}} disabled={saving || isSubaccount} />)
                                                                }
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={'match--column'}>
                                                        <div className="ant-form-item-label">
                                                            <label className="ant-form-item-no-colon" title="Min. Saver Balance">Min. Saver Balance</label>
                                                        </div>
                                                        <div className="match--row">
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator('balance_minimum', {
                                                                        rules: [{ required: true, type: 'number', min: 0, message: 'Please provide a minimum.', transform: (val) => Number(val), whitespace: true }],
                                                                        initialValue: balance_minimum,
                                                                    })(<Input prefix="$" style={{maxWidth: '100%'}} disabled={saving || isSubaccount} />)
                                                                }
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                type === 'MATCHING' &&
                                                <div className={'match--container'}>
                                                    <div className={'match--column'}>
                                                        <div className="ant-form-item-label">
                                                            <label className="ant-form-item-no-colon" title="Reward Disclaimer">Matching Ratio</label>
                                                        </div>
                                                        <div className="match--row">
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator('amount_saved', {
                                                                        rules: [{ required: true, type: 'number', min: 0.01, message: 'Please provide a valid match ratio.', transform: (val) => Number(val), whitespace: true }],
                                                                        initialValue: amount_saved,
                                                                    })(<Input prefix="$" addonAfter="Saved" disabled={saving || isSubaccount} />)
                                                                }
                                                            </Form.Item>
                                                            <span className={'seperator'}> : </span>
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator('amount_rewarded', {
                                                                        rules: [{ required: true, type: 'number', min: 0.01, message: 'Please provide a valid match ratio.', transform: (val) => Number(val), whitespace: true }],
                                                                        initialValue: amount_rewarded,
                                                                    })(<Input prefix="$" addonAfter="Rewarded" disabled={saving || isSubaccount} />)
                                                                }
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={'match--column'}>
                                                        <div className="ant-form-item-label">
                                                            <label className="ant-form-item-no-colon" title="Reward Cap">Reward Cap</label>
                                                        </div>
                                                        <div className="match--row">
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator('reward_total', {
                                                                        rules: [{ required: true, type: 'number', min: 0.01, message: 'Please provide a reward cap.', transform: (val) => Number(val), whitespace: true }],
                                                                        initialValue: reward_total,
                                                                    })(<Input prefix="$" disabled={saving || isSubaccount} />)
                                                                }
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <Form.Item label={'Reward Name'} className={'reward-name'}>
                                                <p className={'subtext'}>This is how your credits will be referred to within client applications. (ex. "Homebuyer Credits")</p>
                                                {
                                                    getFieldDecorator('name', {
                                                        rules: [{ required: true, message: 'Please provide a reward name.', whitespace: true }],
                                                        initialValue: name,
                                                    })(<Input disabled={saving || isSubaccount} />)
                                                }
                                            </Form.Item>
                                            <Form.Item label={'Reward Disclaimer'}>
                                                <p className={'subtext'}>This is the disclaimer your client will see when they read about your reward.</p>
                                                {
                                                    getFieldDecorator('disclaimer', {
                                                        rules: [{ required: true, message: 'Please provide a disclaimer.', whitespace: true }],
                                                        initialValue: disclaimer,
                                                    })(<Input.TextArea rows={8} disabled={saving || isSubaccount}/>)
                                                }
                                            </Form.Item>
                                        </div>
                                        <div className="card--footer">
                                            <div className="card--footer-left">
                                                <Button onClick={this.handleOnReset} disabled={!hasChanged || saving || isSubaccount}>Discard Changes</Button>
                                            </div>
                                            <div className="card--footer-right">
                                                <Button type={'primary'} onClick={this.handleOnSubmit} loading={saving} disabled={!hasChanged || saving || !isValid || isSubaccount}>Save</Button>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} xl={8}>
                                    <
                                        Card
                                        title={'About Rewards'}
                                        bordered={false}
                                    >
                                        <div className="card--inner">
                                            {
                                                active !== this.state.historical.active &&
                                                <p className={'alert'}>Rewards are not { active ? 'enabled' : 'disabled' } until you hit save below.</p>
                                            }
                                            <p><b>Rewards are currently turned { active ? 'on' : 'off'}</b>. Please read and agree to Digs’ <a href="https://digs.co/terms/" target="_blank">terms and conditions</a> before saving your rewards.</p>
                                            <h5>Why give rewards?</h5>
                                            <p>Rewards are a great way to incentivize clients to purchase their home with you rather than someone else.</p>
                                            <h5>Who gives the rewards?</h5>
                                            <p>Rewards are closing cost credits given by you to your clients. You are responsible for honoring any rewards you give in Digs.</p>
                                        </div>
                                    </Card>
                                </Col>
                            </>
                        }
                    </Row>
                </UnlockFeature>
            </Form>
        )
    }

}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

const mapDispatchToProps = {
    fetchRewards,
    saveRewards
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Rewards));
