import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as userActions from "../../../actions/user";
import {Button, Col, Form, Icon, Input, Row, Tooltip} from "antd";
import {UPDATE_USER_PENDING} from "../../../actions/types";

const { TextArea } = Input;

class Biography extends Component {

    handleOnSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.updateUser(values)
                    .then(() => {
                        this.props.history.push('/onboarding/prospects');
                    });
            }
        });
    };

    render() {

        const { user, form } = this.props;
        const { getFieldDecorator } = form;
        const { action } = this.props;
        const type = (action && action.type) ? action.type : null;

        return (
            <div className={'page-onboarding page-onboarding-biography fadeIn'}>
                <Row gutter={24} type="flex" justify="center">
                    <Col xs={24} md={10} xl={7}>
                        <div className={'title-block'}>
                            <h2>Biography & Reviews</h2>
                            <p>This is your chance to tell your prospects more about your expertise and include other helpful information like customer reviews and social media links.</p>
                            <Link to={`/onboarding/prospects`}>Skip this for now</Link>
                        </div>
                    </Col>
                    <Col xs={24} md={14} xl={11}>
                        <Form colon={false} className={'bio-form'}>
                            <Form.Item label={'Professional Biography'}>
                                {
                                    getFieldDecorator('short_bio', {
                                        initialValue: user.short_bio ? user.short_bio : null
                                    })(
                                        <TextArea rows={4} />
                                    )
                                }
                            </Form.Item>
                            <Form.Item label={`${user.agent_lender === 'Agent' ? 'MLS Number' : 'NMLS Number'}`}>
                                {
                                    getFieldDecorator('mls', {
                                        initialValue: user.mls ? user.mls : null
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                            <Form.Item label={<span>
                                LinkedIn Profile &nbsp;
                                    <Tooltip title={
                                        <span>
                                            Need help finding it?<br />
                                            <a
                                                href={'https://www.linkedin.com/help/linkedin/answer/49315/finding-your-linkedin-public-profile-url?lang=en'}
                                                target={'_blank'}
                                            >Learn more here.</a>
                                        </span>}>
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>}>
                                {
                                    getFieldDecorator('linkedin_url', {
                                        initialValue: user.linkedin_url ? user.linkedin_url : null
                                    })(
                                        <Input placeholder={'https://www.linkedin.com/in/username'}/>
                                    )
                                }
                            </Form.Item>
                            <Form.Item label={<span>
                                Zillow Profile &nbsp;
                                <Tooltip title={
                                    <span>Open your Zillow page in a browser and copy and paste the URL. The format should look something like this: <i>https://www/zillow.com/profile/Jamie-Smith</i></span>
                                }>
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>}>
                                {
                                    getFieldDecorator('zillow_url', {
                                        initialValue: user.zillow_url ? user.zillow_url : null
                                    })(
                                        <Input placeholder={'https://www.zillow.com/profile/'}/>
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    htmlType={'submit'}
                                    type={'primary'}
                                    className={'button primary'}
                                    onClick={() => { this.handleOnSubmit() }}
                                    loading={ type === UPDATE_USER_PENDING }
                                >Save & Continue</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user: user.user, errors: user.errors, action: user.action });
const mapDispatchToProps = {
    ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(Biography)));
