import React, {Component} from 'react';
import {Button, Card, Form, Input, Modal, Table} from 'antd'
import {connect} from 'react-redux';
import * as referralsActions from "../actions/referrals";
import moment from 'moment';
import {FETCH_REFERRALS_PENDING,} from "../actions/types";

const FormItem = Form.Item;
const getValue = obj =>
    Object.keys(obj)
        .map(key => obj[key])
        .join(',');

class InboundReferrals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userModal:false,
            userInfo: {},
        }

    }

    componentDidMount() {
        this.props.fetchReferrals();
    }

    handleStandardTableChange = (pagination, filtersArg, sorter) => {
        const { formValues } = this.state;

        const filters = Object.keys(filtersArg).reduce((obj, key) => {
            const newObj = { ...obj };
            newObj[key] = getValue(filtersArg[key]);
            return newObj;
        }, {});

        const params = {
            page: pagination.current,
            pageSize: pagination.pageSize,
            ...formValues,
            ...filters,
        };
        if (sorter.field) {
            params.sorter = `${sorter.field}_${sorter.order}`;
        }

        this.props.fetchReferrals(params);
    };

    handleSearch = e => {
        e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (err) return;

            const values = {
                ...fieldsValue,
                updatedAt: fieldsValue.updatedAt && fieldsValue.updatedAt.valueOf(),
            };

            this.setState({
                formValues: values,
            });

            this.props.fetchReferrals(values);

        });
    };

    renderForm() {
        const {
            form: { getFieldDecorator },
        } = this.props;
        return (
            <Form onSubmit={this.handleSearch} layout="inline">
                <FormItem label="Name">
                    {getFieldDecorator('name')(<Input placeholder="Name" />)}
                </FormItem>
                <FormItem label="Email">
                    {getFieldDecorator('email')(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit">Search</Button>
                </FormItem>
            </Form>
        );
    }

    render() {

        const { referrals } = this.props;

        const action = referrals.action;
        const type = (action && action.type) ? action.type : null;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'firstname',
                render: (val, record) => <a onClick={()=>{this.setState({userInfo: record, userModal:true})}} href="javascript:void(0)">{record.firstname} {record.lastname}</a>,
            },
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: 'Member Since',
                dataIndex: 'created_at',
                render: val => <span>{moment(val).format('MMM DD, YYYY')}</span>,
            },
            {
                title: 'Tracking',
                dataIndex: 'tracking_param',
            },
            {
                title: 'Active',
                dataIndex: 'is_deleted',
                render: val => (val === 1) ? 'No' : 'Yes',
            },
        ];

        return (
            <div className="page-inbound-referrals">
                <Card bordered={false}>
                    <div className='table-form'>
                        {this.renderForm()}
                    </div>
                    {referrals && <Table
                        rowKey={record => record.id}
                        dataSource={referrals.data.data}
                        pagination={{
                            showSizeChanger: false,
                            showQuickJumper: false,
                            pageSize: referrals.data.per_page,
                            total: referrals.data.total
                        }}
                        loading={type === FETCH_REFERRALS_PENDING}
                        columns={columns}
                        onChange={this.handleStandardTableChange}
                    />}
                </Card>
                <Modal
                    title="User Info"
                    closable={true}
                    onCancel={()=>{this.setState({userModal:false, userInfo:{}})}}
                    visible={this.state.userModal}
                    footer={null}
                >
                    <p>Name: {this.state.userInfo.firstname} {this.state.userInfo.lastname}</p>
                    <p>Email: {this.state.userInfo.email}</p>
                    <p>Mobile: {this.state.userInfo.mobile}</p>
                    <p>Address: {this.state.userInfo.city}</p>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ referrals }) => ({ referrals });
const mapDispatchToProps = {
    ...referralsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InboundReferrals));
