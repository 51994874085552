import React, {Component} from "react";
import {Button, Card, Modal,} from "antd";
import client from '../../../actions'
import moment from 'moment';
import {Elements} from "react-stripe-elements";
import StripeForm from "./StripeForm";
import * as userActions from "../../../actions/user";
import {connect} from "react-redux";
import creditCardImages from "react-payment-inputs/es/images";
import {Link} from "react-router-dom";

class SubscriptionModule extends Component {

    constructor(props){
        super(props);

        this.state = {
            subscription: {},
            pauseSubscriptionModal: false,
            updatingSubscription: false,
            loadingSubscriptions: false,
        }
    }

    componentDidMount() {
        this.fetchSubscriptions();
    }

    fetchSubscriptions() {

        this.setState({ loadingSubscriptions: true });

        client.get(`/provider/subscription`)
            .then(({ data }) => {
                this.setState({
                    loadingSubscriptions: false,
                    subscription: data.subscription,
                });

            });

    }

    renderSubscriptionDetails = () => {
        const { subscription, updatingSubscription } = this.state;

        return (
            <div className={'subscription-details'}>
                {
                    subscription &&
                    <div>
                        <ul>
                            <li><b>Status:</b> <span className={'badge'}>{ subscription.stripe_status ? subscription.stripe_status : '-'}</span></li>
                            <li><b>Plan:</b> { subscription.stripe_price ? subscription.stripe_price : '-' }</li>
                            <li><b>Start Date:</b> { subscription.created_at ? moment(subscription.created_at).format('ddd, MMM DD, YYYY') : '-'}</li>
                            {
                                subscription.stripe_status === 'active' && !subscription.ends_at &&
                                <li><b>Next Billing Cycle:</b> {subscription.current_period_end ? moment(subscription.current_period_end).format('ddd, MMM DD, YYYY') : '-'}
                                </li>
                            }
                            {
                                subscription.stripe_status === 'active' && subscription.ends_at &&
                                <li className={'warning'}><b>Next Billing Cycle:</b> {subscription.current_period_end ? moment(subscription.current_period_end).format('ddd, MMM DD, YYYY') : '-'} (Currently Paused)</li>
                            }
                        </ul>
                        {
                            subscription.stripe_status === 'active' && !subscription.ends_at && (subscription.name !== 'free' && subscription.name !== 'legacy') &&
                            <div>
                                <Button loading={updatingSubscription} disabled={updatingSubscription} onClick={() => this.setState({pauseSubscriptionModal: true})}>
                                    Pause Subscription
                                </Button>
                            </div>
                        }
                        {
                            subscription.stripe_status === 'active' && subscription.ends_at && (subscription.name !== 'free' && subscription.name !== 'legacy') &&
                            <div>
                                <Button type={'primary'} loading={updatingSubscription} disabled={updatingSubscription} onClick={() => { this.resumeSubscription() }}>Resume Subscription</Button>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

    renderFreeAccountDetails = () => {
        const { user } = this.props;

        return (
            <div className={'subscription-details'}>
                <div>
                    <ul>
                        <li><b>Status:</b> <span className={'badge'}>Active</span></li>
                        <li><b>Plan:</b> Free - $0/month</li>
                        <li><b>Start Date:</b> { user.created_at ? moment(user.created_at).format('ddd, MMM DD, YYYY') : '-'}</li>
                    </ul>
                </div>
            </div>
        )
    }

    cancelSubscription = () => {

        this.setState({ updatingSubscription: true });

        client.patch(`/provider/subscription`, { action: 'cancel', subscription_name: this.state.subscription.name })
            .then(response => {
                this.setState({
                    updatingSubscription: false,
                    pauseSubscriptionModal: false,
                });
                this.fetchSubscriptions();
            });

    };

    resumeSubscription = () => {

        this.setState({ updatingSubscription: true });

        client.patch(`/provider/subscription`, { action: 'resume', subscription_name: this.state.subscription.name })
            .then(response => {
                this.setState({
                    updatingSubscription: false,
                    pauseSubscriptionModal: false,
                });
                this.fetchSubscriptions();
            });

    };

    getCardImageProps = (cardBrand) => {

        const images = creditCardImages || {};

        return {
            'aria-label': cardBrand ? `${cardBrand[0].toUpperCase() + cardBrand.slice(1)} card` : 'Placeholder card',
            children: images[cardBrand],
            width: '1.5em',
            height: '1em',
            viewBox: '0 0 24 16',
        };
    };

    render() {
        const { subscription } = this.state;
        const { user } = this.props;

        return (
            <div>
                <Card
                    title={'Subscription'}
                    className={'subscription'}
                    loading={user.active_subscription && this.state.loadingSubscriptions}
                    extra={user.active_subscription ? null : <Link to={'/upgrade'} className={'button primary'}>Upgrade</Link>}
                >
                    {
                        user.active_subscription &&
                        this.renderSubscriptionDetails()
                    }
                    {
                        !user.active_subscription &&
                        this.renderFreeAccountDetails()
                    }
                </Card>
                {
                    user.active_subscription && subscription && subscription.name !== 'free' &&
                    <Card
                        title={'Payment Method'}
                        className={'payment-method'}
                        extra={
                            (user.pm_last_four && user.pm_type) &&
                            <span className={'current-method'}>{ user.pm_last_four } <svg style={{width: 30, height: 16}} {...this.getCardImageProps(user.pm_type)} /></span>
                        }
                    >
                        <Elements>
                            <StripeForm />
                        </Elements>
                    </Card>
                }
                <Modal
                    title={'Confirm subscription update'}
                    visible={this.state.pauseSubscriptionModal}
                    onCancel={() => this.setState({ pauseSubscriptionModal: false})}
                    footer={[
                        <div>
                            <Button disabled={this.state.updatingSubscription} onClick={() => this.setState({ pauseSubscriptionModal: false})}>No, thanks.</Button>
                            <Button loading={this.state.updatingSubscription} disabled={this.state.updatingSubscription} onClick={() => { this.cancelSubscription() }} type={'primary'}>Ok, pause my subscription.</Button>
                        </div>
                    ]}
                >
                    <div>
                        <p>Pausing your subscription will prevent your credit card from being charged at the next billing cycle. If your credit card is not billed or your payment method has not been updated within 7 days of the previously missed billing cycle, your account will be closed.</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user: user.user });
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionModule);
