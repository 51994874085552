import React, {Component} from 'react';
import {Card, Modal, Button, Row, Col} from "antd";
import moment from 'moment';
import DigsHelper from "../../helpers/DigsHelper";
import MiniRadialProgress from "../ui/MiniRadialProgress";
import InlineEditor from "./InlineEditor";
import ValuationModal from "./ValuationModal";
import MortgageModal from "./MortgageModal";
import config from '../../config';

const consumerAppUrl = config.REACT_APP_URL;

class OwnerPropertyCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMore: false,
            property: {},
            valuationModal: false,
            mortgageModal: false,
        }
    }

    onShowMore(e) {
        const { showMore } = this.state;

        e.preventDefault();
        this.setState({ showMore: !showMore })
    };

    handleSave = (property_id, data) => {

        return new Promise((resolve, reject) => {

            this.setState({ property: {...this.state.property, ...data}});
            this.props.handleSave(property_id, data)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });

    };

    toggleValuation = () => {
        this.setState({ valuationModal: !this.state.valuationModal});
    };

    toggleMortgage = () => {
        this.setState({ mortgageModal: !this.state.mortgageModal});
    };

    render() {

        const { showMore, valuationModal, mortgageModal } = this.state;
        const { locked } = this.props;

        const property = { ...this.props.property, ...this.state.property };

        const propertyDetails = {
            street_address: property.street_address ? property.street_address : 'No address provided',
            loan_amount: property.mortgage &&property.mortgage.loan_amount ? DigsHelper.formatMoney(property.mortgage.loan_amount, 0) : '-',
            loan_term_length: property.mortgage && property.mortgage.loan_term_length ? `${property.mortgage.loan_term_length} years` : '-',
            loan_interest_rate: property.mortgage && property.mortgage.loan_interest_rate ? `${property.mortgage.loan_interest_rate}%` : '-',
            estimated_home_value:  property.estimated_home_value ? DigsHelper.formatMoney(property.estimated_home_value, 0) : <i className={'icon-caution'} style={{color: '#FFB930', fontSize: 27}} />,
            purchase_price:  property.purchase_price ? DigsHelper.formatMoney(property.purchase_price, 0) : '-',
            purchase_date: property.purchase_date ? moment(property.purchase_date).format('MMM D, YYYY') : '-',
            loan_origination_date: property.mortgage && property.mortgage.loan_origination_date ? moment(property.mortgage.loan_origination_date).format('MMM D, YYYY') : '-',
            loan_balance: property.loan_balance ? DigsHelper.formatMoney(property.mortgage.loan_balance, 0) : DigsHelper.formatMoney(0, 0),
            equity: property.equity ? DigsHelper.formatMoney(property.equity, 0) : DigsHelper.formatMoney(0, 0),
            city: property.city ? property.city : '-',
            state: property.state ? property.state : '-',
            zip_code: property.zip_code ? property.zip_code : '-',
            equity_progress: (Math.floor(property.equity / property.estimated_home_value * 100 ))
        };

        return (
            <>
                <Card
                    className={'card dark'}
                    bordered={false}
                >
                    <div className="card--inner">
                        <div className={'card--left'}>
                            <a href={`${consumerAppUrl}/owner/${property.uuid}`} target="_blank">
                                <h2>{ propertyDetails.street_address } <i className="icon-external-link" /></h2>
                            </a>
                            <ul className={'card-details'}>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Property Type
                                    </div>
                                    <div className="card-details--right green">
                                        Homeowner
                                    </div>
                                </li>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Loan Amount
                                    </div>
                                    <div className="card-details--right">
                                        <i className={'icon-pencil'} onClick={this.toggleMortgage}/> { propertyDetails.loan_amount }
                                    </div>
                                </li>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Interest Rate
                                    </div>
                                    <div className="card-details--right">
                                        <i className={'icon-pencil'} onClick={this.toggleMortgage}/> { propertyDetails.loan_interest_rate }
                                    </div>
                                </li>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Loan Term
                                    </div>
                                    <div className="card-details--right">
                                        <i className={'icon-pencil'} onClick={this.toggleMortgage}/> { propertyDetails.loan_term_length }
                                    </div>
                                </li>
                                <li className="card-details--row">
                                    <div className="card-details--left">
                                        Origination Date
                                    </div>
                                    <div className="card-details--right">
                                        <i className={'icon-pencil'} onClick={this.toggleMortgage}/> { propertyDetails.loan_origination_date }
                                    </div>
                                </li>
                            </ul>
                            {
                                showMore &&
                                <>
                                    <ul className={'card-details'}>
                                        <li className="card-details--row">
                                            <div className="card-details--left">
                                                City
                                            </div>
                                            <div className="card-details--right">
                                                <InlineEditor
                                                    propertyId={property.id}
                                                    value={property.city}
                                                    fieldName={'city'}
                                                    handleSave={this.handleSave}
                                                >{ propertyDetails.city }</InlineEditor>
                                            </div>
                                        </li>
                                        <li className="card-details--row">
                                            <div className="card-details--left">
                                                State
                                            </div>
                                            <div className="card-details--right">
                                                <InlineEditor
                                                    propertyId={property.id}
                                                    value={property.state}
                                                    fieldName={'state'}
                                                    handleSave={this.handleSave}
                                                >{ propertyDetails.state }</InlineEditor>
                                            </div>
                                        </li>
                                        <li className="card-details--row">
                                            <div className="card-details--left">
                                                Zip Code
                                            </div>
                                            <div className="card-details--right">
                                                <InlineEditor
                                                    propertyId={property.id}
                                                    value={property.zip_code}
                                                    fieldName={'zip_code'}
                                                    type={'number'}
                                                    handleSave={this.handleSave}
                                                >{ propertyDetails.zip_code }</InlineEditor>
                                            </div>
                                        </li>
                                        <li className="card-details--row">
                                            <div className="card-details--left">
                                                Purchase Price
                                            </div>
                                            <div className="card-details--right">
                                                <InlineEditor
                                                    propertyId={property.id}
                                                    value={property.purchase_price}
                                                    fieldName={'purchase_price'}
                                                    type={'number'}
                                                    handleSave={this.handleSave}
                                                >{ propertyDetails.purchase_price }</InlineEditor>
                                            </div>
                                        </li>
                                        <li className="card-details--row">
                                            <div className="card-details--left">
                                                Purchase Date
                                            </div>
                                            <div className="card-details--right">
                                                <InlineEditor
                                                    propertyId={property.id}
                                                    value={property.purchase_date}
                                                    fieldName={'purchase_date'} date
                                                    handleSave={this.handleSave}
                                                >{ propertyDetails.purchase_date }</InlineEditor>
                                            </div>
                                        </li>
                                    </ul>
                                </>
                            }
                            <a href={"#"} className="card-details--action" onClick={(e) => { this.onShowMore(e) }}>{ showMore ? 'See less' : 'See more'}</a>
                        </div>
                        <div className={'card--right'}>
                            <h2>Home Value</h2>
                            <h4><i className={'icon-pencil'} onClick={this.toggleValuation}/> { propertyDetails.estimated_home_value }</h4>
                            <div className={'card--ownership'}>
                                <div className="card--ownership-lockup">
                                    <div className={'card--ownership-progress'}>
                                        {
                                            locked &&
                                            <div className="card--ownership-progress-overlay">
                                                <i className="icon-lock" />
                                            </div>
                                        }
                                        <MiniRadialProgress progress={locked ? 0 : propertyDetails.equity_progress} />
                                    </div>
                                    <div className="card--ownership-details">
                                        <div className="card--ownership-details-title">Equity</div>
                                        <div className="card--ownership-details-value">{ locked ? 'Locked' : propertyDetails.equity }</div>
                                    </div>
                                </div>
                                <div className="card--ownership-divider"></div>
                                <div className="card--ownership-lockup">
                                    <div className={'card--ownership-progress'}>
                                        {
                                            locked &&
                                            <div className="card--ownership-progress-overlay">
                                                <i className="icon-lock" />
                                            </div>
                                        }
                                        <MiniRadialProgress progress={ locked ? 100 : propertyDetails.equity_progress} inverted />
                                    </div>
                                    <div className="card--ownership-details">
                                        <div className="card--ownership-details-title">Balance</div>
                                        <div className="card--ownership-details-value">{ locked ? 'Locked' : propertyDetails.loan_balance}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <ValuationModal
                    visible={valuationModal}
                    property={property}
                    toggleValuation={this.toggleValuation}
                    handleSave={this.handleSave}
                />
                <MortgageModal
                    visible={mortgageModal}
                    property={property}
                    toggleMortgage={this.toggleMortgage}
                    handleSave={this.handleSave}
                />
            </>
        );

    }

}

export default OwnerPropertyCard;
