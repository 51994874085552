import {
    FETCH_INTEGRATIONS_ERROR,
    FETCH_INTEGRATIONS_PENDING,
    FETCH_INTEGRATIONS_SUCCESS,
    FUB_CONFIG_ERROR,
    FUB_CONFIG_PENDING,
    FUB_CONNECT_ERROR,
    FUB_CONNECT_PENDING,
    FUB_DISCONNECT_ERROR,
    FUB_DISCONNECT_PENDING,
    FUB_FETCH_ERROR,
    FUB_FETCH_PENDING,
    FUB_FETCH_SUCCESS,
    FUB_SYNC_ERROR,
    FUB_SYNC_PENDING,
    FUB_SYNC_SUCCESS,
    SALESFORCE_CONFIG_ERROR,
    SALESFORCE_CONFIG_PENDING,
    SALESFORCE_CONNECT_ERROR,
    SALESFORCE_CONNECT_PENDING,
    SALESFORCE_DISCONNECT_ERROR,
    SALESFORCE_DISCONNECT_PENDING,
    SALESFORCE_FETCH_ERROR,
    SALESFORCE_FETCH_PENDING,
    SALESFORCE_FETCH_SUCCESS,
    SALESFORCE_SYNC_ERROR,
    SALESFORCE_SYNC_PENDING,
    SALESFORCE_SYNC_SUCCESS,
} from "./types";

import client from './';

/* Integrations */

export const fetchIntegrations = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_INTEGRATIONS_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/integrations`)
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                }).catch((err) => {
                dispatch({
                    type: FETCH_INTEGRATIONS_ERROR,
                    payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                });
                reject();
            });
        });
    };
};

/* Salesforce Integration */

export const connectSalesforce = (code, redirect_uri) => {

    return (dispatch) => {
        dispatch({type: SALESFORCE_CONNECT_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/integrations/salesforce`, { code: code, redirect_uri: redirect_uri})
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: SALESFORCE_CONNECT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const disconnectSalesforce = () => {

    return (dispatch) => {
        dispatch({type: SALESFORCE_DISCONNECT_PENDING});
        return new Promise((resolve, reject) => {
            return client.delete(`/provider/integrations/salesforce`)
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: SALESFORCE_DISCONNECT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const syncSalesforce = () => {

    return (dispatch) => {
        dispatch({type: SALESFORCE_SYNC_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/integrations/salesforce/sync`)
                .then(response => {
                    dispatch({ type: SALESFORCE_SYNC_SUCCESS});
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: SALESFORCE_SYNC_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                })
        });
    }

}

export const confirmSalesforceObjects = () => {

    return (dispatch) => {
        dispatch({type: SALESFORCE_FETCH_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/integrations/salesforce/confirm`)
                .then(response => {
                    dispatch({
                        type: SALESFORCE_FETCH_SUCCESS,
                    });
                    resolve(response.data);
                })
                .catch((err) => {
                    dispatch({
                        type: SALESFORCE_FETCH_ERROR,
                    });
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                })
        });
    }

};

export const deployDigsObjectsPackage = () => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            client.post(`/provider/integrations/salesforce/deploy`)
                .then(response => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                })
        });
    }

};

export const saveSalesforceConfig = (config) => {

    return (dispatch) => {
        dispatch({type: SALESFORCE_CONFIG_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/integrations/salesforce/config`, config)
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: SALESFORCE_CONFIG_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

/* Follow Up Boss Integration */
export const connectFUB = (apiKey, defaultConfiguration = null) => {

    return (dispatch) => {
        dispatch({type: FUB_CONNECT_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/integrations/fub`, { api_key: apiKey, config: defaultConfiguration })
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: FUB_CONNECT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const disconnectFUB = () => {

    return (dispatch) => {
        dispatch({type: FUB_DISCONNECT_PENDING});
        return new Promise((resolve, reject) => {
            return client.delete(`/provider/integrations/fub`)
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: FUB_DISCONNECT_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const fetchFUB = () => {

    return (dispatch) => {
        dispatch({type: FUB_FETCH_PENDING});
        return new Promise((resolve, reject) => {
            client.get(`/provider/integrations/fub/fetch`)
                .then(response => {
                    dispatch({
                        type: FUB_FETCH_SUCCESS,
                    });
                    resolve(response.data);
                })
                .catch((err) => {
                    dispatch({
                        type: FUB_FETCH_ERROR,
                    });
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                })
        });
    }

}

export const saveFUBConfig = (config) => {

    return (dispatch) => {
        dispatch({type: FUB_CONFIG_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/integrations/fub/config`, config)
                .then(response => {
                    dispatch({ type: FETCH_INTEGRATIONS_SUCCESS, payload: response.data.integrations });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: FUB_CONFIG_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}

export const syncFUB = () => {

    return (dispatch) => {
        dispatch({type: FUB_SYNC_PENDING});
        return new Promise((resolve, reject) => {
            client.post(`/provider/integrations/fub/sync`)
                .then(response => {
                    dispatch({ type: FUB_SYNC_SUCCESS });
                    resolve();
                })
                .catch((err) => {
                    dispatch({
                        type: FUB_SYNC_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject();
                })
        });
    }

}
