import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, Icon, Modal, Row} from 'antd'
import {connect} from 'react-redux';
import * as integrationsActions from "../../actions/integrations";
import {integrationsSelector} from "../../reducers/integrations";

class IntegrationsGrid extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            makeRequestModal: false,
        }
    }

    componentDidMount() {
        this.props.fetchIntegrations();
    }

    generateStatus(name) {
        const { integrations } = this.props;
        let status = false;
        if(integrations && integrations.length > 0) {
            const search = integrations.find(integration => integration.service === name);
            if (search && search.is_active) {
                status = true;
            }
        }
        return status ? (<div className="integrations-list--label active">Active</div>) : (<div className="integrations-list--label inactive">Inactive</div>);
    }

    render() {

        const { history } = this.props;
        const gridSize = this.props.grid ? 24 / this.props.grid : 6;

        return (
            <Row gutter={20} type="flex" className={'integrations-list'}>
                <Col xs={24} sm={12} md={8} xl={gridSize}>
                    <Card onClick={() => { history.push('/my-app/integrations/zapier')}}>
                        <img src="/images/integrations/zapier-logo.png" alt=""/>
                        <p>Digs integrates with Zapier to connect with over 1500 technology services. Signup with Zapier today.</p>
                        {
                            this.generateStatus('zapier')
                        }
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} xl={gridSize}>
                    <Card onClick={() => { history.push('/my-app/integrations/follow-up-boss')}}>
                        <img src="/images/integrations/fub-logo.png"  alt=""/>
                        <p>Connect to your Follow Up Boss account to sync prospects and collect more data about the people in your funnel.</p>
                        {
                            this.generateStatus('followupboss')
                        }
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} xl={gridSize}>
                    <Card onClick={() => { history.push('/my-app/integrations/salesforce')}}>
                        <img src="/images/integrations/salesforce-logo.png" alt=""/>
                        <p>Connect to your Salesforce account to sync prospects and collect more data about the people in your funnel.</p>
                        <div className="integrations-list--label soon">Coming Soon</div>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} xl={gridSize}>
                    <Card onClick={() => { history.push('/my-app/integrations/surefire')}}>
                        <img src="/images/integrations/surefire-logo.png" alt=""/>
                        <p>Connect to Surefire account to sync prospects and collect more data about the people in your funnel.</p>
                        <div className="integrations-list--label soon">Coming Soon</div>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} xl={gridSize}>
                    <Card onClick={() => { history.push('/my-app/integrations/velocify')}}>
                        <img src="/images/integrations/velocify-logo.png" alt=""/>
                        <p>Connect to your Velocify account to sync prospects and collect more data about the people in your funnel.</p>
                        <div className="integrations-list--label soon">Coming Soon</div>
                    </Card>
                </Col>
                {
                    !this.props.minimal &&
                    <Col xs={24} sm={12} md={8} xl={6}>
                        <Card className={'empty'} onClick={ () => { this.setState({ makeRequestModal: true}) } }>
                            <Icon type="plus-circle" theme="filled" />
                            <p>Make a request</p>
                        </Card>
                        <Modal
                            visible={this.state.makeRequestModal}
                            onCancel={ () => { this.setState({ makeRequestModal: false}) } }
                            title={'Missing an integration?'}
                            footer={
                                [
                                    <Button key="back" onClick={ () => { this.setState({ makeRequestModal: false}) } }>
                                        No, thanks.
                                    </Button>,
                                    <Button href="https://digs.co/contact" target="_blank" key="submit" type="primary" style={{marginLeft: 10}}>
                                        Contact Us
                                    </Button>,
                                ]
                            }
                        >
                            <p>Are you looking for a way to connect Digs with your company's CRM but don't see it listed here? Maybe you just have an idea for how we can make your life easier? Either way we'd love to hear from you.</p>
                        </Modal>
                    </Col>
                }

            </Row>
        );
    }

}


const mapStateToProps = (state) => ({
    integrations: integrationsSelector(state),
    action: state.integrations.action,
    user: state.user.user,
});

const mapDispatchToProps = {
    ...integrationsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IntegrationsGrid));
