import React, {Component} from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import {Steps} from "antd";

/* Onboarding Steps */
import Headshot from '../../components/onboarding/profile/Headshot';
import Logo from '../../components/onboarding/profile/Logo';
import Biography from '../../components/onboarding/profile/Biography';

const { Step } = Steps;

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
        };

        this.onboardingSteps = [
            'headshot',
            'logo',
            'bio'
        ];

    }

    componentDidMount() {
        this.loadOnboarding();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const { match } = this.props;

        if(match.params.step !== prevProps.match.params.step) {
            this.loadOnboarding();
        }

    }

    loadOnboarding = () => {

        const { match } = this.props;

        if(match.params.step) {

            const index = this.onboardingSteps.findIndex(step => step === match.params.step);

            if(index > -1) {
                this.setState({
                    currentStep: index,
                })
            }

        }

    };

    render() {

        return (
            <div className={'page-onboarding'}>
                <h1>Customize App</h1>
                <div className={`steps step-${this.state.currentStep}`} >
                    <Steps current={0} labelPlacement={'vertical'}>
                        <Step title="Customize App" />
                        <Step title="Import Contacts" />
                    </Steps>
                </div>
                <Switch>
                    <Redirect exact from='/onboarding/profile' to='/onboarding/profile/headshot'/>
                    <Route exact path={'/onboarding/profile/headshot'} component={Headshot} />
                    <Route exact path={'/onboarding/profile/logo'} component={Logo} />
                    <Route exact path={'/onboarding/profile/bio'} component={Biography} />
                </Switch>
            </div>
        );
    }

}

export default Profile;
