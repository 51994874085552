import React, {Component} from "react";
import {Button, Card} from "antd";

class RemaxModule extends Component {

    render() {
        return (
            <div>
                <Card
                    title={'Subscription'}
                    className={'subscription'}
                >
                    <div className={'subscription-details'}>
                        <ul>
                            <li><b>Status:</b> <span className={'badge'}>Active</span></li>
                            <li><b>Plan:</b> RE/MAX Marketplace Agent</li>
                        </ul>
                        <p>To manage your billing and/or subscription settings, please visit the  <a href="https://marketplace.remax.com/apps">app settings page</a> within the RE/MAX Marketplace.</p>
                    </div>
                </Card>
            </div>
        );
    }
}

export default RemaxModule;
