import {
    FETCH_REWARDS_PENDING,
    FETCH_REWARDS_SUCCESS,
    FETCH_REWARDS_ERROR,
    UPDATE_REWARDS_PENDING,
    UPDATE_REWARDS_SUCCESS,
    UPDATE_REWARDS_ERROR,
} from "./types";

import client from './';

export const fetchRewards = () => {

    return (dispatch) => {
        dispatch({type: FETCH_REWARDS_PENDING});

        return new Promise((resolve, reject) => {
            client.get(`/provider/rewards`)
                .then(({ data: { config }}) => {
                    dispatch({type: FETCH_REWARDS_SUCCESS, payload: config});
                    resolve(config);
                })
                .catch((err) => {
                    dispatch({
                        type: FETCH_REWARDS_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                })
        });
    }

};

export const saveRewards = (data) => {

    return (dispatch) => {
        dispatch({type: UPDATE_REWARDS_PENDING});
        return new Promise((resolve, reject) => {
            return client.post(`/provider/rewards`, data)
                .then(({ data: { config }}) => {
                    dispatch({type: UPDATE_REWARDS_SUCCESS, payload: config});
                    resolve(config);
                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_REWARDS_ERROR,
                        payload: err.response.data.errors ? err.response.data.errors : err.response.data.message
                    });
                    reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                })
        });
    }

};
