import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as userActions from "../actions/user";
import {Col, Divider, Icon, Menu, Row} from "antd";
import {Link, withRouter, Switch, Route, Redirect } from "react-router-dom";
import Security from "../components/settings/Security";
import Notifications from "../components/settings/Notifications";
import Billing from "../components/settings/Billing/Index";

class Settings extends Component {

    render() {

        const {pathname} = this.props.location;
        const { user } = this.props;

        return (
            <div className="page page-settings">
                <Row>
                    <Col xs={24} xl={16}>
                        <div className={'title-module'}>
                            <h1><Icon type="setting" /> Account Settings</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Divider />
                </Row>
                <div className="primary-container">
                    <div className="menu-container">
                        <Menu
                            onClick={this.handleClick}
                            style={{ width: '100%' }}
                            selectedKeys={[pathname]}
                            mode="inline"
                        >
                            <Menu.Item key="/settings/security">
                                <Link to="/settings/security">
                                    <span>Security</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/settings/notifications">
                                <Link to="/settings/notifications">
                                    <span>Notifications</span>
                                </Link>
                            </Menu.Item>
                            {
                                user.active_subscription &&
                                user.active_subscription.type !== 'EXTERNAL_PAYMENT' &&
                                <Menu.Item key="/settings/billing">
                                    <Link to="/settings/billing">
                                        <span>Billing</span>
                                    </Link>
                                </Menu.Item>
                            }
                        </Menu>
                    </div>
                    <div className="info-right">
                        <Switch>
                            <Route exact path="/settings/security" component={Security} />
                            <Route exact path="/settings/notifications" component={Notifications} />
                            <Route exact path="/settings/billing" component={Billing} />
                            <Redirect to={'/settings'} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user: user.user });
const mapDispatchToProps = {
    ...userActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));
